import type { FieldTagElement, ObjectType } from "@brm/schema-types/types.js"
import { Transforms } from "slate"
import type { CustomEditor } from "../types.js"

/** Field tagging support */
export const withFieldTags = (editor: CustomEditor) => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = (element) => {
    return element.type === "field-tag" || isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === "field-tag" || isVoid(element)
  }

  editor.markableVoid = (element) => {
    return element.type === "field-tag" || markableVoid(element)
  }

  return editor
}

export const insertFieldTag = (editor: CustomEditor, fieldName: string, objectType: ObjectType, isCustom: boolean) => {
  const fieldTag: FieldTagElement = {
    type: "field-tag",
    children: [{ text: "" }],
    field_name: fieldName,
    object_type: objectType,
    is_custom: isCustom,
  }
  Transforms.insertNodes(editor, fieldTag)
  Transforms.move(editor)
  Transforms.insertText(editor, " ")
  Transforms.move(editor)
}
