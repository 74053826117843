import type { GridProps } from "@chakra-ui/react"
import { Grid, GridItem, Spacer } from "@chakra-ui/react"
import type { ReactNode } from "react"
import FullTextSearch from "../features/search/FullTextSearch.js"
import { PAGE_PADDING_X, PAGE_PADDING_Y } from "../util/constant.js"
import { logoHeaderBoxSize } from "./constants.js"

interface Props extends GridProps {
  /** logoElement should always have a boxSize of the logoHeaderBoxSize constant */
  logoElement: ReactNode
  heading: ReactNode

  /**
   * @default true
   */
  showSearch?: boolean

  rightActions?: ReactNode
  children?: ReactNode
}

export const LogoHeader = ({
  logoElement,
  heading,
  rightActions,
  showSearch = true,
  children,
  ...gridProps
}: Props) => {
  return (
    <Grid
      gridTemplateColumns={`${logoHeaderBoxSize * 4}px 1fr minmax(200px, 320px)`}
      columnGap={2}
      rowGap={1}
      alignItems="center"
      px={PAGE_PADDING_X}
      py={PAGE_PADDING_Y}
      {...gridProps}
    >
      <GridItem height={logoHeaderBoxSize}>{logoElement}</GridItem>
      {/* minW 0 to allow heading to shrink so that it never overflows the grid */}
      <GridItem minW={0}>{heading}</GridItem>
      {showSearch && (
        <GridItem display="flex" gap={2} justifyContent="flex-end" height="full">
          <FullTextSearch />
        </GridItem>
      )}
      <GridItem colStart={2} colSpan={2} minW={0} display="flex" gap={2}>
        {children}
        <Spacer />
        {rightActions}
      </GridItem>
    </Grid>
  )
}
