import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/react"
import { useRef, type FC } from "react"
import { FormattedMessage } from "react-intl"
import Button from "../../../components/Button/Button.js"
import { ButtonStyles } from "../../../components/Button/types.js"
import { useIntl } from "react-intl"

interface TaskSnoozeModalProps {
  isOpen: boolean
  onClose: () => void
  taskDisplayName: string
  onDismiss: () => void
}
const TaskSnoozeModal: FC<TaskSnoozeModalProps> = ({ isOpen, onClose, taskDisplayName, onDismiss }) => {
  const cancelDismissRef = useRef<HTMLButtonElement>(null)
  const intl = useIntl()

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelDismissRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            <FormattedMessage
              id="task.dismiss.modal.header"
              description="Modal header for dismissing a task confirmation"
              defaultMessage="Dismiss Task"
            />
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormattedMessage
              id="inbox.task.dismiss.modal.body"
              description="Text shown in modal confirming that user wants to dismiss a task"
              defaultMessage="Are you sure you want to dismiss <strong>{name}</strong>? Dismissing the task means you will get no more reminders about it and it will not come back. Consider snoozing if you only want it gone temporarily."
              values={{
                name: taskDisplayName,
                strong: (content) => <strong>{content}</strong>,
              }}
            />
          </AlertDialogBody>
          <AlertDialogFooter gap={2}>
            <Button
              ref={cancelDismissRef}
              onClick={onClose}
              buttonStyles={ButtonStyles.OutlinedGray}
              label={intl.formatMessage({
                id: "task.dismiss.modal.cancel_button",
                description: "Cancel button label in the task dismiss confirmation modal",
                defaultMessage: "Cancel",
              })}
              size="md"
            />
            <Button
              ref={cancelDismissRef}
              onClick={onDismiss}
              buttonStyles={ButtonStyles.SolidDestructive}
              label={intl.formatMessage({
                id: "task.dismiss.modal.dismiss_button",
                description: "Dismiss button label in the task dismiss confirmation modal",
                defaultMessage: "Dismiss",
              })}
              size="md"
            />
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default TaskSnoozeModal
