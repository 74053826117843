import type { FieldTagElement } from "@brm/schema-types/types.js"
import { getSchemaAtPath, getTitle } from "@brm/util/schema.js"
import { Skeleton, chakra, type ChakraProps } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { useFocused, useSelected, type RenderElementProps } from "slate-react"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { useObjectSchema } from "../../util/use-schema.js"

export const FieldTag = ({
  attributes,
  children,
  element,
}: RenderElementProps &
  GetLogoForOrganizationProps & {
    element: FieldTagElement
  }) => {
  const objectSchema = useObjectSchema(element.object_type)

  const selected = useSelected()
  const focused = useFocused()
  const spanStyle: ChakraProps = {
    padding: "0 2px",
    margin: "0 1px",
    verticalAlign: "baseline",
    display: "inline-block",
    borderRadius: "4px",
    backgroundColor: "blueLight.50",
    borderWidth: "1px",
    borderColor: "blueLight.200",
    fontSize: "0.9em",
    boxShadow: selected && focused ? "0 0 0 2px var(--chakra-colors-blue-200)" : "none",
  }
  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0]?.bold) {
    spanStyle.fontWeight = "bold"
  }
  if (element.children[0]?.italic) {
    spanStyle.fontStyle = "italic"
  }

  if (!objectSchema) {
    return <Skeleton as="span" width="20ch" />
  }

  const fieldSchema = getSchemaAtPath(objectSchema, [element.field_name])

  return (
    <chakra.span {...attributes} contentEditable={false} data-cy={`fieldTag-${element.field_name}`} {...spanStyle}>
      {getTitle(element.field_name, fieldSchema)}{" "}
      <FormattedMessage
        id="fieldTag.objectType"
        description="Object type indicator on a field tag"
        defaultMessage="({objectType})"
        values={{ objectType: getTitle(element.object_type, objectSchema) }}
      />
      {children}
    </chakra.span>
  )
}
