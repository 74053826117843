import { Flex, Heading, Text } from "@chakra-ui/react"
import type { FunctionComponent } from "react"
import FullTextSearch from "../search/FullTextSearch.js"

export const SettingsHeader: FunctionComponent<{ title: string; subtitle?: string }> = ({ title, subtitle }) => (
  <Flex gap={2} mb={4}>
    <FullTextSearch order={2} ml="auto" />
    <Heading as="h1" size="sm">
      {title}
      {subtitle && (
        <Text fontSize="sm" color="gray.600" fontWeight="light">
          {subtitle}
        </Text>
      )}
    </Heading>
  </Flex>
)
