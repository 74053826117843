import { Text } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { Link } from "../Link.js"

interface Props {
  emptyState: string
}

export default function EmptyTableState({ emptyState }: Props) {
  return (
    <Text>
      <FormattedMessage
        id="table.emptyState.action"
        description="Helper message to direct users to their integrations"
        defaultMessage="{emptyState}. <integrationsLink>Manage your integrations</integrationsLink>"
        values={{
          emptyState,
          integrationsLink: (parts) => (
            <Link colorScheme="brand" to="/settings/integrations">
              {parts}
            </Link>
          ),
        }}
      />
    </Text>
  )
}
