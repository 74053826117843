import type { IntegrationSummary } from "@brm/schema-types/types.js"
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  Input,
  useToast,
} from "@chakra-ui/react"
import { useCallback, useEffect, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDeleteIntegrationV1ByIdMutation } from "../../app/services/generated-api.js"

export function DeleteIntegrationConfirmationModal({
  integrationBeingDeleted,
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
  integrationBeingDeleted: IntegrationSummary
}) {
  const intl = useIntl()
  const toast = useToast()

  const [deleteIntegration, deleteIntegrationResult] = useDeleteIntegrationV1ByIdMutation()
  const cancelDeleteIntegrationRef = useRef<HTMLButtonElement>(null)
  const [confirm, setConfirm] = useState("")

  const confirmDataDelete =
    deleteIntegrationResult.isError &&
    ("status" in deleteIntegrationResult.error ? deleteIntegrationResult.error.status === 409 : true)

  const closeModal = useCallback(() => {
    deleteIntegrationResult.reset()
    onClose()
  }, [deleteIntegrationResult, onClose])

  useEffect(() => {
    if (deleteIntegrationResult.isSuccess) {
      toast({
        description: intl.formatMessage({
          id: "organization.deleteIntegration.toastMsg.success",
          description: "Toast success message when deleting an integration",
          defaultMessage: "Integration queued for deletion!",
        }),
        status: "success",
      })
      closeModal()
    } else if (
      deleteIntegrationResult.isError &&
      ("status" in deleteIntegrationResult.error ? deleteIntegrationResult.error.status !== 409 : true)
    ) {
      toast({
        description: intl.formatMessage({
          id: "organization.deleteIntegration.toastMsg.failure",
          description: "Toast failure message when deleting a integration",
          defaultMessage: "Failed to delete integration",
        }),
        status: "error",
      })
      deleteIntegrationResult.reset()
      closeModal()
    }
  }, [
    deleteIntegrationResult.isSuccess,
    deleteIntegrationResult.isError,
    deleteIntegrationResult,
    toast,
    intl,
    closeModal,
  ])

  return (
    <AlertDialog isOpen={isOpen} onClose={closeModal} leastDestructiveRef={cancelDeleteIntegrationRef}>
      <AlertDialogOverlay>
        {integrationBeingDeleted && (
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <FormattedMessage
                id="organization.view.integration.delete.confirm.header"
                description="Modal header for delete integration confirmation"
                defaultMessage="Disconnect Integration"
              />
            </AlertDialogHeader>

            <AlertDialogBody>
              {confirmDataDelete ? (
                <>
                  <Alert status="warning">
                    <AlertIcon />
                    <FormattedMessage
                      id="organization.view.integration.delete.confirm.body.data"
                      description="Confirmation text for deleting integration and data, should sound scary"
                      defaultMessage="There is existing data associated with the integration {name}. If you still want to proceed, type DELETE DATA below. The data deletion can not be undone."
                      values={{
                        name: integrationBeingDeleted.display_name,
                      }}
                    />
                  </Alert>
                  <Input
                    mt={4}
                    onChange={(e) => {
                      setConfirm(e.target.value)
                    }}
                  />
                </>
              ) : integrationBeingDeleted.provider === "gmail_oauth" ? (
                <FormattedMessage
                  id="organization.view.gmail.integration.delete.confirm.body"
                  description="Body text of the gmail integration deletion confirmation modal"
                  defaultMessage="Are you sure you want to disconnect automagical contract extraction from the email <strong>{email}</strong>? Your unverified gmail agreements will be deleted."
                  values={{
                    email: integrationBeingDeleted.email,
                    strong: (content) => <strong>{content}</strong>,
                  }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Are you sure you want to disconnect <strong>{name}</strong>? You will lose automagical abilities, and at the least cost your team time, and money!"
                  id="organization.view.integration.delete.confirm.body"
                  description="Body text of the integration deletion confirmation modal"
                  values={{
                    name: integrationBeingDeleted.display_name,
                    strong: (content) => <strong>{content}</strong>,
                  }}
                />
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelDeleteIntegrationRef} onClick={closeModal}>
                <FormattedMessage
                  id="organization.view.integration.delete.confirm.keep"
                  description="Button text for keeping integration in the delete integration confirmation modal."
                  defaultMessage="Keep"
                />
              </Button>

              <Button
                colorScheme="error"
                isLoading={deleteIntegrationResult.isLoading}
                isDisabled={confirmDataDelete && confirm !== "DELETE DATA"}
                onClick={async () => {
                  await deleteIntegration({
                    id: integrationBeingDeleted.id,
                    data: confirmDataDelete ? "DELETE" : undefined,
                  })
                }}
                ml={3}
              >
                {confirmDataDelete ? (
                  <FormattedMessage
                    id="organization.view.integration.delete.confirm.delete"
                    description="Button text for deleting integration in the delete integration confirmation modal."
                    defaultMessage="Delete Data"
                  />
                ) : (
                  <FormattedMessage
                    id="organization.view.integration.delete.confirm.delete-data"
                    description="Button text for deleting integration and data in the delete integration confirmation modal."
                    defaultMessage="Disconnect"
                  />
                )}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        )}
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
