import type { SquareProps } from "@chakra-ui/react"
import { Circle } from "@chakra-ui/react"
import type { ReactElement } from "react"

interface Props extends SquareProps {
  icon: ReactElement
}

/**
 * Untitled UI icon component. Renders an icon in the center of concentric circles with varying background colors
 */
export default function FeaturedIcon({ icon, ...rest }: Props) {
  return (
    <Circle boxSize={8} borderWidth={4} borderColor="gray.100" borderStyle="solid" backgroundColor="gray.200" {...rest}>
      {icon}
    </Circle>
  )
}
