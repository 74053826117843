import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { Stack, Text } from "@chakra-ui/react"
import { useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { isNotUndefined } from "typed-assert"
import type { DocumentListItem } from "../../app/services/generated-api.js"
import { PAGE_PADDING_X, PAGE_PADDING_Y } from "../../util/constant.js"
import { shownColumnsForTableParamState, TABLE_DEFAULT_PARAMS, TABLE_INITIAL_PAGE } from "../../util/schema-table.js"
import { getTabIndexFromLocation, type FilterTabData } from "../../util/tabs.js"
import { useObjectSchema } from "../../util/use-schema.js"
import DataTable from "../DataTable/DataTable.js"
import TablePageHeader from "../DataTable/SchemaFilter/TablePageHeader.js"
import { ROOT_COLUMN_ID, useSchemaColumns } from "../DataTable/use-schema-columns.js"
import { useLocalStorageTableParamsSync, useUrlTableParams } from "../DataTable/use-schema-table-params.js"
import { DOCUMENTS_TABLE_ID } from "./util.js"

const primaryColumn = ROOT_COLUMN_ID
const defaultColumns: BRMPaths<DocumentListItem>[] = ["field_category", "uploaded_by", "document_relation_created_at"]

export default function DocumentsPanel({ documents }: { documents: DocumentListItem[] }) {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()

  useLocalStorageTableParamsSync(DOCUMENTS_TABLE_ID)

  const documentSchema = useObjectSchema("Document")
  const { tableParams, getUpdatedSerializedParams, updateTableParams } = useUrlTableParams<string>({
    defaultParams: TABLE_DEFAULT_PARAMS,
    objectSchema: documentSchema,
    savedViews: [],
  })

  const shownColumns = useMemo(
    () => tableParams && shownColumnsForTableParamState(tableParams, defaultColumns),
    [tableParams]
  )
  const columns = useSchemaColumns<DocumentListItem>({ objectSchema: documentSchema, shownColumns, primaryColumn })

  const pendingDocuments = documents.filter((doc) => !doc.finalized_at)
  const tabData = [documents, pendingDocuments]
  const tabConfig: FilterTabData[] = [
    {
      locationHash: "",
      label: (
        <FormattedMessage
          id="documents.tab.all"
          description="Tab label to show documents that are currently uploaded to a field"
          defaultMessage="Current"
        />
      ),
    },
    ...(pendingDocuments.length > 0
      ? [
          {
            locationHash: "pending",
            label: (
              <FormattedMessage
                id="documents.tab.pending"
                description="Tab label to show documents that are not yet confirmed to the vendor"
                defaultMessage="Pending"
              />
            ),
          } satisfies FilterTabData,
        ]
      : []),
  ]

  const [tabIndex, setTabIndex] = useState(getTabIndexFromLocation(location, tabConfig))

  const data = tabData[tabIndex]

  if (!tableParams || !documentSchema || !shownColumns || !columns || !getUpdatedSerializedParams || !data) {
    return null
  }

  const handleChangeTab = (index: number) => {
    const newTab = tabConfig[index]
    isNotUndefined(newTab)
    const hashParams = new URLSearchParams(location.hash.slice(1))
    if (newTab.locationHash) {
      hashParams.set("tab", newTab.locationHash)
    } else {
      hashParams.delete("tab")
    }
    navigate(
      { search: getUpdatedSerializedParams({ page: TABLE_INITIAL_PAGE }).toString(), hash: `#${hashParams}` },
      { replace: true }
    )
    setTabIndex(index)
  }

  return (
    <Stack width="full" gap={4} paddingX={PAGE_PADDING_X} paddingY={PAGE_PADDING_Y}>
      <TablePageHeader
        tableId={DOCUMENTS_TABLE_ID}
        title={
          tabConfig.length > 1
            ? undefined
            : intl.formatMessage(
                {
                  id: "documents.title",
                  description: "Title for the documents panel",
                  defaultMessage: "Documents ({count})",
                },
                { count: documents.length }
              )
        }
        tabProps={
          tabConfig.length > 1
            ? {
                data: tabConfig.map((tab, idx) => ({ ...tab, total: tabData[idx]?.length })),
                handleChangeTab,
                selectedIndex: tabIndex,
              }
            : undefined
        }
        filterMap={tableParams.filterMap}
        onChangeFilters={(filterMap) => updateTableParams?.({ filterMap })}
        objectSchema={documentSchema}
        selectedColumns={shownColumns}
        hideShowAllFilters={true}
      />
      {documents.length > 0 ? (
        <DataTable
          borderWidth="1px"
          overflow="auto"
          data={data}
          columns={columns}
          sorting={tableParams.sorting}
          onSortingChange={(newSorting) =>
            newSorting instanceof Function
              ? updateTableParams?.({ sorting: newSorting(tableParams.sorting) })
              : updateTableParams?.({ sorting: newSorting })
          }
          paginationProps={{
            page: tableParams.page,
            pageSize: tableParams.pageSize,
            onPageChange: (page) => updateTableParams?.({ page }),
            onPageSizeChange: (pageSize) => updateTableParams?.({ pageSize }),
            totalListElements: documents.length,
          }}
        />
      ) : (
        tableParams.filterMap.size === 0 && (
          <Text>
            <FormattedMessage
              id="documents.emptyState.action"
              description="What the user sees when no documents are uploaded yet"
              defaultMessage="No documents uploaded yet"
            />
          </Text>
        )
      )}
    </Stack>
  )
}
