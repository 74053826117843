import { hasPermission } from "@brm/schema-helpers/role.js"
import { Flags } from "@brm/util/flags.js"
import { Box, Button, HStack, Icon, Stack, Text } from "@chakra-ui/react"
import { useFlags } from "launchdarkly-react-client-sdk"
import { FormattedMessage } from "react-intl"
import {
  useGetUserV1WhoamiQuery,
  useGetWorkflowV1DefinitionsPurchaseQuery,
  useGetWorkflowV1DefinitionsRenewalQuery,
  type WorkflowKind,
} from "../../../../app/services/generated-api.js"
import { NextIcon } from "../../../../components/icons/icons.js"
import { Link } from "../../../../components/Link.js"

export default function WorkflowKindSection({
  startWorkflow,
  closeModal,
}: {
  startWorkflow: (workflowKind: WorkflowKind) => void
  closeModal: () => void
}) {
  const { [Flags.DATA_GATHER_REQUEST_FLAG]: dataGatheringEnabled } = useFlags()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { error: renewalError, isLoading: isRenewalLoading } = useGetWorkflowV1DefinitionsRenewalQuery()
  const { error: purchaseError, isLoading: isPurchaseLoading } = useGetWorkflowV1DefinitionsPurchaseQuery()
  const missingPurchaseConfig = purchaseError && "status" in purchaseError && purchaseError.status === 404
  const missingRenewalConfig = renewalError && "status" in renewalError && renewalError.status === 404

  let missing: JSX.Element | null = null
  if (!isPurchaseLoading) {
    if (missingRenewalConfig && missingPurchaseConfig) {
      missing = (
        <FormattedMessage
          id="request.start.config.cta.requests"
          description="Link text shown to user when they have no request types configured. Clicking this will navigate them to the request configuration page"
          defaultMessage="requests"
        />
      )
    } else if (missingPurchaseConfig) {
      missing = (
        <FormattedMessage
          id="request.start.config.cta.purchase"
          description="Link text shown to user when they have no purchase request types configured. Clicking this will navigate them to the request configuration page"
          defaultMessage="new purchases"
        />
      )
    } else if (missingRenewalConfig) {
      missing = (
        <FormattedMessage
          id="request.start.config.cta.renewal"
          description="Link text shown to user when they have no renewal request types configured. Clicking this will navigate them to the request configuration page"
          defaultMessage="renewals"
        />
      )
    }
  }

  return (
    <Stack gap={4}>
      <Text>
        <FormattedMessage
          id="requests.start.modal.greeting"
          description="Greeting text of the start request modal prompting user for input"
          defaultMessage="Hi {user}, what would you like to request?"
          values={{
            user: whoami?.first_name,
          }}
        />
      </Text>
      <HStack alignItems="center">
        <Button
          onClick={() => {
            startWorkflow("purchase")
          }}
          variant="hoverOutline"
          isDisabled={missingPurchaseConfig}
          width="full"
          justifyContent="start"
        >
          <FormattedMessage
            id="request.start.modal.kind.purchase"
            defaultMessage="New purchase"
            description="Request start modal button option to request to purchase a tool"
          />
        </Button>
      </HStack>
      <HStack alignItems="center">
        <Button
          onClick={() => {
            startWorkflow("renewal")
          }}
          variant="hoverOutline"
          isDisabled={missingRenewalConfig}
          width="full"
          justifyContent="start"
        >
          <FormattedMessage
            id="request.start.modal.kind.renewal.default"
            defaultMessage="Renewal"
            description="Request start button option to request a renewal"
          />
        </Button>
      </HStack>
      {dataGatheringEnabled && (
        <HStack alignItems="center">
          <Button
            onClick={() => {
              startWorkflow("gather_data")
            }}
            variant="hoverOutline"
            width="full"
            justifyContent="start"
          >
            <FormattedMessage
              id="request.start.modal.kind.gather_data"
              defaultMessage="Gather compliance data"
              description="Request start button option to request to gather compliance data"
            />
          </Button>
        </HStack>
      )}
      {!isRenewalLoading && !isPurchaseLoading && missing ? (
        <Box mt={6} fontSize="sm">
          {hasPermission(whoami?.roles, "workflow_def:create") ? (
            <Link
              to="/settings/requests"
              onClick={closeModal}
              variant="highlighted"
              display="flex"
              gap={0.5}
              alignItems="center"
            >
              <FormattedMessage
                id="request.start.config.cta"
                description="Link text shown to user when they have no request types configured. Clicking this will navigate them to the request configuration page"
                defaultMessage="Your organization hasn’t configured {missing} yet. Get started."
                values={{ missing }}
              />
              <Icon as={NextIcon} />
            </Link>
          ) : (
            <Text>
              <FormattedMessage
                id="request.start.config.no_permission"
                description="Message shown to user when no request types have been configured"
                defaultMessage="Your organization hasn’t configured {missing} yet. Please contact an administrator to get started."
                values={{ missing }}
              />
            </Text>
          )}
        </Box>
      ) : null}
    </Stack>
  )
}
