import type { ObjectType } from "@brm/schema-types/types.js"
import type { Filter } from "@brm/type-helpers/filters.js"
import { getSchemaAtPath, isNullableSchema, unwrapNullableSchema } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import { Button, HStack, Icon } from "@chakra-ui/react"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { FormattedMessage } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { isNotUndefined } from "typed-assert"
import { XIcon } from "../../icons/icons.js"
import AppliedFilter from "./AppliedFilter.js"
import { FieldSchemaIcon } from "./FieldSchemaIcon.js"
import { displayPathFromFilterPath } from "./util.js"

export interface FilterRowProps {
  objectSchema: ReadonlyDeep<JSONSchema>
  filterMap: ReadonlyMap<string, Filter>
  pickableFilters?: Partial<Record<ObjectType, object>>
  onChange: (updatedFilterMap: ReadonlyMap<string, Filter>) => void
}

/**
 * Filter Row renders a {@link AppliedFilter} for each filter that is applied.
 */
export default function AppliedFiltersRow({ objectSchema, filterMap, onChange, pickableFilters }: FilterRowProps) {
  return (
    <HStack wrap="wrap" background="white">
      {Array.from(filterMap, ([filterPathStr, filter]) => {
        const filterPathArray = filterPathStr.split(".").filter(Boolean)

        const displayPath = displayPathFromFilterPath(filterPathArray)
        const fieldSchema = getSchemaAtPath(objectSchema, displayPath, false)
        const fieldName = displayPath.at(-1)
        isNotUndefined(fieldName, `Got empty display path for filter path ${filterPathStr}`)
        isNotUndefined(fieldSchema, `Did not find schema of ${displayPath.join(".")}`)

        if (!isObject(fieldSchema)) {
          return null
        }

        const unwrappedFieldSchema = unwrapNullableSchema(fieldSchema)
        const isNullable = isNullableSchema(fieldSchema)

        return (
          <AppliedFilter
            key={filterPathStr}
            displayPath={displayPath}
            fieldSchema={unwrappedFieldSchema}
            isNullable={isNullable}
            objectSchema={objectSchema}
            icon={<Icon as={FieldSchemaIcon} fieldSchema={fieldSchema} displayPath={displayPath} />}
            filter={filter.fields}
            onChange={(updatedFilter) => {
              const copy = new Map(filterMap)
              copy.set(filterPathStr, { column: filterPathStr, fields: updatedFilter })
              onChange(copy)
            }}
            onRemove={() => {
              const copy = new Map(filterMap)
              copy.delete(filterPathStr)
              onChange(copy)
            }}
            pickableFilters={pickableFilters}
          />
        )
      })}
      {filterMap.size > 0 && (
        <Button
          variant="outline"
          borderStyle="dashed"
          fontWeight="normal"
          rightIcon={<Icon as={XIcon} />}
          onClick={() => onChange(new Map())}
        >
          <FormattedMessage
            id="tool.list.clearFilters.button"
            description="Title of a button that allows users to clear filters from a table"
            defaultMessage="Clear filters"
          />
        </Button>
      )}
    </HStack>
  )
}
