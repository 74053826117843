import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Flex } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"

/**
 * RichTextError is a fallback component displayed when the RichTextEditor encounters an error.
 * Used as the fallback render prop in ErrorBoundary wrapping RichTextEditor.
 */
export default function RichTextError() {
  return (
    <Alert status="warning" flexDirection="column" alignItems="start">
      <Flex alignItems="center">
        <AlertIcon />
        <AlertTitle>
          <FormattedMessage
            id="richTextError.title"
            defaultMessage="Oops! Something went wrong"
            description="Title shown when the rich text editor encounters an error"
          />
        </AlertTitle>
      </Flex>
      <AlertDescription>
        <FormattedMessage
          id="richTextError.description"
          defaultMessage="Refresh the page and try again. If the problem persists, please <contactSupportLink>contact support</contactSupportLink>."
          description="Description shown when the rich text editor encounters an error"
          values={{
            contactSupportLink: (chunks) => (
              <Button variant="link" onClick={() => window.Pylon?.("show")}>
                {chunks}
              </Button>
            ),
          }}
        />
      </AlertDescription>
    </Alert>
  )
}
