import type {
  IntegrationProvider,
  MergeIntegrationAccountingType,
  MergeIntegrationHRISType,
} from "@brm/schema-types/types.js"
import type { IntlShape } from "react-intl"
import type { TabData } from "../../util/tabs.js"

export interface IntegrationConnectOption {
  name: string
  description: string
  provider: IntegrationProvider

  mergeHrisSource?: MergeIntegrationHRISType
  mergeAccountingSource?: MergeIntegrationAccountingType
}

export const notificationIntegrationConnectOptions: IntegrationConnectOption[] = [
  {
    name: "Slack",
    // TODO add tooltip: With this on, newly invited users that are in the slack org will not need to manually connect their slack account
    description: "Slack user data and notifications",
    provider: "slack_oauth",
  },
]

export const ssoIntegrationConnectOptions: IntegrationConnectOption[] = [
  {
    name: "Google",
    description: "Google SSO logins",
    provider: "google_oauth",
  },
  {
    name: "Okta",
    description: "Okta SSO logins",
    provider: "okta_apikey",
  },
  {
    name: "Okta (OAuth 2.0)",
    description: "Okta SSO logins (Oauth 2.0)",
    provider: "okta_oauth",
  },
]

export const paymentIntegrationConnectOptions: IntegrationConnectOption[] = [
  {
    name: "Brex",
    description: "Brex credit card transactions",
    provider: "brex_oauth",
  },
  {
    name: "Ramp",
    description: "Ramp credit card transactions",
    provider: "ramp_client_creds",
  },
  {
    name: "Mercury",
    description: "Mercury bank transactions",
    provider: "mercury_apikey",
  },
  {
    name: "Plaid",
    description: "Bank and Credit Card Transactions",
    provider: "plaid_access_token",
  },
]

export const accountingIntegrationConnectOptions: IntegrationConnectOption[] = [
  {
    name: "Quickbooks",
    description: "Quickbooks accounting data",
    provider: "quickbooks_oauth",
  },
]

export const hrisLinkTokenIntegrationConnectionOptions: IntegrationConnectOption[] = [
  {
    name: "ADP Workforce Now",
    description: "ADP Workforce Now employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "adp-workforce-now",
  },
  {
    name: "Bamboo HR",
    description: "Bamboo HR employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "bamboohr",
  },
  {
    name: "ChartHop",
    description: "ChartHop employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "charthop",
  },
  {
    name: "Deel",
    description: "Deel employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "deel",
  },
  {
    name: "Gusto",
    description: "Gusto employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "gusto",
  },
  {
    name: "HiBob",
    description: "HiBob employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "hibob",
  },
  {
    name: "Justworks",
    description: "Justworks employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "justworks",
  },
  {
    name: "Namely",
    description: "Namely employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "namely",
  },
  {
    name: "Paylocity",
    description: "Paylocity employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "paylocity",
  },
  {
    name: "Rippling",
    description: "Rippling employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "rippling",
  },
  {
    name: "UKG Pro Workforce Management",
    description: "UKG Pro Workforce Management employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "ukg-pro-workforce-management",
  },
  {
    name: "UKG Pro",
    description: "UKG Pro employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "ukg-pro",
  },
  {
    name: "UKG Ready",
    description: "UKG Ready employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "ukg-ready",
  },
  {
    name: "Workday",
    description: "Workday employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "workday",
  },
  {
    name: "Zenefits",
    description: "Zenefits employee data",
    provider: "merge_hris_link_token",
    mergeHrisSource: "zenefits",
  },
]

export const accountingLinkTokenIntegrationConnectionOptions: IntegrationConnectOption[] = [
  {
    name: "Netsuite",
    description: "Netsuite accounting data",
    provider: "merge_accounting_link_token",
    mergeAccountingSource: "netsuite",
  },
  {
    name: "Sage Intacct",
    description: "Sage Intacct accounting data",
    provider: "merge_accounting_link_token",
    mergeAccountingSource: "sage-intacct",
  },
  {
    name: "Xero",
    description: "Xero accounting data",
    provider: "merge_accounting_link_token",
    mergeAccountingSource: "xero",
  },
]

export const allAccountingIntegrationConnectOptions: IntegrationConnectOption[] = [
  ...accountingIntegrationConnectOptions,
  ...accountingLinkTokenIntegrationConnectionOptions,
]

export const allIntegrationsConnectOptions: IntegrationConnectOption[] = [
  ...ssoIntegrationConnectOptions,
  ...allAccountingIntegrationConnectOptions,
  ...paymentIntegrationConnectOptions,
  ...hrisLinkTokenIntegrationConnectionOptions,
  ...notificationIntegrationConnectOptions,
]

export const getIntegrationConnectTabs = (
  intl: IntlShape,
  {
    xeroEnabled,
    plaidEnabled,
  }: {
    xeroEnabled?: boolean
    plaidEnabled?: boolean
  }
): (TabData & { integrationOptions: IntegrationConnectOption[] })[] => [
  {
    locationHash: "",
    label: intl.formatMessage({
      id: "integrations.page.connect.tab.all",
      defaultMessage: "Show all",
      description: "Tab label for integration connect page all integrations tab",
    }),
    integrationOptions: plaidEnabled
      ? allIntegrationsConnectOptions
      : allIntegrationsConnectOptions.filter(
          (integration) => integration.provider !== "plaid_access_token" && integration.mergeAccountingSource !== "xero"
        ),
  },
  {
    locationHash: "sso",
    label: intl.formatMessage({
      id: "integrations.page.connect.tab.sso",
      defaultMessage: "SSO",
      description: "Tab label for integration connect page sso integrations tab",
    }),
    integrationOptions: ssoIntegrationConnectOptions,
  },
  {
    locationHash: "accounting",
    label: intl.formatMessage({
      id: "integrations.page.connect.tab.accounting",
      defaultMessage: "Accounting",
      description: "Tab label for integration connect page accounting integrations tab",
    }),
    integrationOptions: xeroEnabled
      ? allAccountingIntegrationConnectOptions
      : allAccountingIntegrationConnectOptions.filter((integration) => integration.mergeAccountingSource !== "xero"),
  },
  {
    locationHash: "payment",
    label: intl.formatMessage({
      id: "integrations.page.connect.tab.payment",
      defaultMessage: "Payment",
      description: "Tab label for integration connect page payment integrations tab",
    }),
    integrationOptions: paymentIntegrationConnectOptions,
  },
  {
    locationHash: "hr",
    label: intl.formatMessage({
      id: "integrations.page.connect.tab.hris",
      defaultMessage: "HR",
      description: "Tab label for integration connect page hris integrations tab",
    }),
    integrationOptions: hrisLinkTokenIntegrationConnectionOptions,
  },
  {
    locationHash: "notification",
    label: intl.formatMessage({
      id: "integrations.page.connect.tab.notification",
      defaultMessage: "Notification",
      description: "Tab label for integration connect page notification integrations tab",
    }),
    integrationOptions: notificationIntegrationConnectOptions,
  },
]
