import type { CurrencyAmount, FieldMetadata } from "@brm/schema-types/types.js"
import { isTruthy } from "@brm/util/type-guard.js"
import { Flex } from "@chakra-ui/react"
import { EmptySymbol } from "../../EmptySymbol.js"
import { ExtractionHighlight } from "../../ExtractionHighlight.js"
import { FormattedMultiCurrency } from "../../FormattedMultiCurrency.js"
import PercentArrowIcon from "../../icons/PercentArrowIcon.js"

export default function MoneyCell({
  currencyAmount,
  amountsPerCurrency,
  percentGrowth,
  source,
}: {
  currencyAmount: CurrencyAmount | null | undefined
  amountsPerCurrency?: CurrencyAmount[]
  percentGrowth?: number | null
  source?: FieldMetadata
}) {
  return (
    <Flex flexDir="column" display="inline-flex">
      <ExtractionHighlight source={source}>
        {currencyAmount ? (
          <FormattedMultiCurrency currencyAmount={currencyAmount} amountsPerCurrency={amountsPerCurrency} />
        ) : (
          <EmptySymbol />
        )}
      </ExtractionHighlight>
      {currencyAmount && isTruthy(percentGrowth) && <PercentArrowIcon percent={percentGrowth} />}
    </Flex>
  )
}
