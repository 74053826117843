import type { ToolListing } from "@brm/schema-types/types.js"
import { Box, HStack, Icon, Text } from "@chakra-ui/react"
import type { CreatableProps, GroupBase, MultiValueProps, OptionProps } from "chakra-react-select"
import { CreatableSelect, chakraComponents } from "chakra-react-select"
import { useMemo, useState } from "react"
import type { RefCallBack } from "react-hook-form"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { useGetToolV1PickerOptionsQuery } from "../../app/services/generated-api.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { PlusIcon } from "../icons/icons.js"
import { ToolLogo } from "../icons/Logo.js"

export type ToolListingMultiPickerProps = {
  allowCreate: boolean
  inputRef?: RefCallBack
} & CreatableProps<ToolListing, true, GroupBase<ToolListing>>

const Option = (props: OptionProps<ToolListing, true>) => {
  return props.data.id !== "new" ? (
    <chakraComponents.Option {...props}>
      <ToolLogo boxSize={6} logo={getPublicImageGcsUrl(props.data.image_asset?.gcs_file_name)} padding={1} />
      <Text flexShrink={1} minWidth={0} textOverflow="ellipsis" overflow="hidden">
        {props.children}
      </Text>
    </chakraComponents.Option>
  ) : (
    <chakraComponents.Option {...props}>
      <Icon boxSize={5} as={PlusIcon} /> {props.children}
    </chakraComponents.Option>
  )
}

const MultiValue = (props: MultiValueProps<ToolListing, true>) => {
  const { data } = props
  return (
    <chakraComponents.MultiValue {...props}>
      <HStack spacing={1}>
        <ToolLogo boxSize={6} logo={getPublicImageGcsUrl(data.image_asset?.gcs_file_name)} padding={1} />
        <Text>{data.display_name}</Text>
      </HStack>
    </chakraComponents.MultiValue>
  )
}

export function ToolListingMultiPicker(props: ToolListingMultiPickerProps) {
  const { value, inputRef } = props
  const intl = useIntl()
  const { code } = useParams<{ code: string }>()
  const [search, setSearch] = useState("")

  const { data, isLoading } = useGetToolV1PickerOptionsQuery({ search, linkCode: code })

  const toolListings = useMemo(
    () =>
      data?.map(
        (toolOption): ToolListing => ({
          id: toolOption.tool_listing_id,
          display_name: toolOption.display_name,
          image_asset: toolOption.image_asset,
          object_type: "ToolListing",
        })
      ) || [],
    [data]
  )

  return (
    <Box flexGrow={1}>
      <CreatableSelect<ToolListing, true>
        isMulti
        closeMenuOnSelect={false}
        options={toolListings}
        isValidNewOption={(newToolName: string) => {
          if (newToolName === "") {
            return false
          }
          return toolListings.every((toolListing) =>
            toolListing.display_name.toLowerCase().includes(newToolName.toLowerCase())
          )
        }}
        ref={(select) => {
          inputRef?.(select?.inputRef ?? null)
        }}
        openMenuOnFocus={true}
        isLoading={isLoading}
        components={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Option,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          MultiValue,
        }}
        chakraStyles={{
          valueContainer: (styles) => ({ ...styles, paddingLeft: "4px" }),
          option: (styles) => {
            return {
              ...styles,
              display: "flex",
              gap: "0.5rem",
            }
          },
        }}
        getOptionLabel={(toolListing) => toolListing.display_name}
        getOptionValue={(toolListing) => toolListing.id}
        getNewOptionData={(inputValue) => ({
          id: "new",
          display_name: inputValue,
          image_asset: null,
          object_type: "ToolListing",
        })}
        value={value}
        inputValue={search}
        onInputChange={setSearch}
        placeholder={intl.formatMessage({
          id: "form.multi-select.placeholder",
          defaultMessage: "Select all that apply...",
          description: "Placeholder for multi selection input",
        })}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
        }}
        {...props}
      />
    </Box>
  )
}
