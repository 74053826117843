import { Flex, Grid, Text } from "@chakra-ui/react"
import { Group } from "@visx/group"
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend"
import { scaleOrdinal } from "@visx/scale"
import Pie from "@visx/shape/lib/shapes/Pie"
import Big from "big.js"
import { useMemo } from "react"
import { useIntl } from "react-intl"
import ChartCard from "./ChartCard.js"
import { PieArc } from "./PieArc.js"
import { PIE_CHART_DEFAULT_MARGIN, pieChartHeight, pieChartWidth } from "./constants.js"
import type { ChartCardProps, PieProps } from "./types.js"

const LEGEND_GLYPH_SIZE = 10

const LEGEND_COLORS = [
  // 600s
  "var(--chakra-colors-green-600)",
  "var(--chakra-colors-cyan-600)",
  "var(--chakra-colors-blue-600)",
  "var(--chakra-colors-brand-600)",
  "var(--chakra-colors-purple-600)",
  "var(--chakra-colors-pink-600)",
  "var(--chakra-colors-yellow-600)",
  "var(--chakra-colors-orange-600)",
  "var(--chakra-colors-gray-600)",
  // 400s
  "var(--chakra-colors-green-400)",
  "var(--chakra-colors-cyan-400)",
  "var(--chakra-colors-blue-400)",
  "var(--chakra-colors-brand-400)",
  "var(--chakra-colors-purple-400)",
  "var(--chakra-colors-pink-400)",
  "var(--chakra-colors-yellow-400)",
  "var(--chakra-colors-orange-400)",
  "var(--chakra-colors-gray-400)",
  // 200s
  "var(--chakra-colors-green-200)",
  "var(--chakra-colors-cyan-200)",
  "var(--chakra-colors-blue-200)",
  "var(--chakra-colors-brand-200)",
  "var(--chakra-colors-purple-200)",
  "var(--chakra-colors-pink-200)",
  "var(--chakra-colors-yellow-200)",
  "var(--chakra-colors-orange-200)",
  "var(--chakra-colors-gray-200)",
]

interface Props<OptionValue = unknown> extends Omit<ChartCardProps<OptionValue>, "children"> {
  pieProps: PieProps
}

export default function PieChartCard<OptionValue = unknown>({
  pieProps: { width = pieChartWidth, height = pieChartHeight, margin = PIE_CHART_DEFAULT_MARGIN, data, totalValue },
  ...chartCardProps
}: Props<OptionValue>) {
  const intl = useIntl()

  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom
  const radius = Math.min(innerWidth, innerHeight) / 2
  const centerY = innerHeight / 2
  const centerX = innerWidth / 2
  const donutThickness = 25

  const { pieSelections, labelById } = useMemo(() => {
    const pieSelections = data.map((d) => d.id)
    const labelById = new Map(data.map((d) => [d.id, d.label]))
    return { pieSelections, labelById }
  }, [data])

  const getSelectionColor = scaleOrdinal({
    domain: Array.from(pieSelections),
    range: LEGEND_COLORS,
  })

  // Prevent division by zero
  if (Big(totalValue).eq(0)) {
    return null
  }

  return (
    <ChartCard {...chartCardProps} flex={undefined} minWidth="max-content">
      <Flex gap={4}>
        <svg width={width} height={height}>
          <rect rx={14} width={width} height={height} fill="transparent" />
          <Group top={centerY + margin.top} left={centerX + margin.left}>
            <Pie
              data={data}
              pieValue={(d) => d.value}
              outerRadius={radius}
              innerRadius={radius - donutThickness}
              cornerRadius={3}
              padAngle={0.02}
            >
              {(pie) =>
                pie.arcs.map((arc) => {
                  const percentageShare = Big(arc.data.value).div(totalValue).mul(100)
                  return (
                    <PieArc
                      key={arc.data.id}
                      arc={arc}
                      path={pie.path}
                      color={getSelectionColor(arc.data.id)}
                      label={
                        percentageShare.gt(4)
                          ? intl.formatMessage(
                              {
                                id: "pie.chart.arc.label",
                                description: "Label on pie chart displaying number a percentage",
                                defaultMessage: "{percent}%",
                              },
                              { percent: percentageShare.toFixed(1) }
                            )
                          : null
                      }
                    />
                  )
                })
              }
            </Pie>
          </Group>
        </svg>
        <LegendOrdinal scale={getSelectionColor} labelFormat={(id) => labelById.get(id)}>
          {(labels) => (
            <Grid maxH={height} autoFlow="column" templateRows="repeat(10, auto)" gap={1}>
              {labels.map((label) => (
                <LegendItem key={label.datum}>
                  <svg width={LEGEND_GLYPH_SIZE} height={LEGEND_GLYPH_SIZE}>
                    <rect fill={label.value} width={LEGEND_GLYPH_SIZE} height={LEGEND_GLYPH_SIZE} />
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 4px">
                    <Text fontSize="xs">{label.text}</Text>
                  </LegendLabel>
                </LegendItem>
              ))}
            </Grid>
          )}
        </LegendOrdinal>
      </Flex>
    </ChartCard>
  )
}
