import type { MentionElement } from "@brm/schema-types/types.js"
import type { ChakraProps } from "@chakra-ui/system"
import { Transforms } from "slate"
import type { CustomEditor } from "../types.js"

/** Mentions support */
export const withMentions = (editor: CustomEditor) => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = (element) => {
    return element.type === "mention" || isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === "mention" || isVoid(element)
  }

  editor.markableVoid = (element) => {
    return element.type === "mention" || markableVoid(element)
  }

  return editor
}

export const insertMention = (editor: CustomEditor, userId: string, displayName: string, organizationId?: string) => {
  const mention: MentionElement = {
    type: "mention",
    user_id: userId,
    display_name: displayName,
    children: [{ text: "" }],
    organization_id: organizationId,
  }
  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
  Transforms.insertText(editor, " ")
  Transforms.move(editor)
}

export const baseMentionSpanStyle: ChakraProps = {
  padding: "0 2px",
  margin: "0 1px",
  verticalAlign: "baseline",
  display: "inline-block",
  borderRadius: "4px",
}
