import { theme } from "@brm/theme"
import { launchDarklyContext } from "@brm/util/flags.js"
import { ChakraProvider, Flex, type ToastProviderProps } from "@chakra-ui/react"
// eslint-disable-next-line no-restricted-imports
import { MantineProvider } from "@mantine/core"
import "@mantine/core/styles.css"
import * as Sentry from "@sentry/react"
import { useLDClient } from "launchdarkly-react-client-sdk"
import { usePostHog } from "posthog-js/react"
import { lazy, useEffect } from "react"
import type { RouterConfig } from "react-aria-components"
import { useIntl } from "react-intl"
import { createBrowserRouter, Navigate, Outlet, redirect, RouterProvider, useLocation } from "react-router-dom"
import { useGetUserV1WhoamiQuery } from "./app/services/generated-api.js"
import FixedHeightContainer from "./components/Container/FixedHeightContainer.js"
import { LinkRouteContainer } from "./components/Container/LinkRouteContainer.js"
import LoggedInContainer from "./components/Container/LoggedInContainer.js"
import PageContainer from "./components/Container/PageContainer.js"
import SellerContainer from "./components/Container/SellerContainer.js"
import { WorkflowRouteContainer } from "./components/Container/WorkflowRouteContainer.js"
import CreateAccount from "./features/auth/CreateAccount.js"
import Login from "./features/auth/Login.js"
import LoginWithSso from "./features/auth/LoginWithSso.js"
import Betsy from "./features/betsy/Betsy.js"
import ErrorBoundary from "./features/error/RouteError.js"
import { Home } from "./features/home/home.js"
import { Inbox } from "./features/inbox/Inbox.js"
import InboxEmptyState from "./features/inbox/empty-state/InboxEmptyState.js"
import LegalAgreementList from "./features/legal/LegalAgreementList.js"
import { LegalAgreementEditor } from "./features/legal/dynamic-form/LegalAgreementEditor.js"
import Person from "./features/person/Person.js"
import type { PersonTab } from "./features/person/constants.js"
import PersonList from "./features/person/person-list/PersonList.js"
import RenewalCalendarPage from "./features/renewal-calendar/RenewalCalendarPage.js"
import Security from "./features/security/Security.js"
import SellerHomePage from "./features/seller/SellerHomePage.js"
import ArchivedSettings from "./features/settings/ArchivedSettings.js"
import { ContractCollectorSettings } from "./features/settings/ContractCollectorSettings.js"
import IntegrationList from "./features/settings/IntegrationList.js"
import NotificationSettings from "./features/settings/NotificationSettings.js"
import SecuritySettings from "./features/settings/SecuritySettings.js"
import Settings from "./features/settings/Settings.js"
import UserProfileSettings from "./features/settings/UserProfileSettings.js"
import UsersSettings from "./features/settings/UsersSettings.js"
import CriteriaSettings from "./features/settings/criteria/CriteriaSettings.js"
import Tool from "./features/tool/Tool.js"
import { type ToolTab } from "./features/tool/constants.js"
import ToolList from "./features/tool/tool-list/ToolList.js"
import Vendor from "./features/vendor/Vendor.js"
import type { VendorTab } from "./features/vendor/constants.js"
import VendorList from "./features/vendor/vendor-list/VendorList.js"
import WorkflowDefinitionCreatePage from "./features/workflows/definition/WorkflowDefinitionCreatePage.js"
import WorkflowDefinitionList from "./features/workflows/definition/WorkflowDefinitionList.js"
import WorkflowDefinitionPage from "./features/workflows/definition/WorkflowDefinitionPage.js"
import { BrmLinkWorkflowRunPage } from "./features/workflows/run/BrmLinkWorkflowRunPage.js"
import WorkflowRunErrorBoundary from "./features/workflows/run/WorkflowRunErrorBoundary.js"
import WorkflowRunList from "./features/workflows/run/WorkflowRunList.js"
import WorkflowRunPage from "./features/workflows/run/WorkflowRunPage.js"
import { mantineTheme } from "./mantine-theme.js"
import { DEFAULT_TOAST_DURATION } from "./util/constant.js"

const SetPassword = lazy(() => import("./features/set-password/SetPassword.js"))
const toastOptions: ToastProviderProps = {
  defaultOptions: {
    duration: DEFAULT_TOAST_DURATION,
    isClosable: true,
  },
}
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const PostHogPageviewTracker = () => {
  const location = useLocation()
  const posthog = usePostHog()
  useEffect(() => {
    if (posthog) {
      posthog.capture("$pageview")
    }
  }, [location, posthog])

  return null
}

const AppWrapper = () => (
  <MantineProvider theme={mantineTheme}>
    <ChakraProvider resetCSS theme={theme} cssVarsRoot=":root" toastOptions={toastOptions}>
      <PostHogPageviewTracker />
      <Outlet />
    </ChakraProvider>
  </MantineProvider>
)

const loggedInRoutes = (forInbox: boolean): RouterConfig[] => [
  {
    children: [
      {
        index: true,
        element: forInbox ? null : <Home />,
        errorElement: <ErrorBoundary />,
      },
      ...(!forInbox
        ? [
            {
              path: "inbox",
              element: <Inbox />,
              children: [
                {
                  index: true,
                  element: <InboxEmptyState />,
                },
                {
                  path: "tasks",
                  children: loggedInRoutes(true),
                },
                {
                  path: ":notificationId",
                  children: loggedInRoutes(true),
                },
              ],
            },
          ]
        : []),
      {
        path: "tools",
        errorElement: <ErrorBoundary />,
        children: [
          {
            index: true,
            element: <ToolList />,
          },
          {
            path: ":toolId",
            children: [
              {
                index: true,
                loader: () => redirect("overview" satisfies ToolTab), // Default tab
              },
              {
                path: ":tab",
                element: <Tool />,
              },
            ],
          },
        ],
      },
      {
        path: "vendors",
        errorElement: <ErrorBoundary />,
        children: [
          {
            index: true,
            element: <VendorList />,
          },
          {
            path: ":vendorId",
            children: [
              {
                index: true,
                loader: () => redirect("overview" satisfies VendorTab), // Default tab
              },
              {
                path: ":tab",
                element: <Vendor />,
                errorElement: <ErrorBoundary />,
              },
            ],
          },
        ],
      },
      {
        path: "renewal-calendar",
        element: <RenewalCalendarPage />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "requests",
        element: <WorkflowRunList />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "agreements",
        children: [
          { index: true, element: <LegalAgreementList /> },
          {
            path: ":legalAgreementId",
            element: <LegalAgreementEditor />,
          },
          {
            path: "create",
            element: <LegalAgreementEditor create />,
          },
        ],
        errorElement: <ErrorBoundary />,
      },
      {
        path: "people",
        element: <PersonList />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
  {
    path: "links",
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: ":code",
        element: <BrmLinkWorkflowRunPage />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
  {
    // Non-list request pages all share the same layout
    path: "requests",
    element: <WorkflowRouteContainer />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "definitions",
        children: [
          {
            path: ":id",
            element: <WorkflowDefinitionPage />,
            errorElement: <ErrorBoundary />,
          },
          { path: "create/:kind", element: <WorkflowDefinitionCreatePage />, errorElement: <ErrorBoundary /> },
        ],
      },
      {
        path: ":workflowRunId",
        element: <WorkflowRunPage />,
        errorElement: <WorkflowRunErrorBoundary />,
      },
    ],
  },
  {
    // Non-list person pages all share the same layout
    path: "people",
    element: <PageContainer />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: ":personId",
        children: [
          {
            index: true,
            loader: () => redirect("overview" satisfies PersonTab), // Default tab
          },
          {
            path: ":tab",
            element: <Person />,
          },
        ],
        errorElement: <ErrorBoundary />,
      },
    ],
  },
  {
    path: "settings",
    element: <Settings />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        loader: () => redirect("profile"),
      },
      {
        path: "profile",
        element: <UserProfileSettings />,
      },
      {
        path: "contract-collector",
        element: <ContractCollectorSettings />,
      },
      {
        path: "notification",
        element: <NotificationSettings />,
      },
      {
        path: "integrations",
        element: <IntegrationList />,
      },
      {
        path: "users",
        element: <UsersSettings />,
      },
      {
        path: "security",
        element: <SecuritySettings />,
      },
      {
        path: "requests",
        element: <WorkflowDefinitionList />,
      },
      {
        path: "archived",
        element: <ArchivedSettings />,
      },
      {
        path: "criteria",
        children: [
          { index: true, element: <CriteriaSettings /> },
          {
            path: ":category",
            element: <CriteriaSettings />,
          },
        ],
      },
    ],
  },
  {
    path: "security",
    element: <Security />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "braim/:id?",
    element: <Betsy />,
    errorElement: <ErrorBoundary />,
  },
]

const router = sentryCreateBrowserRouter([
  {
    /**
     * Moved closer to elements so chakra always has all the providers.
     * Eg. without this we will not be able to navigate within toasts.
     */
    element: <AppWrapper />,
    children: [
      {
        element: <FixedHeightContainer />,
        children: [
          {
            path: "/",
            // Displays a nav sidebar for routes with a logged-in user
            element: <LoggedInContainer />,
            errorElement: <ErrorBoundary />,
            children: loggedInRoutes(false),
          },
        ],
      },
      {
        path: "/seller",
        element: <SellerContainer />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            index: true,
            element: <SellerHomePage />,
            errorElement: <ErrorBoundary />,
          },
        ],
      },
      {
        path: "/login",
        children: [
          { index: true, element: <Login />, errorElement: <ErrorBoundary /> },
          { path: "sso", element: <LoginWithSso />, errorElement: <ErrorBoundary /> },
        ],

        errorElement: <ErrorBoundary />,
      },
      {
        path: "/create_account/:code",
        element: <CreateAccount />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/set_password",
        element: <SetPassword />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/links/:code",
        element: <LinkRouteContainer />,
        children: [
          {
            index: true,
            element: <BrmLinkWorkflowRunPage />,
            errorElement: <ErrorBoundary />,
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
])

function App() {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const intl = useIntl()
  const ldClient = useLDClient()
  useEffect(() => {
    const identifyUser = async () => {
      await ldClient?.identify(
        launchDarklyContext(
          whoami ? { user: whoami, organization: whoami.organization } : { fallbackKey: crypto.randomUUID() }
        )
      )
    }
    void identifyUser()
  }, [intl, ldClient, whoami])
  return (
    <Flex className="App" direction="column" minHeight={0} grow={1}>
      <RouterProvider router={router} />
    </Flex>
  )
}

export default App
