import type { InvitedSellerUser, WorkflowRunStepWithContext } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { getPublicImageGcsUrl } from "../../../../util/url.js"
import type { GetLogoForOrganizationProps } from "../utils.js"

export default function WorkflowRunStepGathererAvatars({
  step,
  externalGatherers,
  getLogoToShowByOrganizationId,
  sellerLogoGcsFileName,
}: {
  step: Pick<WorkflowRunStepWithContext, "owner" | "submitted_by">
  externalGatherers: InvitedSellerUser[]
  sellerLogoGcsFileName?: string
} & GetLogoForOrganizationProps) {
  const intl = useIntl()

  const ownerLogo = getLogoToShowByOrganizationId?.(step.owner.organization_id)
  return (
    <AvatarGroup spacing={-2}>
      <Avatar
        key={step.owner.id}
        name={displayPersonName(step.owner, intl)}
        src={getPublicImageGcsUrl(step.owner.profile_image?.gcs_file_name)}
        borderWidth={0}
      >
        {ownerLogo && <AvatarBadge as={Avatar} boxSize={3} src={ownerLogo} />}
      </Avatar>

      {externalGatherers.map((gatherer) => (
        <Avatar
          key={gatherer.email}
          name={displayPersonName({ id: gatherer.email, ...gatherer }, intl)}
          src={getPublicImageGcsUrl(gatherer.profile_image?.gcs_file_name)}
          borderWidth={0}
        >
          {sellerLogoGcsFileName && (
            <AvatarBadge as={Avatar} boxSize={3} src={getPublicImageGcsUrl(sellerLogoGcsFileName)} />
          )}
        </Avatar>
      ))}
    </AvatarGroup>
  )
}
