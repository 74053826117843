import { Box, HStack } from "@chakra-ui/react"
import { Children, Fragment, type ReactNode } from "react"
import { isPresent } from "ts-is-present"

/** Used as the child of LogoHeader to display a horizontal row of elements spaced with vertical dividers in various detail pages */
export const DividedRowSubHeader = ({ children }: { children: ReactNode }) => {
  const childrenArray = Children.toArray(children).filter(isPresent)
  return (
    <HStack gap={2} fontSize="sm" fontWeight="medium">
      {childrenArray.map((child, index) => (
        <Fragment key={index}>
          {index > 0 && <Box borderRightWidth={1} alignSelf="stretch" />}
          {child}
        </Fragment>
      ))}
    </HStack>
  )
}
