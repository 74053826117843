import type {
  CustomizableObjectType,
  DocumentMinimal,
  ExtractedFieldConfig,
  FieldConfigMinimal,
} from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { getTitle } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  Grid,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  chakra,
  useToast,
  type UseModalProps,
} from "@chakra-ui/react"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { Fragment, useCallback, useId, useMemo, useRef, useState, type FunctionComponent } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { FormattedMessage, useIntl } from "react-intl"
import { usePutSchemaV1ByObjectTypeFieldsAndFieldNameMutation } from "../../../app/services/generated-api.js"
import DocumentViewer from "../../../components/Document/DocumentViewer.js"
import { ExtractionIcon } from "../../../components/ExtractionHighlight.js"
import { IconButtonWithTooltip } from "../../../components/IconButtonWithTooltip.js"
import { RenderedMarkdown } from "../../../components/RenderedMarkdown.js"
import { CheckIcon, XIcon } from "../../../components/icons/icons.js"
import { ownDocumentDownloadUrl } from "../../../util/document.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import { CriterionConfigForm } from "./CriterionConfigForm.js"
import type { CriterionFormState, JSONSchemaObjectOnly } from "./types.js"

export interface FormCriteriaReviewModalProps extends UseModalProps {
  document: DocumentMinimal
  extractedFields: ExtractedFieldConfig[]
}

const fieldGroupOrder: readonly ExtractedFieldConfig["type"][] = ["new_field", "existing_field"]

export const FormCriteriaReviewModal: FunctionComponent<FormCriteriaReviewModalProps> = ({
  document,
  extractedFields,
  ...modalProps
}) => {
  const [expanded, setExpanded] = useState<number[]>([])
  const [fields, setFields] = useState(() => structuredClone(extractedFields))
  const fieldsByType = useMemo(
    () =>
      Map.groupBy(
        fields.toSorted((a, b) => fieldGroupOrder.indexOf(a.type) - fieldGroupOrder.indexOf(b.type)),
        (f) => f.type
      ),
    [fields]
  )

  const suggestedFieldsCount = fields.filter((f) => f.type === "new_field").length
  const matchingFieldsCount = fields.filter((f) => f.type === "existing_field").length

  return (
    <Modal {...modalProps} isCentered size="6xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent height="98vh" maxWidth="98vw" overflow="hidden" p={0}>
        <Flex flexGrow={1} flexShrink={1} minH={0}>
          <Flex flexGrow={1} flexShrink={3} minW="50%" overflow="auto" alignItems="center">
            <DocumentViewer document={document} downloadUrl={ownDocumentDownloadUrl(document)} />
          </Flex>
          <Flex flexDir="column" borderLeftWidth="1px" maxW="70rem" flexShrink={1} flexBasis="65rem" minW={0}>
            <ModalHeader>
              <Flex flexDir="column">
                <chakra.h2 whiteSpace="nowrap" overflow="hidden" display="flex" alignItems="center" gap={2}>
                  <chakra.span>
                    <FormattedMessage
                      defaultMessage="Generate custom criteria from document"
                      description="Modal header for uploading a document to generate custom criteria"
                      id="settings.criteria.form_upload_modal.header"
                    />
                  </chakra.span>{" "}
                  <Badge
                    variant="subtleOutlined"
                    colorScheme="purple"
                    verticalAlign="middle"
                    size="sm"
                    display="inline-flex"
                    alignItems="center"
                    gap={1}
                  >
                    <ExtractionIcon boxSize={undefined} verticalAlign="middle" />{" "}
                    <FormattedMessage
                      defaultMessage="{count} suggested criteria"
                      description="Badge for the number of suggested criteria"
                      id="settings.criteria.form_upload_modal.badge"
                      values={{ count: suggestedFieldsCount }}
                    />
                  </Badge>{" "}
                  <Badge variant="subtleOutlined" verticalAlign="middle" size="sm">
                    <FormattedMessage
                      defaultMessage="{count} matching criteria"
                      description="Badge for the number of suggested criteria"
                      id="settings.criteria.form_upload_modal.badge"
                      values={{ count: matchingFieldsCount }}
                    />
                  </Badge>
                </chakra.h2>
                <Text fontWeight="normal">
                  <FormattedMessage
                    defaultMessage="Review and create custom criteria extracted from the document"
                    description="Subtitle for the document upload modal"
                    id="settings.criteria.form_upload_modal.subtitle"
                  />
                </Text>
              </Flex>
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody display="flex" flexDirection="column">
              {fields.length > 0 ? (
                <Accordion
                  variant="pills"
                  allowMultiple
                  index={expanded}
                  onChange={(newExpanded) => {
                    setExpanded(Array.isArray(newExpanded) ? newExpanded : [newExpanded])
                  }}
                  mt={4}
                  gap={6}
                >
                  {Array.from(fieldsByType, ([type, fields]) => (
                    <Fragment key={type}>
                      {type === "existing_field" && (
                        <Box mt={2}>
                          <Heading size="xss" as="h3">
                            <FormattedMessage
                              defaultMessage="Existing criteria"
                              description="Heading for matching criteria section"
                              id="settings.criteria.form_upload_modal.matching_criteria"
                            />
                          </Heading>
                          <Text>
                            <FormattedMessage
                              defaultMessage="We found existing criteria that already exists in your BRM."
                              description="Message to show when there are matching criteria"
                              id="settings.criteria.form_upload_modal.matching_criteria_message"
                            />
                          </Text>
                        </Box>
                      )}
                      {fields.map((field, index) => (
                        <ExtractedCriterion
                          key={`${field.object_type}:${field.field_name}:${field.is_custom}`}
                          field={field}
                          onRemove={() => {
                            setFields((fields) =>
                              fields.filter(
                                (f) => !(f.field_name === field.field_name && f.is_custom === field.is_custom)
                              )
                            )
                            setExpanded((indexes) =>
                              indexes.filter((i) => i !== index).map((i) => (i > index ? i - 1 : i))
                            )
                          }}
                          onOpen={() => setExpanded((expanded) => [...expanded, index])}
                        />
                      ))}
                    </Fragment>
                  ))}
                </Accordion>
              ) : (
                <Grid color="gray.400" fontSize="md" placeContent="center" flexGrow={1}>
                  <FormattedMessage
                    defaultMessage="No criteria to review"
                    description="Message to show when there are no criteria to review"
                    id="settings.criteria.form_upload_modal.no_criteria"
                  />
                </Grid>
              )}
            </ModalBody>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  )
}

function ExtractedCriterion({
  field,
  onRemove,
  onOpen,
}: {
  field: ExtractedFieldConfig
  onRemove: () => void
  onOpen: () => void
}) {
  const intl = useIntl()
  const toast = useToast()
  const formId = `extracted-criterion-${useId()}`
  const formRef = useRef<HTMLFormElement>(null)

  const [upsertFieldConfig, upsertFieldConfigStatus] = usePutSchemaV1ByObjectTypeFieldsAndFieldNameMutation()

  const readOnlyField =
    field.type === "existing_field" &&
    isObject(field.field_schema) &&
    (field.field_schema as JSONSchemaObject).configurable === false

  const onValidSubmit = useCallback(
    async (fieldConfigInput: FieldConfigMinimal): Promise<void> => {
      try {
        if (!readOnlyField) {
          await upsertFieldConfig({
            objectType: fieldConfigInput.object_type,
            fieldName: fieldConfigInput.field_name,
            fieldConfigInput,
          }).unwrap()
          toast({
            status: "success",
            description: (
              <FormattedMessage
                defaultMessage="Created criterion"
                description="Created criterion toast message"
                id="settings.criteria.object.criterionCreated"
              />
            ),
          })
        }
        onRemove()
      } catch (err) {
        toast({
          status: "error",
          description: getAPIErrorMessage(err) ?? (
            <FormattedMessage
              defaultMessage="An error occurred updating the criterion"
              description="Error message when updating a criterion fails"
              id="settings.criteria.object.updateCriterionFailed"
            />
          ),
        })
      }
    },
    [onRemove, readOnlyField, toast, upsertFieldConfig]
  )

  const defaultFormValues: CriterionFormState = {
    object_type: field.object_type as CustomizableObjectType,
    title: (field.field_schema as JSONSchemaObject)?.title ?? "",
    description: (field.field_schema as JSONSchemaObject)?.description ?? "",
    dataType: field.field_schema as JSONSchemaObjectOnly,
    category: field.category ?? null,
    is_internal_only: field.is_internal_only ?? false,
    is_enabled: field.is_enabled ?? true,
  }

  const badge = useMemo(() => {
    if (!field.category) {
      return null
    }
    const fieldCategorySchema = FieldCategorySchema.anyOf.find((category) => category.const === field.category)
    return (
      <Badge colorScheme={fieldCategorySchema?.colorScheme ?? "gray"} variant="subtleOutlined">
        {getTitle(field.category, fieldCategorySchema)}
      </Badge>
    )
  }, [field.category])

  return (
    <chakra.article>
      <chakra.blockquote
        borderLeftColor="gray.300"
        borderLeftWidth="2.5px"
        whiteSpace="pre-wrap"
        mb={3}
        fontStyle="italic"
        pl={3}
        py={1}
      >
        {field.question}
      </chakra.blockquote>
      <AccordionItem minW={0} display="flex" flexDirection="column">
        <AccordionButton
          // Have to use <div> because buttons cannot be nested
          as="div"
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === " " || event.key === "ArrowRight") {
              onOpen()
            }
          }}
          cursor="pointer"
          textAlign="left"
          display="flex"
          gap={3}
          minW={0}
        >
          <AccordionIcon />
          <Stack spacing={0} minW={0} overflow="hidden">
            <Heading size="xxs" display="flex" alignItems="center" gap={1} minW={0}>
              <chakra.span isTruncated mr={1}>
                {/* eslint-disable-next-line react/jsx-no-literals */}
                {getTitle(field.object_type, null)} › {getTitle(field.field_name, field.field_schema)}
              </chakra.span>
              {badge}
              {field.type === "existing_field" &&
                (field.is_custom ? (
                  <Badge colorScheme="blue" variant="subtleOutlined">
                    <FormattedMessage
                      defaultMessage="Custom"
                      description="Badge for custom criterion"
                      id="settings.criteria.badge.custom"
                    />
                  </Badge>
                ) : (
                  <Badge colorScheme="brand" variant="subtleOutlined">
                    <FormattedMessage
                      defaultMessage="BRM Global"
                      description="Badge for existing criterion"
                      id="settings.criteria.badge.existing"
                    />
                  </Badge>
                ))}
            </Heading>
            <Box noOfLines={1} color="gray.600" fontSize="sm">
              {isObject(field.field_schema) && (field.field_schema as JSONSchemaObject).description && (
                <RenderedMarkdown content={(field.field_schema as JSONSchemaObject).description!} />
              )}
            </Box>
          </Stack>

          {/* Accept/discard buttons */}
          <Flex ml="auto" onClick={(event) => event.stopPropagation()}>
            <IconButtonWithTooltip
              size="sm"
              variant="ghost"
              icon={<Icon as={XIcon} />}
              color="error.600"
              label={intl.formatMessage({
                defaultMessage: "Discard criterion",
                description: "Discard criterion button label",
                id: "settings.criteria.extracted_criterion.discard.tooltip",
              })}
              isDisabled={upsertFieldConfigStatus.isLoading || upsertFieldConfigStatus.isSuccess}
              onClick={onRemove}
            />
            <IconButtonWithTooltip
              size="sm"
              variant="ghost"
              icon={<Icon as={CheckIcon} />}
              color="success.600"
              isLoading={upsertFieldConfigStatus.isLoading}
              label={intl.formatMessage({
                defaultMessage: "Accept criterion",
                description: "Accept criterion button label",
                id: "settings.criteria.extracted_criterion.accept.tooltip",
              })}
              onClick={() => {
                formRef.current?.requestSubmit()
              }}
            />
          </Flex>
        </AccordionButton>
        <AccordionPanel display="flex" flexDir="column" gap={4}>
          <ErrorBoundary fallbackRender={({ error }) => `Error: ${error}`}>
            <CriterionConfigForm
              defaultValues={defaultFormValues}
              fieldConfig={field.type === "existing_field" ? field : undefined}
              id={formId}
              ref={formRef}
              onValidSubmit={onValidSubmit}
              listWorkflowDefs={false}
            />
          </ErrorBoundary>
        </AccordionPanel>
      </AccordionItem>
    </chakra.article>
  )
}
