import { Box } from "@chakra-ui/react"
// eslint-disable-next-line no-restricted-imports
import { Combobox, type ComboboxStore } from "@mantine/core"
import type { ReactElement } from "react"
import { FormattedMessage } from "react-intl"
import zIndices from "../../../../packages/theme/src/foundations/z-index.js"
import styles from "./SearchCardsComboBox.module.css"

interface SearchCardsComboBoxProps<T> {
  combobox: ComboboxStore
  data: T[]
  getItemValue: (item: T) => string
  onSelectItem: (item: T) => void
  inputValue: string
  renderOption: (item: T) => ReactElement
  children: React.ReactNode
  error?: unknown
}

export default function SearchCardsComboBox<T>({
  combobox,
  data,
  getItemValue,
  onSelectItem,
  inputValue,
  renderOption,
  children,
  error,
}: SearchCardsComboBoxProps<T>) {
  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        const selectedItem = data.find((item) => getItemValue(item) === val)
        if (selectedItem) {
          onSelectItem(selectedItem)
        }
        combobox.closeDropdown()
      }}
      transitionProps={{ duration: 200, transition: "fade-down" }}
      zIndex={zIndices.modal}
      onClose={() => combobox.resetSelectedOption()}
      classNames={{ option: styles.searchCardsComboBoxOption }}
    >
      <Combobox.Target>{children}</Combobox.Target>
      <Combobox.Dropdown p={0}>
        <Box overflowY="auto" maxH="80vh" p={4}>
          <Combobox.Options style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            {error ? (
              <FormattedMessage
                defaultMessage="Error loading search results"
                description="Search generic error"
                id="globalSearch.error"
              />
            ) : inputValue.length === 0 ? (
              <FormattedMessage
                defaultMessage="Enter a search query"
                description="Search inputValue too short"
                id="globalSearch.inputValueTooShort"
              />
            ) : data.length > 0 ? (
              data.map((option) => (
                <Combobox.Option value={getItemValue(option)} key={getItemValue(option)}>
                  {renderOption(option)}
                </Combobox.Option>
              ))
            ) : (
              <FormattedMessage
                defaultMessage="No results"
                description="Search has no results"
                id="globalSearch.noResults"
              />
            )}
          </Combobox.Options>
        </Box>
      </Combobox.Dropdown>
    </Combobox>
  )
}
