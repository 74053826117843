import type { AllSpendByCurrency, CurrencyAmount, CurrencyRange } from "@brm/schema-types/types.js"
import { AllSpendByCurrencySchema } from "@brm/schemas"
import { currencyAmountsFromSpendByCurrency } from "@brm/util/currency/format.js"
import { isObject } from "@brm/util/type-guard.js"

export const DEFAULT_CURRENCY = "USD"

// Default USD options
export const STANDARD_CURRENCY_RANGES: CurrencyRange[] = [
  { min: "0", max: "4999", currency_code: DEFAULT_CURRENCY },
  { min: "5000", max: "24999", currency_code: DEFAULT_CURRENCY },
  { min: "25000", max: "49999", currency_code: DEFAULT_CURRENCY },
  { min: "50000", max: "99999", currency_code: DEFAULT_CURRENCY },
  { min: "100000", max: "249999", currency_code: DEFAULT_CURRENCY },
  { min: "250000", max: null, currency_code: DEFAULT_CURRENCY },
]

// Create a function that checks if the field name is a key of the type SpendByCurrency
const isSpendByCurrencyKey = (fieldName: string): fieldName is keyof AllSpendByCurrency => {
  return fieldName in AllSpendByCurrencySchema.properties
}

export const getCurrencyAmountsFromParent = (parentValue: unknown, fieldName?: string): CurrencyAmount[] => {
  if (
    fieldName &&
    isSpendByCurrencyKey(fieldName) &&
    isObject(parentValue) &&
    "spend_by_currency_code" in parentValue &&
    isObject(parentValue.spend_by_currency_code) &&
    fieldName in (parentValue.spend_by_currency_code as AllSpendByCurrency)
  ) {
    return currencyAmountsFromSpendByCurrency((parentValue.spend_by_currency_code as AllSpendByCurrency)[fieldName])
  }

  if (
    fieldName === "total_contract_value" &&
    isObject(parentValue) &&
    "usd_total_contract_value" in parentValue &&
    typeof parentValue.usd_total_contract_value === "string"
  ) {
    const usdTotalContractValue = parentValue.usd_total_contract_value
    return [{ currency_code: "USD", amount: usdTotalContractValue }]
  }
  return []
}
