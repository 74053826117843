import type { StandardObjectElement } from "@brm/schema-types/types.js"
import { chakra, type ChakraProps } from "@chakra-ui/react"
import { useFocused, useSelected, type RenderElementProps } from "slate-react"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { LinkOrSpan } from "../Link.js"

const PATHNAME_MAP: Partial<Record<StandardObjectElement["object_type"], string>> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LegalAgreement: "/agreements",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Tool: "/tools",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Vendor: "/vendors",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WorkflowRun: "/requests",
}

const getHref = (element: StandardObjectElement) => {
  const { object_type, website } = element
  if (website) {
    return website
  }
  const pathname = PATHNAME_MAP[object_type]
  if (!pathname) {
    return undefined
  }
  return `${pathname}/${element.id}`
}

export const StandardObjectTag = ({
  attributes,
  children,
  element,
}: RenderElementProps &
  GetLogoForOrganizationProps & {
    element: StandardObjectElement
  }) => {
  const selected = useSelected()
  const focused = useFocused()
  const spanStyle: ChakraProps = {
    padding: "0 2px",
    margin: "0 1px",
    verticalAlign: "baseline",
    display: "inline-block",
    borderRadius: "4px",
    backgroundColor: "brand.50",
    borderWidth: "1px",
    borderColor: "brand.200",
    fontSize: "0.9em",
    boxShadow: selected && focused ? "0 0 0 2px var(--chakra-colors-blue-200)" : "none",
  }

  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0]?.bold) {
    spanStyle.fontWeight = "bold"
  }
  if (element.children[0]?.italic) {
    spanStyle.fontStyle = "italic"
  }

  return (
    <chakra.span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.display_name.replaceAll(" ", "-")}`}
      {...spanStyle}
    >
      <LinkOrSpan target="_blank" to={getHref(element)}>
        {element.display_name}
      </LinkOrSpan>
      {children}
    </chakra.span>
  )
}
