import { getEmailDomain, isValidEmail } from "@brm/util/email.js"
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import type { FC } from "react"
import { Controller, useFieldArray, type Control } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { DatePickerInput } from "../../../../components/DynamicForm/DatePicker.js"
import { HelpIcon } from "../../../../components/icons/icons.js"
import { getDefaultDateShortcuts } from "../../../../util/form.js"
import type { WorkflowInviteSectionFormState } from "./util.js"

interface WorkflowInviteSectionProps {
  control: Control<WorkflowInviteSectionFormState>
}

const WorkflowInviteSection: FC<WorkflowInviteSectionProps> = (props) => {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: "recipients",
  })

  const intl = useIntl()

  const firstNamePlaceHolder = intl.formatMessage({
    id: "requests.run.shareLink.first_name.placeholder",
    description: "The placeholder of the first name input in the share link modal",
    defaultMessage: "E.g. Jackson",
  })

  const lastNamePlaceHolder = intl.formatMessage({
    id: "requests.run.shareLink.last_name.placeholder",
    description: "The placeholder of the last name input in the share link modal",
    defaultMessage: "E.g. Reed",
  })

  const emailPlaceholder = intl.formatMessage({
    id: "requests.run.shareLink.email.placeholder",
    description: "The placeholder of the email input in the share link modal",
    defaultMessage: "jackson@nods.ai",
  })

  const messagePlaceholder = intl.formatMessage({
    id: "requests.run.shareLink.message.placeholder",
    description: "The placeholder of the message input in the share link modal",
    defaultMessage: "Enter a message for the recipient",
  })

  return (
    <Box>
      <Box>
        <Stack spacing={8}>
          {fields.map((field, index) => (
            <Stack key={field.id} spacing={2}>
              <Controller
                name={`recipients.${index}.first_name`}
                control={props.control}
                rules={{
                  validate: (value, formValues) => {
                    return formValues.skipped_invites || value.length > 0
                  },
                }}
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={fieldState.invalid} isRequired>
                    <FormLabel>
                      <FormattedMessage
                        id="requests.run.shareLink.firstName.label"
                        description="The label of the first name input in the share link modal"
                        defaultMessage="First Name"
                      />
                    </FormLabel>
                    <Input onChange={field.onChange} value={field.value || ""} placeholder={firstNamePlaceHolder} />
                  </FormControl>
                )}
              />
              <Controller
                name={`recipients.${index}.last_name`}
                control={props.control}
                rules={{
                  validate: (value, formValues) => {
                    return formValues.skipped_invites || value.length > 0
                  },
                }}
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={fieldState.invalid} isRequired>
                    <FormLabel>
                      <FormattedMessage
                        id="requests.run.shareLink.lastName.label"
                        description="The label of the last name input in the share link modal"
                        defaultMessage="Last Name"
                      />
                    </FormLabel>
                    <Input onChange={field.onChange} value={field.value || ""} placeholder={lastNamePlaceHolder} />
                  </FormControl>
                )}
              />
              <Controller
                name={`recipients.${index}.email`}
                control={props.control}
                rules={{
                  validate: (value, formValues) => {
                    if (formValues.skipped_invites) {
                      return true
                    }
                    const isEmail = isValidEmail(value)
                    const emailDomain = getEmailDomain(value)
                    if (!isEmail || !emailDomain) {
                      return intl.formatMessage({
                        id: "requests.run.shareLink.error.notValidEmail",
                        description: "The error message when the email is not valid",
                        defaultMessage: "Please enter a valid email address",
                      })
                    }
                    const isSameDomain = formValues.recipients.every((recipient) => {
                      return recipient.email.toLowerCase().endsWith(`@${emailDomain.toLowerCase()}`)
                    })
                    if (!isSameDomain) {
                      return intl.formatMessage({
                        id: "requests.run.shareLink.error.emailDomain",
                        description: "The error message when the email domain is not the same for all recipients",
                        defaultMessage: "All recipients must have the same email domain",
                      })
                    }
                    return true
                  },
                }}
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={fieldState.invalid} isRequired>
                    <FormLabel>
                      <FormattedMessage
                        id="requests.run.shareLink.email.label"
                        description="The label of the email input in the share link modal"
                        defaultMessage="Email"
                      />
                    </FormLabel>
                    <Input
                      onChange={field.onChange}
                      value={field.value || ""}
                      type="email"
                      placeholder={emailPlaceholder}
                    />
                    {fieldState.error?.message && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />
              <HStack justifyContent="space-between">
                {index === fields.length - 1 && (
                  <Button
                    variant="ghost"
                    onClick={() => append({ first_name: "", last_name: "", email: "" })}
                    colorScheme="brand"
                    size="sm"
                  >
                    <FormattedMessage
                      id="requests.run.shareLink.recipient.addAnother"
                      description="The label of the button to add another recipient in the share link modal"
                      defaultMessage="+ Add another"
                    />
                  </Button>
                )}
                {index > 0 && (
                  <Button variant="ghost" onClick={() => remove(index)} colorScheme="gray" marginLeft="auto" size="xs">
                    <FormattedMessage
                      id="requests.run.shareLink.recipient.remove"
                      description="The label of the button to remove a recipient in the share link modal"
                      defaultMessage="Remove"
                    />
                  </Button>
                )}
              </HStack>
            </Stack>
          ))}
        </Stack>
      </Box>
      <Divider marginTop={2} marginBottom={4} />
      <Stack spacing={4}>
        <Controller
          name="message"
          control={props.control}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <FormLabel>
                <FormattedMessage
                  id="requests.run.shareLink.message.label"
                  description="The label of the message input in the share link modal"
                  defaultMessage="Message"
                />
              </FormLabel>
              <Textarea onChange={field.onChange} value={field.value} placeholder={messagePlaceholder} />
            </FormControl>
          )}
        />
        <Controller
          name="seller_due_date"
          control={props.control}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid}>
              <FormLabel display="flex" alignItems="center" gap="2">
                <FormattedMessage
                  id="requests.run.shareLink.dueDate.label"
                  description="The label of the due date input in the share link modal"
                  defaultMessage="Seller Due Date"
                />
                <Tooltip
                  display="flex"
                  width={254}
                  label={
                    <Box>
                      <Text fontSize="xs" fontWeight="semibold">
                        <FormattedMessage
                          id="requests.run.shareLink.dueDate.tooltip.header"
                          description="The tooltip header of the due date input in the share link modal"
                          defaultMessage="Buy on your terms, not the seller’s"
                        />
                      </Text>
                      <Text fontSize="xs" fontWeight="medium" color="gray.500">
                        <FormattedMessage
                          id="requests.run.shareLink.dueDate.tooltip.body"
                          description="The tooltip body of the due date input in the share link modal"
                          defaultMessage="Require the seller to submit information back to you by a specified date."
                        />
                      </Text>
                    </Box>
                  }
                  shouldWrapChildren
                >
                  <Icon as={HelpIcon} color="gray.400" display="flex" />
                </Tooltip>
              </FormLabel>
              <DatePickerInput
                value={field.value}
                ariaLabel={intl.formatMessage({
                  id: "requests.run.shareLink.dueDate.aria_label",
                  description: "Aria label for the due date input in the share link modal",
                  defaultMessage: "Seller due date",
                })}
                onChange={field.onChange}
                dateShortcuts={getDefaultDateShortcuts(intl, {
                  sign: 1,
                  additionalShortcuts: [
                    {
                      displayName: intl.formatMessage({
                        id: "requests.run.shareLink.dueDate.shortcuts.in2Weeks",
                        description: "The label of the due date shortcut in the share link modal",
                        defaultMessage: "In 2 weeks",
                      }),
                      date: Temporal.PlainDate.from(Temporal.Now.plainDateISO())
                        .add(Temporal.Duration.from({ weeks: 2 }))
                        .toString(),
                    },
                    {
                      displayName: intl.formatMessage({
                        id: "requests.run.shareLink.dueDate.shortcuts.in2Months",
                        description: "The label of the due date shortcut in the share link modal",
                        defaultMessage: "In 2 months",
                      }),
                      date: Temporal.PlainDate.from(Temporal.Now.plainDateISO())
                        .add(Temporal.Duration.from({ months: 2 }))
                        .toString(),
                    },
                  ],
                })}
                minDate={Temporal.Now.plainDateISO().toString()}
              />
            </FormControl>
          )}
        />
      </Stack>
    </Box>
  )
}

export default WorkflowInviteSection
