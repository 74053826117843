import type { As, ButtonProps, ComponentWithAs } from "@chakra-ui/react"
import { Button, Icon, Stack, chakra, forwardRef } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"

interface NavButtonProps extends ButtonProps {
  icon: As
  label: string
  subItems?: { label: string; to: string; icon?: As }[]
}

// see https://chakra-ui.com/community/recipes/as-prop#option-1-using-forwardref-from-chakra-uireact
export const NavButton: ComponentWithAs<"button", NavButtonProps> = forwardRef<NavButtonProps, "button">(
  (props, ref) => {
    const { icon, label, subItems, ...buttonProps } = props
    return (
      <>
        <Button ref={ref} variant="ghost" justifyContent="start" leftIcon={<Icon as={icon} />} {...buttonProps}>
          <chakra.span>{label}</chakra.span>
        </Button>
        {subItems && subItems.length > 0 && (
          <Stack pl={3} gap={1}>
            {subItems.map((item) => (
              <Button
                as={NavLink}
                key={item.to}
                to={item.to}
                leftIcon={<Icon as={item.icon} />}
                variant="ghost"
                justifyContent="start"
                size="sm"
              >
                {item.label}
              </Button>
            ))}
          </Stack>
        )}
      </>
    )
  }
)
