import { useEffect } from "react"
import { Outlet } from "react-router-dom"
export default function FixedHeightContainer({ children }: { children?: React.ReactNode }) {
  useEffect(() => {
    document.documentElement.style.height = "100%" // Set desired height
    document.documentElement.style.overflow = "hidden" // Prevent e.g. a menu for making the root scroll
    // Revert the modifications when the component unmounts
    return () => {
      document.documentElement.style.removeProperty("height")
      document.documentElement.style.removeProperty("overflow")
    }
  }, [])

  return children ?? <Outlet />
}
