import { useState } from "react"

export interface StreamingData {
  content: string
  status: string
  isLoading: boolean
}

export interface StreamingController {
  onText: (text: string) => void
  onStatus: (status: string) => void
  start: () => void
  done: () => void
}

export function useStreaming(): [StreamingData, StreamingController] {
  const [streaming, setStreaming] = useState<StreamingData>({
    content: "",
    status: "waiting",
    isLoading: false,
  })

  const streamingController: StreamingController = {
    onText: (text: string) => setStreaming((prev) => ({ ...prev, content: prev.content + text })),
    onStatus: (status: string) => setStreaming((prev) => ({ ...prev, status })),
    start: () => setStreaming((prev) => ({ ...prev, status: "waiting", isLoading: true })),
    done: () => {
      // this timeout is here because we want to clear out the context of the previous streaming request,
      // but if we immediately update the state, the app flickers.
      // We want a smooth transition from the streamed content to the static timeline event content.
      setTimeout(() => {
        setStreaming(() => ({ content: "", status: "waiting", isLoading: false }))
      }, 1750)
    },
  }

  return [streaming, streamingController]
}
