import type { Permission, PermissionedEntityType, PermissionedPickableEntity } from "@brm/schema-types/types.js"
import { type PersonPicker, type UserPicker } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Box } from "@chakra-ui/react"
import type { Props, SelectInstance } from "chakra-react-select"
import { Select } from "chakra-react-select"
import { useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"
import type { PersonVariablePicker } from "../../app/services/generated-api.js"
import { usePostOrganizationV1PermissionedPickableEntitiesQuery } from "../../app/services/generated-api.js"
import { translatePersonVariableName } from "../../features/person/util.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { chakraStylesForProfilePicturePickerOption } from "./constants.js"

interface OrganizationEntityPickerProps extends Props<PermissionedPickableEntity, false> {
  initialSelected?: PermissionedPickableEntity | null
  ignoreMap?: Map<PermissionedEntityType, Set<string>>
  includedEntities: PermissionedEntityType[]
  onChangeUser?: (user: UserPicker) => void
  onChangePerson?: (person: PersonPicker) => void
  onChangeVariable?: (variable: PersonVariablePicker) => void
  // If the field the entity is being picked for requires a permission to be set
  requiredPermission?: Permission
  limit?: number
}

/**
 * Basic Organization Entity Picker that does not allow for inviting users or updating users, it simply includes different organizations entities
 * and allows for onChange callbacks to be called when a user or person is selected.
 *
 * This is used by components that are used by only InviteOrUpdateUserModal and UserUpdateModal because those components are used by OrganizationEntityPicker
 * and it would cause a circular dependency if they also used OrganizationEntityPicker to pick persons or users in their forms.
 */
function BasicOrganizationEntityPicker(props: OrganizationEntityPickerProps) {
  const {
    includedEntities,
    ignoreMap,
    onChangePerson,
    onChangeUser,
    onChangeVariable,
    initialSelected: initial,
    requiredPermission,
    limit = 10,
  } = props
  const intl = useIntl()
  const [pickableSearchValue, setPickableSearchValue] = useState("")
  const [selected, setSelected] = useState<PermissionedPickableEntity | null>(initial || null)
  const ref = useRef<SelectInstance<PermissionedPickableEntity, false>>(null)

  const { data, isLoading } = usePostOrganizationV1PermissionedPickableEntitiesQuery({
    body: {
      filter: {
        name: pickableSearchValue,
        entities: includedEntities,
        ...(requiredPermission ? { permission: { value: requiredPermission, includeAll: false } } : {}),
      },
      limit,
    },
  })

  const options = useMemo(() => {
    if (!ignoreMap || !data) {
      return data
    }
    return data.filter(
      (entity) => !ignoreMap.get(entity.type)?.has(entity.type === "variable" ? entity.variable : entity.id)
    )
  }, [data, ignoreMap])

  const onChange = (pickable: PermissionedPickableEntity | null) => {
    if (!pickable) {
      return
    }
    switch (pickable.type) {
      case "user":
        onChangeUser?.(pickable)
        setSelected(pickable)
        break
      case "person":
        onChangePerson?.(pickable)
        setSelected(pickable)
        break
      case "variable":
        setSelected(pickable)
        onChangeVariable?.(pickable)
        break
      default:
        unreachable(pickable)
    }
  }

  return (
    <Box flexGrow={1}>
      <Select<PermissionedPickableEntity>
        options={options}
        isLoading={isLoading}
        chakraStyles={chakraStylesForProfilePicturePickerOption((pickable: PermissionedPickableEntity) =>
          pickable.type !== "variable" ? getPublicImageGcsUrl(pickable.image_asset?.gcs_file_name) : undefined
        )}
        styles={{
          menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
        }}
        getOptionValue={(pickable) => (pickable.type === "variable" ? pickable.variable : pickable.id)}
        onChange={onChange}
        value={selected}
        inputValue={pickableSearchValue}
        onInputChange={(val) => setPickableSearchValue(val)}
        ref={ref}
        getOptionLabel={(pickable) =>
          pickable.type === "variable"
            ? translatePersonVariableName(pickable.variable, intl)
            : displayPersonName(pickable, intl)
        }
        {...props}
      />
    </Box>
  )
}
export default BasicOrganizationEntityPicker
