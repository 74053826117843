import { getParentObjectPath } from "@brm/schema-helpers/workflow.js"
import type {
  DocumentWithExtraction,
  FieldMetadataWithSuggestions,
  FieldSourceOutputProperties,
  LegalClausesFieldsMetadata,
  WorkflowRunStatePatch,
  WorkflowRunStepWithContext,
} from "@brm/schema-types/types.js"
import { FieldSourceInputPropertiesSchema } from "@brm/schemas"
import { isEmpty } from "@brm/util/type-guard.js"
import type { HTMLChakraProps } from "@chakra-ui/react"
import { Button, Center, Circle, Flex, HStack, Icon, Spinner, Stack, Text, Tooltip, chakra } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { skipToken } from "@reduxjs/toolkit/query"
import { excludeKeys } from "filter-obj"
import { flatten } from "flat"
import { useFlags } from "launchdarkly-react-client-sdk"
import type { FC, ReactElement, ReactNode } from "react"
import { useCallback, useEffect, useRef, useState, type FormEvent } from "react"
import type { SetValueConfig } from "react-hook-form"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useSearchParams } from "react-router-dom"
import { path } from "slate"
import type { Except, ReadonlyDeep } from "type-fest"
import { isInstanceOf } from "typed-assert"
import { useDebouncedCallback } from "use-debounce"
import { useSessionStorage } from "usehooks-ts"
import {
  useGetUserV1WhoamiQuery,
  useGetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrlQuery,
} from "../../../../app/services/generated-api.js"
import { DocumentViewerWithHighlighting } from "../../../../components/Document/DocumentViewerWithHighlighting.js"
import { DynamicFormField, type DynamicFormFieldProps } from "../../../../components/DynamicForm/DynamicFormField.js"
import FieldApprovalButton from "../../../../components/DynamicForm/FieldApprovalButton.js"
import { FieldSourceFooter } from "../../../../components/DynamicForm/FieldSourceFooter.js"
import type { InputContainerProps } from "../../../../components/DynamicForm/InputContainer.js"
import {
  FORM_MAX_WIDTH,
  getDefaultUserFieldSource,
  updateFormFieldsWithDirtyFields,
} from "../../../../components/DynamicForm/utils.js"
import { IconButtonWithTooltip } from "../../../../components/IconButtonWithTooltip.js"
import { CircleCheckIcon, NextIcon, XIcon } from "../../../../components/icons/icons.js"
import { getLegalDateShortcuts, getLegalDurationShortcuts } from "../../../../util/form.js"
import { log } from "../../../../util/logger.js"
import DynamoTelemetry from "../../../dynamo/TelemetryCard.js"
import {
  DRAFT_SAVE_DEBOUNCE_DELAY,
  WORKFLOW_TIMELINE_HIDE_STORAGE_KEY,
  canApproveStepFields,
  canApproveWorkflowRunStep,
  cleanValues,
  type GetLogoForOrganizationProps,
  type GetOrganizationActorProps,
  type WorkflowRunWithExternalFlag,
} from "../utils.js"

export interface WorkflowRunStepFormProps extends HTMLChakraProps<"form"> {
  /** The workflow run step to render a form for. */
  workflowRunStep: WorkflowRunStepWithContext

  workflowRun: WorkflowRunWithExternalFlag

  headerComponent: ReactElement

  /** Whether to make all fields in the form read-only. */
  isReadOnly: boolean

  /** Called when values were changed without submitting. Can be used to implement draft autosave. */
  saveDraft: (values: WorkflowRunStatePatch) => Promise<void>

  /** Called if the reset event is fired on the `<form>` element, e.g. by a `<button type="reset">`. */
  onReset?: () => void

  /** Called when the form was submitted and passed validation (or the submit button had the `formnovalidate` attribute). */
  onValidSubmit: (values: WorkflowRunStatePatch, event: SubmitEvent) => void

  /** Buttons to render below last form field where user can take submission action */
  submitButtons?: ReactElement | null

  /** An ID to attach to the `<form>` so that other buttons outside the form can refer to it through the `form` attribute. */
  id?: string

  contextSchema: ReadonlyDeep<JSONSchema>
  document?: DocumentWithExtraction
  setDocument: (document: DocumentWithExtraction | undefined) => void
}

export const WorkflowRunStepForm: React.FunctionComponent<
  WorkflowRunStepFormProps & GetOrganizationActorProps & GetLogoForOrganizationProps
> = ({
  workflowRunStep,
  workflowRun,
  headerComponent,
  isReadOnly,
  saveDraft,
  onValidSubmit,
  onReset,
  submitButtons,
  getOrganizationActorWhenActorMissing,
  getLogoToShowByOrganizationId,
  contextSchema,
  document,
  setDocument,
  ...formProps
}) => {
  const intl = useIntl()
  const formRef = useRef<HTMLElement | null>(null)
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { dynamoTelemetryEnabled } = useFlags()

  const [uncontrolledComponentResetId, setUncontrolledComponentResetId] = useState(0)
  const incrementUncontrolledComponentResetId = useCallback(() => setUncontrolledComponentResetId((id) => id + 1), [])
  const [_isTimelineHidden, setIsTimelineHidden] = useSessionStorage(WORKFLOW_TIMELINE_HIDE_STORAGE_KEY, false)

  const workflowToolListingId = workflowRun.tools?.[0]?.tool_listing_id
  const [selectedProvenancePath, setSelectedProvenancePath] = useState<{
    path: (string | number)[]
    parentPath: (string | number)[]
  }>()

  const {
    data: documentDownloadUrl,
    isLoading: documentIsLoading,
    isFetching: documentIsFetching,
  } = useGetWorkflowV1RunsByWorkflowRunIdDocumentsAndDocumentIdUrlQuery(
    document?.id
      ? {
          workflowRunId: workflowRun.id,
          documentId: document.id,
        }
      : skipToken
  )
  const form = useForm<WorkflowRunStatePatch>({
    defaultValues: workflowRun.merged_state,
    reValidateMode: "onSubmit",
  })

  const onProvenanceClick = useCallback(
    (path: (number | string)[], fieldSource?: FieldSourceOutputProperties) => {
      if (!fieldSource || !("id" in fieldSource)) {
        return
      }
      const documentId =
        (fieldSource.type === "document" && fieldSource.id) ||
        (fieldSource.assigned_by_metadata?.object_field_source.type === "document" &&
          fieldSource.assigned_by_metadata?.object_field_source.id)
      if (documentId) {
        // We need all the documents from the legal object since some step may not have the document configured
        const fieldSourceDocuments = workflowRun.merged_state.legal_agreement?.documents ?? []
        const fieldSourceDocument = fieldSourceDocuments.find((document) => document.id === documentId)

        if (path.length < 2) {
          log.warn("Unexpected field source path for document", path)
          return
        }
        // Legal agreement object is nested within context, we need the relative path
        const parentPath = path.slice(0, 1)
        const relativePath = path.slice(1)
        if (fieldSourceDocument) {
          setSelectedProvenancePath({ path: relativePath, parentPath })
          setDocument(fieldSourceDocument)
          setIsTimelineHidden(true)
        }
      }
    },
    [setDocument, setIsTimelineHidden, workflowRun.merged_state.legal_agreement?.documents]
  )
  useEffect(() => {
    // Close document viewer & reset provenance when switching between steps
    setSelectedProvenancePath(undefined)
    setDocument(undefined)
  }, [setDocument, workflowRunStep.id])

  // When context changes we update the form values
  useEffect(() => {
    // Maintain error states on fields so visual indicators don't disappear
    form.reset(workflowRun.merged_state, { keepDirtyValues: true, keepErrors: true })
  }, [form, workflowRun.merged_state])

  // When the draft state is updated by someone else, we need to reset the form to reflect the new state
  useEffect(() => {
    if (workflowRunStep.draft_state_updated_by?.id !== whoami?.id) {
      incrementUncontrolledComponentResetId()
    }
  }, [
    workflowRunStep.draft_state_updated_at,
    workflowRunStep.draft_state_updated_by,
    whoami?.id,
    incrementUncontrolledComponentResetId,
  ])

  const debouncedSaveDraftState = useDebouncedCallback(async () => {
    const draftStatePayload = updateFormFieldsWithDirtyFields<WorkflowRunStatePatch>(
      form.formState.dirtyFields,
      form.getValues(),
      contextSchema
    )
    if (!isEmpty(draftStatePayload)) {
      const cleanedValues = cleanValues(draftStatePayload, contextSchema) as WorkflowRunStatePatch
      void form.trigger(Object.keys(flatten(form.formState.dirtyFields)) as (keyof typeof form.formState.dirtyFields)[])
      await saveDraft(cleanedValues)
      // Reset dirty fields when the draft state gets flushed to the server so that changing a value back
      // to its original value will get recorded as a field change event even though from the perspective
      // of the form, the value is equal to its default value and therefore not dirty.
      form.reset(form.getValues(), { keepValues: true, keepDefaultValues: false, keepErrors: true })
    }
  }, DRAFT_SAVE_DEBOUNCE_DELAY)

  useEffect(() => {
    // Subscribe to react-hook-form watch to execute onChange on form state change
    const subscription = form.watch(() => {
      // react-hook-form does not update dirtyFields by the time the watch onChange is called.
      // setTimeout so we can leverage the dirtyField state and cleanly update only the draftState
      // fields that have been changed since the form was loaded.
      setTimeout(debouncedSaveDraftState, 1)
    })
    return () => subscription.unsubscribe()
  }, [form, debouncedSaveDraftState])

  const renderFieldSource = useCallback(
    (path: (string | number)[], fieldSource?: FieldSourceOutputProperties): ReactNode => {
      if (!fieldSource) {
        return null
      }

      return <FieldSourceFooter fieldSource={fieldSource} onClick={() => onProvenanceClick?.(path, fieldSource)} />
    },
    [onProvenanceClick]
  )

  const renderFieldWrapper = useCallback(
    (wrapperProps: InputContainerProps) => {
      const { workflowRunStep, workflowRun, fieldApproval } = wrapperProps
      const fieldIsApproved = fieldApproval?.status === "approved" || workflowRunStep?.status === "approved"
      const userCanApproveField =
        wrapperProps.workflowRunStep &&
        wrapperProps.workflowRun &&
        canApproveWorkflowRunStep(whoami, wrapperProps.workflowRunStep, wrapperProps.workflowRun)
      const approvalDisabled = workflowRunStep ? !canApproveStepFields(workflowRunStep) : true
      return (
        <Flex gap={2} alignItems="center">
          <Tooltip
            label={intl.formatMessage({
              id: "field.approved.tooltip",
              description: "Tooltip text when hovering over an approved readonly form field",
              defaultMessage:
                "This field has been approved and locked. To edit, click the approval check to the right.",
            })}
            closeOnClick={false}
            isDisabled={!fieldIsApproved || userCanApproveField || approvalDisabled}
          >
            <Flex direction="column" flexGrow={1} flexShrink={1} minWidth={0}>
              {wrapperProps.children}
            </Flex>
          </Tooltip>
          {workflowRun && workflowRunStep && (
            <FieldApprovalButton
              formField={wrapperProps.formField}
              label={wrapperProps.label}
              objectId={wrapperProps.objectId}
              workflowRunStep={workflowRunStep}
              workflowRun={workflowRun}
              fieldApproval={wrapperProps.fieldApproval}
              fieldFilledOut={wrapperProps.fieldFilledOut}
            />
          )}
        </Flex>
      )
    },
    [intl, whoami]
  )

  const onDocumentClick = useCallback(
    (document: DocumentWithExtraction) => {
      setDocument(document)
      setSelectedProvenancePath(undefined)
      setIsTimelineHidden(true)
    },
    [setDocument, setIsTimelineHidden]
  )

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()

    // Use the native Event object to get access to event.submitter, which React doesn't implement
    const { nativeEvent } = event
    isInstanceOf(nativeEvent, SubmitEvent)
    isInstanceOf(nativeEvent.submitter, HTMLButtonElement)
    const values = form.getValues()

    const cleanedValues = cleanValues(values, contextSchema) as WorkflowRunStatePatch
    if (nativeEvent.submitter.formNoValidate) {
      // Submit without validation in the case of reject decision
      onValidSubmit(cleanedValues, nativeEvent)
    } else {
      // Validate fields first in the case of approval decision
      await form.handleSubmit(() => {
        onValidSubmit(cleanedValues, nativeEvent)
      })(event)
    }
  }

  const handleReset = () => {
    form.reset(workflowRun.merged_state, { keepDefaultValues: true })
    onReset?.()
  }

  // Need to use `any` here because WorkflowRunContextPatch has circular references which causes a type error here otherwise.
  // Since this function is typed to take any string and returns unknown, type safety inside the function doesn't matter anyway.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formGetValue = useCallback((path: string): unknown => form.watch<any>(path || undefined), [form])
  const formSetValue = form.setValue as (path: string, value: unknown, options?: SetValueConfig) => void

  if (workflowRun.is_external && workflowRunStep.fields.length === 0) {
    return (
      <ExternalWorkflowRunStepEmptyState stepName={workflowRunStep.display_name} nextStep={workflowRunStep.next[0]} />
    )
  }

  const formFieldProps: Except<DynamicFormFieldProps, "path" | "formField"> &
    GetLogoForOrganizationProps &
    GetOrganizationActorProps = {
    selectedDocument: document,
    control: form.control,
    getValue: formGetValue,
    setValue: formSetValue,
    resetField: form.resetField as (path: string, options?: SetValueConfig) => void,
    rootBaseSchema: contextSchema,
    isReadOnly,
    commentCounts: "comment_counts" in workflowRun ? workflowRun.comment_counts : undefined,
    workflowRun,
    workflowRunStep,
    onDocumentClick,
    renderFieldWrapper,
    uncontrolledComponentResetId,
    incrementUncontrolledComponentResetId,
    getLogoToShowByOrganizationId,
    getOrganizationActorWhenActorMissing,
    renderFieldSource,
    onProvenanceClick,
  }

  return (
    <Flex alignItems="stretch" minHeight={0} minWidth={0} flex={1} gap={2}>
      {document && (
        <Center boxShadow="lg" minH={0} borderRightWidth="1px" flex={3}>
          {documentIsLoading || documentIsFetching ? (
            <Spinner />
          ) : (
            <DocumentViewerWithHighlighting
              document={document}
              path={selectedProvenancePath?.path}
              parentPath={selectedProvenancePath?.parentPath}
              rootSchema={contextSchema}
              downloadUrl={documentDownloadUrl?.download_url}
              requestingEntity={{
                object_type: "WorkflowRun",
                object_id: workflowRun.id,
                workflow_step_id: workflowRunStep?.id ?? undefined,
              }}
              fieldMetadata={
                selectedProvenancePath &&
                (formGetValue(
                  [...selectedProvenancePath.parentPath, "fields_metadata", ...selectedProvenancePath.path].join(".")
                ) as FieldMetadataWithSuggestions)
              }
              onAssignToCriteriaSuccess={(fieldPath, newVal, source, field) => {
                const parentObjectPath = getParentObjectPath(field.object_type)
                if (!parentObjectPath) {
                  return
                }
                formSetValue([...parentObjectPath, ...fieldPath].join("."), newVal, {
                  shouldDirty: true,
                })
                const existingFieldMetadata = excludeKeys(
                  formGetValue([...parentObjectPath, "fields_metadata", ...fieldPath].join(".")) || {},
                  (k) => k in FieldSourceInputPropertiesSchema.properties
                )
                if (document?.id && whoami) {
                  // Set assigned by metadata
                  if (fieldPath.includes("clauses")) {
                    formSetValue(
                      [...parentObjectPath, "fields_metadata", ...fieldPath.slice(path.length - 2)].join("."),
                      {
                        ...existingFieldMetadata,
                        ...getDefaultUserFieldSource(intl, whoami),
                        assigned_by_metadata: {
                          source,
                          object_field_source: {
                            type: "document",
                            id: document.id,
                            source_display_name: document.file_name ?? undefined,
                          },
                        },
                      } satisfies FieldMetadataWithSuggestions,
                      {
                        shouldDirty: true,
                      }
                    )
                    const clausesMetadata = excludeKeys(
                      (formGetValue([...parentObjectPath, "fields_metadata", "clauses"].toString()) ||
                        {}) as LegalClausesFieldsMetadata,
                      Array.from(Object.keys(FieldSourceInputPropertiesSchema.properties))
                    )
                    formSetValue(
                      [...parentObjectPath, "fields_metadata", "clauses"].join("."),
                      {
                        ...clausesMetadata,
                        verified: false,
                        updated_at: Temporal.Now.instant().toString(),
                      } satisfies FieldMetadataWithSuggestions,
                      {
                        shouldDirty: true,
                      }
                    )
                  } else {
                    formSetValue(
                      [...parentObjectPath, "fields_metadata", field.field_name].join("."),
                      {
                        ...existingFieldMetadata,
                        ...getDefaultUserFieldSource(intl, whoami),
                        verified: false,
                        assigned_by_metadata: {
                          source,
                          object_field_source: {
                            type: "document",
                            id: document.id,
                            source_display_name: document.file_name ?? undefined,
                          },
                        },
                      } satisfies FieldMetadataWithSuggestions,
                      {
                        shouldDirty: true,
                      }
                    )
                  }
                }
                incrementUncontrolledComponentResetId()
              }}
              closeButton={
                <IconButtonWithTooltip
                  icon={<Icon as={XIcon} />}
                  onClick={() => {
                    setDocument(undefined)
                  }}
                  variant="ghost"
                  label={intl.formatMessage({
                    defaultMessage: "Close",
                    id: "documentViewer.backButton.tooltip",
                    description: "label for previous page button in document viewer toolbar",
                  })}
                />
              }
            />
          )}
        </Center>
      )}
      <Flex flex={2} overflowY="auto" justifyContent="center">
        <Stack
          maxWidth={FORM_MAX_WIDTH}
          overflow="hidden"
          flexGrow={1}
          height="fit-content"
          // Only show border and shadow if there is no document
          borderWidth={document ? 0 : 1}
          boxShadow={document ? "none" : "xs"}
          borderRadius={document ? undefined : "lg"}
          m={2}
        >
          {headerComponent}
          <chakra.form
            {...formProps}
            display="flex"
            flexDirection="column"
            gap={6}
            onSubmit={onSubmit}
            onReset={handleReset}
            flexGrow={1}
            paddingX={4}
            onKeyDown={(event) => {
              // Prevents accidental form rejection or submission when pressing Enter while approving a step
              if (
                workflowRunStep.status === "submitted" &&
                event.key === "Enter" &&
                !(event.target instanceof HTMLButtonElement && event.target.type === "submit")
              ) {
                event.preventDefault()
              }
            }}
            noValidate={true} // ensure react-hook-form validation is used
            ref={formRef}
            sx={{
              // Block scrolling while suggestions are open to prevent lagging repositioning on scroll
              "&:has(> .mentions-input__suggestions)": {
                overflow: "hidden",
              },
            }}
          >
            {workflowRunStep.type === "compliance" && dynamoTelemetryEnabled ? (
              <DynamoTelemetry toolListingId={workflowToolListingId} />
            ) : null}
            {workflowRunStep.fields.flatMap((field) => {
              const maybeCustomFieldName = field.is_custom ? ["custom", field.field_name] : [field.field_name]
              if (field.object_type === "LegalAgreement") {
                const path = ["legal_agreement", ...maybeCustomFieldName]
                const legalAgreementPatch = form.getValues("legal_agreement")
                const dateShortcuts =
                  legalAgreementPatch &&
                  getLegalDateShortcuts(field.field_name, contextSchema, legalAgreementPatch, intl)
                const durationShortcuts = getLegalDurationShortcuts(field.field_name, intl)
                return (
                  <DynamicFormField
                    key={path.join(".")}
                    {...formFieldProps}
                    formField={field}
                    path={path}
                    dateShortcuts={dateShortcuts}
                    durationShortcuts={durationShortcuts}
                  />
                )
              }
              if (field.object_type === "Vendor") {
                const path = ["vendor", ...maybeCustomFieldName]
                return <DynamicFormField key={path.join(".")} formField={field} path={path} {...formFieldProps} />
              }
              if (field.object_type === "Tool") {
                return (
                  workflowRun.merged_state.tools?.map((_, toolIndex) => {
                    const path = ["tools", toolIndex, ...maybeCustomFieldName]
                    return <DynamicFormField key={path.join(".")} formField={field} path={path} {...formFieldProps} />
                  }) ?? []
                )
              }
              if (field.object_type === "WorkflowRun") {
                const path = maybeCustomFieldName
                return <DynamicFormField key={path.join(".")} formField={field} path={path} {...formFieldProps} />
              }
              return null
            })}
            {submitButtons ? <HStack justifyContent="end">{submitButtons}</HStack> : null}
          </chakra.form>
        </Stack>
      </Flex>
    </Flex>
  )
}

interface ExternalWorkflowRunStepEmptyStateProps {
  stepName: string
  nextStep?: string
}

const ExternalWorkflowRunStepEmptyState: FC<ExternalWorkflowRunStepEmptyStateProps> = ({ stepName, nextStep }) => {
  const [_, setSearchParams] = useSearchParams()
  return (
    <Stack alignItems="center" paddingTop={20}>
      <Circle size={24} bg="gray.100" color="white" p={2} marginBottom={4}>
        <Icon as={CircleCheckIcon} color="brand.600" boxSize={10} strokeWidth="1px" />
      </Circle>
      <Text fontSize="xl" fontWeight="semibold">
        <FormattedMessage
          id="workflow.step.empty.header"
          defaultMessage="Nothing to do here"
          description="Header message when there is nothing for the external party to do on a step"
        />
      </Text>
      <Text fontSize="md" color="gray.600">
        <FormattedMessage
          id="workflow.step.empty.message"
          defaultMessage="Your input is not required on the {stepName} step."
          description="Message when there is nothing for the external party to do on a step"
          values={{ stepName }}
        />
      </Text>
      {nextStep && (
        <Button
          onClick={() => {
            const urlParams = new URLSearchParams()
            urlParams.set("step", nextStep)
            setSearchParams(urlParams)
          }}
          colorScheme="brand"
          size="lg"
          rightIcon={<Icon as={NextIcon} />}
          marginTop={6}
        >
          <FormattedMessage
            id="workflow.step.empty.nextStepButton"
            defaultMessage="Next step"
            description="Button to navigate to the next step when there is nothing for the external party to do on a step"
          />
        </Button>
      )}
    </Stack>
  )
}
