import { HStack, Icon, Text } from "@chakra-ui/react"
import DateRange from "../../components/DateRange.js"
import { EmptySymbol } from "../../components/EmptySymbol.js"
import { CalendarIcon } from "../../components/icons/icons.js"

interface Props {
  startDate: string | null
  endDate: string | null
}

export default function AgreementStartEndDate({ startDate, endDate }: Props) {
  return (
    <HStack alignItems="center">
      <Icon as={CalendarIcon} />
      <Text>
        <DateRange startDate={startDate} endDate={endDate} defaultLabel={<EmptySymbol />} />
      </Text>
    </HStack>
  )
}
