import type { Task } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { Location } from "react-router-dom"
import { isNotVoid } from "typed-assert"
import { AGREEMENT_VERIFICATION_SELECT_TOP_AGREEMENT_HASH, AUTO_SELECT_STEP_HASH } from "../../workflows/constants.js"

export const getTaskPrimarySrcUrl = (task: Task) => {
  const { pathName, searchParams, hash } = getTaskUrlParts(task)
  if (hash) {
    return `${pathName}#${hash}`
  }
  if (searchParams) {
    return `${pathName}?${searchParams.toString()}`
  }
  return pathName
}

const getTaskUrlParts = (task: Task): { pathName: string; searchParams?: URLSearchParams; hash?: string } => {
  switch (task.type) {
    case "workflow_step_gatherer":
      return {
        pathName: `/inbox/tasks/requests/${task.workflow_run_id}`,
        hash: AUTO_SELECT_STEP_HASH,
      }
    case "workflow_run_step_ready_for_review":
    case "workflow_run_aggregated_steps_ready_for_review_or_approval":
      return {
        pathName: `/inbox/tasks/requests/${task.workflow_run_id}`,
        hash: AUTO_SELECT_STEP_HASH,
      }
    case "workflow_field_gatherer":
      return {
        pathName: `/inbox/tasks/requests/${task.workflow_run_id}`,
        searchParams: new URLSearchParams({
          step: task.workflow_run_step_id,
          task: task.type,
          type: task.object_type,
          object: task.object_id,
          field: task.field_name,
        }),
      }
    case "workflow_run_aggregated_gather_fields":
      return {
        pathName: `/inbox/tasks/requests/${task.workflow_run_id}`,
        hash: AUTO_SELECT_STEP_HASH,
      }
    case "agreement_renewal":
      return {
        pathName: task.tool_id ? `/inbox/tasks/tools/${task.tool_id}` : `/inbox/tasks/vendors/${task.vendor_id}`,
      }
    case "agreement_verification":
      return {
        pathName: "/inbox/tasks/agreements",
        hash: AGREEMENT_VERIFICATION_SELECT_TOP_AGREEMENT_HASH,
      }
    case "reply":
      if (task.workflow_run_id) {
        isNotVoid(task.object_type)
        isNotVoid(task.object_id)

        const searchParams = new URLSearchParams({
          event: task.timeline_event_id,
          type: task.object_type,
          object: task.object_id,
        })
        if (task.workflow_run_step_id) {
          searchParams.set("step", task.workflow_run_step_id)
        }

        if (task.field_name) {
          searchParams.set("field", task.field_name)
        }

        return {
          pathName: `/inbox/tasks/requests/${task.workflow_run_id}`,
          searchParams,
        }
      }
      if (task.vendor_id) {
        return {
          pathName: `/inbox/tasks/vendors/${task.vendor_id}/overview`,
          searchParams: new URLSearchParams({
            event: task.timeline_event_id,
          }),
        }
      }
      if (task.tool_id) {
        return {
          pathName: `/inbox/tasks/tools/${task.tool_id}/overview`,
          searchParams: new URLSearchParams({
            event: task.timeline_event_id,
          }),
        }
      }
      throw new Error("Reply task has no workflow run or vendor or tool")
    default:
      unreachable(task)
  }
}

export const compareLocationToTaskUrl = (location: Location, task: Task) => {
  const { pathname } = location
  const taskUrlData = getTaskUrlParts(task)
  return pathname.includes(taskUrlData.pathName)
}
