import { getLegalAgreementStatusSchema } from "@brm/schema-helpers/legal.js"
import type {
  AgreementEffectiveStatus,
  DocumentMinimal,
  FieldMetadata,
  LegalAgreementFieldsMetadata,
} from "@brm/schema-types/types.js"
import { DocumentExtractionStatusSchema } from "@brm/schemas"
import { getEnumMemberTitle } from "@brm/util/schema.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Temporal } from "@js-temporal/polyfill"
import type { IntlShape } from "react-intl"
import { isNotUndefined } from "typed-assert"
import type { AgreementType } from "../../app/services/generated-api.js"

/**
 * Returns a highlight color for a given start and end time, using the ratio returned from {@link getTimeLeftRelative}.
 */
export function getHighlightColorForRelativeTimeLeft(
  start: string | Temporal.PlainDateLike | undefined | null,
  end: string | Temporal.PlainDateLike | undefined | null
): "error" | "orange" | undefined {
  if (!start || !end) {
    return undefined
  }

  const ratio = getTimeLeftRelative(start, end)
  if (ratio < 0) {
    return undefined
  }
  if (ratio <= 0.1) {
    return "error"
  }
  if (ratio <= 0.2) {
    return "orange"
  }
  return undefined
}

/**
 * Returns the relative time left between now and a future date within a period up to the future date as a ratio
 * between 0-1 (0 = no time remaining, 1 = whole period still remaining).
 */
export function getTimeLeftRelative(
  start: string | Temporal.PlainDateLike,
  end: string | Temporal.PlainDateLike
): number {
  const periodDaysTotal = Temporal.PlainDate.from(start).until(end).total({ unit: "days", relativeTo: start })
  const now = Temporal.Now.plainDateISO()
  const daysUntilPeriodEnd = now.until(end).total({ unit: "days", relativeTo: now })
  const timeLeftRelative = daysUntilPeriodEnd / periodDaysTotal
  return timeLeftRelative
}

/**
 * Returns whether any of the fields in a legal object came from automatic extraction.
 */
export function hasExtractedFields(fieldsMetadata: LegalAgreementFieldsMetadata | undefined): boolean {
  return (
    !!fieldsMetadata && Object.values(fieldsMetadata).some((props: FieldMetadata) => props && props.type !== "user")
  )
}

export function getAgreementTypeDisplayText(intl: IntlShape, type: AgreementType): string {
  switch (type) {
    case "enterprise":
      return intl.formatMessage({
        defaultMessage: "Enterprise",
        description: "Agreement type: Enterprise",
        id: "agreementType.enterprise",
      })
    case "subscription":
      return intl.formatMessage({
        defaultMessage: "Subscription",
        description: "Agreement type: Subscription",
        id: "agreementType.subscription",
      })
    default:
      unreachable(type)
  }
}

export const legalAgreementStatusColors = (status: AgreementEffectiveStatus) => {
  const schema = getLegalAgreementStatusSchema(status)
  isNotUndefined(schema)
  return schema.colorScheme
}

export const displayLegalAgreementStatus = (status: AgreementEffectiveStatus) => {
  const agreementStatusSchema = getLegalAgreementStatusSchema(status)
  isNotUndefined(agreementStatusSchema)
  return agreementStatusSchema.title
}

export const documentStatusText = (
  document: Pick<DocumentMinimal, "status" | "extraction_status">,
  intl: IntlShape
) => {
  switch (document.status) {
    case "pending":
      return intl.formatMessage({
        defaultMessage: "Pending",
        description: "Document status: Pending",
        id: "document.status.pending",
      })
    case "uploaded":
      return intl.formatMessage({
        defaultMessage: "Scanning",
        description: "Document status: Scanning",
        id: "document.status.scanning",
      })
    case "scanned":
      return getEnumMemberTitle(document.extraction_status, DocumentExtractionStatusSchema)
    case "quarantined":
      return intl.formatMessage({
        defaultMessage: "Infected",
        description: "Document scan result: Infected",
        id: "document.scanResult.infected",
      })
    default:
      unreachable(document.status)
  }
}
