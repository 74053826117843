import { Alert, AlertIcon, Icon } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"

export default function ErrorAlert() {
  return (
    <Alert status="error">
      <Icon as={AlertIcon} color="error" />
      <FormattedMessage
        id="requests.start.modal.object_type.error"
        description="User-friendly error message to display when something goes wrong"
        defaultMessage="Oops! Something went wrong. Please try again or contact support if the issue persists."
      />
    </Alert>
  )
}
