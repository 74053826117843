import { Box, Card, chakra, Divider, HStack, Stack } from "@chakra-ui/react"
import { Select } from "chakra-react-select"
import { DisplayableIntegrationTypeIcon } from "../../components/icons/IntegrationProviderIcon.js"
import type { ChartCardProps } from "./types.js"

export default function ChartCard<SelectOption = unknown>({
  title,
  description,
  legend,
  selectProps,
  footer,
  children,
  chartSources,
  ...cardProps
}: ChartCardProps<SelectOption>) {
  return (
    <Card variant="outline" height="22.5rem" minWidth="36.5rem" flex={1} {...cardProps}>
      <Stack gap={0} px={6} pt={6} flexGrow={1} maxH="100%">
        <HStack
          alignItems="start"
          justifyContent="space-between"
          sx={{
            ".chakra-menu__icon-wrapper": {
              order: 2,
              marginInlineStart: "0.75rem",
              marginInlineEnd: "0",
              marginLeft: "auto",
            },
          }}
        >
          <Stack gap={0}>
            <HStack fontWeight="semibold" fontSize="lg">
              <chakra.span>{title}</chakra.span>
              {chartSources?.map((integrationType) => (
                <DisplayableIntegrationTypeIcon key={integrationType} type={integrationType} />
              ))}
            </HStack>
            {description}
          </Stack>
          <Stack alignItems="end" gap={4}>
            {selectProps && (
              <Box maxW="400px" flexShrink={1}>
                <Select size="sm" isSearchable={false} {...selectProps} />
              </Box>
            )}
            {legend}
          </Stack>
        </HStack>
        {children}
      </Stack>
      {footer && (
        <>
          <Divider />
          <HStack justify="flex-end" py={4} px={6} flexGrow={0}>
            {footer}
          </HStack>
        </>
      )}
    </Card>
  )
}
