import type { PersonDetails, PersonTool } from "@brm/schema-types/types.js"
import { getLastCharacter } from "@brm/util/string.js"
import { Box, Heading } from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"
import type { IntlShape } from "react-intl"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetPersonV1ByIdToolsQuery } from "../../app/services/generated-api.js"
import PersonCell from "../../components/DataTable/CellRenderer/PersonCell.js"
import ToolCell from "../../components/DataTable/CellRenderer/ToolCell.js"
import DataTable from "../../components/DataTable/DataTable.js"
import EmptyTableState from "../../components/DataTable/EmptyTableState.js"
import { FormattedDate } from "../../components/FormattedDate.js"

const columnHelper = createColumnHelper<PersonTool>()

const getColumns = (intl: IntlShape) => [
  columnHelper.accessor("display_name", {
    cell: ({ row }) => <ToolCell tool={row.original} />,
    header: intl.formatMessage({
      id: "person.tools.table.header.tool",
      description: "Person tools table column header - tool",
      defaultMessage: "Tool",
    }),
    enableSorting: false,
    size: 400,
  }),
  columnHelper.accessor("last_login", {
    cell: (info) => {
      const lastLogin = info.getValue()
      return lastLogin ? <FormattedDate value={lastLogin} /> : null
    },
    header: intl.formatMessage({
      id: "tool.users.table.header.login",
      description: "Tool users table column header - last login",
      defaultMessage: "Last Login",
    }),
    enableSorting: false,
  }),
]

interface Props {
  person: PersonDetails
}

export default function PersonOverview({ person }: Props) {
  const intl = useIntl()
  const { data: personTools } = useGetPersonV1ByIdToolsQuery({ id: person.id })

  return (
    <>
      {person.manager && (
        <>
          <Heading size="xs">
            <FormattedMessage
              id="person.view.manager"
              description="Heading for person’s manager section"
              defaultMessage="Manager"
            />
          </Heading>
          <Box pt={1}>
            <PersonCell person={person} />
          </Box>
        </>
      )}
      <Heading size="xs" paddingBottom={1} paddingTop={person.manager ? 4 : 0}>
        <FormattedMessage
          id="person.view.tools.table.heading"
          description="Heading for person’s tools table"
          defaultMessage="{name}{nameLastCharacter, select, s {’} other {’s}} Tools"
          values={{
            name: person.display_name,
            nameLastCharacter: getLastCharacter(person.display_name, intl.locale),
          }}
        />
      </Heading>

      {personTools && personTools.length > 0 ? (
        <DataTable data={personTools} columns={getColumns(intl)} />
      ) : (
        <EmptyTableState
          emptyState={intl.formatMessage({
            id: "person.toolUsage.emptyState",
            description: "Text to display instead of the tool usage table when there is no tool usage for a person",
            defaultMessage:
              "No employee data found. Connect your single sign-on provider to BRM to surface employee activity",
          })}
        />
      )}
    </>
  )
}
