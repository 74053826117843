import type { ListQueryStringParams, PersonListItem, PersonStatus } from "@brm/schema-types/types.js"
import type { Filter } from "@brm/type-helpers/filters.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { useMemo } from "react"
import type { Person } from "../../../app/services/generated-api.js"
import { usePostPersonV1Query } from "../../../app/services/generated-api.js"
import EmailTypeahead from "../../../components/Typeahead/EmailTypeahead.js"
import PersonDisplay from "../../person/PersonDisplay.js"

interface Props {
  onChangeSelectedPerson: (person: Person) => void
  onChangeEmail: (email: string) => void
  email: string
}

export default function InviteEmailAutocomplete(props: Props) {
  const { email, onChangeEmail, onChangeSelectedPerson } = props

  const apiParams = useMemo((): ListQueryStringParams => {
    const filter: Filter<BRMPaths<PersonListItem>>[][] = [
      [
        {
          column: "status",
          fields: {
            comparator: "any",
            values: ["active_employee", "active_user", "pending_employee", "pending_user"] satisfies PersonStatus[],
          },
        },
      ],
      // AND
      [
        {
          // person has no linked user
          column: "user_id",
          fields: { comparator: "exists", value: false },
        },
        // OR
        {
          // person is linked to a deleted user
          column: "user.status",
          fields: { comparator: "eq", value: "deleted" },
        },
      ],
    ]
    if (email) {
      filter.push([
        {
          column: "email",
          fields: { comparator: "contains", value: email },
        },
        // OR
        {
          column: "display_name",
          fields: { comparator: "contains", value: email },
        },
      ])
    }
    return { filter, limit: 10 }
  }, [email])

  const { data: personsData, error } = usePostPersonV1Query({ listQueryStringParams: apiParams })

  return (
    <EmailTypeahead
      data={personsData?.persons ?? []}
      emailValue={email}
      onChangeEmail={onChangeEmail}
      getItemValue={(item) => item.id}
      onSelectItem={(item) => {
        onChangeEmail(item.email ?? "")
        onChangeSelectedPerson(item)
      }}
      renderOption={(item) => <PersonDisplay person={item} />}
      error={error}
    />
  )
}
