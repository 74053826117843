import { unreachable } from "@brm/util/unreachable.js"
import { Button as ChakraButton, type ThemeTypings } from "@chakra-ui/react"
import type { FC, JSXElementConstructor, ReactElement, RefObject } from "react"
import { ButtonStyles } from "./types.js"

export const ButtonSizes = ["xs", "sm", "md", "lg"] as const

enum ButtonVariants {
  Solid = "solid",
  Outline = "outline",
  Ghost = "ghost",
  Link = "link",
}

interface ButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  label: string
  rightIcon?: ReactElement<unknown, string | JSXElementConstructor<unknown>>
  leftIcon?: ReactElement<unknown, string | JSXElementConstructor<unknown>>
  isDisabled?: boolean
  isLoading?: boolean
  size: (typeof ButtonSizes)[number]
  buttonStyles: ButtonStyles
  ref?: RefObject<HTMLButtonElement>
}

const getColorFromStyle = (style: ButtonStyles): ThemeTypings["colorSchemes"] => {
  switch (style) {
    case ButtonStyles.SolidBrand:
    case ButtonStyles.LinkBrand:
    case ButtonStyles.GhostBrand:
      return "brand"
    case ButtonStyles.SolidDestructive:
    case ButtonStyles.GhostDestructive:
      return "error"
    case ButtonStyles.Ghost:
    case ButtonStyles.OutlinedGray:
    case ButtonStyles.Link:
      return "gray"
    default:
      unreachable(style)
  }
}

const getVariantFromStyle = (style: ButtonStyles): ButtonVariants => {
  switch (style) {
    case ButtonStyles.SolidBrand:
    case ButtonStyles.SolidDestructive:
      return ButtonVariants.Solid
    case ButtonStyles.OutlinedGray:
      return ButtonVariants.Outline
    case ButtonStyles.Ghost:
    case ButtonStyles.GhostBrand:
    case ButtonStyles.GhostDestructive:
      return ButtonVariants.Ghost
    case ButtonStyles.Link:
    case ButtonStyles.LinkBrand:
      return ButtonVariants.Link
    default:
      unreachable(style)
  }
}

const Button: FC<ButtonProps> = ({
  label,
  onClick,
  leftIcon,
  rightIcon,
  isDisabled,
  isLoading,
  size,
  buttonStyles,
  ref,
}) => {
  return (
    <ChakraButton
      rightIcon={rightIcon}
      leftIcon={leftIcon}
      size={size}
      variant={getVariantFromStyle(buttonStyles)}
      colorScheme={getColorFromStyle(buttonStyles)}
      onClick={onClick}
      isDisabled={isDisabled}
      isLoading={isLoading}
      ref={ref}
    >
      {label}
    </ChakraButton>
  )
}

export default Button
