import { createSlice } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/browser"
import posthog from "posthog-js"
import { generatedApi } from "../../app/services/generated-api.js"

const userSlice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(generatedApi.endpoints.getUserV1Whoami.matchFulfilled, (_state, { payload }) => {
      Sentry.setUser({ id: payload.id, email: payload.email })
      posthog.identify(payload.id, { email: payload.email, organization_id: payload.organization_id })
    })
  },
})

export const userReducer = userSlice.reducer
