import type { PersonStatus, VariableType } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { IntlShape } from "react-intl"

export const displayPersonStatus = (status: PersonStatus, intl: IntlShape) => {
  switch (status) {
    case "active_employee":
      return intl.formatMessage({
        id: "person.status.active_employee",
        defaultMessage: "Current Employee",
        description: "The label for the person status `active_employee`",
      })
    case "inactive_employee":
      return intl.formatMessage({
        id: "person.status.former",
        defaultMessage: "Former Employee",
        description: "The label for the person status `former_employee`",
      })
    case "pending_employee":
      return intl.formatMessage({
        id: "person.status.pending",
        defaultMessage: "Pending Employee",
        description: "The label for the person status `pending_employee`",
      })
    case "active_user":
      return intl.formatMessage({
        id: "person.status.active_user",
        defaultMessage: "Active User",
        description: "The label for the person status `active_user`",
      })
    case "inactive_user":
      return intl.formatMessage({
        id: "person.status.inactive_user",
        defaultMessage: "Inactive User",
        description: "The label for the person status `inactive_user`",
      })
    case "pending_user":
      return intl.formatMessage({
        id: "person.status.pending_user",
        defaultMessage: "Pending User",
        description: "The label for the person status `pending_user`",
      })
    default:
      unreachable(status)
  }
}

export const translatePersonVariableName = (variable: VariableType, intl: IntlShape) => {
  switch (variable) {
    case "manager":
      return intl.formatMessage({
        id: "person.variable.manager",
        defaultMessage: "Manager",
        description: "The label for the person variable `manager`",
      })
    default:
      unreachable(variable)
  }
}
