import type { FieldMetadata, FieldMetadataWithSuggestions } from "@brm/schema-types/types.js"
import { getEnumOptions, type ConstSchema, type EnumTypeSchema } from "@brm/util/schema.js"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { Select, type Props } from "chakra-react-select"
import { forwardRef, useMemo } from "react"
import { useIntl } from "react-intl"
import type { ReadonlyObjectDeep } from "type-fest/source/readonly-deep.js"
import { isNewOption } from "../../util/form.js"
import OptionWithFieldSource from "../DynamicForm/OptionWithFieldSource.js"
import type { DynamicFormFieldApproval, ValueWithSource } from "../DynamicForm/types.js"

type EnumValue = ConstSchema<string | null | undefined> & {
  title?: string
}

type EnumSelectProps = Pick<Props, "isRequired" | "isReadOnly" | "isDisabled" | "menuPortalTarget"> & {
  value: string | null
  onChange: (value: string | null, fieldSource?: FieldMetadata) => void
  schema: EnumTypeSchema<ReadonlyObjectDeep<JSONSchemaObject>>
  allowNull: boolean
  suggestions?: ValueWithSource<string | null>[]
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: DynamicFormFieldApproval
}

export const EnumSelect = forwardRef<HTMLInputElement, EnumSelectProps>(function EnumSelect(
  {
    value,
    onChange,
    schema,
    allowNull,
    isReadOnly,
    isDisabled,
    menuPortalTarget,
    suggestions,
    fieldMetadata,
    fieldApproval,
  },
  ref
) {
  const intl = useIntl()

  const options = useMemo(
    () => [
      ...(allowNull ? [{ value: null }] : []),
      ...getEnumOptions(schema).map((option) => ({
        value: { const: option.const, title: option.title },
        field_sources: suggestions?.find((suggestion) => suggestion.value === option.const)?.field_sources ?? [],
      })),
    ],
    [schema, suggestions, allowNull]
  )

  const valueOption = useMemo(() => options.find((option) => option.value?.const === value), [options, value])

  return (
    <Select<ValueWithSource<EnumValue | null>>
      options={options}
      isDisabled={isDisabled}
      value={valueOption?.value ? valueOption : null}
      onChange={(option) => onChange(option?.value?.const ?? null, option?.field_sources?.[0])}
      placeholder={intl.formatMessage({
        id: "form.select.placeholder",
        defaultMessage: "Select an option...",
        description: "Placeholder for selection input",
      })}
      menuPortalTarget={menuPortalTarget}
      ref={(select) => {
        if (ref) {
          if (typeof ref === "function") {
            ref(select?.inputRef ?? null)
          } else {
            ref.current = select?.inputRef ?? null
          }
        }
      }}
      isSearchable={false}
      isOptionSelected={(option, [selectedOption]) =>
        !option ? !selectedOption : option.value?.const === selectedOption?.value?.const
      }
      isReadOnly={isReadOnly}
      formatOptionLabel={({ value, field_sources }, { context }) => {
        if (!value) {
          return ""
        }
        const label = value.title
        return context === "menu" ? (
          <OptionWithFieldSource fieldSources={field_sources}>{label}</OptionWithFieldSource>
        ) : (
          label
        )
      }}
      styles={{
        menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
      }}
      chakraStyles={{
        option: (provided, { data }) => {
          const { isNew, colorScheme } = isNewOption(data.field_sources, fieldMetadata, fieldApproval)
          return {
            ...provided,
            ...(isNew && {
              backgroundColor: `${colorScheme}.50`,
            }),
          }
        },
      }}
    />
  )
})
