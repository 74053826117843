import type { AgentTagElement } from "@brm/schema-types/types.js"
import { chakra, type ChakraProps } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { useFocused, useSelected, type RenderElementProps } from "slate-react"
import BetsyDisplayName from "../ContextMenu/BetsyDisplayName.js"
import { baseMentionSpanStyle } from "./util/mentions.js"

export const AgentTag = ({
  attributes,
  children,
  element,
}: RenderElementProps & {
  element: AgentTagElement
}) => {
  const selected = useSelected()
  const focused = useFocused()
  const spanStyle: ChakraProps = {
    ...baseMentionSpanStyle,
    backgroundColor: "blue.50",
    outline: "1px solid",
    outlineColor: "blue.200",
    boxShadow: selected && focused ? "0 0 0 2px var(--chakra-colors-blue-200)" : "none",
  }
  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0]?.bold) {
    spanStyle.fontWeight = "bold"
  }
  if (element.children[0]?.italic) {
    spanStyle.fontStyle = "italic"
  }

  return (
    <chakra.span {...attributes} contentEditable={false} data-cy={`agentTag-${element.display_name}`} {...spanStyle}>
      <FormattedMessage id="agentTag" description="Agent tag" defaultMessage="@" />
      <BetsyDisplayName />
      {children}
    </chakra.span>
  )
}
