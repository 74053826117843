import type { WorkflowRunStep } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { ThemeTypings } from "@chakra-ui/react"
import type { WorkflowRunStatus } from "../../../app/services/generated-api.js"

export const workflowRunStatusColors: Record<WorkflowRunStatus, ThemeTypings["colorSchemes"]> = {
  in_progress: "brand",
  approved: "green",
  rejected: "error",
  cancelled: "gray",
}

export function getStepStatusColor(step: Pick<WorkflowRunStep, "status">): ThemeTypings["colorSchemes"] {
  switch (step.status) {
    case "pending":
    case "pending_submitted":
    case "cancelled":
    case "skipped":
      return "gray"
    case "in_progress":
      return "brand"
    case "submitted":
      return "blue"
    case "completed":
    case "approved":
      return "green"
    case "rejected":
      return "error"
    default:
      unreachable(step.status)
  }
}
