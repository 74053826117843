import { formatFieldPathLabel } from "@brm/schema-helpers/format.js"
import { actionPermission, hasPermission } from "@brm/schema-helpers/role.js"
import { getSchemaObjectType } from "@brm/type-helpers/schema.js"
import { iterateJsonSchema, type SchemaNode } from "@brm/util/iterate-schema.js"
import { getSchemaAtPath, getTitle, shouldSkipChildrenDisplay } from "@brm/util/schema.js"
import { isObject } from "@brm/util/type-guard.js"
import type { ButtonProps, CheckboxProps } from "@chakra-ui/react"
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Heading,
  Icon,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
  chakra,
  useDisclosure,
} from "@chakra-ui/react"
import type { DraggableProvided, DraggableStateSnapshot } from "@hello-pangea/dnd"
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DraggingStyle,
  type DropResult,
  type NotDraggingStyle,
} from "@hello-pangea/dnd"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import equal from "fast-deep-equal"
import type { CSSProperties, ReactNode } from "react"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import { walkDOMParents } from "../../util/dom.js"
import { getSearchableString } from "../../util/searchable-string.js"
import { ChevronDownIcon, CustomizeColumnsIcon, DragAndDropIcon } from "../icons/icons.js"
import { useDraggableInPortal } from "./use-draggable-in-portal.js"
import { ROOT_COLUMN_ID } from "./use-schema-columns.js"

const inactiveKey = "inactive"
const activeKey = "active"

// The distance the menu list opens from the top of the TableFilter header plus a pagination control buffer at the bottom and the popover footer and header
const POPOVER_BODY_HEIGHT_SUBTRACTION = 470
// The padding on the left and right of the popover. This is not simply set on the PopoverContent because dividers need to take up the entire width
const POPOVER_PADDING_X = "1rem"

/**
 * Helper function that returns a new list with the element at startIndex and moved to be after the element at endIndex
 */
function reorder(items: Iterable<string>, startIndex: number, endIndex: number): string[] {
  const list = Array.from(items)
  const start = list[startIndex]
  if (!start) {
    return list
  }
  const removed = list.toSpliced(startIndex, 1)
  return removed.toSpliced(endIndex, 0, start)
}

/**
 * Helper function that overrides the style of a draggable element that is inside a menu list. This is necessary because
 * Draggable refs use fixed positioning relative to the entire page and not inside the menu list. We need to recalculate
 * what left and top should be based on the menu list's index.
 */
const getStyle = (
  style: DraggingStyle | NotDraggingStyle | undefined,
  snapshot: DraggableStateSnapshot
): CSSProperties => {
  let adjustedStyle: CSSProperties | undefined =
    snapshot.isDragging && style ? { ...style, background: "var(--chakra-colors-gray-200)" } : style

  if (snapshot.draggingOver === inactiveKey) {
    adjustedStyle = adjustedStyle
      ? {
          ...adjustedStyle,
          opacity: 0.5,
        }
      : {}
  }
  if (!snapshot.isDropAnimating) {
    return adjustedStyle || {}
  }

  return {
    ...adjustedStyle,
    // Instant drop animation
    transitionDuration: "0.001s",
  }
}

interface SchemaTableColumnCustomizationProps {
  objectSchema: ReadonlyDeep<JSONSchemaObject>

  /**
   * The paths of the currently shown columns in the table.
   */
  activeColumns: readonly string[]
  onActiveColumnsChange?: (newActivePaths: readonly string[]) => void

  /**
   * The path for the primary column.
   */
  primaryColumn: string | undefined
}

export default function SchemaTableColumnCustomization({
  primaryColumn,
  activeColumns,
  onActiveColumnsChange,
  objectSchema,
}: SchemaTableColumnCustomizationProps) {
  const intl = useIntl()
  const collator = useMemo(() => new Intl.Collator(intl.locale), [intl.locale])

  const searchInputRef = useRef<HTMLInputElement>(null)
  const [searchQuery, setSearchQuery] = useState("")

  const { data: whoami } = useGetUserV1WhoamiQuery()

  const popoverTriggerButtonRef = useRef<HTMLButtonElement>(null)
  const { isOpen, onClose, onOpen } = useDisclosure()

  // Scroll-lock any ancestors of the button when the popover is open
  useEffect(() => {
    if (isOpen) {
      scrollLock()
    } else {
      undoScrollLock()
    }
    return undoScrollLock

    function scrollLock() {
      if (!popoverTriggerButtonRef.current) {
        return
      }
      for (const element of walkDOMParents(popoverTriggerButtonRef.current)) {
        const overflow = getComputedStyle(element).overflow
        if (overflow === "auto" || overflow === "scroll") {
          if (element.style.overflow) {
            element.style.setProperty("--original-overflow", element.style.overflow)
          }
          element.dataset.scrollLock = "true"
          element.style.overflow = "hidden"
        }
      }
    }
    function undoScrollLock() {
      if (!popoverTriggerButtonRef.current) {
        return
      }
      for (const element of walkDOMParents(popoverTriggerButtonRef.current)) {
        if (!element.dataset.scrollLock) {
          continue
        }
        delete element.dataset.scrollLock
        element.style.removeProperty("overflow")
        const originalOverflow = element.style.getPropertyValue("--original-overflow")
        element.style.removeProperty("--original-overflow")
        if (originalOverflow) {
          element.style.setProperty("overflow", originalOverflow)
        } else {
          element.style.removeProperty("overflow")
        }
      }
    }
  }, [isOpen])

  const initialColumns = useMemo(
    (): readonly string[] =>
      activeColumns.filter((column) => getSchemaAtPath(objectSchema, column) && column !== primaryColumn),
    [activeColumns, objectSchema, primaryColumn]
  )

  /** The paths into each row object that will be displayed after clicking save. */
  const [newActivePaths, setNewActivePaths] = useState<readonly string[]>(initialColumns)
  const newActivePathsSet = useMemo(() => new Set(newActivePaths), [newActivePaths])

  // When new activeColumns are passed in, reset the newActivePaths.
  useEffect(() => {
    setNewActivePaths(initialColumns)
  }, [initialColumns])

  const onDragEnd = useCallback(({ source, destination }: DropResult) => {
    if (!destination) {
      // Dropped outside the list
      return
    }
    if (destination.droppableId === inactiveKey) {
      setNewActivePaths((paths) => paths.toSpliced(source.index, 1))
    } else {
      setNewActivePaths((paths) => reorder(paths, source.index, destination.index))
    }
  }, [])

  const allPossibleColumns = useMemo(() => {
    const nodes: (SchemaNode & { dataPath: readonly string[] })[] = []
    // Iterate manually (without using `for of`) so that we can send a skip instruction back to the generator when needed.
    const iterator = iterateJsonSchema(objectSchema)
    let result = iterator.next()
    while (!result.done) {
      const node = result.value
      if (
        (node.dataPath.length === 0 || node.keyword === "properties") &&
        !(isObject(node.schema) && (node.schema.tableDisplay === false || node.schema.displayable === false)) &&
        // Don't offer to index into specific array items (e.g. departments should always display the whole list of
        // departments not only the Nth).
        node.dataPath.every((part) => typeof part === "string") &&
        (!isObject(node.schema) ||
          !node.schema.permission ||
          hasPermission(whoami?.roles, actionPermission(node.schema.permission, "read")))
      ) {
        nodes.push(node as SchemaNode & { dataPath: readonly string[] })
      }

      result = iterator.next(shouldSkipChildrenDisplay(node.schema) ? "skip" : undefined)
    }
    nodes.sort((a, b) =>
      collator.compare(formatFieldPathLabel(a.dataPath, objectSchema), formatFieldPathLabel(b.dataPath, objectSchema))
    )
    return nodes
  }, [collator, objectSchema, whoami?.roles])

  const additionalColumns = useMemo(() => {
    const searchableQuery = getSearchableString(searchQuery, intl)
    return allPossibleColumns.filter(({ dataPath }) => {
      const pathStr = dataPath.join(".")
      return (
        !newActivePathsSet.has(pathStr) &&
        pathStr !== "" &&
        pathStr !== primaryColumn &&
        (!searchQuery ||
          getSearchableString(formatFieldPathLabel(dataPath, objectSchema), intl).includes(searchableQuery))
      )
    })
  }, [allPossibleColumns, intl, newActivePathsSet, objectSchema, primaryColumn, searchQuery])

  const renderDraggable = useDraggableInPortal()

  const customizeColumnsLabel = intl.formatMessage({
    id: "table.column.customize.label",
    description:
      "Label for icon button near tables that opens a popover to allow users to customize the shown columns on the table",
    defaultMessage: "Customize columns",
  })

  return (
    <Popover
      placement="bottom-end"
      onClose={onClose}
      isOpen={isOpen}
      onOpen={onOpen}
      isLazy
      initialFocusRef={searchInputRef}
    >
      {/* https://github.com/chakra-ui/chakra-ui/issues/2843 */}
      <Tooltip label={customizeColumnsLabel}>
        <Box display="inline-block">
          <PopoverTrigger>
            <Button
              variant="outline"
              leftIcon={<Icon as={CustomizeColumnsIcon} />}
              rightIcon={<Icon as={ChevronDownIcon} />}
              iconSpacing={0.5}
              aria-label={customizeColumnsLabel}
              ref={popoverTriggerButtonRef}
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>
      {/* Use a fixed width to avoid layout shift when filtering using the search box. */}
      <PopoverContent width="30rem">
        <PopoverHeader
          zIndex={1}
          background="white"
          justifyContent="space-between"
          fontSize="md"
          paddingX={POPOVER_PADDING_X}
          display="flex"
          flexDirection="column"
          gap={4}
        >
          <Flex>
            <Text color="gray.900" fontWeight="medium">
              {customizeColumnsLabel}
            </Text>
            <PopoverCloseButton position="inherit" ml="auto" />
          </Flex>
          <Input
            type="search"
            placeholder={intl.formatMessage({
              defaultMessage: "Search for columns...",
              id: "table.column.customization.search.placeholder",
              description: "Placeholder text for the search input in the column customization menu",
            })}
            aria-label={intl.formatMessage({
              defaultMessage: "Search for columns",
              id: "table.column.customization.search.ariaLabel",
              description: "ARIA label for the search input in the column customization menu",
            })}
            ref={searchInputRef}
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
          />
        </PopoverHeader>
        <PopoverBody
          flexGrow={1}
          minHeight={0}
          flexShrink={1}
          gap={0}
          padding={0}
          overflowY="auto"
          maxH={`calc(100vh - ${POPOVER_BODY_HEIGHT_SUBTRACTION}px)`}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            {!searchQuery && (
              <Box paddingX={POPOVER_PADDING_X} paddingY="0.75rem" borderBottomWidth="1px">
                <Heading size="xxs" as="h3" pt={0} pl={2} pb={2} color="gray.600" fontWeight="medium">
                  <FormattedMessage
                    id="table.column.customization.active"
                    defaultMessage="Active"
                    description="Text displayed over the list of active columns in the column customization menu"
                  />
                </Heading>
                {primaryColumn !== undefined && (
                  <ColumnCustomizationCheckbox
                    isDisabled={true}
                    label={getTitle(
                      primaryColumn === ROOT_COLUMN_ID ? (getSchemaObjectType(objectSchema) ?? "") : primaryColumn,
                      getSchemaAtPath(objectSchema, primaryColumn === ROOT_COLUMN_ID ? "" : primaryColumn)
                    )}
                    checkboxProps={{
                      pointerEvents: "none",
                      isIndeterminate: true,
                    }}
                  />
                )}
                {newActivePaths.length > 0 ? (
                  <Droppable droppableId={activeKey} type="columnCustomization">
                    {(droppableProvided) => (
                      <Stack paddingTop={2} {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                        {newActivePaths.map((path, idx) => {
                          const pathArray = path.split(".").filter(Boolean)
                          const schema = getSchemaAtPath(objectSchema, pathArray)
                          if (!schema) {
                            return null
                          }
                          return (
                            <Draggable key={path} draggableId={path} index={idx} disableInteractiveElementBlocking>
                              {renderDraggable((draggableProvided, draggableSnapshot) => (
                                <ColumnCustomizationCheckbox
                                  drag={{ provided: draggableProvided, snapshot: draggableSnapshot }}
                                  label={formatFieldPathLabel(pathArray, objectSchema)}
                                  checkboxProps={{
                                    isChecked: true,
                                    onChange: () => {
                                      setNewActivePaths((paths) => paths.toSpliced(idx, 1))
                                    },
                                  }}
                                />
                              ))}
                            </Draggable>
                          )
                        })}
                        {droppableProvided.placeholder}
                      </Stack>
                    )}
                  </Droppable>
                ) : (
                  <Text px={3} mt={3} mb={1} color="gray.500" textAlign="center" userSelect="none">
                    <FormattedMessage
                      id="table.column.customization.menu.noActiveColumns"
                      description="Text displayed when no columns are selected in the column customization menu"
                      defaultMessage="Columns will revert to the default selection"
                    />
                  </Text>
                )}
              </Box>
            )}

            {/* Available non-active columns */}
            <Box paddingX={POPOVER_PADDING_X} paddingY="0.75rem">
              <Heading size="xxs" as="h3" paddingTop={0} paddingLeft={2} color="gray.600" fontWeight="medium">
                <FormattedMessage
                  id="table.column.customization.available"
                  defaultMessage="Available"
                  description="Text for the section of the column customization menu that lists the available columns to add"
                />
              </Heading>
              {additionalColumns.length > 0 ? (
                <Droppable droppableId={inactiveKey} type="columnCustomization">
                  {(droppableProvided) => (
                    <Stack paddingTop={2} {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                      {droppableProvided.placeholder}
                      {additionalColumns.map(({ dataPath }) => {
                        const pathStr = dataPath.join(".")
                        return (
                          <ColumnCustomizationCheckbox
                            key={pathStr}
                            label={formatFieldPathLabel(dataPath, objectSchema)}
                            onClick={() => {
                              setNewActivePaths((paths) => [...paths, pathStr])
                              setSearchQuery("")
                              searchInputRef.current?.focus()
                            }}
                            checkboxProps={{ isChecked: false }}
                          />
                        )
                      })}
                    </Stack>
                  )}
                </Droppable>
              ) : (
                <Text px={3} mt={3} mb={1} color="gray.500">
                  {searchQuery ? (
                    <FormattedMessage
                      defaultMessage="No columns matching {searchQuery}"
                      id="table.column.customization.menu.noMatchingColumns"
                      description="Text displayed when no columns match the search term in the column customization menu"
                      values={{ searchQuery: <q>{searchQuery}</q> }}
                    />
                  ) : (
                    <FormattedMessage
                      id="table.column.customization.menu.noAdditionalColumns"
                      description="Text displayed when no additional columns are available in the column customization menu"
                      defaultMessage="All columns are active"
                    />
                  )}
                </Text>
              )}
            </Box>
          </DragDropContext>
        </PopoverBody>
        <PopoverFooter px={POPOVER_PADDING_X} py={4} borderTopWidth="1px" zIndex={1} background="white">
          <HStack>
            <Button
              m={0}
              ml="auto"
              onClick={() => {
                setNewActivePaths(initialColumns)
                onClose()
              }}
            >
              <FormattedMessage
                id="tool.list.addFilter.button"
                description="Title of a button that allows users to add filters to a table"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              margin={0}
              colorScheme="brand"
              onClick={() => {
                onActiveColumnsChange?.(newActivePaths)
                onClose()
              }}
              isDisabled={equal(newActivePaths, initialColumns)}
            >
              <FormattedMessage
                id="tool.list.addFilter.button"
                description="Title of a button that allows users to add filters to a table"
                defaultMessage="Save"
              />
            </Button>
          </HStack>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  )
}

interface ColumnCustomizationCheckboxProps extends ButtonProps {
  // Can't conflict with ButtonProps draggable
  drag?: {
    provided: DraggableProvided
    snapshot: DraggableStateSnapshot
  }
  isDisabled?: boolean
  checkboxProps?: CheckboxProps
  label: ReactNode
}

function ColumnCustomizationCheckbox({
  label,
  checkboxProps,
  drag,
  isDisabled,
  ...buttonProps
}: ColumnCustomizationCheckboxProps) {
  return (
    <Button
      ref={drag ? drag.provided.innerRef : undefined}
      {...drag?.provided.draggableProps}
      {...drag?.provided.dragHandleProps}
      style={drag ? getStyle(drag.provided.draggableProps.style, drag.snapshot) : undefined}
      isDisabled={isDisabled}
      _disabled={{
        opacity: 1,
        background: "gray.50",
        cursor: "not-allowed",
        _hover: { background: "gray.50" },
      }}
      width="100%"
      fontSize="sm"
      fontWeight="medium"
      paddingX="0.875rem"
      paddingY="0.625rem"
      variant="outline"
      {...buttonProps}
    >
      <HStack width="100%">
        <Checkbox {...checkboxProps} />
        <HStack justifyContent="space-between" flexGrow={1} minWidth={0}>
          <chakra.span overflow="hidden" textOverflow="ellipsis" minWidth={0}>
            {label}
          </chakra.span>
          {drag && <Icon as={DragAndDropIcon} ml="auto" />}
        </HStack>
      </HStack>
    </Button>
  )
}
