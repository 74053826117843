import type { BlockQuoteElement, ObjectType } from "@brm/schema-types/types.js"
import { isEmpty } from "@brm/util/type-guard.js"
import {
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  type MenuItemProps,
  type ModalProps,
} from "@chakra-ui/react"
import equal from "fast-deep-equal"
import { useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useLocation, type Location } from "react-router-dom"
import { type Descendant } from "slate"
import { useCustomCompareEffect } from "use-custom-compare"
import {
  useLazyGetLegalV1AgreementsByIdQuery,
  useLazyGetToolV1ByIdQuery,
  useLazyGetVendorV1ByIdQuery,
  useLazyGetWorkflowV1RunsByIdQuery,
  type LegalAgreementListItem,
  type Tool,
  type Vendor,
  type WorkflowRun,
} from "../../app/services/generated-api.js"
import BetsyChat from "../../features/betsy/BetsyChat.js"
import BraimIcon from "../BraimIcon.js"
import BetsyDisplayName from "./BetsyDisplayName.js"

const getObjectAndId = (location: Location<string>): { object: ObjectType; id?: string } | undefined => {
  const pathName = location.pathname
  const agreementMatch = pathName.match(/\/agreements\/([^/]+)/u)
  if (agreementMatch) {
    return { id: agreementMatch[1], object: "LegalAgreement" }
  }
  const toolMatch = pathName.match(/\/tools\/([^/]+)/u)
  if (toolMatch) {
    return {
      object: "Tool",
      id: toolMatch[1],
    }
  }
  const vendorMatch = pathName.match(/\/vendors\/([^/]+)/u)
  if (vendorMatch) {
    return {
      object: "Vendor",
      id: vendorMatch[1],
    }
  }
  const requestMatch = pathName.match(/\/requests\/([^/]+)/u)
  if (requestMatch) {
    return {
      object: "WorkflowRun",
      id: requestMatch[1],
    }
  }
  return undefined
}

export const BetsyContextMenuItem = (props: MenuItemProps) => {
  return (
    <MenuItem {...props} icon={<BraimIcon />}>
      <FormattedMessage
        defaultMessage="Ask {ai}"
        description="Label for the menu item open up chat with brm ai"
        id="context-menu.item.ask-betsy"
        values={{
          ai: <BetsyDisplayName />,
        }}
      />
    </MenuItem>
  )
}

type ContextType = LegalAgreementListItem | Tool | Vendor | WorkflowRun | undefined

export const BetsyModal = (
  props: Omit<ModalProps, "children"> & {
    /** Initial highlighted text before opening betsy modal */
    highlightedText?: string
  }
) => {
  const { highlightedText, ...modalProps } = props
  const location = useLocation()
  const parsedObjectAndId = getObjectAndId(location)
  const [context, setContext] = useState<ContextType>(undefined)

  const [getAgreementById] = useLazyGetLegalV1AgreementsByIdQuery()
  const [getToolById] = useLazyGetToolV1ByIdQuery()
  const [getWorkflowRunById] = useLazyGetWorkflowV1RunsByIdQuery()
  const [getVendorById] = useLazyGetVendorV1ByIdQuery()

  const initialValue = useMemo(() => {
    const trimmedHighlightedText = highlightedText?.trim()
    return context
      ? ([
          {
            type: "paragraph",
            children: [
              {
                type: "standard-object",
                display_name: context.display_name,
                object_type: context.object_type,
                id: context.id,
                children: [{ text: "" }],
              },
            ],
          },
          ...(trimmedHighlightedText && !isEmpty(trimmedHighlightedText)
            ? [
                {
                  type: "block-quote",
                  children: [
                    {
                      text: trimmedHighlightedText,
                    },
                  ],
                } as BlockQuoteElement,
              ]
            : []),
          {
            type: "paragraph",
            children: [{ text: " " }],
          },
        ] satisfies Descendant[])
      : undefined
  }, [context, highlightedText])

  useCustomCompareEffect(
    () => {
      const fetchData = async () => {
        if (parsedObjectAndId) {
          const { object, id } = parsedObjectAndId
          if (!id) return
          switch (object) {
            case "LegalAgreement": {
              const agreement = await getAgreementById({ id }).unwrap()
              setContext(agreement)
              break
            }
            case "Tool": {
              const tool = await getToolById({ id }).unwrap()
              setContext(tool)
              break
            }
            case "Vendor": {
              const vendor = await getVendorById({ id }).unwrap()
              setContext(vendor)
              break
            }
            case "WorkflowRun": {
              const workflowRun = await getWorkflowRunById({ id }).unwrap()
              setContext(workflowRun)
              break
            }
          }
        }
      }

      void fetchData()
    },
    [getAgreementById, getToolById, getVendorById, getWorkflowRunById, parsedObjectAndId],
    equal
  )
  const [conversationId, setConversationId] = useState<string>(crypto.randomUUID())
  return (
    <Modal {...modalProps} size="2xl">
      <ModalContent maxH="85vh" minH="40vh" boxShadow="2xl">
        <ModalCloseButton />
        <ModalBody
          overflow="auto"
          sx={{
            "scrollbar-width": "thin",
          }}
        >
          <BetsyChat
            startNewConversation={() => setConversationId(crypto.randomUUID())}
            conversation={{ id: conversationId }}
            value={initialValue}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
