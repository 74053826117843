import { isObject } from "@brm/util/type-guard.js"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  chakra,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react"
import { useState, type FC } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useStore } from "react-redux"
import { useParams } from "react-router-dom"
import { brmApi } from "../../app/services/brm-api.js"
import { usePostUserV1CreateUserMutation } from "../../app/services/generated-api.js"
import BrmLogo from "../../components/BrmLogo.js"
import FeaturedIcon from "../../components/FeaturedIcon/FeaturedIcon.js"
import { MailIcon } from "../../components/icons/icons.js"
import { Link } from "../../components/Link.js"
import { getAPIErrorMessage } from "../../util/error.js"
import { TermsAndConditions } from "./LoginBaseContainer.js"
import OAuthButtonGroup from "./OAuthButtonGroup.js"

interface CreateUserStateForm {
  email: string
  first_name: string
  last_name: string
}

/**
 * @see https://pro.chakra-ui.com/components/application/authentication
 */
export default function CreateAccount() {
  const [sentEmailSuccessfully, setSentEmailSuccessfully] = useState(false)
  const [email, setEmail] = useState("")

  return (
    <Container maxW="lg" py={{ base: "12", md: "24" }} px={{ base: "0", sm: "8" }}>
      <Stack spacing="8">
        <Stack spacing="6">
          <Flex justifyContent="center">
            <BrmLogo h="200px" w="200px" />
          </Flex>
          {!sentEmailSuccessfully && <CreateAccountPageHeaders />}
        </Stack>
        <Box
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          bg={useBreakpointValue({
            base: "transparent",
            sm: "bg-surface",
          })}
          borderRadius={{ base: "none", sm: "xl" }}
        >
          <Stack spacing="5">
            {sentEmailSuccessfully ? (
              <SentEmailResult email={email} />
            ) : (
              <CreateAccountForm
                onSuccessfulSubmit={(email) => {
                  setSentEmailSuccessfully(true)
                  setEmail(email)
                }}
              />
            )}
          </Stack>
        </Box>
      </Stack>
      {!sentEmailSuccessfully && <TermsAndConditions />}
    </Container>
  )
}

interface CreateAccountFormProps {
  onSuccessfulSubmit: (email: string) => void
}

const CreateAccountForm: FC<CreateAccountFormProps> = ({ onSuccessfulSubmit }) => {
  const { code } = useParams<{ code: string }>()
  const [createUser, createUserResult] = usePostUserV1CreateUserMutation()

  const toast = useToast()
  const store = useStore()
  const intl = useIntl()

  const form = useForm<CreateUserStateForm>({
    defaultValues: {
      email: "",
      first_name: "",
      last_name: "",
    },
    reValidateMode: "onSubmit",
  })

  const errorMessage =
    createUserResult.isError &&
    (getAPIErrorMessage(createUserResult.error) ??
      intl.formatMessage({
        id: "createAccount.errorMessage",
        description: "Error message when user creation fails",
        defaultMessage: "User creation failed",
      }))

  return (
    <>
      <OAuthButtonGroup isCreateAccountFlow={true} linkAuthCode={code} />
      <HStack>
        <Divider />
        <Text whiteSpace="nowrap" color="muted">
          <FormattedMessage
            id="createAccount.form.oauth"
            description="Create Account form message to direct users to oauth sign in options"
            defaultMessage="OR"
          />
        </Text>
        <Divider />
      </HStack>
      <Stack
        spacing="5"
        as={chakra.form}
        onSubmit={(event) =>
          form.handleSubmit(async (values) => {
            try {
              const result = await createUser({
                createUserInput: {
                  firstName: values.first_name,
                  lastName: values.last_name,
                  email: values.email,
                  linkCode: code || "",
                },
              }).unwrap()
              store.dispatch(brmApi.util.resetApiState())
              if (result.status === "success") {
                onSuccessfulSubmit(values.email)
              }
            } catch (err) {
              if (isObject(err) && "status" in err && err.status !== 401) {
                toast({
                  status: "error",
                  description: intl.formatMessage({
                    id: "createAccount.error.toast",
                    description: "Account Creation error toast message",
                    defaultMessage:
                      "Something went wrong while trying to create your account. Please try again or contact support.",
                  }),
                })
              }
            }
          })(event)
        }
      >
        <Controller
          control={form.control}
          name="first_name"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid} isRequired>
              <FormLabel htmlFor="first_name">
                <FormattedMessage
                  id="createAccount.form.first_name"
                  description="Label for account creation form first name field"
                  defaultMessage="First name"
                />
              </FormLabel>
              <Input {...field} autoFocus id="first_name" type="text" />
            </FormControl>
          )}
        />
        <Controller
          control={form.control}
          name="last_name"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid} isRequired>
              <FormLabel htmlFor="last_name">
                <FormattedMessage
                  id="createAccount.form.last_name"
                  description="Label for account creation form last name field"
                  defaultMessage="Last name"
                />
              </FormLabel>
              <Input {...field} autoFocus id="last_name" type="text" />
            </FormControl>
          )}
        />
        <Controller
          control={form.control}
          name="email"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid} isRequired>
              <FormLabel htmlFor="email">
                <FormattedMessage
                  id="createAccount.form.email"
                  description="Label for account creation form email field"
                  defaultMessage="Email"
                />
              </FormLabel>
              <Input {...field} autoFocus id="email" type="email" />
            </FormControl>
          )}
        />
        {errorMessage && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        <Button id="password-login" isLoading={createUserResult.isLoading} colorScheme="brand" type="submit">
          <FormattedMessage
            id="createAccount.form.signUpButton"
            description="Create Account form sign up button text"
            defaultMessage="Get started"
          />
        </Button>
      </Stack>
      <Text size="sm" textAlign="center">
        <FormattedMessage
          id="createAccount.loginLink.description"
          description="Description of link to login page"
          defaultMessage="Already have an account? {loginLink}"
          values={{
            loginLink: (
              <Link to="/login" fontWeight="semibold" color="brand.700" state={{ from: `/links/${code}` }}>
                <FormattedMessage
                  description="link to the login page"
                  id="createAccount.loginLink.link"
                  defaultMessage="Log in"
                />
              </Link>
            ),
          }}
        />
      </Text>
    </>
  )
}

interface SentEmailResultProps {
  email: string
}

const SentEmailResult: FC<SentEmailResultProps> = (props) => {
  return (
    <Stack alignItems="center">
      <FeaturedIcon icon={<Icon as={MailIcon} color="green.700" />} backgroundColor="green.100" />
      <Text size="lg" fontWeight="semibold">
        <FormattedMessage
          id="createAccount.sentEmailResult.header"
          description="Header to show when email is sent successfully"
          defaultMessage="We’ve sent you an email."
        />
      </Text>
      <Text size="sm" fontWeight="regular" textAlign="center" color="gray.600">
        <FormattedMessage
          id="createAccount.sentEmailResult.message"
          description="Message to show when email is sent successfully"
          defaultMessage="Finish creating your account using the link sent to {email}"
          values={{ email: props.email }}
        />
      </Text>
    </Stack>
  )
}

const CreateAccountPageHeaders = () => {
  return (
    <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
      <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
        <FormattedMessage
          id="createAccount.heading"
          description="Heading on create an account page"
          defaultMessage="Create an account"
        />
      </Heading>
      <Text size={useBreakpointValue({ base: "xs", md: "sm" })}>
        <FormattedMessage
          id="createAccount.subheading"
          description="Subheading on create an account page"
          defaultMessage="Start selling with BRM"
        />
      </Text>
    </Stack>
  )
}
