import { isRichTextType } from "@brm/schema-helpers/rich-text/rich-text.js"
import { isObject } from "@brm/util/type-guard.js"
import { Text } from "@chakra-ui/react"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import type { ReactElement, ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { FieldValue } from "../../DynamicForm/FieldValue.js"

function isPlainTextSchema(schema: ReadonlyDeep<JSONSchema> | undefined): boolean {
  return isObject(schema) && schema.type === "string" && !schema.format && !schema.enum && !schema.pattern
}

export function ValueChangeFieldTimelineEvent({
  actor,
  oldValue,
  newValue,
  objectLink,
  fieldSchema,
  fieldLabel,
  fieldPath,
  showFieldName,
}: {
  actor: ReactElement
  oldValue: unknown
  newValue: unknown
  objectLink?: ReactElement
  fieldSchema: ReadonlyDeep<JSONSchema> | undefined
  fieldLabel: ReactNode
  fieldPath: readonly (string | number)[]
  showFieldName?: boolean
}) {
  return (
    <Text as="span" color="gray.600">
      {isPlainTextSchema(fieldSchema) || isRichTextType(fieldSchema) ? (
        // Plain text and rich text fields are multiline and should not have their value shown
        showFieldName ? (
          objectLink ? (
            <FormattedMessage
              defaultMessage="{actor} updated {fieldLabel} for {objectLink}"
              description="Subtitle of a field change event with an old and new value on the field timeline"
              id="timeline.field_change.withField.withLink"
              values={{ actor, fieldLabel, objectLink }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="{actor} updated {fieldLabel}"
              description="Subtitle of a field change event with an old and new value on the field timeline"
              id="timeline.field_change.withField"
              values={{ actor, fieldLabel }}
            />
          )
        ) : objectLink ? (
          <FormattedMessage
            defaultMessage="{actor} updated for {objectLink}"
            description="Subtitle of a field change event with an old and new value on the field timeline"
            id="timeline.field_change.withLink"
            values={{ actor, objectLink }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="{actor} updated"
            description="Subtitle of a field change event with an old and new value on the field timeline"
            id="timeline.field_change"
            values={{ actor }}
          />
        )
      ) : oldValue !== undefined && oldValue !== null ? (
        showFieldName ? (
          objectLink ? (
            <FormattedMessage
              defaultMessage="{actor} updated {fieldLabel} from {oldValue} to {newValue} for {objectLink}"
              description="Subtitle of a field change event with an old and new value on the field timeline"
              id="timeline.field_change.with_old_value.subheading.withField.withLink"
              values={{
                actor,
                fieldLabel,
                oldValue: <FieldValue value={oldValue} fieldPath={fieldPath} fieldSchema={fieldSchema} />,
                newValue: <FieldValue value={newValue} fieldPath={fieldPath} fieldSchema={fieldSchema} />,
                objectLink,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="{actor} updated {fieldLabel} from {oldValue} to {newValue}"
              description="Subtitle of a field change event with an old and new value on the field timeline"
              id="timeline.field_change.with_old_value.subheading.withField"
              values={{
                actor,
                fieldLabel,
                oldValue: <FieldValue value={oldValue} fieldPath={fieldPath} fieldSchema={fieldSchema} />,
                newValue: <FieldValue value={newValue} fieldPath={fieldPath} fieldSchema={fieldSchema} />,
              }}
            />
          )
        ) : objectLink ? (
          <FormattedMessage
            defaultMessage="{actor} updated from {oldValue} to {newValue} for {objectLink}"
            description="Subtitle of a field change event with an old and new value on the field timeline"
            id="timeline.field_change.with_old_value.subheading.withLink"
            values={{
              actor,
              oldValue: <FieldValue value={oldValue} fieldPath={fieldPath} fieldSchema={fieldSchema} />,
              newValue: <FieldValue value={newValue} fieldPath={fieldPath} fieldSchema={fieldSchema} />,
              objectLink,
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="{actor} updated from {oldValue} to {newValue}"
            description="Subtitle of a field change event with an old and new value on the field timeline"
            id="timeline.field_change.with_old_value.subheading"
            values={{
              actor,
              oldValue: <FieldValue value={oldValue} fieldPath={fieldPath} fieldSchema={fieldSchema} />,
              newValue: <FieldValue value={newValue} fieldPath={fieldPath} fieldSchema={fieldSchema} />,
            }}
          />
        )
      ) : showFieldName ? (
        objectLink ? (
          <FormattedMessage
            defaultMessage="{actor} updated {fieldLabel} to {newValue} for {objectLink}"
            description="Subtitle of a field change event with only a new value on the field timeline"
            id="timeline.field_change.subheading.withField.withLink"
            values={{
              actor,
              fieldLabel,
              newValue: <FieldValue value={newValue} fieldPath={fieldPath} fieldSchema={fieldSchema} />,
              objectLink,
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="{actor} updated {fieldLabel} to {newValue}"
            description="Subtitle of a field change event with only a new value on the field timeline"
            id="timeline.field_change.subheading.withField"
            values={{
              actor,
              fieldLabel,
              newValue: <FieldValue value={newValue} fieldPath={fieldPath} fieldSchema={fieldSchema} />,
            }}
          />
        )
      ) : objectLink ? (
        <FormattedMessage
          defaultMessage="{actor} updated to {newValue} for {objectLink}"
          description="Subtitle of a field change event with only a new value on the field timeline"
          id="timeline.field_change.subheading.withLink"
          values={{
            actor,
            newValue: <FieldValue value={newValue} fieldPath={fieldPath} fieldSchema={fieldSchema} />,
            objectLink,
          }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="{actor} updated to {newValue}"
          description="Subtitle of a field change event with only a new value on the field timeline"
          id="timeline.field_change.subheading"
          values={{ actor, newValue: <FieldValue value={newValue} fieldPath={fieldPath} fieldSchema={fieldSchema} /> }}
        />
      )}
    </Text>
  )
}
