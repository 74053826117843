export type IconType = "mail" | "request" | "tool" | "vendor" | "agreement" | "user"

export type EnumColorScheme =
  | "green"
  | "yellow"
  | "blue"
  | "gray"
  | "orange"
  | "purple"
  | "warning"
  | "error"
  | "success"
  | "brand"

export interface CustomKeywords {
  /**
   * Override of the generated type for this field. Helpful for conditionals today where the generated type of the allOf is not wanted in the generated type.
   */
  readonly tsType?: string
  /**
   * Flag to determine whether to display for betsy schema driven context
   */
  readonly betsyEnabled?: boolean

  /**
   * Flag to determine whether this field is available to users in request configuration
   */
  readonly requestConfigDisabled?: boolean

  /**
   * Flag to determine whether the field is editable in request configuration. It is still a field that appears in config
   * but cannot be removed or modified.
   */
  readonly requestConfigReadOnly?: boolean

  /**
   * Flag to determine whether this field is enabled in new organizations by default.
   */
  readonly defaultEnabled?: boolean

  /**
   * Flag to determine whether this field is only available to internal users by default.
   * Setting this to `true` will set a field metadata row as internal_only = true whenever a new organization is created.
   */
  readonly defaultInternalOnly?: boolean

  /**
   * Flag to determine whether this field is shown in the schema form
   */
  readonly dynamicFormDisabled?: boolean

  /**
   * A description that will be displayed to the user in the UI, should it be different from `description`, which
   * is used for API documentation.
   */
  readonly uiDescription?: string

  /**
   * If `false`, the field will not be displayed anywhere in the UI.
   */
  readonly displayable?: boolean

  /**
   * If `false`, the field will not be displayed in the table UI.
   * Children may still be displayed unless `childrenTableDisplay` is `false`.
   */
  readonly tableDisplay?: boolean

  /**
   * If `false`, the field will not show up in the criteria settings field list.
   */
  readonly configurable?: boolean

  /**
   * If `false`, any children and descendants of this schema will not be displayed in the UI.
   */
  readonly childrenTableDisplay?: boolean

  /**
   * If `false`, the column will not be offered as a filter, even if it is displayable.
   * The column must be table-displayable to be filterable.
   *
   * @default true Unless `tableDisplay` is `false`.
   */
  readonly filterable?: boolean

  /**
   * If `true`, the user will be shown a single-line `<input>` field instead of a `<textarea>`.
   */
  readonly singleLine?: boolean

  /**
   * See the [`placeholder`](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/placeholder) attribute
   * in HTML.
   */
  readonly placeholder?: string

  /**
   * See the [`inputmode`](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode)
   * attribute in HTML.
   */
  readonly inputMode?: "text" | "decimal" | "numeric" | "tel" | "search" | "email" | "url"

  /**
   * See `Intl.NumberFormat()` option `style`
   */
  readonly numberStyle?: "percent"

  /**
   * The color scheme to use when rendering an enum member in a Tag/Badge. This keyword is used together with `const`.
   */
  readonly colorScheme?: EnumColorScheme

  /**
   * Icon to render instead of a Tag/Badge for enum members. The Enum title will still be shown as a tooltip.
   */
  readonly icon?: IconType

  /**
   * Used to customize the default direction of date filters.
   */
  readonly defaultFilter?: "gte" | "lt"

  /**
   * If `true`, the enum group values will not be displayed in the UI, only the grouped titles will be shown.
   */
  readonly hideEnumGroupValues?: boolean

  /**
   * The functional category the field is in.
   * In our schema definitions, this acts as a default. Organizations can override it in their criteria configuration.
   *
   * Defining a category for a field restricts in which request step type the field can appear in.
   */
  readonly category?: "it" | "compliance" | "finance" | "legal"

  /**
   * The base permission (before the colon that separates the action) the user must have to be allowed to `read` this field (including sort or filter) or `update` it.
   * `:read` or `:update` will be appended to this string depending on the action being performed.
   * Note that this keyword does nothing unless `validateFilterPermissions()`, `validateSortPermissions()`, and `enforcePermissions()` are called on the input/output.
   *
   * **If this keyword is used, the field MUST be optional**.
   */
  readonly permission?: "tool" | "vendor" | "compliance" | "it" | "legal" | "spend" | "transaction"
}

/**
 * Array of all of our custom keywords so that AJV doesn't warn on them.
 *
 * @see {CustomKeywords}
 */

export const customKeywords: string[] = Object.keys({
  tsType: true,
  uiDescription: true,
  placeholder: true,
  inputMode: true,
  singleLine: true,
  displayable: true,
  tableDisplay: true,
  childrenTableDisplay: true,
  filterable: true,
  configurable: true,
  betsyEnabled: true,
  requestConfigDisabled: true,
  requestConfigReadOnly: true,
  dynamicFormDisabled: true,
  numberStyle: true,
  colorScheme: true,
  icon: true,
  permission: true,
  hideEnumGroupValues: true,
  defaultFilter: true,
  category: true,
  defaultInternalOnly: true,
  defaultEnabled: true,
} satisfies Record<keyof CustomKeywords, true>)
