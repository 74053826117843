import type {
  AgentPicker,
  ObjectType,
  PickableEntity,
  PickableEntityType,
  StandardObjectElement,
  UserPicker,
  WorkflowBuyerPicker,
  WorkflowSellerPicker,
} from "@brm/schema-types/types.js"
import { PickableEntityTypeSchema } from "@brm/schemas"
import { displayPersonName } from "@brm/util/names.js"
import { getTitle } from "@brm/util/schema.js"
import { Box, Center, chakra, HStack, Text } from "@chakra-ui/react"
import type { Boundary, VirtualElement } from "@popperjs/core/index.js"
import equal from "fast-deep-equal"
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  type RefObject,
} from "react"
import { Header, Section } from "react-aria-components"
import { FormattedMessage, useIntl } from "react-intl"
import { usePopper } from "react-popper"
import type { Range } from "slate"
import { ReactEditor, useSlate } from "slate-react"
import { isPresent } from "ts-is-present"
import zIndices from "../../../../packages/theme/src/foundations/z-index.js"
import { usePostOrganizationV1PickableEntitiesQuery } from "../../app/services/generated-api.js"
import PickableEntityDisplay from "../../features/organization/PickableEntityDisplay.js"
import { translatePersonVariableName } from "../../features/person/util.js"
import WorkflowRunStatusIcon from "../../features/workflows/run/WorkflowRunStatusIcon.js"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import BraimIcon from "../BraimIcon.js"
import BetsyDisplayName from "../ContextMenu/BetsyDisplayName.js"
import { ListBox, ListBoxItem } from "../ListBox.js"
import Spinner from "../spinner.js"
import type { RichTextEditorProps } from "./types.js"

const MENTION_OPTIONS_LIMIT = 3

export interface TagPickerListRef {
  incrementIndex: () => void
  decrementIndex: () => void
  selectCurrent: () => void
}

type TagPickerListProps = {
  mentionSearch: string
  insertMentionElement: (user: UserPicker | WorkflowSellerPicker | WorkflowBuyerPicker) => void
  insertAgentTagElement: (agent: AgentPicker) => void
  insertFieldTagElement: (fieldName: string, objectType: ObjectType, isCustom: boolean) => void
  insertStandardObjectElement: (
    objectType: StandardObjectElement["object_type"],
    id: string,
    displayName: string,
    website?: string
  ) => void
  resetMentionRange: () => void
  mentionRange: Range | null
} & GetLogoForOrganizationProps &
  Pick<RichTextEditorProps, "pickableEntityFilters">

const getPickerTitle = (type: PickableEntityType) =>
  getTitle(
    type,
    PickableEntityTypeSchema.anyOf.find((schema) => schema.const === type)
  )

/**
 * Container which houses the picker list. This is important because it ensures that the popper positioning is initialized when the virtual element is defined.
 */
const TagPickerListContainer = function TagPickerListContainer({
  children,
  virtualElement,
}: {
  children: React.ReactNode
  virtualElement: VirtualElement
}) {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const { styles, attributes } = usePopper(virtualElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top-start"],
          flipVariations: false, // Prevent flipping to -end variations
        },
      },
      {
        name: "preventOverflow",
        options: {
          boundary: "viewport" as Boundary,
          mainAxis: true, // Prevent overflow on the main axis (vertical)
          altAxis: true, // Prevent overflow on horizontal axis
          tether: true, // Keep the popper anchored to the reference
          tetherOffset: 0, // Distance the popper can move from its origin
          padding: 8,
        },
      },
      {
        name: "maxSize",
        enabled: true,
        options: {
          max: "height",
          boundary: "viewport",
          padding: 8,
        },
      },
    ],
  })

  return (
    <chakra.div
      ref={setPopperElement}
      style={{ ...styles.popper, width: "250px", maxHeight: "calc(100vh - 16px)" }}
      zIndex={zIndices.popover}
      {...attributes.popper}
    >
      {children}
    </chakra.div>
  )
}

const TagPickerList = forwardRef<TagPickerListRef, TagPickerListProps>(function TagPickerList(
  {
    mentionSearch,
    mentionRange,
    resetMentionRange,
    getLogoToShowByOrganizationId,
    insertMentionElement,
    insertAgentTagElement,
    insertFieldTagElement,
    insertStandardObjectElement,
    pickableEntityFilters,
  },
  ref
) {
  const intl = useIntl()
  const editor = useSlate()
  const containerRef = useRef<HTMLDivElement>(null)
  const [isKeyboardActive, setIsKeyboardActive] = useState(false)
  const [mentionIndex, setMentionIndex] = useState<number>(0)
  const [virtualPosition, setVirtualPosition] = useState<VirtualElement | null>(null)
  const { data: pickables, isLoading: pickablesLoading } = usePostOrganizationV1PickableEntitiesQuery({
    body: {
      filter: {
        name: mentionSearch || undefined,
        ...pickableEntityFilters,
        entities: ["user", ...(pickableEntityFilters?.entities || [])],
      },
      limit: MENTION_OPTIONS_LIMIT,
    },
  })

  const pickablesByType: Partial<Record<PickableEntityType, PickableEntity[]>> = useMemo(
    () => (pickables ? Object.groupBy(pickables, ({ type }: PickableEntity) => type) : {}),
    [pickables]
  )

  // Ordering of pickable types
  const pickableTypes = useMemo(
    () =>
      [
        "agent",
        "user",
        "workflow_buyer",
        "workflow_seller",
        "person",
        "field",
        "tool",
        "vendor",
        "legal_agreement",
        "workflow_run",
        "tool_listing",
        "vendor_listing",
      ] satisfies PickableEntityType[],
    []
  )

  const pickablesList = useMemo(
    () => pickableTypes.flatMap((k) => pickablesByType[k as PickableEntityType]).filter(isPresent),
    [pickablesByType, pickableTypes]
  )
  const pickableLength = useMemo(() => pickablesList?.length ?? 0, [pickablesList])

  useEffect(() => {
    // Reset the selection index to 0 when the search query changes
    setMentionIndex(0)
  }, [mentionSearch])

  useLayoutEffect(() => {
    if (mentionRange) {
      const domRange = ReactEditor.toDOMRange(editor, mentionRange)
      const rect = domRange.getBoundingClientRect()

      // Create a virtual element anchored to the top-left or bottom-left of the selection
      setVirtualPosition({
        getBoundingClientRect: () => rect.toJSON(),
      })
    }
  }, [editor, mentionRange, pickablesLoading])

  useEffect(() => {
    const handleMouseMove = () => setIsKeyboardActive(false)
    window.addEventListener("mousemove", handleMouseMove)
    return () => {
      window.removeEventListener("mousemove", handleMouseMove)
    }
  }, [])

  const onAction = useCallback(
    (pickable: PickableEntity) => {
      switch (pickable.type) {
        case "user":
        case "workflow_buyer":
        case "workflow_seller":
          insertMentionElement(pickable)
          break
        case "agent":
          insertAgentTagElement(pickable)
          break
        case "field":
          insertFieldTagElement(pickable.field_name, pickable.object_type, pickable.is_custom)
          break
        case "legal_agreement":
        case "workflow_run":
        case "vendor":
        case "tool":
          insertStandardObjectElement(pickable.object_type, pickable.id, pickable.display_name)
          break
        case "tool_listing":
        case "vendor_listing":
          insertStandardObjectElement(
            pickable.object_type,
            pickable.id,
            pickable.display_name,
            pickable.website ?? undefined
          )
          break
      }
    },
    [insertMentionElement, insertFieldTagElement, insertStandardObjectElement, insertAgentTagElement]
  )

  useImperativeHandle(
    ref,
    () => ({
      incrementIndex: () => {
        setMentionIndex((i) => (i + 1) % pickableLength)
        setIsKeyboardActive(true)
      },
      decrementIndex: () => {
        setMentionIndex((i) => (i - 1 < 0 ? pickableLength - 1 : i - 1))
        setIsKeyboardActive(true)
      },
      selectCurrent: () => {
        setIsKeyboardActive(true)
        if (mentionIndex < pickableLength) {
          const pickable = pickablesList[mentionIndex]
          if (pickable) {
            onAction(pickable)
          }
        }
      },
    }),
    [pickableLength, onAction, mentionIndex, setMentionIndex, pickablesList]
  )

  if (!virtualPosition) {
    return null
  }

  return (
    <TagPickerListContainer virtualElement={virtualPosition}>
      <Box
        ref={containerRef}
        boxShadow="xl"
        borderRadius="lg"
        borderWidth={1}
        overflow="hidden"
        background="gray.50"
        w="100%"
        maxH="inherit"
      >
        {pickablesLoading ? (
          <Center w="100%" h="100%" p={4}>
            <Spinner />
          </Center>
        ) : (
          <ListBox
            aria-label={intl.formatMessage({
              id: "mention.listbox-label",
              defaultMessage: "Mention listbox",
              description: "Label for the listbox of users and fields to mention",
            })}
            overflowY="auto"
            maxH="inherit"
          >
            {pickableLength === 0 ? (
              <ListBoxItem
                className="mention-option"
                textValue={intl.formatMessage({
                  id: "mention.no-results.textValue",
                  defaultMessage: "No results",
                  description: "No results text for mention search",
                })}
                onAction={resetMentionRange}
              >
                <FormattedMessage
                  id="mention.no-results"
                  defaultMessage="No results <light>(Dismiss)</light>"
                  description="No results for mention search"
                  values={{
                    light: (content) => (
                      <chakra.span verticalAlign="middle" color="gray.500">
                        {content}
                      </chakra.span>
                    ),
                  }}
                />
              </ListBoxItem>
            ) : (
              pickableTypes.map((type) => {
                const pickableListByType = pickablesByType[type]
                if (!pickableListByType) {
                  return null
                }
                return (
                  <Section key={type}>
                    <Header id={type}>
                      <Text fontWeight="semibold" m={2}>
                        {getPickerTitle(type)}
                      </Text>
                    </Header>
                    {pickableListByType?.map((pickable, i) => {
                      const listIndex = pickablesList.findIndex((p) => equal(p, pickable))
                      const isSelected = listIndex === mentionIndex
                      return (
                        <PickerOption
                          key={i}
                          containerRef={containerRef}
                          /** If the user is using a keyboard, we don't want to show the hover effect. */
                          onHoverStart={() => !isKeyboardActive && setMentionIndex(listIndex)}
                          isSelected={isSelected}
                          pickable={pickable}
                          onAction={onAction}
                          getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                          shouldScrollIntoView={isSelected && isKeyboardActive}
                        />
                      )
                    })}
                  </Section>
                )
              })
            )}
          </ListBox>
        )}
      </Box>
    </TagPickerListContainer>
  )
})

const PickerOption = ({
  pickable,
  isSelected,
  onHoverStart,
  onAction,
  getLogoToShowByOrganizationId,
  shouldScrollIntoView,
  containerRef,
}: {
  pickable: PickableEntity
  isSelected: boolean
  onHoverStart: () => void
  onAction: (pickable: PickableEntity) => void
  /** If true, scroll the option into view if it is not already visible. */
  shouldScrollIntoView?: boolean
  containerRef?: RefObject<HTMLDivElement>
} & GetLogoForOrganizationProps) => {
  const intl = useIntl()
  const optionRef = useRef<HTMLDivElement>(null)

  const displayName =
    pickable.type === "user" || pickable.type === "person"
      ? displayPersonName(pickable, intl)
      : pickable.type === "variable"
        ? translatePersonVariableName(pickable.variable, intl)
        : pickable.display_name

  useEffect(() => {
    if (shouldScrollIntoView && optionRef?.current && containerRef?.current) {
      const containerRect = containerRef.current?.getBoundingClientRect()
      const bounding = optionRef.current?.getBoundingClientRect()
      const isVisible =
        bounding.top >= containerRect.top &&
        bounding.bottom <= containerRect.bottom &&
        bounding.left >= containerRect.left &&
        bounding.right <= containerRect.right
      if (!isVisible) {
        optionRef?.current?.scrollIntoView()
      }
    }
  }, [shouldScrollIntoView, containerRef, optionRef])
  return (
    <ListBoxItem
      ref={optionRef}
      textValue={displayName}
      // Classname is used to identify the mention option in blur handler so blur events do not bubble up to the parent of the editor
      className="mention-option"
      onHoverStart={onHoverStart}
      background={isSelected ? "brand.100" : "transparent"}
      onAction={() => onAction(pickable)}
    >
      {pickable.type === "agent" ? (
        <HStack width="100%">
          <BraimIcon />
          <BetsyDisplayName />
        </HStack>
      ) : (
        <PickableEntityDisplay
          displayText={displayName}
          image={"image_asset" in pickable ? getPublicImageGcsUrl(pickable.image_asset?.gcs_file_name) : undefined}
          before={
            "status" in pickable && pickable.type === "workflow_run" ? (
              <WorkflowRunStatusIcon status={pickable.status} boxSize={3} />
            ) : undefined
          }
          secondaryImage={
            getLogoToShowByOrganizationId && pickable.type === "user"
              ? getPublicImageGcsUrl(getLogoToShowByOrganizationId(pickable.organization_id))
              : undefined
          }
          size="sm"
        />
      )}
    </ListBoxItem>
  )
}

export default TagPickerList
