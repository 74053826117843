import { Input, Text } from "@chakra-ui/react"
// eslint-disable-next-line no-restricted-imports
import { Combobox, useCombobox } from "@mantine/core"
import type { ReactElement } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import zIndices from "../../../../packages/theme/src/foundations/z-index.js"
import styles from "./EmailTypeahead.module.css"

export interface EmailTypeaheadProps<T> {
  data: T[]
  emailValue: string
  onChangeEmail: (email: string) => void
  getItemValue: (item: T) => string
  onSelectItem: (item: T) => void
  renderOption: (item: T) => ReactElement
  error?: unknown
}

export default function EmailTypeahead<T>({
  data,
  emailValue,
  onChangeEmail,
  getItemValue,
  onSelectItem,
  renderOption,
  error,
}: EmailTypeaheadProps<T>) {
  const intl = useIntl()
  const combobox = useCombobox()
  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        const selectedItem = data.find((item) => getItemValue(item) === val)
        if (selectedItem) {
          onSelectItem(selectedItem)
        }
        combobox.closeDropdown()
      }}
      zIndex={zIndices.modal}
      onClose={() => combobox.resetSelectedOption()}
      classNames={{ option: styles.emailTypeaheadOption }}
    >
      <Combobox.Target>
        <Input
          data-1p-ignore
          value={emailValue}
          onChange={(event) => {
            onChangeEmail(event.currentTarget.value)
            combobox.openDropdown()
            combobox.updateSelectedOptionIndex()
          }}
          placeholder={intl.formatMessage({
            id: "comboBox.email.placeholder",
            defaultMessage: "example@brm.ai",
            description: "Placeholder for the email typeahead",
          })}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
        />
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>
          {error ? (
            <Text p={2}>
              <FormattedMessage
                defaultMessage="Error loading results"
                description="Search generic error"
                id="comboBox.error"
              />
            </Text>
          ) : data.length > 0 ? (
            data.map((option) => (
              <Combobox.Option value={getItemValue(option)} key={getItemValue(option)}>
                {renderOption(option)}
              </Combobox.Option>
            ))
          ) : (
            <Text p={2}>
              <FormattedMessage
                defaultMessage="No results"
                description="Search has no results"
                id="comboBox.noResults"
              />
            </Text>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
