import type { StandardObjectElement } from "@brm/schema-types/types.js"
import { Transforms } from "slate"
import type { CustomEditor } from "../types.js"

/** Field tagging support */
export const withStandardObjectTags = (editor: CustomEditor) => {
  const { isInline, isVoid, markableVoid } = editor

  editor.isInline = (element) => {
    return element.type === "standard-object" || isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === "standard-object" || isVoid(element)
  }

  editor.markableVoid = (element) => {
    return element.type === "standard-object" || markableVoid(element)
  }

  return editor
}

export const insertStandardObjectTag = (
  editor: CustomEditor,
  objectType: StandardObjectElement["object_type"],
  displayName: string,
  id: string,
  website?: string
) => {
  const standardObjectElement: StandardObjectElement = {
    type: "standard-object",
    children: [{ text: "" }],
    display_name: displayName,
    object_type: objectType,
    id,
    website,
  }
  Transforms.insertNodes(editor, standardObjectElement)
  Transforms.move(editor)
  Transforms.insertText(editor, " ")
  Transforms.move(editor)
}
