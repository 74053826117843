import type { MentionElement } from "@brm/schema-types/types.js"
import { Avatar, chakra, type ChakraProps } from "@chakra-ui/react"
import { useFocused, useSelected, type RenderElementProps } from "slate-react"
import type { GetLogoForOrganizationProps } from "../../features/workflows/run/utils.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { baseMentionSpanStyle } from "./util/mentions.js"

export const Mention = ({
  attributes,
  children,
  element,
  getLogoToShowByOrganizationId,
}: RenderElementProps &
  GetLogoForOrganizationProps & {
    element: MentionElement
  }) => {
  const selected = useSelected()
  const focused = useFocused()
  const spanStyle: ChakraProps = {
    ...baseMentionSpanStyle,
    backgroundColor: "brand.50",
    outline: "1px solid",
    outlineColor: "brand.200",
    boxShadow: selected && focused ? "0 0 0 2px var(--chakra-colors-blue-200)" : "none",
  }
  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0]?.bold) {
    spanStyle.fontWeight = "bold"
  }
  if (element.children[0]?.italic) {
    spanStyle.fontStyle = "italic"
  }

  const logo =
    getLogoToShowByOrganizationId && element.organization_id && getLogoToShowByOrganizationId(element.organization_id)

  return (
    <chakra.span
      contentEditable={false}
      data-cy={`mention-${element.display_name.replaceAll(" ", "-")}`}
      {...spanStyle}
      {...attributes}
    >
      {`@${element.display_name}`}
      {logo && (
        <Avatar
          referrerPolicy="no-referrer"
          src={getPublicImageGcsUrl(logo)}
          boxSize={3.5}
          verticalAlign="text-bottom"
          marginLeft={1}
        />
      )}
      {children}
    </chakra.span>
  )
}
