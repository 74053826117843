import type {
  FieldCategory,
  FormFieldConfig,
  ObjectType,
  ToolWithStandardObjects,
  Vendor,
  WorkflowConditionGroupInput,
  WorkflowDefApproverInput,
  WorkflowDefStepInput,
  WorkflowRun,
  WorkflowStepStandardType,
} from "@brm/schema-types/types.js"
import { type WorkflowDefInput, type WorkflowKind } from "@brm/schema-types/types.js"
import { ObjectTypeSchema } from "@brm/schemas"
import { isNullSchema } from "@brm/util/schema.js"
import { hasOwnProperty, isObject } from "@brm/util/type-guard.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import type { IntlShape } from "react-intl"
import type { Descendant } from "slate"
import { Text } from "slate"
import { isPresent } from "ts-is-present"
import type { ReadonlyDeep } from "type-fest"
import type { LegalAgreement } from "../../../app/services/generated-api.js"
import {
  CLOSE_FIELDS,
  DETAILS_FIELDS,
  LEGAL_AGREEMENT_FIELDS,
  TOOL_FIELDS,
  WORKFLOW_RUN_FIELDS,
} from "./brm-standard-config.js"

interface WorkflowDefApprovalStepFormState {
  approvers: (WorkflowDefApproverInput | null)[]
  condition: WorkflowConditionGroupInput | null
}

interface WorkflowDefStepFormState {
  display_name: string
  fields: WorkflowDefStepInput["fields"]
  type: WorkflowStepStandardType
  approval_steps: WorkflowDefApprovalStepFormState[]
  condition: WorkflowConditionGroupInput | null
}

export interface ConfigureWorkflowFormState {
  display_name: string
  workflow_kind: WorkflowKind
  description: string
  steps: WorkflowDefStepFormState[]
}

export type ConfigureWorkflowStepsFormState = Pick<ConfigureWorkflowFormState, "steps">

export const defaultActiveSteps = new Set<WorkflowStepStandardType>(["details", "finance", "it", "compliance", "close"])

export const OPTIONAL_APPROVAL_STEP_TYPES: Array<WorkflowStepStandardType> = ["details", "close"]

export const getDefaultStepState = (
  intl: IntlShape,
  workflowKind: WorkflowKind,
  toolSchema: ReadonlyDeep<JSONSchemaObject>
): ConfigureWorkflowFormState["steps"] => {
  return [
    {
      display_name:
        workflowKind === "purchase"
          ? intl.formatMessage({
              id: "workflows.configuration.step.purchase_details",
              description: "Default step name for purchase details request step",
              defaultMessage: "Purchase Details",
            })
          : intl.formatMessage({
              id: "workflows.configuration.step.renewal_details",
              description: "Default step name for renewal details request step",
              defaultMessage: "Renewal Details",
            }),
      approval_steps: [],
      type: "details",
      condition: null,
      fields: [...DETAILS_FIELDS, ...WORKFLOW_RUN_FIELDS],
    },
    {
      display_name: intl.formatMessage({
        id: "workflows.configuration.step.compliance",
        description: "Default step name for compliance request step",
        defaultMessage: "Compliance",
      }),
      approval_steps: [{ approvers: [null], condition: null }],
      type: "compliance",
      condition: null,
      fields: TOOL_FIELDS.filter(
        (fieldConfig) =>
          toolSchema.properties?.[fieldConfig.field_name]?.category === ("compliance" satisfies FieldCategory)
      ),
    },
    {
      display_name: intl.formatMessage({
        id: "workflows.configuration.step.finance",
        description: "Default step name for finance request step",
        defaultMessage: "Finance",
      }),
      approval_steps: [{ approvers: [null], condition: null }],
      type: "finance",
      condition: null,
      fields: [
        ...WORKFLOW_RUN_FIELDS,
        ...TOOL_FIELDS.filter(
          (fieldConfig) =>
            toolSchema.properties?.[fieldConfig.field_name]?.category === ("finance" satisfies FieldCategory)
        ),
        ...LEGAL_AGREEMENT_FIELDS,
      ],
    },
    {
      display_name: intl.formatMessage({
        id: "workflows.configuration.step.legal",
        description: "Default step name for legal request step",
        defaultMessage: "Legal",
      }),
      approval_steps: [{ approvers: [null], condition: null }],
      type: "legal",
      condition: null,
      fields: [
        ...LEGAL_AGREEMENT_FIELDS,
        ...TOOL_FIELDS.filter(
          (fieldConfig) =>
            toolSchema.properties?.[fieldConfig.field_name]?.category === ("legal" satisfies FieldCategory)
        ),
      ],
    },
    {
      display_name: intl.formatMessage({
        id: "workflows.configuration.step.it",
        description: "Default step name for IT request step",
        defaultMessage: "IT",
      }),
      approval_steps: [{ approvers: [null], condition: null }],
      type: "it",
      condition: null,
      fields: TOOL_FIELDS.filter(
        (fieldConfig) => toolSchema.properties?.[fieldConfig.field_name]?.category === ("it" satisfies FieldCategory)
      ),
    },
    {
      display_name: intl.formatMessage({
        id: "workflows.configuration.step.close",
        description: "Default step name for close request step",
        defaultMessage: "Close",
      }),
      approval_steps: [],
      type: "close",
      condition: null,
      fields: CLOSE_FIELDS,
    },
  ] satisfies ConfigureWorkflowFormState["steps"]
}

export const isNotNullConfig = (configValue: unknown) => {
  return isObject(configValue) && hasOwnProperty(configValue, "not") && isNullSchema(configValue.not)
}

export const isInternalOnlyFieldConfig = (configValue: unknown) => {
  return isObject(configValue) && hasOwnProperty(configValue, "internalOnly") && configValue.internalOnly === true
}

/**
 * Preprocess steps payload to prepare for api submission. Fills in all required arrays necessary to validate the schema and
 * assigns temporary ids to each step to be used for matching up next_input_ids.
 */
export const fillStepTempIdsAndRequiredArrays = (formValues: ConfigureWorkflowFormState): WorkflowDefInput => {
  const workflowDefInput: WorkflowDefInput = {
    kind: formValues.workflow_kind,
    display_name: formValues.display_name,
    description: formValues.description || null,
    steps: formValues.steps.map((step, stepIndex) => ({
      ...step,
      approval_steps: step.approval_steps.filter(isPresent).map((approvalStep) => ({
        ...approvalStep,
        approvers: approvalStep.approvers.filter(isPresent),
      })),
      step_configuration_input_id: stepIndex,
      // assign a temp id but leave next pointers empty for matching after
      next_input_ids: [],
    })),
  }

  const allSteps = workflowDefInput.steps

  // TODO: This is a hardcoded solution to match up temporary step ids in the next_input_ids arrays.
  // We should immediately get rid of this when we implement the ability to configure step order and dependencies.
  const detailsStep = allSteps.find((step) => step.type === "details")
  const complianceStep = allSteps.find((step) => step.type === "compliance")
  const itStep = allSteps.find((step) => step.type === "it")
  const financeStep = allSteps.find((step) => step.type === "finance")
  const legalStep = allSteps.find((step) => step.type === "legal")
  const closeStep = allSteps.find((step) => step.type === "close")

  if (detailsStep) {
    // Find first defined step in each chain that could come after the justification step
    detailsStep.next_input_ids = [
      complianceStep?.step_configuration_input_id,
      itStep?.step_configuration_input_id,
      financeStep?.step_configuration_input_id,
      legalStep?.step_configuration_input_id,
    ].filter(isPresent)

    if (detailsStep.next_input_ids.length === 0) {
      if (closeStep) {
        detailsStep.next_input_ids = [closeStep.step_configuration_input_id]
      }
    }
  }
  if (complianceStep) {
    complianceStep.next_input_ids = closeStep ? [closeStep.step_configuration_input_id] : []
  }
  if (itStep) {
    itStep.next_input_ids = closeStep ? [closeStep.step_configuration_input_id] : []
  }
  if (financeStep) {
    financeStep.next_input_ids = closeStep ? [closeStep.step_configuration_input_id] : []
  }
  if (legalStep) {
    legalStep.next_input_ids = closeStep ? [closeStep.step_configuration_input_id] : []
  }

  return workflowDefInput
}

export function getPrevStepFields(
  steps: Pick<WorkflowDefStepInput, "type" | "fields">[],
  currStep: Pick<WorkflowDefStepInput, "type">
): FormFieldConfig[] {
  switch (currStep.type) {
    case "details":
      return []
    case "compliance":
    case "it":
    case "finance":
    case "legal":
      return steps.filter((step) => step.type === "details").flatMap((step) => step.fields)
    case "close":
      return steps.filter((step) => step.type !== "close").flatMap((step) => step.fields)
    case "custom":
      throw new Error("Custom steps are not supported yet")
    default:
      unreachable(currStep.type)
  }
}

export const enabledStandardObjectsForStepTypes: Record<WorkflowStepStandardType, ObjectType[]> = {
  details: ["Tool", "Vendor", "WorkflowRun"],
  compliance: ["Tool", "Vendor"],
  it: ["Tool", "Vendor"],
  finance: ["LegalAgreement", "Tool", "Vendor", "WorkflowRun"],
  legal: ["LegalAgreement", "Tool", "Vendor"],
  close: ["LegalAgreement", "Vendor", "WorkflowRun"],
  custom: ObjectTypeSchema.enum.map((obj) => obj as ObjectType),
}

export const getUniqueFormFieldKey = (field: FormFieldConfig) => {
  return `${field.object_type}.${field.field_name}.${field.is_custom}`
}

/**
 * The conditional editor is a simplified rich text editor that only supports basic text and field tags.
 * This function serializes the conditional generation input to a string that can be used in the conditional editor.
 * Field tags are serialized to a string that directly corresponds to the field name of our api schemas.
 */
export const serializeConditionalGenerationInput = (node: Descendant): string => {
  if (Text.isText(node)) {
    return node.text
  }

  switch (node.type) {
    case "field-tag":
      return `[Field Name: ${node.field_name}, Object Type: ${node.object_type}, Is Custom: ${node.is_custom}]`
    default:
      return node.children.map((n) => serializeConditionalGenerationInput(n)).join("")
  }
}

export interface SuggestedConditionalPrompt {
  label: string
  richTextPrompt: Descendant[]
}

export const getSuggestedConditionalPrompts = (
  intl: IntlShape
): Partial<Record<ObjectType, Record<string, SuggestedConditionalPrompt>>> => {
  return {
    /* eslint-disable @typescript-eslint/naming-convention */
    LegalAgreement: getLegalAgreementSuggestedPrompts(intl),
    Tool: getToolSuggestedPrompts(intl),
    Vendor: getVendorSuggestedPrompts(intl),
    WorkflowRun: getWorkflowRunSuggestedPrompts(intl),
    /* eslint-enable @typescript-eslint/naming-convention */
  }
}

export const getWorkflowRunSuggestedPrompts = (
  intl: IntlShape
): Partial<Record<keyof WorkflowRun, SuggestedConditionalPrompt>> => ({
  requested_spend: {
    label: intl.formatMessage({
      id: "tool.suggestedPrompts.requestedSpend",
      description: "Suggested prompt for tool requested spend condition",
      defaultMessage: "Requested spend is greater than $5,000",
    }),
    richTextPrompt: [
      {
        type: "paragraph",
        children: [
          {
            type: "field-tag",
            field_name: "requested_spend",
            object_type: "WorkflowRun",
            is_custom: false,
            children: [{ text: "" }],
          },
          { text: " is greater than $5,000" },
        ],
      },
    ],
  },
})

export const getLegalAgreementSuggestedPrompts = (
  intl: IntlShape
): Partial<Record<keyof LegalAgreement, SuggestedConditionalPrompt>> => ({
  total_contract_value: {
    label: intl.formatMessage({
      id: "legalAgreement.suggestedPrompts.totalContractValue",
      description: "Suggested prompt for total contract value condition",
      defaultMessage: "Total Contract value is greater than $10,000",
    }),
    richTextPrompt: [
      {
        type: "paragraph",
        children: [
          {
            type: "field-tag",
            field_name: "total_contract_value",
            object_type: "LegalAgreement",
            is_custom: false,
            children: [{ text: "" }],
          },
          { text: " is greater than $10,000" },
        ],
      },
    ],
  },
  auto_renewal_opt_out_period: {
    label: intl.formatMessage({
      id: "legalAgreement.suggestedPrompts.autoRenewalOptOutPeriod",
      description: "Suggested prompt for auto renewal opt out period condition",
      defaultMessage: "Auto Renewal Opt Out Period is greater than 30 days",
    }),
    richTextPrompt: [
      {
        type: "paragraph",
        children: [
          {
            type: "field-tag",
            field_name: "auto_renewal_opt_out_period",
            object_type: "LegalAgreement",
            is_custom: false,
            children: [{ text: "" }],
          },
          { text: " is greater than 30 days" },
        ],
      },
    ],
  },
  auto_renews: {
    label: intl.formatMessage({
      id: "legalAgreement.suggestedPrompts.autoRenews",
      description: "Suggested prompt for auto renews condition",
      defaultMessage: "The agreement auto renews",
    }),
    richTextPrompt: [
      {
        type: "paragraph",
        children: [
          { text: "The agreement " },
          {
            type: "field-tag",
            field_name: "auto_renews",
            object_type: "LegalAgreement",
            is_custom: false,
            children: [{ text: "" }],
          },
        ],
      },
    ],
  },
})

export const getToolSuggestedPrompts = (
  intl: IntlShape
): Partial<Record<keyof ToolWithStandardObjects, SuggestedConditionalPrompt>> => ({
  soc2: {
    label: intl.formatMessage({
      id: "tool.suggestedPrompts.soc2",
      description: "Suggested prompt for tool SOC2 compliance condition",
      defaultMessage: "The tool is not SOC2 compliant",
    }),
    richTextPrompt: [
      {
        type: "paragraph",
        children: [
          { text: "The tool is not " },
          {
            type: "field-tag",
            field_name: "soc2",
            object_type: "Tool",
            is_custom: false,
            children: [{ text: "" }],
          },
          { text: " compliant" },
        ],
      },
    ],
  },
  is_critical: {
    label: intl.formatMessage({
      id: "tool.suggestedPrompts.isCritical",
      description: "Suggested prompt for tool is critical condition",
      defaultMessage: "The tool is critical",
    }),
    richTextPrompt: [
      {
        type: "paragraph",
        children: [
          { text: "The tool " },
          {
            type: "field-tag",
            field_name: "is_critical",
            object_type: "Tool",
            is_custom: false,
            children: [{ text: "" }],
          },
        ],
      },
    ],
  },
  sso_types: {
    label: intl.formatMessage({
      id: "tool.suggestedPrompts.ssoTypes",
      description: "Suggested prompt for tool SSO types condition",
      defaultMessage: "Supports Google OAuth as an SSO Type",
    }),
    richTextPrompt: [
      {
        type: "paragraph",
        children: [
          { text: "Supports Google OAuth as an " },
          {
            type: "field-tag",
            field_name: "sso_types",
            object_type: "Tool",
            is_custom: false,
            children: [{ text: "" }],
          },
        ],
      },
    ],
  },
  scim: {
    label: intl.formatMessage({
      id: "tool.suggestedPrompts.scim",
      description: "Suggested prompt for tool SCIM condition",
      defaultMessage: "The tool does not support SCIM",
    }),
    richTextPrompt: [
      {
        type: "paragraph",
        children: [
          { text: "The tool does not support " },
          {
            type: "field-tag",
            field_name: "scim",
            object_type: "Tool",
            is_custom: false,
            children: [{ text: "" }],
          },
        ],
      },
    ],
  },
})

export const getVendorSuggestedPrompts = (
  intl: IntlShape
): Partial<Record<keyof Vendor, SuggestedConditionalPrompt>> => ({
  nda: {
    label: intl.formatMessage({
      id: "vendor.suggestedPrompts.nda",
      description: "Suggested prompt for vendor NDA condition",
      defaultMessage: "The vendor has an NDA",
    }),
    richTextPrompt: [
      {
        type: "paragraph",
        children: [
          {
            type: "field-tag",
            field_name: "nda",
            object_type: "Vendor",
            is_custom: false,
            children: [{ text: "" }],
          },
          { text: " has an NDA" },
        ],
      },
    ],
  },
})
