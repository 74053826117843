import { Button, Icon, useToast } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { useStore } from "react-redux"
import { brmApi } from "../../app/services/brm-api.js"
import { useGetUserV1LogoutMutation } from "../../app/services/generated-api.js"
import { LogoutIcon } from "../icons/icons.js"

export function LogOutButton() {
  const toast = useToast()
  const store = useStore()
  const intl = useIntl()

  const [logout] = useGetUserV1LogoutMutation()

  const label = intl.formatMessage({
    defaultMessage: "Log out",
    description: "Label for the logout button",
    id: "navHeader.logout",
  })

  return (
    <Button
      variant="outline"
      rightIcon={<Icon as={LogoutIcon} />}
      onClick={async () => {
        const logoutResult = await logout().unwrap()

        if (logoutResult.status === "success") {
          localStorage.clear()
          store.dispatch(brmApi.util.resetApiState())
          // Use a hard navigation to ensure no client state is left behind.
          window.location.href = "/login"
        } else {
          toast({
            description: intl.formatMessage({
              id: "logout.toast.error",
              description: "Failed to log out",
              defaultMessage: "Logout Failed",
            }),
            status: "error",
          })
        }
      }}
    >
      {label}
    </Button>
  )
}
