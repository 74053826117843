import { Center, Flex, Heading, chakra } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetLoginV1SummaryQuery } from "../../app/services/generated-api.js"
import EmptyTableState from "../../components/DataTable/EmptyTableState.js"
import PageWrapper from "../../components/PageWrapper.js"
import Spinner from "../../components/spinner.js"
import FullTextSearch from "../search/FullTextSearch.js"
import SecurityOverviewTable from "./SecurityOverviewTable.js"

export default function Security() {
  const intl = useIntl()
  const { data, isLoading } = useGetLoginV1SummaryQuery({})

  return (
    <PageWrapper>
      <Flex gap={2} borderBottomWidth="1px" pb={3} mb={4}>
        <Heading size="xs">
          <FormattedMessage
            id="security.scopes.summary.heading"
            description="Heading of security page scope summary"
            defaultMessage="Permissions Analysis"
          />
        </Heading>
        <FullTextSearch ml="auto" />
      </Flex>
      <chakra.div overflow="scroll">
        {isLoading ? (
          <Center height="90vh">
            <Spinner size="md" />
          </Center>
        ) : !data || Object.keys(data).length === 0 ? (
          <EmptyTableState
            emptyState={intl.formatMessage({
              id: "security.overview.emptyState",
              description: "Text to display instead of the security table when there are is no data",
              defaultMessage:
                "No security data found. Connect your single sign-on provider to BRM to surface security permissions for your apps",
            })}
          />
        ) : (
          <SecurityOverviewTable data={data} />
        )}
      </chakra.div>
    </PageWrapper>
  )
}
