import { displayPersonName } from "@brm/util/names.js"
import { Avatar, type AvatarProps } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { useGetUserV1ByIdDisplayQuery } from "../app/services/generated-api.js"
import { getPublicImageGcsUrl } from "../util/url.js"

/**
 * UserProfileAvatar is a component that fetches a user by id and displays their avatar
 */
export default function UserProfileAvatar({ id, ...avatarProps }: { id: string } & AvatarProps) {
  const intl = useIntl()
  // Link code if a user is being fetching in a BRM link page
  const { code } = useParams<{ code: string }>()
  const { data: user } = useGetUserV1ByIdDisplayQuery({ id, linkCode: code })
  if (!user) {
    return null
  }
  return (
    <Avatar
      referrerPolicy="no-referrer"
      src={getPublicImageGcsUrl(user.profile_image?.gcs_file_name)}
      // Avatar defaults the icon to the name if it is set, even if the icon is set.
      name={displayPersonName(user, intl)}
      {...avatarProps}
    />
  )
}
