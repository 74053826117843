import { Editor, Range, Element as SlateElement, Transforms } from "slate"

/** Inline elements (links) */

import type { LinkElement } from "@brm/schema-types/types.js"
import type { CustomEditor } from "../types.js"
import { RICH_TEXT_SPACE } from "./common.js"

export const isLinkActive = (editor: CustomEditor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  })
  return !!link
}

export const stringIsValidLinkToInsert = (url: string) => {
  return url && URL.canParse(url) && (url.startsWith("http://") || url.startsWith("https://"))
}

const wrapLink = (editor: CustomEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link: LinkElement = {
    type: "link",
    url: encodeURI(url),
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, [link, RICH_TEXT_SPACE])
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: "end" })
  }
  const nextNode = Editor.next(editor)
  if (!nextNode) {
    return
  }
  const [_, path] = nextNode
  // After inserting the link, move cursor to the next node so we are no longer in link mode
  const nextNodeStartPoint = Editor.start(editor, path)
  Transforms.select(editor, nextNodeStartPoint)
}

export const insertLink = (editor: CustomEditor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const unwrapLink = (editor: CustomEditor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  })
}

export const withInlines = (editor: CustomEditor) => {
  const { insertData, insertText, isInline, insertBreak } = editor

  editor.isInline = (element) => element.type === "link" || isInline(element)

  editor.insertText = (text) => {
    if (stringIsValidLinkToInsert(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data) => {
    const text = data.getData("text/plain")

    if (stringIsValidLinkToInsert(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  editor.insertBreak = () => {
    insertBreak()

    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      Transforms.unwrapNodes(editor, {
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
        split: true,
      })
    }
  }

  return editor
}

export const isLinkAtCursorEnd = (editor: CustomEditor) => {
  const { selection } = editor
  if (!selection || !Range.isCollapsed(selection)) return false

  const [linkNode] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  })

  if (linkNode) {
    const [, path] = linkNode
    const endPoint = Editor.end(editor, path)
    return Range.equals(selection, { anchor: endPoint, focus: endPoint })
  }

  return false
}
