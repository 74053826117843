import type {
  FieldMetadata,
  LegalClauses,
  LegalClausesFieldsMetadata,
  LegalClausesPatch,
} from "@brm/schema-types/types.js"
import { LegalClausesSchema } from "@brm/schemas"
import { Button, Icon, Tag, css, useDisclosure, useStyleConfig, useTheme } from "@chakra-ui/react"
import type { ControlProps, MultiValueGenericProps, Props as SelectProps } from "chakra-react-select"
import { Select as MultiSelect, components } from "chakra-react-select"
import { forwardRef, useImperativeHandle, useRef, type Ref } from "react"
import { useIntl } from "react-intl"
import FieldSource from "../../components/DynamicForm/FieldSource.js"
import OptionWithFieldSource from "../../components/DynamicForm/OptionWithFieldSource.js"
import type { DynamicFormFieldApproval, ValueWithSource } from "../../components/DynamicForm/types.js"
import { FIELD_SOURCE_COLORS } from "../../components/DynamicForm/utils.js"
import { TwoStarsIcon } from "../../components/icons/icons.js"
import { isNewOption } from "../../util/form.js"
import { getLegalClausesDisplayNames } from "./legal-clause-labels.js"

const Control = ({ children, ...props }: ControlProps<ValueWithSource<keyof LegalClauses>>) => {
  return (
    <components.Control
      {...props}
      innerProps={{
        ...props.innerProps,
        onMouseDown: (e) => {
          if (e.target instanceof Element && e.target.tagName === "SPAN") {
            e.preventDefault()
            return
          }
          props?.innerProps?.onMouseDown?.(e)
        },
      }}
    >
      {children}
    </components.Control>
  )
}

const MultiValueContainer = (
  props: MultiValueGenericProps<ValueWithSource<keyof LegalClauses>> & {
    selected: boolean
    onClick: (e: React.MouseEvent) => void
  }
) => {
  const { children, data, selected, onClick } = props
  const fieldSource = (data as ValueWithSource<keyof LegalClauses>)?.field_sources?.[0]
  const color =
    fieldSource?.type && fieldSource.type !== "user"
      ? FIELD_SOURCE_COLORS[fieldSource?.type]
      : fieldSource?.type === "user" && fieldSource.assigned_by_metadata
        ? "brand"
        : "gray"
  return (
    <components.MultiValueContainer {...props}>
      <Tag
        as={Button}
        onClick={onClick}
        size="md"
        display="flex"
        flexDirection="row"
        flexGrow={1}
        variant="outline"
        borderColor={selected ? `${color}.500` : "gray.300"}
        color="gray.700"
        height="auto"
        boxSizing="border-box"
        _hover={{ backgroundColor: `${color}.50` }}
        _active={{ backgroundColor: `${color}.50` }}
        backgroundColor={selected ? `${color}.50` : "white"}
        gap={1}
      >
        {fieldSource?.type === "document" ? (
          <Icon as={TwoStarsIcon} color={`${color}.700`} />
        ) : (
          fieldSource && <FieldSource fieldMetadata={fieldSource} boxSize={4} />
        )}
        {children}
      </Tag>
    </components.MultiValueContainer>
  )
}

const LegalClausesSelector = forwardRef(function LegalClausesSelector(
  props: Omit<SelectProps<ValueWithSource<keyof LegalClauses>, true>, "value" | "onChange"> & {
    value: LegalClausesPatch
    onChangeClauses: (clauses: LegalClauses, fieldSource: LegalClausesFieldsMetadata) => void
    getDefaultFieldSource: () => FieldMetadata
    suggestions?: ValueWithSource<keyof LegalClauses>[]
    tagOnClick?: (clause: keyof LegalClauses) => void
    selected?: keyof LegalClauses
    fieldMetadata?: LegalClausesFieldsMetadata
    fieldApproval?: DynamicFormFieldApproval
  },
  ref: Ref<HTMLInputElement | null>
) {
  const intl = useIntl()
  const theme = useTheme()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputStyleConfig = (useStyleConfig("Input") as any)?.field
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    value,
    onChangeClauses,
    getDefaultFieldSource,
    suggestions,
    tagOnClick,
    selected,
    fieldMetadata,
    fieldApproval,
    ...rest
  } = props

  const inputRef = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(ref, () => inputRef.current)

  const displayNames = getLegalClausesDisplayNames(intl)
  const options = [
    ...(suggestions || []),
    ...(Object.entries(displayNames) as [keyof LegalClauses, string][])
      .filter(([key]) => {
        // Filter out selected and suggestions values
        const isSuggested = suggestions?.some((suggestion) => suggestion.value === key)
        const inValue = Object.entries(value).some(([clause, selected]) => clause === key && selected)
        return !isSuggested && !inValue
      })
      .map(([key]) => ({
        value: key,
      })),
  ]
  // Merge values with suggestions to get fieldSource per clause option
  const valueWithFieldSource = (Object.entries(value) as [keyof LegalClauses, string][])
    .filter(([, value]) => value)
    .map(([key]) => {
      const fieldSource = fieldMetadata?.[key]
      return { value: key, field_sources: fieldSource ? [fieldSource] : [] }
    })

  return (
    <MultiSelect<ValueWithSource<keyof LegalClauses>, true>
      {...rest}
      isMulti
      closeMenuOnSelect={false}
      openMenuOnFocus={true}
      menuIsOpen={isOpen}
      onMenuOpen={onOpen}
      menuPortalTarget={document.body}
      onMenuClose={onClose}
      value={valueWithFieldSource}
      options={options}
      getOptionLabel={(option) => displayNames[option.value]}
      formatOptionLabel={(option, { context }) => {
        const { field_sources } = option
        const optionLabel = displayNames[option.value]
        return context === "menu" ? (
          <OptionWithFieldSource fieldSources={field_sources}>{optionLabel}</OptionWithFieldSource>
        ) : (
          optionLabel
        )
      }}
      onChange={(clauses) => {
        const set = new Set(clauses.map((c) => c.value))
        const fieldSourceMetadata: LegalClausesFieldsMetadata = Object.fromEntries(
          Array.from(set, (value) => [
            value,
            clauses?.find((c) => c.value === value)?.field_sources?.[0] || getDefaultFieldSource(),
          ])
        )
        onChangeClauses(
          Object.fromEntries(
            (Object.keys(LegalClausesSchema.properties) as (keyof LegalClauses)[]).map((clause) => [
              clause,
              set.has(clause),
            ])
          ) as unknown as LegalClauses,
          fieldSourceMetadata
        )
      }}
      ref={(select) => {
        inputRef.current = select?.inputRef ?? null
      }}
      components={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Control,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        MultiValueContainer: (props) => (
          <MultiValueContainer
            {...props}
            selected={selected === props.data?.value}
            onClick={() => {
              const clause = props.data.value
              tagOnClick?.(clause)
            }}
          />
        ),
      }}
      styles={{
        menuPortal: (styles) => ({ ...styles, zIndex: "var(--chakra-zIndices-dropdown)" }),
        // chakraStyles prop are not passed down into custom components
        control: () => {
          const baseStyles = css(inputStyleConfig)(theme)
          const focusedStyles = css(inputStyleConfig?._focus)(theme)
          const hoverStyles = css(inputStyleConfig?._hover)(theme)
          return {
            ...baseStyles,
            height: "auto",
            display: "flex",
            padding: 0,
            paddingTop: "8px",
            paddingBottom: "8px",
            ...(isOpen ? css(inputStyleConfig?._focus)(theme) : {}),
            "&:hover": isOpen ? focusedStyles : hoverStyles,
          }
        },
      }}
      chakraStyles={{
        valueContainer: (base) => ({ ...base, gap: 2, flexGrow: 1 }),
        multiValueRemove: (base) => ({
          ...base,
          color: "gray.600",
          marginLeft: 1,
        }),
        option: (provided, { data }) => {
          const { isNew, colorScheme } = isNewOption(data.field_sources, fieldMetadata, fieldApproval)
          return { ...provided, ...(isNew && { backgroundColor: `${colorScheme}.50` }) }
        },
      }}
    />
  )
})

export default LegalClausesSelector
