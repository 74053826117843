import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  type UseModalProps,
} from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { usePostToolV1ByIdHideMutation } from "../../app/services/generated-api.js"
import { Link } from "../../components/Link.js"
import { LONG_TOAST_DURATION } from "../../util/constant.js"
import { getAPIErrorMessage } from "../../util/error.js"

interface Props extends UseModalProps {
  toolId: string
}

export function HideToolModal({ toolId, ...modalProps }: Props) {
  const intl = useIntl()
  const toast = useToast()
  const navigate = useNavigate()

  const [hideTool] = usePostToolV1ByIdHideMutation()

  return (
    <Modal {...modalProps} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage
            id="hide.tool.title"
            description="The title of the hide tool modal"
            defaultMessage="Hide Tool"
          />
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" gap={4}>
          <Text>
            <FormattedMessage
              id="hide.tool.description"
              description="The description of the hide tool modal"
              defaultMessage="Hiding this tool will remove it from your organization’s tool list and prevent new software purchase requests for it. As an admin, you can manage your hidden tools {here}."
              values={{
                here: (
                  <Link to="/settings/archived" color="brand.500" fontWeight="bold">
                    {intl.formatMessage({
                      id: "hide.tool.here",
                      description: "The label of the link to the tools page",
                      defaultMessage: "here",
                    })}
                  </Link>
                ),
              }}
            />
          </Text>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button type="button" onClick={modalProps.onClose}>
            <FormattedMessage
              defaultMessage="Cancel"
              id="hide.tool.cancel"
              description="The label of the cancel button in the hide tool modal"
            />
          </Button>
          <Button
            type="submit"
            colorScheme="brand"
            onClick={async () => {
              try {
                await hideTool({ id: toolId, body: { hidden: true } }).unwrap()

                modalProps.onClose()
                toast({
                  description: (
                    <FormattedMessage
                      id="tool.hidden.toast.title"
                      description="The title of the toast message when a tool is hidden"
                      defaultMessage="Tool hidden. Manage your hidden tools {here}."
                      values={{
                        here: (
                          <Link to="/settings/archived" fontWeight="bold">
                            {intl.formatMessage({
                              id: "hide.tool.here",
                              description: "The label of the link to the tools page",
                              defaultMessage: "here",
                            })}
                          </Link>
                        ),
                      }}
                    />
                  ),
                  duration: LONG_TOAST_DURATION,
                  status: "success",
                })
                navigate("/tools")
              } catch (err) {
                const title =
                  getAPIErrorMessage(err) ||
                  intl.formatMessage({
                    id: "tool.hidden.toast.error",
                    description: "The title of the toast message when a tool hiding fails",
                    defaultMessage: "Failed to hide tool",
                  })
                toast({ status: "error", title })
              }
            }}
          >
            <FormattedMessage
              id="hide.tool.button"
              description="The label of the hide button in the hide tool modal"
              defaultMessage="Hide Tool"
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
