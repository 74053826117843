import type {
  CustomizableObjectType,
  DocumentMinimal,
  ExtractedFieldConfig,
  FieldConfig,
  FieldConfigMinimal,
} from "@brm/schema-types/types.js"
import { getFieldMapKey } from "@brm/type-helpers/field.js"
import { getTitle } from "@brm/util/schema.js"
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
  useGetSchemaV1FieldsQuery,
  usePutSchemaV1FieldsDocumentExtractionJobMutation,
} from "../../../app/services/generated-api.js"
import { ExtractionIcon } from "../../../components/ExtractionHighlight.js"
import { IconButtonWithTooltip } from "../../../components/IconButtonWithTooltip.js"
import { PlusIcon, SearchIcon, UploadIcon, XIcon } from "../../../components/icons/icons.js"
import Spinner from "../../../components/spinner.js"
import { SettingsHeader } from "../SettingsHeader.js"
import { CriterionListItem } from "./CriteriaListItem.js"
import { CriterionInspector } from "./CriterionInspector.js"
import { FormCriteriaReviewModal } from "./FormCriteriaReviewModal.js"
import { FormUploadModal } from "./FormUploadModal.js"

interface CriteriaTab {
  id: string
  title: string
  pathSegment: string
}

export default function CriteriaSettings() {
  const intl = useIntl()
  const navigate = useNavigate()
  const toast = useToast()

  const { category } = useParams()

  const navItems: CriteriaTab[] = [
    {
      id: "all",
      title: intl.formatMessage({
        id: "settings.criteria.category.all",
        defaultMessage: "All",
        description: "Navigation item for all criteria settings",
      }),
      pathSegment: "",
    },
    {
      id: "general",
      title: intl.formatMessage({
        id: "settings.criteria.category.general",
        defaultMessage: "General",
        description: "Navigation item for general criteria settings",
      }),
      pathSegment: "general",
    },
    {
      id: "finance",
      title: intl.formatMessage({
        id: "settings.criteria.category.finance",
        defaultMessage: "Finance",
        description: "Navigation item for finance criteria settings",
      }),
      pathSegment: "finance",
    },
    {
      id: "compliance",
      title: intl.formatMessage({
        id: "settings.criteria.category.compliance",
        defaultMessage: "Compliance",
        description: "Navigation item for compliance criteria settings",
      }),
      pathSegment: "compliance",
    },
    {
      id: "it",
      title: intl.formatMessage({
        id: "settings.criteria.category.it",
        defaultMessage: "IT",
        description: "Navigation item for IT criteria settings",
      }),
      pathSegment: "it",
    },
    {
      id: "legal",
      title: intl.formatMessage({
        id: "settings.criteria.category.legal",
        defaultMessage: "Legal",
        description: "Navigation item for legal criteria settings",
      }),
      pathSegment: "legal",
    },
  ]

  const objectTypes = [
    {
      title: intl.formatMessage({
        id: "criteria.settings.nav.tool",
        defaultMessage: "Tool",
        description: "Tab for tool criteria settings",
      }),
      customizableType: "Tool",
      pathSegment: "tool",
    },
    {
      title: intl.formatMessage({
        id: "criteria.settings.nav.vendor",
        defaultMessage: "Vendor",
        description: "Navigation item for vendor criteria settings",
      }),
      customizableType: "Vendor",
      pathSegment: "vendor",
    },
    {
      title: intl.formatMessage({
        id: "criteria.settings.nav.agreement",
        defaultMessage: "Agreement",
        description: "Navigation item for legal agreement criteria settings",
      }),
      customizableType: "LegalAgreement",
      pathSegment: "legal-agreement",
    },
    {
      title: intl.formatMessage({
        id: "criteria.settings.nav.request",
        defaultMessage: "Request",
        description: "Navigation item for request criteria settings",
      }),
      customizableType: "WorkflowRun",
      pathSegment: "workflow-run",
    },
  ]

  const formUploadModal = useDisclosure()
  const formCriteriaReviewModal = useDisclosure()
  const criteriaReextractionModal = useDisclosure()

  const [extractedFields, setExtractedFields] = useState<{
    fields: ExtractedFieldConfig[]
    document: DocumentMinimal
  }>()

  const [searchParams, setSearchParams] = useSearchParams()
  const objectType = searchParams.get("objectType") as CustomizableObjectType
  const filterText = searchParams.get("filterText") ?? ""

  const { data: fieldsByObjectType } = useGetSchemaV1FieldsQuery()
  const [startFieldExtractionJob] = usePutSchemaV1FieldsDocumentExtractionJobMutation()

  const [selectedCriterion, setSelectedCriterion] = useState<FieldConfig | null>(null)
  const [newlyCreatedFieldKey, setNewlyCreatedFieldKey] = useState<string | null>(null)

  // Map from unique field key to field config. Used to validate conflicts when creating new criteria
  const fieldKeyMap = useMemo(() => {
    const fieldKeyMap: Record<string, FieldConfig> = {}
    for (const [_, fields] of Object.entries(fieldsByObjectType?.fields ?? {})) {
      for (const field of fields) {
        fieldKeyMap[getFieldMapKey(field)] = field
      }
    }
    return fieldKeyMap
  }, [fieldsByObjectType])

  // Filter and sort criteria based on search text and selected category
  const filteredCriteria = useMemo(() => {
    // Return empty object if no criteria available
    if (!fieldsByObjectType?.fields) {
      return {} as Record<CustomizableObjectType, FieldConfig[]>
    }

    // Create new fields object with filtered criteria for each object type
    const filteredFields = Object.entries(fieldsByObjectType.fields).reduce(
      (acc, [type, fields]) => {
        // Filter criteria based on:
        // 1. Title matches search text (case insensitive)
        // 2. Category matches selected category, or no category for "general"
        const filtered = fields.filter(
          (criterion) =>
            (!category || (category === "general" && !criterion.category) || criterion.category === category) &&
            getTitle(criterion.field_name, criterion.field_schema as JSONSchemaObject)
              .toLowerCase()
              .includes(filterText.toLowerCase())
        )

        // Sort criteria by:
        // 1. Enabled status (enabled criteria first)
        // 2. Alphabetically by title
        const sorted = filtered.sort((a, b) => {
          // Move disabled criteria to the end
          if (a.is_enabled === false && b.is_enabled !== false) return 1
          if (a.is_enabled !== false && b.is_enabled === false) return -1
          // Sort alphabetically if enabled status is the same
          const titleA = getTitle(a.field_name, a.field_schema as JSONSchemaObject).toLowerCase()
          const titleB = getTitle(b.field_name, b.field_schema as JSONSchemaObject).toLowerCase()
          return titleA.localeCompare(titleB)
        })

        acc[type as CustomizableObjectType] = sorted
        return acc
      },
      {} as Record<CustomizableObjectType, FieldConfig[]>
    )

    return filteredFields
  }, [fieldsByObjectType, filterText, category])

  const criteriaNeedingReextraction = useMemo(() => {
    return Object.values(filteredCriteria).flatMap((criteria) => criteria.filter((c) => c.needs_reextraction))
  }, [filteredCriteria])

  const handleCriterionDeleted = useCallback(() => {
    setSelectedCriterion(null)
  }, [])

  const [isCreatingNewCriterion, setIsCreatingNewCriterion] = useState(false)

  const handleCriterionSelect = useCallback((criterion: FieldConfig) => {
    setSelectedCriterion(criterion)
    setIsCreatingNewCriterion(false)
  }, [])

  const handleCriterionCreated = useCallback(
    (newCriterion: FieldConfigMinimal) => {
      setIsCreatingNewCriterion(false)
      navigate(`/settings/criteria/${newCriterion.category ?? "general"}?objectType=${newCriterion.object_type}`)
      setNewlyCreatedFieldKey(getFieldMapKey(newCriterion))
    },
    [navigate]
  )

  // Effect to set the newly created criterion as selected once it's available in the criteria list
  useEffect(() => {
    if (newlyCreatedFieldKey && fieldsByObjectType?.fields) {
      const fullCriterion = Object.values(fieldsByObjectType.fields)
        .flatMap((fields) => fields)
        .find((c) => getFieldMapKey(c) === newlyCreatedFieldKey)
      if (fullCriterion) {
        setSelectedCriterion(fullCriterion)
        setNewlyCreatedFieldKey(null)
      }
    }
  }, [fieldsByObjectType, newlyCreatedFieldKey, category, navigate, objectType])

  // Add ref for the headings
  const headingRefs = useRef<Record<string, HTMLHeadingElement | null>>({})

  // Add effect to handle scrolling when objectType changes
  useEffect(() => {
    const headingElement = headingRefs.current[objectType]
    if (headingElement) {
      headingElement.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }, [objectType])

  if (!fieldsByObjectType) {
    return (
      <Center flexGrow={1}>
        <Spinner size="md" />
      </Center>
    )
  }

  return (
    <>
      <Flex direction="column" flexGrow={1} gap={4} minHeight={0} pt={6} px={8}>
        <SettingsHeader
          title={intl.formatMessage({
            defaultMessage: "Criteria",
            description: "The heading for the criteria settings page",
            id: "settings.criteria.header",
          })}
          subtitle={intl.formatMessage({
            defaultMessage:
              "Configure your organization’s criteria. These criteria will appear in requests, as well as the System of Record (SOR) for tools and vendors.",
            description: "The subtitle for the criteria settings page",
            id: "settings.criteria.subtitle",
          })}
        />
        <Flex flexDirection="row" flex={1} minHeight={0}>
          <Tabs
            flex={1}
            minHeight={0}
            display="flex"
            flexDirection="column"
            isFitted
            variant="line"
            colorScheme="brand"
            onChange={(index) => {
              const pathSegment = navItems[index]?.pathSegment
              if (typeof pathSegment === "string") {
                setSelectedCriterion(null)
                setIsCreatingNewCriterion(false)
                navigate({ pathname: `/settings/criteria/${pathSegment}`, search: searchParams.toString() })
              }
            }}
            index={navItems.findIndex((item) => item.pathSegment === category || (!category && !item.pathSegment))}
          >
            <TabList>
              {navItems.map(({ title }) => (
                <Tab key={title}>{title}</Tab>
              ))}
            </TabList>
            <TabPanels minHeight={0} display="flex" flexDirection="column">
              <HStack>
                <InputGroup my={3}>
                  <InputLeftElement pointerEvents="none">
                    <Icon as={SearchIcon} color="gray.500" />
                  </InputLeftElement>
                  <Input
                    placeholder={intl.formatMessage({
                      id: "settings.criteria.search.placeholder",
                      defaultMessage: "Search criteria...",
                      description: "Placeholder text for criteria search input",
                    })}
                    value={filterText}
                    onChange={(e) =>
                      setSearchParams((prev) => {
                        if (e.target.value) {
                          prev.set("filterText", e.target.value)
                        } else {
                          prev.delete("filterText")
                        }
                        return prev
                      })
                    }
                  />
                  {filterText && (
                    <InputRightElement
                      onClick={() =>
                        setSearchParams((prev) => {
                          prev.delete("filterText")
                          return prev
                        })
                      }
                      cursor="pointer"
                    >
                      <Icon as={XIcon} />
                    </InputRightElement>
                  )}
                </InputGroup>
                {criteriaNeedingReextraction.length > 0 && (
                  <IconButtonWithTooltip
                    variant="outline"
                    icon={<ExtractionIcon />}
                    onClick={criteriaReextractionModal.onOpen}
                    label={intl.formatMessage({
                      id: "settings.criteria.reextraction_modal.tooltip",
                      description: "Tooltip for criteria re-extraction button",
                      defaultMessage: "Legal Agreement Criteria has changed",
                    })}
                  />
                )}
                <IconButtonWithTooltip
                  {...formUploadModal.getButtonProps()}
                  variant="outline"
                  icon={<Icon as={UploadIcon} />}
                  onClick={formUploadModal.onOpen}
                  label={intl.formatMessage({
                    defaultMessage: "Upload document to generate criteria",
                    description: "Tooltip label for upload document button",
                    id: "settings.criteria.upload_form_tooltip",
                  })}
                />
                <IconButtonWithTooltip
                  colorScheme="brand"
                  icon={<Icon as={PlusIcon} />}
                  onClick={() => {
                    setSelectedCriterion(null)
                    setIsCreatingNewCriterion(true)
                  }}
                  label={intl.formatMessage({
                    defaultMessage: "Add new criterion",
                    description: "Tooltip label for add new criterion button",
                    id: "settings.criteria.add_new_criterion_tooltip",
                  })}
                />
              </HStack>
              <Flex gap={8} minHeight={0}>
                <Stack>
                  {Object.entries(filteredCriteria).map(([ot]) => (
                    <Button
                      key={ot}
                      variant="ghost"
                      justifyContent="flex-start"
                      isActive={objectType === ot}
                      onClick={() => {
                        setIsCreatingNewCriterion(false)
                        setSearchParams((prev) => {
                          prev.set("objectType", ot)
                          return prev
                        })
                      }}
                    >
                      {objectTypes.find((objectTypeData) => objectTypeData.customizableType === ot)?.title ?? ot}
                    </Button>
                  ))}
                </Stack>
                <Flex flexDirection="column" overflowY="auto" gap={1} p={1} minHeight={0} flex={1}>
                  {Object.entries(filteredCriteria).map(([ot, criteria], idx) => (
                    <Fragment key={ot}>
                      {idx > 0 && <Divider my={4} />}
                      <Stack key={ot} spacing={1}>
                        <Heading
                          size="xs"
                          color={objectType === ot ? "brand.600" : undefined}
                          px={2}
                          ref={(el) => (headingRefs.current[ot] = el)}
                        >
                          {objectTypes.find((objectTypeData) => objectTypeData.customizableType === ot)?.title ?? ot}
                        </Heading>
                        {criteria.length > 0 ? (
                          criteria.map((criterion) => (
                            <CriterionListItem
                              key={getFieldMapKey(criterion)}
                              criterion={criterion}
                              defs={fieldsByObjectType.$defs}
                              onSelect={handleCriterionSelect}
                              isActive={
                                selectedCriterion
                                  ? getFieldMapKey(selectedCriterion) === getFieldMapKey(criterion)
                                  : false
                              }
                            />
                          ))
                        ) : (
                          <Text px={2} color="gray.500">
                            <FormattedMessage
                              id="settings.criteria.no_criteria"
                              description="Message shown when no criteria are found"
                              defaultMessage="No criteria found. <newCriterionLink>Create custom field</newCriterionLink>"
                              values={{
                                newCriterionLink: (chunks) => (
                                  <Button
                                    variant="link"
                                    onClick={() => {
                                      setSearchParams((prev) => {
                                        prev.set("objectType", ot)
                                        return prev
                                      })
                                      setSelectedCriterion(null)
                                      setIsCreatingNewCriterion(true)
                                    }}
                                  >
                                    {chunks}
                                  </Button>
                                ),
                              }}
                            />
                          </Text>
                        )}
                      </Stack>
                    </Fragment>
                  ))}
                </Flex>
              </Flex>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>

      {/* Inspector Panel */}
      <Box
        pt={6}
        px={4}
        overflowY="auto"
        borderLeft="1px solid"
        borderColor="gray.200"
        width={{ base: "xs", "2xl": "md" }}
        flexShrink={0}
      >
        {isCreatingNewCriterion ? (
          <CriterionInspector
            objectType={objectType}
            fieldConfig={undefined}
            defs={fieldsByObjectType?.$defs ?? {}}
            onCriterionDeleted={() => setIsCreatingNewCriterion(false)}
            onCriterionCreated={handleCriterionCreated}
            fieldKeyMap={fieldKeyMap}
          />
        ) : selectedCriterion ? (
          <CriterionInspector
            key={selectedCriterion.field_name}
            objectType={objectType}
            fieldConfig={selectedCriterion}
            defs={fieldsByObjectType?.$defs ?? {}}
            onCriterionDeleted={handleCriterionDeleted}
          />
        ) : (
          <Text color="gray.500">
            <FormattedMessage
              id="settings.criteria.inspector.placeholder"
              description="Placeholder text for the criteria inspector panel"
              defaultMessage="Select a criterion to view its details or click the + button to add a new one"
            />
          </Text>
        )}
      </Box>

      {/* Criteria Re-extraction Modal */}
      {criteriaReextractionModal.isOpen && (
        <Modal isOpen={criteriaReextractionModal.isOpen} onClose={criteriaReextractionModal.onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <FormattedMessage
                id="settings.criteria.reextraction_modal.title"
                description="Title for criteria re-extraction modal"
                defaultMessage="Extract Criteria"
              />
            </ModalHeader>
            <ModalBody>
              <Text>
                <FormattedMessage
                  id="settings.criteria.reextraction_modal.message"
                  description="Message in criteria re-extraction modal"
                  defaultMessage="The following fields have changed and can be re-extracted:"
                />
              </Text>
              <Stack spacing={2} marginY={2}>
                {criteriaNeedingReextraction.map((criteria) => (
                  <Text key={criteria.field_name} fontWeight="bold">
                    {getTitle(criteria.field_name, criteria.field_schema as JSONSchemaObject)}
                  </Text>
                ))}
              </Stack>
              <FormattedMessage
                defaultMessage="Starting an extraction will update the changed fields to all existing legal agreements. Any agreement that has new extracted values will need to be re-verified."
                description="Message in criteria re-extraction modal"
                id="settings.criteria.reextraction_modal.message"
              />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={2}>
                <Button variant="outline" onClick={criteriaReextractionModal.onClose}>
                  <FormattedMessage
                    id="settings.criteria.reextraction_modal.skip"
                    description="Dismiss button label in re-extraction modal"
                    defaultMessage="Close"
                  />
                </Button>
                <Button
                  colorScheme="brand"
                  onClick={async () => {
                    try {
                      await startFieldExtractionJob({
                        body: { fields: criteriaNeedingReextraction },
                      })
                      toast({
                        status: "success",
                        description: intl.formatMessage({
                          id: "settings.criteria.reextraction_modal.reextract.success",
                          description: "Toast shown when extraction is started",
                          defaultMessage: "Extraction started",
                        }),
                      })
                    } catch {
                      toast({
                        status: "error",
                        description: intl.formatMessage({
                          id: "settings.criteria.reextraction_modal.reextract.error",
                          description: "Toast shown when extraction fails",
                          defaultMessage: "Failed to start extraction",
                        }),
                      })
                    }
                    criteriaReextractionModal.onClose()
                  }}
                >
                  <FormattedMessage
                    id="settings.criteria.reextraction_modal.reextract"
                    description="Re-extraction button label in re-extraction modal"
                    defaultMessage="Begin Extraction"
                  />
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {formUploadModal.isOpen && (
        <FormUploadModal
          isOpen={formUploadModal.isOpen}
          onClose={formUploadModal.onClose}
          onExtracted={(fields, document) => {
            setExtractedFields({ fields, document })
            formUploadModal.onClose()
            formCriteriaReviewModal.onOpen()
          }}
        />
      )}
      {formCriteriaReviewModal.isOpen && extractedFields && (
        <FormCriteriaReviewModal
          document={extractedFields.document}
          extractedFields={extractedFields.fields}
          isOpen={formCriteriaReviewModal.isOpen}
          onClose={formCriteriaReviewModal.onClose}
        />
      )}
    </>
  )
}
