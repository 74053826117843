import type { AvatarProps, SystemStyleObject } from "@chakra-ui/react"
import { Avatar, AvatarBadge, HStack, Icon, Stack, Text } from "@chakra-ui/react"
import { CELL_SIZE_PROPS_MAP, type CellSize } from "../../components/DataTable/CellRenderer/util.js"
import { UserIcon } from "../../components/icons/icons.js"
import { getPublicImageGcsUrl } from "../../util/url.js"

interface Props {
  displayText: string
  subText?: string | null
  image?: string | null
  size?: CellSize
  /** Image takes priority over icon if both are set. If both are not set, it will default it to the initials of the displayText of the Entity */
  icon?: React.ReactElement
  /** Secondary Image is shown on the right side of the displayText */
  secondaryImage?: string | null
  organizationLogoGcsFileName?: string | null
  before?: React.ReactNode
  textContentStyle?: SystemStyleObject
}

export default function PickableEntityDisplay({
  displayText,
  subText,
  image,
  icon,
  secondaryImage,
  organizationLogoGcsFileName,
  textContentStyle,
  size = "md",
  /** Element to render before the image and title */
  before,
}: Props) {
  const cellSizeProps = CELL_SIZE_PROPS_MAP[size]

  // Avatar defaults the icon to the name if it is set
  const avatarProps: AvatarProps = icon
    ? { icon }
    : {
        name: displayText,
        // If the display text and icon are not set, default to FiUser
        icon: <Icon as={UserIcon} />,
      }

  return (
    <HStack maxW="100%">
      {before}
      <Avatar
        referrerPolicy="no-referrer"
        src={image || ""}
        // Avatar defaults the icon to the name if it is set, even if the icon is set.
        boxSize={cellSizeProps.boxSize}
        textColor="black"
        {...avatarProps}
      >
        {organizationLogoGcsFileName && (
          <AvatarBadge as={Avatar} boxSize={3} src={getPublicImageGcsUrl(organizationLogoGcsFileName)} />
        )}
      </Avatar>
      <Stack gap={0} overflow="hidden" alignItems="start" sx={textContentStyle}>
        <Text
          maxW="100%"
          fontWeight={cellSizeProps.fontWeight}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {displayText}
        </Text>
        {subText && (
          <Text maxW="100%" fontWeight="light" textOverflow="ellipsis" overflow="hidden">
            {subText}
          </Text>
        )}
      </Stack>
      {secondaryImage && (
        <Avatar referrerPolicy="no-referrer" src={secondaryImage || ""} boxSize={cellSizeProps.boxSize} />
      )}
    </HStack>
  )
}
