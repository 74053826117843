import { isEmpty } from "@brm/util/type-guard.js"
import { fetchEventSource } from "@microsoft/fetch-event-source"
import {
  type PostBetsyV1AskApiArg,
  type PostTimelineV1EventsObjectsByObjectTypeAndObjectIdStreamingApiArg,
} from "../../app/services/generated-api.js"
import { log } from "../../util/logger.js"

/**
 * Reusable function to ask Betsy questions from other components.
 */
export async function askBetsy(
  body: PostBetsyV1AskApiArg["body"],
  onMessage: (text: string) => void,
  signal: AbortSignal
) {
  await fetchEventSource(`${import.meta.env.VITE_API_BASE_URL}/betsy/v1/ask`, {
    method: "POST",
    credentials: "include",
    openWhenHidden: true,
    body: JSON.stringify(body),
    signal,
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: "text/event-stream",
      "Content-Type": "application/json",
    },
    onmessage(event) {
      if (event.event === "data") {
        const data = JSON.parse(event.data)
        const text = data?.text
        if (!isEmpty(text)) {
          onMessage(text)
        }
      }
    },
    onerror(e) {
      log.error("braim api failure", e)
    },
  })
}

/**
 * intended to be used with the useStreaming hook, but you can also supply your own onText and onStatus callbacks
 */
export async function dynamoTimelineEventStream(
  args: PostTimelineV1EventsObjectsByObjectTypeAndObjectIdStreamingApiArg,
  signal: AbortSignal,
  onText?: (text: string) => void,
  onStatus?: (status: string) => void
) {
  const { objectType, objectId, timelineInput, workflowRunId, workflowRunStepId, fieldName } = args

  const queryParams = new URLSearchParams(
    [
      ["field_name", fieldName],
      ["workflow_run_id", workflowRunId],
      ["workflow_run_step_id", workflowRunStepId],
    ].filter(([_, value]) => value !== undefined) as [string, string][]
  )

  const url = new URL(
    `${import.meta.env.VITE_API_BASE_URL}/timeline/v1/events/objects/${objectType}/${objectId}/streaming?${queryParams}`
  )

  await fetchEventSource(url.href, {
    method: "POST",
    credentials: "include",
    openWhenHidden: true,
    body: JSON.stringify(timelineInput),
    signal,
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Accept: "text/event-stream",
      "Content-Type": "application/json",
    },
    onmessage(event) {
      if (event.event === "text" && event.data) {
        const data = JSON.parse(event.data)
        onText?.(data.text)
      }
      if (event.event === "status" && event.data) {
        const data = JSON.parse(event.data)
        onStatus?.(data.status)
      }
    },
    onerror(e) {
      throw e
    },
  })
}
