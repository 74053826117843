import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  type UseModalProps,
} from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { usePostVendorV1ByIdHideMutation } from "../../app/services/generated-api.js"
import { Link } from "../../components/Link.js"
import { LONG_TOAST_DURATION } from "../../util/constant.js"
import { getAPIErrorMessage } from "../../util/error.js"

interface Props extends UseModalProps {
  vendorId: string
}

export function HideVendorModal({ vendorId, ...modalProps }: Props) {
  const intl = useIntl()
  const toast = useToast()
  const navigate = useNavigate()

  const [hideVendor] = usePostVendorV1ByIdHideMutation()

  return (
    <Modal {...modalProps} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FormattedMessage
            id="hide.vendor.title"
            description="The title of the hide vendor modal"
            defaultMessage="Hide Vendor"
          />
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody display="flex" flexDirection="column" gap={4}>
          <Text>
            <FormattedMessage
              id="hide.vendor.description"
              description="The description of the hide vendor modal"
              defaultMessage="Hiding this vendor will remove it and the related tools from your organization’s vendor list and prevent purchase requests for it. As an admin, you can manage your hidden vendors {here}."
              values={{
                here: (
                  <Link to="/settings/archived" color="brand.500" fontWeight="bold">
                    {intl.formatMessage({
                      id: "hide.vendor.here",
                      description: "The label of the link to the vendors page",
                      defaultMessage: "here",
                    })}
                  </Link>
                ),
              }}
            />
          </Text>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button type="button" onClick={modalProps.onClose}>
            <FormattedMessage
              defaultMessage="Cancel"
              id="hide.vendor.cancel"
              description="The label of the cancel button in the hide vendor modal"
            />
          </Button>
          <Button
            type="submit"
            colorScheme="brand"
            onClick={async () => {
              try {
                await hideVendor({ id: vendorId, body: { hidden: true } }).unwrap()

                modalProps.onClose()
                toast({
                  description: (
                    <FormattedMessage
                      id="vendor.hidden.toast.title"
                      description="The title of the toast message when a vendor is hidden"
                      defaultMessage="Vendor hidden. Manage your hidden vendors {here}."
                      values={{
                        here: (
                          <Link to="/settings/archived" fontWeight="bold">
                            {intl.formatMessage({
                              id: "hide.vendor.here",
                              description: "The label of the link to the vendors page",
                              defaultMessage: "here",
                            })}
                          </Link>
                        ),
                      }}
                    />
                  ),
                  duration: LONG_TOAST_DURATION,
                  status: "success",
                })
                navigate("/vendors")
              } catch (err) {
                const title =
                  getAPIErrorMessage(err) ||
                  intl.formatMessage({
                    id: "vendor.hidden.toast.error",
                    description: "The title of the toast message when a vendor hiding fails",
                    defaultMessage: "Failed to hide vendor",
                  })
                toast({ status: "error", title })
              }
            }}
          >
            <FormattedMessage
              id="hide.vendor.button"
              description="The label of the hide button in the hide vendor modal"
              defaultMessage="Hide Vendor"
            />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
