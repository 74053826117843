import { getCurrentApprovalStep } from "@brm/schema-helpers/workflow.js"
import type { WorkflowRunApprovalStep, WorkflowRunStepWithContext } from "@brm/schema-types/types.js"
import { uniqBy } from "@brm/util/collections.js"
import { displayPersonName } from "@brm/util/names.js"
import type { AvatarGroupProps, AvatarProps, IconProps } from "@chakra-ui/react"
import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  HStack,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react"
import type { FC } from "react"
import { FormattedList, FormattedMessage, useIntl } from "react-intl"
import { CircleCheckIcon, CircleDotIcon } from "../../../../components/icons/icons.js"
import { getPublicImageGcsUrl } from "../../../../util/url.js"

export default function WorkflowRunStepAvatars({
  step,
  avatarProps,
  ...avatarGroupProps
}: {
  step: Pick<WorkflowRunStepWithContext, "owner" | "approval_steps" | "status" | "submitted_by">
  avatarProps?: AvatarProps
} & Omit<AvatarGroupProps, "children">) {
  const intl = useIntl()
  const uniqueApproverUsers = uniqBy(
    step.approval_steps.flatMap((as) => as.approvers.map((a) => a.user)),
    (u) => u.id
  )

  return (
    <HStack>
      <Popover size="xs" trigger="hover" placement="bottom-start">
        <PopoverTrigger>
          <Avatar
            src={getPublicImageGcsUrl(step.owner.profile_image?.gcs_file_name)}
            name={displayPersonName(step.owner, intl)}
            {...avatarProps}
          />
        </PopoverTrigger>
        <PopoverContent width="unset">
          <PopoverBody>
            <Text fontWeight="medium" mb={2} color="gray.600">
              <FormattedMessage
                id="step.header.gatherer.popover.label"
                description="Label for gatherer in step header popover"
                defaultMessage="Gatherer"
              />
            </Text>
            <HStack>
              <Avatar
                name={displayPersonName(step.owner, intl)}
                src={getPublicImageGcsUrl(step.owner.profile_image?.gcs_file_name)}
                {...avatarProps}
                size="md"
              />
              <Stack gap={1}>
                <Text fontWeight="semibold">{displayPersonName(step.owner, intl)}</Text>
                <Text color="gray.600">
                  {step.submitted_by ? (
                    step.submitted_by.id === step.owner.id ? (
                      <FormattedMessage
                        id="step.header.gathered_by.popover.label"
                        description="Label for gatherer in step header popover after gathering"
                        defaultMessage="Submitted"
                      />
                    ) : (
                      <FormattedMessage
                        id="step.header.gathered_by.popover.label_on_behalf"
                        description="Label for gatherer in step header popover after gathering"
                        defaultMessage="Submitted by {submittedByName} on behalf of {gathererName}"
                        values={{
                          gathererName: displayPersonName(step.owner, intl),
                          submittedByName: displayPersonName(step.submitted_by, intl),
                        }}
                      />
                    )
                  ) : (
                    <FormattedMessage
                      id="step.header.gatherer.popover.label"
                      description="Label for gatherer in step header popover"
                      defaultMessage="Gatherer"
                    />
                  )}
                </Text>
              </Stack>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      {step.approval_steps.length > 0 && (
        <>
          <Divider orientation="vertical" minHeight="6" />
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <AvatarGroup spacing={-2} {...avatarGroupProps}>
                {uniqueApproverUsers.map((approverUser) => (
                  <Avatar
                    key={approverUser.id}
                    name={displayPersonName(approverUser, intl)}
                    src={getPublicImageGcsUrl(approverUser.profile_image?.gcs_file_name)}
                    opacity={
                      step.status === "submitted" &&
                      getCurrentApprovalStep(step.approval_steps)?.approvers.find((a) => a.user.id === approverUser.id)
                        ? 1
                        : 0.4
                    }
                    borderWidth={0}
                    {...avatarProps}
                  />
                ))}
              </AvatarGroup>
            </PopoverTrigger>
            <PopoverContent width="unset" maxWidth={600}>
              <PopoverBody>
                <Text fontWeight="medium" mb={2} color="gray.600">
                  <FormattedMessage
                    id="step.header.approvers.popover.label"
                    defaultMessage="Approvers"
                    description="Label for approvers in step header popover"
                  />
                </Text>
                <Stack gap={4}>
                  {step.approval_steps.map((approvalStep, approvalStepIdx) => (
                    <HStack key={approvalStep.id}>
                      {step.approval_steps.length > 1 && (
                        <Box position="relative">
                          <Box
                            // eslint-disable-next-line prefer-smart-quotes/prefer
                            content="''"
                            position="absolute"
                            top="100%"
                            left="50%"
                            transform="translateX(-50%)"
                            width="2px"
                            height="32px"
                            backgroundColor={
                              approvalStep.approved_by || approvalStep.skipped_by_conditional ? "brand.600" : "gray.200"
                            }
                            display={approvalStepIdx === step.approval_steps.length - 1 ? "none" : "block"}
                          />
                          <WorkflowRunApprovalStepStatusIcon
                            approvalStep={approvalStep}
                            isCurrentApprovalStep={
                              step.status === "submitted" &&
                              getCurrentApprovalStep(step.approval_steps)?.id === approvalStep.id
                            }
                          />
                        </Box>
                      )}
                      <AvatarGroup spacing="-2" {...avatarGroupProps}>
                        {approvalStep.approvers.map((approver) => (
                          <Avatar
                            key={approver.id}
                            name={displayPersonName(approver.user, intl)}
                            src={getPublicImageGcsUrl(approver.user.profile_image?.gcs_file_name)}
                            size="md"
                            {...avatarProps}
                          />
                        ))}
                      </AvatarGroup>
                      <Stack gap={1} overflowX="hidden">
                        <Text fontWeight="semibold" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                          {approvalStep.approvers.length === 1 ? (
                            displayPersonName(approvalStep.approvers[0]!.user, intl)
                          ) : (
                            <FormattedList
                              value={approvalStep.approvers.map((approver) => approver.user.first_name)}
                              type="disjunction"
                            />
                          )}
                        </Text>
                        <Text color="gray.600">
                          <WorkflowRunApprovalStepStatusText
                            approvalStep={approvalStep}
                            approvalSteps={step.approval_steps}
                            step={step}
                            approvalIndex={approvalStepIdx}
                          />
                        </Text>
                      </Stack>
                    </HStack>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </>
      )}
    </HStack>
  )
}

function WorkflowRunApprovalStepStatusText({
  approvalStep,
  approvalSteps,
  step,
  approvalIndex,
}: {
  approvalStep: WorkflowRunApprovalStep
  approvalSteps: WorkflowRunApprovalStep[]
  step: Pick<WorkflowRunStepWithContext, "status">
  approvalIndex: number
}) {
  const intl = useIntl()
  if (approvalStep.approved_by) {
    const approvedByUser = approvalStep.approved_by
    if (approvalStep.approvers.some((approver) => approvedByUser.id === approver.user.id)) {
      return (
        <FormattedMessage
          id="step.header.approval.popover.approved"
          description="Label for approval step that has been approved"
          defaultMessage="Approved"
        />
      )
    }
    return (
      <FormattedMessage
        id="step.header.approval.popover.approved_by"
        description="Label for approval step that has been approved by a different approver"
        defaultMessage="Approved by {approverName}, on behalf of {approvers}"
        values={{
          approverName: displayPersonName(approvedByUser, intl),
          approvers: intl.formatList(
            approvalStep.approvers.map((approver) => approver.user.first_name),
            {
              type: "disjunction",
            }
          ),
        }}
      />
    )
  }
  if (approvalStep.skipped_by_conditional) {
    return (
      <FormattedMessage
        id="step.header.approval.popover.automatically_approved"
        description="Label for approval step that has been automatically approved"
        defaultMessage="Automatically approved"
      />
    )
  }
  if (step.status === "submitted") {
    // If step is submitted, show who needs to approve and who is up next
    const currentApprovalStep = getCurrentApprovalStep(approvalSteps)
    const nextApprovalStep = approvalSteps[approvalSteps.findIndex((as) => as.id === currentApprovalStep?.id) + 1]
    if (currentApprovalStep?.id === approvalStep.id) {
      return (
        <FormattedMessage
          id="step.header.approval.popover.to_approve"
          description="Label for approval step that is currently being approved"
          defaultMessage="To approve"
        />
      )
    }
    if (nextApprovalStep?.id === approvalStep.id) {
      return (
        <FormattedMessage
          id="step.header.approval.popover.to_approve_next"
          description="Label for approval step that is up next"
          defaultMessage="To approve next"
        />
      )
    }
  }
  if (approvalSteps.length === 1) {
    return (
      <FormattedMessage
        id="step.header.approval.popover.approver"
        description="Label for approval step when it is the only approval step"
        defaultMessage="Approver"
      />
    )
  }
  return (
    <FormattedMessage
      id="workflows.run.step.approver.ordinal"
      description="Ordinal number for the approver in the approval step"
      defaultMessage="{approvalIndex, selectordinal, one {#st} two {#nd} few {#rd} other{#th}} approver"
      values={{ approvalIndex: approvalIndex + 1 }}
    />
  )
}

const defaultStatusIconProps: IconProps = {
  borderRadius: "full",
  background: "white",
  color: "brand.600",
  boxSize: 5,
  verticalAlign: "middle",
}

const WorkflowRunApprovalStepStatusIcon: FC<{
  approvalStep: Pick<WorkflowRunApprovalStep, "approved_by" | "skipped_by_conditional">
  isCurrentApprovalStep?: boolean
}> = ({ approvalStep, isCurrentApprovalStep }) => {
  if (approvalStep.approved_by || approvalStep.skipped_by_conditional) {
    return <Icon as={CircleCheckIcon} {...defaultStatusIconProps} />
  }
  return (
    <Icon as={CircleDotIcon} {...defaultStatusIconProps} color={!isCurrentApprovalStep ? "gray.200" : "brand.600"} />
  )
}
