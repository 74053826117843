import type { DisplayableIntegrationType } from "@brm/schema-types/types.js"
import { Button, Text, type CardProps } from "@chakra-ui/react"
import { useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Link } from "../../../components/Link.js"
import PieChartCard from "../../charts/PieChartCard.js"
import type { PieDatum } from "../../charts/types.js"

interface Props {
  toolId: string
  departmentUserCounts: { id: string; count: number; display_name: string | null }[]
  // Still pass totalUsers here because usersByGroup may have the same user repeated across multiple groups
  totalUsers: number
  chartSources?: DisplayableIntegrationType[]
  width?: CardProps["width"]
  showNoDataState?: boolean
}

export default function ToolDepartmentPie({
  toolId,
  departmentUserCounts,
  totalUsers,
  chartSources,
  width,
  showNoDataState,
}: Props) {
  const intl = useIntl()

  const departmentData = useMemo(() => {
    const numUsersByDepartment: PieDatum[] = []
    for (const { id, count, display_name } of departmentUserCounts) {
      numUsersByDepartment.push({
        id,
        label:
          display_name ||
          intl.formatMessage(
            {
              id: "department.pie.display_name.unknown",
              description:
                "placeholder name shown on department user breakdown pie chart when display name of the department is not present",
              defaultMessage: "Unknown Department: {id}",
            },
            { id }
          ),
        value: count,
      })
    }
    return numUsersByDepartment
  }, [departmentUserCounts, intl])

  if (departmentUserCounts.length === 0 || totalUsers === 0) {
    return showNoDataState ? (
      <Text fontSize="lg" color="gray.600" alignSelf="center" align="center" py={8}>
        <FormattedMessage
          id="tool.departmentPie.noData"
          description="The tool department pie no data state"
          defaultMessage="No department data found"
        />
      </Text>
    ) : null
  }

  return (
    <PieChartCard
      pieProps={{ data: departmentData, totalValue: totalUsers, height: 220 }}
      title={intl.formatMessage({
        id: "tool.context.users.departments.title",
        description: "Title for the tool context users by department breakdown pie chart",
        defaultMessage: "Users by Department",
      })}
      width={width}
      minWidth="unset"
      chartSources={chartSources}
      description={
        <Text fontSize={12} color="muted">
          <FormattedMessage
            defaultMessage="{numUsers} {numUsers, plural, =1 {user} other {users}} across {numDepartments} {numDepartments, plural, =1 {department} other {departments}}"
            description="Description for the tool context users by department breakdown pie chart"
            id="tool.context.users.departments.description"
            values={{
              numUsers: totalUsers,
              numDepartments: departmentData.length,
            }}
          />
        </Text>
      }
      footer={
        <Button as={Link} variant="outline" to={`/tools/${toolId}/users`} fontSize="sm">
          <FormattedMessage
            description="Button to view all users"
            id="tool.context.users.departments.view_all_users"
            defaultMessage="View all users"
          />
        </Button>
      }
    />
  )
}
