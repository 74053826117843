import type { ButtonProps, Theme } from "@chakra-ui/react"
import {
  chakra,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tooltip,
  useMultiStyleConfig,
} from "@chakra-ui/react"
import type { EmojiClickData } from "emoji-picker-react"
import EmojiPicker, { EmojiStyle } from "emoji-picker-react"
import type { FunctionComponent } from "react"
import { useRef } from "react"
import { useIntl } from "react-intl"
import { isChromium } from "../../util/platform.js"
import { ReactionIcon } from "../icons/icons.js"

export const ReactionPopover: FunctionComponent<{ onSelect: (emoji: string) => void; buttonProps?: ButtonProps }> = ({
  onSelect,
  buttonProps,
}) => {
  const intl = useIntl()
  const popoverContentRef = useRef<HTMLDivElement>(null)
  const inputStyleConfig = useMultiStyleConfig("Input", { size: "sm" }) as NonNullable<
    Theme["components"]["Input"]["baseStyle"]
  >
  const reactionLabel = intl.formatMessage({
    defaultMessage: "Add Reaction",
    id: "timeline.comment.react",
    description: "aria and tooltip label for reacting to a comment",
  })

  return (
    <Popover
      placement="bottom-end"
      onOpen={() => {
        // `autoFocus` prop on the popover didn't work and we can't get a ref to the input
        setTimeout(() => {
          popoverContentRef.current?.querySelector("input")?.focus()
        }, 1)
      }}
      isLazy // Important for rendering performance of the timeline
    >
      {({ onClose }) => (
        <>
          <Tooltip label={reactionLabel}>
            {/* Tooltip and Popover cannot share the same DOM element as a trigger, need this Box to isolate them see: https://github.com/chakra-ui/chakra-ui/issues/2843 */}
            <chakra.span>
              <PopoverTrigger>
                <IconButton aria-label={reactionLabel} icon={<Icon as={ReactionIcon} />} {...buttonProps} />
              </PopoverTrigger>
            </chakra.span>
          </Tooltip>
          <Portal>
            <PopoverContent
              // Animating the popover open is too expensive and lags
              transform="none !important"
              ref={popoverContentRef}
              sx={{
                ".EmojiPickerReact *": {
                  fontFamily: "inherit",
                },
                "aside.EmojiPickerReact": {
                  borderWidth: 0,

                  "--epr-header-padding": "0 1rem",

                  "--epr-search-input-bg-color": "transparent",

                  "--epr-category-label-height": "auto",
                  "--epr-category-label-padding": "0.25rem 1rem",
                  "--epr-category-label-text-color": "var(--chakra-colors-emphasized)",

                  "--epr-emoji-size": "1.5rem",
                },
                ".EmojiPickerReact .epr-header": {
                  paddingTop: "0.75rem",
                  paddingBottom: "0.25rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                },
                ".EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label": {
                  fontWeight: "medium",
                  fontSize: "inherit",
                },
                ".EmojiPickerReact .epr-search-container input.epr-search": {
                  ...inputStyleConfig.field,
                },
                ".EmojiPickerReact .epr-search-container .epr-icn-search": {
                  display: "none",
                },
                ".EmojiPickerReact .epr-skin-tones": {
                  padding: 0,
                  transition: "none",
                },
                ".EmojiPickerReact button.epr-btn-clear-search": {
                  display: "none",
                },
              }}
            >
              <PopoverArrow />
              <EmojiPicker
                // Safari has performance issues with img-based picker
                // Chrome has flickering issues with the native Unicode-based picker (https://github.com/ealush/emoji-picker-react/issues/374)
                // Firefox handles native fine.
                emojiStyle={isChromium() ? EmojiStyle.APPLE : EmojiStyle.NATIVE}
                width="100%"
                previewConfig={{ showPreview: false }}
                lazyLoadEmojis={true}
                onEmojiClick={({ emoji }: EmojiClickData) => {
                  onClose()
                  onSelect(emoji)
                }}
              />
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  )
}
