import { getEnumOptions, getTitle, type EnumTypeSchema } from "@brm/util/schema.js"
import { Badge, Icon, Tooltip } from "@chakra-ui/react"
import { iconMap } from "./icons/icon-map.js"

export const EnumBadge = <T,>({ value, schema }: { schema: EnumTypeSchema<T>; value?: string }) => {
  if (!value) return null
  const enumMemberSchema = getEnumOptions(schema).find((s) => s.const === value)
  return enumMemberSchema?.icon ? (
    <Tooltip shouldWrapChildren label={getTitle(value, enumMemberSchema)} placement="top" openDelay={500}>
      <Icon as={iconMap[enumMemberSchema.icon]} />
    </Tooltip>
  ) : (
    <Badge variant="subtleOutlined" colorScheme={enumMemberSchema?.colorScheme}>
      {getTitle(value, enumMemberSchema)}
    </Badge>
  )
}
