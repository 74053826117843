import type { LegalAgreementListItem } from "@brm/schema-types/types.js"
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  chakra,
  type ButtonProps,
} from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import AgreementCalendarDisplay from "./AgreementCalendarDisplay.js"

const buttonStyle: ButtonProps = {
  variant: "ghost",
  colorScheme: "brand",
  h: "unset",
  p: 1,
  justifyContent: "start",
}

const AgreementCalendarListItem: React.FC<{
  agreement: LegalAgreementListItem
  onClick?: () => void
  autoFocus?: boolean
}> = ({ agreement, onClick, autoFocus }) => {
  const agreementButton = (
    <chakra.span minWidth={0} isTruncated>
      <FormattedMessage
        id="renewalCalendar.agreementLink"
        defaultMessage="{agreementName}"
        description="Displays the name of the agreement"
        values={{ agreementName: agreement.display_name }}
      />
    </chakra.span>
  )
  if (onClick) {
    return (
      <Button onClick={onClick} autoFocus={autoFocus} {...buttonStyle}>
        {agreementButton}
      </Button>
    )
  }
  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Button autoFocus={autoFocus} {...buttonStyle}>
          {agreementButton}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <AgreementCalendarDisplay agreement={agreement} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default AgreementCalendarListItem
