import type { LegalAgreementListItem } from "@brm/schema-types/types.js"
import { Button, Popover, PopoverBody, PopoverContent, PopoverTrigger, Stack, chakra } from "@chakra-ui/react"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import AgreementCalendarDisplay from "./AgreementCalendarDisplay.js"
import AgreementCalendarListItem from "./AgreementCalendarListItem.js"

interface AgreementOverflowProps {
  allAgreements: LegalAgreementListItem[]
  overflowCount: number
}

export default function AgreementOverflow({ allAgreements, overflowCount }: AgreementOverflowProps) {
  const [selectedAgreement, setSelectedAgreement] = useState<LegalAgreementListItem | null>(null)
  return (
    <Popover placement="bottom-start" onClose={() => setSelectedAgreement(null)}>
      <PopoverTrigger>
        <Button variant="ghost" colorScheme="brand" h="unset" p={1} justifyContent="start">
          <chakra.span flexShrink={1} minWidth={0} isTruncated>
            <FormattedMessage
              id="renewalCalendar.moreAgreements"
              defaultMessage="{count} more&nbsp;<light>(show all)</light>"
              description="Text showing the number of overflow agreements, which can be clicked to show all agreements"
              values={{
                count: overflowCount,
                light: (chunks: React.ReactNode) => <chakra.span color="gray.400">{chunks}</chakra.span>,
              }}
            />
          </chakra.span>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody as={Stack}>
          {selectedAgreement ? (
            <AgreementCalendarDisplay agreement={selectedAgreement} onClickBack={() => setSelectedAgreement(null)} />
          ) : (
            allAgreements.map((agreement, index) => (
              <AgreementCalendarListItem
                key={agreement.id}
                // Auto focus the first item when the popover is opened
                autoFocus={index === 0}
                agreement={agreement}
                onClick={() => setSelectedAgreement(agreement)}
              />
            ))
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
