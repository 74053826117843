import type { FieldMetadataWithSuggestions } from "@brm/schema-types/types.js"
import { Flex, Spacer, Text } from "@chakra-ui/react"
import type React from "react"
import { FormattedMessage } from "react-intl"
import FieldSource from "./FieldSource.js"

export default function OptionWithFieldSource(props: {
  fieldSources?: FieldMetadataWithSuggestions[]
  children?: React.ReactNode
}) {
  const { fieldSources, children } = props

  return (
    <Flex textOverflow="ellipsis" overflow="hidden" flex={1}>
      {children}
      <Spacer />
      {fieldSources && fieldSources[0] && (
        <Text flex={1} textAlign="right" color="purple.600" fontSize="sm" isTruncated mx={2}>
          {fieldSources.length > 1 ? (
            <FormattedMessage
              defaultMessage="Multiple Sources"
              description="The label for a value that is extracted from multiple sources"
              id="OptionWithFieldSource.multipleSources"
            />
          ) : (
            <>
              {fieldSources[0]?.source_display_name || ""} <FieldSource fieldMetadata={fieldSources[0]} boxSize={3} />
            </>
          )}
        </Text>
      )}
    </Flex>
  )
}
