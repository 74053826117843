import type {
  FormFieldConfig,
  WorkflowConditionGroupInput,
  WorkflowConditionResponse,
} from "@brm/schema-types/types.js"
import { getSchemaAtPath, getTitle } from "@brm/util/schema.js"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  HStack,
  Icon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react"
import { forwardRef, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { type Descendant } from "slate"
import { isNotVoid } from "typed-assert"
import { usePostWorkflowV1DefinitionsConditionalMutation } from "../../../app/services/generated-api.js"
import { EMPTY_RICH_TEXT_BODY, isEmptyRichText } from "../../../components/RichTextEditor/util/common.js"
import SuggestedPromptBadge from "../../../components/SuggestedPromptBadge/SuggestedPromptBadge.js"
import { AlertTriangleIcon, WriteIcon } from "../../../components/icons/icons.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import { log } from "../../../util/logger.js"
import { useObjectSchemasMap } from "../../../util/use-schema.js"
import type { ConditionGroupProps } from "./ConditionGroup.js"
import ConditionGroup from "./ConditionGroup.js"
import ConditionalEditor from "./ConditionalEditor.js"
import {
  getSuggestedConditionalPrompts,
  serializeConditionalGenerationInput,
  type SuggestedConditionalPrompt,
} from "./utils.js"

interface ConditionalPromptProps extends ConditionGroupProps {
  getEligibleFields: () => FormFieldConfig[]
  onChange: (conditionalSchema: WorkflowConditionGroupInput) => void
  addMissingFieldsToCurrentStep?: (missingFields: WorkflowConditionResponse["missing_fields"]) => void
}

const ConditionalPrompt = forwardRef<{ focus: () => void }, ConditionalPromptProps>(function ConditionalPrompt(
  { getEligibleFields, onChange, addMissingFieldsToCurrentStep, ...conditionGroupProps },
  ref
) {
  const intl = useIntl()
  const missingFieldsModal = useDisclosure()

  // Force a reset of the conditional group rendering when we generate a new full conditional
  const [conditionalResetId, setConditionalResetId] = useState<number>(0)
  const [conditionalPromptResetId, setConditionalPromptResetId] = useState<number>(0)
  const [conditionalPrompt, setConditionalPrompt] = useState<Descendant[]>(EMPTY_RICH_TEXT_BODY)
  const [
    generateConditional,
    { isLoading: generateConditionalIsLoading, error: generateConditionalError, data: generateConditionalData },
  ] = usePostWorkflowV1DefinitionsConditionalMutation()

  const eligibleFields = useMemo(() => getEligibleFields(), [getEligibleFields])
  const uniqueObjectTypes = useMemo(
    () => Array.from(new Set(eligibleFields.map((field) => field.object_type))),
    [eligibleFields]
  )
  const objectSchemas = useObjectSchemasMap(uniqueObjectTypes)
  const eligibleFieldsWithDisplayName = useMemo(() => {
    // Augment the display name from the field schema to the eligible fields array
    return eligibleFields.map((field) => {
      const schema = objectSchemas?.[field.object_type]
      const fieldSchema = getSchemaAtPath(schema, field.field_name)
      return {
        ...field,
        display_name: getTitle(field.field_name, fieldSchema),
      }
    })
  }, [eligibleFields, objectSchemas])

  const suggestedPrompts = useMemo(() => {
    const eligibleFieldPrompts: SuggestedConditionalPrompt[] = []
    const promptsMap = getSuggestedConditionalPrompts(intl)
    for (const field of eligibleFields) {
      const prompt = promptsMap[field.object_type]?.[field.field_name]
      if (prompt) {
        eligibleFieldPrompts.push(prompt)
        if (eligibleFieldPrompts.length === 3) {
          // Return early if we have 3 prompts
          return eligibleFieldPrompts
        }
      }
    }
    // Return any prompts that were found, can be empty
    return eligibleFieldPrompts
  }, [intl, eligibleFields])

  const handleGenerateConditional = async () => {
    try {
      const promptText = conditionalPrompt.map(serializeConditionalGenerationInput).join("\n")
      const conditionalResponse = await generateConditional({
        body: {
          prompt: promptText,
          eligible_fields: eligibleFields,
        },
      }).unwrap()
      if (conditionalResponse.missing_fields.length === 0) {
        onChange(structuredClone(conditionalResponse.condition))
        setConditionalResetId((prev) => prev + 1)
      } else {
        missingFieldsModal.onOpen()
      }
    } catch (err) {
      log.error("Failed to generate conditional", err)
    }
  }

  return (
    <Stack>
      <Box>
        <ConditionalEditor
          ref={ref}
          key={conditionalPromptResetId}
          initialValue={conditionalPrompt}
          onChange={(value) => setConditionalPrompt(value)}
          eligibleFields={eligibleFieldsWithDisplayName}
          containerProps={{
            onKeyDown: async (event) => {
              if (event.key === "Enter" && (event.metaKey || event.ctrlKey) && !generateConditionalIsLoading) {
                await handleGenerateConditional()
              }
            },
          }}
        />
      </Box>
      {generateConditionalError && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{getAPIErrorMessage(generateConditionalError)}</AlertTitle>
          <AlertDescription>
            <FormattedMessage
              id="request.config.step.prompt.button.error.description"
              description="Description of the error that occurs when a conditional prompt fails to generate"
              defaultMessage="Try modifying your prompt or contact {contactSupportLink}."
              values={{
                contactSupportLink: (
                  <Button variant="link" onClick={() => window.Pylon?.("show")}>
                    <FormattedMessage
                      id="request.config.step.prompt.button.error.contactSupportLink"
                      description="Link to contact support"
                      defaultMessage="BRM support"
                    />
                  </Button>
                ),
              }}
            />
          </AlertDescription>
        </Alert>
      )}
      {isEmptyRichText(conditionalPrompt) && suggestedPrompts.length > 0 && (
        <HStack flexWrap="wrap">
          {suggestedPrompts.map((prompt) => (
            <SuggestedPromptBadge
              key={prompt.label}
              prompt={prompt.label}
              onClick={() => {
                setConditionalPrompt(prompt.richTextPrompt)
                // Reset the conditional editor to trigger a re-render of the editor
                setConditionalPromptResetId((prev) => prev + 1)
              }}
            />
          ))}
        </HStack>
      )}
      <HStack justify="flex-end">
        {generateConditionalIsLoading ? (
          <HStack>
            <CircularProgress color="brand.500" isIndeterminate={true} size="1.1em" />
            <Text>
              <FormattedMessage
                id="request.config.step.prompt.button.loading"
                description="Button text to add a new conditional prompt to a step"
                defaultMessage="Generating conditional..."
              />
            </Text>
          </HStack>
        ) : (
          <Button colorScheme="brand" leftIcon={<Icon as={WriteIcon} />} onClick={handleGenerateConditional}>
            <FormattedMessage
              id="request.config.step.prompt.button"
              description="Button text to add a new conditional prompt to a step"
              defaultMessage="Generate"
            />
          </Button>
        )}
      </HStack>
      <Modal {...missingFieldsModal} size="md" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Icon as={AlertTriangleIcon} mr={2} color="warning.500" />
            <FormattedMessage
              id="request.config.step.prompt.missingFields.title"
              description="Title for the missing fields modal"
              defaultMessage="Missing fields"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color="gray.600" fontSize="md">
              <FormattedMessage
                id="request.config.step.prompt.missingFields.description"
                description="Description for the missing fields modal"
                defaultMessage="You referenced some fields in your prompt that are not configured on this step:"
              />
            </Text>
            <UnorderedList mt={2} color="gray.600" fontSize="md">
              {generateConditionalData?.missing_fields.map((field, index) => (
                <ListItem key={index}>{field.field_title}</ListItem>
              ))}
            </UnorderedList>
            <Text mt={4} fontWeight="semibold">
              {addMissingFieldsToCurrentStep ? (
                <FormattedMessage
                  id="request.config.step.prompt.missingFields.updateFields.description"
                  description="Description for the update fields button in the missing fields modal"
                  defaultMessage="Adding these fields will update your current step criteria and apply your conditions."
                />
              ) : (
                <FormattedMessage
                  id="request.config.step.prompt.missingFields.updateFields.description"
                  description="Description for the update fields button in the missing fields modal"
                  defaultMessage="You will need to add these fields to a previous step in order to use them for this condition."
                />
              )}
            </Text>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button onClick={missingFieldsModal.onClose}>
              <FormattedMessage
                id="request.config.step.prompt.missingFields.close"
                description="Close button text for the missing fields modal"
                defaultMessage="Close"
              />
            </Button>
            {addMissingFieldsToCurrentStep && (
              <Button
                colorScheme="brand"
                onClick={() => {
                  const generatedCondition = generateConditionalData?.condition
                  isNotVoid(generatedCondition)
                  addMissingFieldsToCurrentStep(generateConditionalData?.missing_fields ?? [])
                  onChange(structuredClone(generatedCondition))
                  setConditionalResetId((prev) => prev + 1)
                  missingFieldsModal.onClose()
                }}
              >
                <FormattedMessage
                  id="request.config.step.prompt.missingFields.add"
                  description="Add missing fields button text for the missing fields modal"
                  defaultMessage="Add missing fields"
                />
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ConditionGroup key={conditionalResetId} {...conditionGroupProps} />
    </Stack>
  )
})

export default ConditionalPrompt
