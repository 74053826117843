import type { CardBodyProps, CardProps, TooltipProps } from "@chakra-ui/react"
import { Button, Card, CardBody, Flex, Tooltip } from "@chakra-ui/react"
import { forwardRef, type ReactNode } from "react"

interface Props extends CardProps {
  leftAction?: ReactNode
  rightAction?: ReactNode
  selected?: boolean
  hasError?: boolean
  onClick?: () => void
  cardBodyProps?: CardBodyProps
  isDisabled?: boolean
  tooltipProps?: Omit<TooltipProps, "children">
  minimized?: boolean
}

const CardWithHoverActions = forwardRef<HTMLDivElement, Props>(function CardWithHoverActions(
  {
    leftAction,
    rightAction,
    selected,
    hasError,
    onClick,
    isDisabled,
    children,
    cardBodyProps,
    tooltipProps,
    minimized,
    ...rest
  },
  ref
) {
  return (
    <Tooltip {...tooltipProps}>
      <Card
        ref={ref}
        outline={`${selected ? "2px" : "1px"} solid`}
        outlineColor={hasError ? "error.500" : selected ? "brand.600" : "gray.300"}
        border="none"
        background={isDisabled ? "gray.50" : hasError ? "error.50" : selected ? "brand.50" : undefined}
        borderRadius="lg"
        _hover={
          !isDisabled ? { outlineColor: hasError ? "error.400" : selected ? "brand.600" : "brand.300" } : undefined
        }
        position="relative"
        variant="outline"
        size="sm"
        {...rest}
        flexGrow={minimized ? 0 : rest.flexGrow}
      >
        <CardBody
          size="sm"
          variant="unstyled"
          borderRadius="lg"
          fontSize="sm"
          as={onClick ? Button : undefined}
          cursor={onClick ? "pointer" : undefined}
          onClick={onClick}
          textAlign="start"
          fontWeight="normal"
          // 'peer' className is used to apply styles to the left and right actions when card body is hovered
          className="peer"
          {...cardBodyProps}
        >
          {children}
        </CardBody>
        {rightAction && (
          <Flex
            opacity={0}
            _peerHover={{ opacity: 1 }}
            _peerFocusVisible={{ opacity: 1 }}
            _hover={{ opacity: 1 }}
            _focusWithin={{ opacity: 1 }}
            position="absolute"
            width={8}
            right={-8}
            top={0}
            height="full"
            justifyContent="center"
            alignItems="center"
            // If the card overall has a click handler, we don't want that behavior to apply to this container
            cursor="initial"
            onClick={(e) => e.stopPropagation()}
          >
            {rightAction}
          </Flex>
        )}
        {leftAction && (
          <Flex
            opacity={0}
            _peerHover={{ opacity: 1 }}
            _peerFocusVisible={{ opacity: 1 }}
            _hover={{ opacity: 1 }}
            _focusWithin={{ opacity: 1 }}
            position="absolute"
            width={8}
            left={-8}
            top={0}
            height="full"
            justifyContent="center"
            alignItems="center"
            // If the card overall has a click handler, we don't want that behavior to apply to this container
            cursor="initial"
            onClick={(e) => e.stopPropagation()}
          >
            {leftAction}
          </Flex>
        )}
      </Card>
    </Tooltip>
  )
})

export default CardWithHoverActions
