import type { CurrencyAmount } from "@brm/schema-types/types.js"
import { Divider, Flex, HStack, Spacer, Stack, Tooltip } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { DEFAULT_CURRENCY } from "../util/currency.js"
import { FormattedCurrency } from "./FormattedCurrency.js"

export const FormattedMultiCurrency: React.FunctionComponent<{
  currencyAmount: CurrencyAmount
  amountsPerCurrency?: CurrencyAmount[]
}> = ({ currencyAmount, amountsPerCurrency }) => {
  if (!amountsPerCurrency) {
    return <FormattedCurrency currencyAmount={currencyAmount} />
  }
  const nonZeroAmounts = amountsPerCurrency.filter((amount) => amount.amount !== "0")
  const [firstAmount, ...restAmounts] = nonZeroAmounts

  return (
    <Tooltip
      label={
        restAmounts.length > 0 ? (
          <Stack spacing={1} color="gray.600" fontWeight="medium">
            {nonZeroAmounts.map((amountPerCurrency, index) => (
              <HStack key={amountPerCurrency.currency_code}>
                {index === 0 && (
                  <FormattedMessage
                    defaultMessage="Amount"
                    description="Text before a list of various currency amounts"
                    id="formattedMultiCurrency.amount"
                  />
                )}
                <Spacer minW={6} />
                <Flex as="span">
                  <FormattedCurrency currencyAmount={amountPerCurrency} />
                </Flex>
              </HStack>
            ))}
            {nonZeroAmounts.length > 1 && <Divider borderColor="gray.400" opacity={1} marginY={1} />}
            <HStack fontWeight="semibold" textColor="gray.800">
              <FormattedMessage
                id="formattedMultiCurrency.total"
                description="The label for the total amount in the spend chart"
                defaultMessage="Total"
              />
              <Spacer />
              <FormattedCurrency currencyAmount={currencyAmount} />
            </HStack>
          </Stack>
        ) : // In the case where there is only one amount spent and it is not in USD, tooltip should be the USD currency
        restAmounts.length === 0 && firstAmount && firstAmount.currency_code !== currencyAmount.currency_code ? (
          <FormattedCurrency
            currencyAmount={firstAmount.currency_code !== DEFAULT_CURRENCY ? currencyAmount : firstAmount}
          />
        ) : undefined
      }
      shouldWrapChildren
      isDisabled={
        !firstAmount || (restAmounts.length === 0 && firstAmount.currency_code === currencyAmount.currency_code)
      }
    >
      {/* Always show the currency amount in USD, unless there is only one amount spent not in USD */}
      <FormattedCurrency
        currencyAmount={
          firstAmount && firstAmount.currency_code !== DEFAULT_CURRENCY && restAmounts.length === 0
            ? firstAmount
            : currencyAmount
        }
      />
    </Tooltip>
  )
}
