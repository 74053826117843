import { chakra, type ChakraProps } from "@chakra-ui/react"
import type { RenderLeafProps } from "slate-react"
import { baseMentionSpanStyle } from "./util/mentions.js"

const highlightStyle: ChakraProps = {
  ...baseMentionSpanStyle,
  backgroundColor: "gray.100",
}

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  // Wrapping the children in an inner span with the attributes is recommended to prevent editor state mismatch issues https://github.com/ianstormtaylor/slate/issues/3421
  const childrenWithAttributes = <chakra.span {...attributes}>{children}</chakra.span>

  if ("highlighted" in leaf && leaf.highlighted) {
    return <chakra.span {...highlightStyle}>{childrenWithAttributes}</chakra.span>
  }
  if ("bold" in leaf && leaf.bold) {
    return <chakra.strong>{childrenWithAttributes}</chakra.strong>
  }

  if ("italic" in leaf && leaf.italic) {
    return <chakra.em>{childrenWithAttributes}</chakra.em>
  }

  if ("underline" in leaf && leaf.underline) {
    return <chakra.u>{childrenWithAttributes}</chakra.u>
  }

  return childrenWithAttributes
}
