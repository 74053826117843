import { getCurrentApprovalStep } from "@brm/schema-helpers/workflow.js"
import type { WorkflowRun, WorkflowRunStepWithContext } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Text, type TextProps } from "@chakra-ui/react"
import { FormattedList, FormattedMessage, useIntl } from "react-intl"
import { isNotNull, isNotUndefined } from "typed-assert"
import { Timestamp } from "../../../../../components/Timestamp.js"

export default function WorkflowRunStepStatusText({
  step,
  workflowRun,
  ...textProps
}: {
  step: Pick<WorkflowRunStepWithContext, "id" | "status" | "approval_steps" | "owner" | "submitted_at" | "completed_at">
  workflowRun: Pick<WorkflowRun, "steps">
} & TextProps) {
  const intl = useIntl()
  let statusText
  switch (step.status) {
    case "pending":
      statusText = (
        <FormattedMessage
          defaultMessage="Waiting on {steps} to be completed"
          description="Step card subtitle when the step is waiting"
          id="requests.run.step.card.subtitle.pending"
          values={{
            steps: (
              <FormattedList
                value={workflowRun.steps
                  .filter((s) => s.next.includes(step.id) && s.status !== "approved")
                  .map((s) => s.display_name)}
              />
            ),
          }}
        />
      )
      break
    case "pending_submitted":
      statusText = (
        <FormattedMessage
          defaultMessage="Gathered by {gathererName}. Waiting on {steps} to be completed"
          description="Step card subtitle when the step is waiting"
          id="requests.run.step.card.subtitle.pending"
          values={{
            steps: (
              <FormattedList
                value={workflowRun.steps
                  .filter((s) => s.next.includes(step.id) && s.status !== "approved")
                  .map((s) => s.display_name)}
              />
            ),
            gathererName: displayPersonName(step.owner, intl),
          }}
        />
      )
      break
    case "in_progress":
      statusText = (
        <FormattedMessage
          defaultMessage="{gathererName} to gather"
          description="Step card subtitle when the step is in progress"
          id="requests.run.stage.group.gather"
          values={{
            gathererName: displayPersonName(step.owner, intl),
          }}
        />
      )
      break
    case "submitted": {
      const currentApprovalStep = getCurrentApprovalStep(step.approval_steps)
      isNotUndefined(currentApprovalStep, "Step is submitted but has no current approver. Something went wrong")
      statusText = (
        <FormattedMessage
          defaultMessage="{approverNames} to approve"
          description="Step card subtitle when the step is submitted and waiting for approval"
          id="requests.run.stage.group.submitted.waitingForApproval"
          values={{
            approverNames: intl.formatList(
              currentApprovalStep.approvers.map((a) => displayPersonName(a.user, intl)),
              { type: "disjunction" }
            ),
          }}
        />
      )
      break
    }
    case "completed": {
      isNotNull(step.submitted_at, "Step is completed but has no submitted_at. Something went wrong")
      statusText = (
        <FormattedMessage
          defaultMessage="Completed by {gathererName}, {relativeTime}"
          description="Step card subtitle when the step is completed and not waiting for approval"
          id="requests.run.stage.group.completed.notWaitingForApproval"
          values={{
            gathererName: displayPersonName(step.owner, intl),
            relativeTime: <Timestamp dateTime={step.submitted_at} />,
          }}
        />
      )
      break
    }
    case "approved": {
      isNotNull(step.completed_at, "Step is approved but has no completed_at. Something went wrong")

      statusText = (
        <FormattedMessage
          defaultMessage="Approved, {relativeTime}"
          description="Step card subtitle when the step was approved"
          id="requests.run.step.card.subtitle.approved"
          values={{
            relativeTime: <Timestamp dateTime={step.completed_at} />,
          }}
        />
      )

      break
    }
    case "cancelled":
      statusText = (
        <FormattedMessage
          defaultMessage="Did not run because the request was cancelled"
          description="Step card subtitle when the step was cancelled"
          id="requests.run.step.card.subtitle.cancelled"
        />
      )
      break
    case "rejected":
      {
        isNotNull(step.completed_at, "Step is rejected but has no completed_at. Something went wrong")
        statusText = (
          <FormattedMessage
            defaultMessage="Rejected, {relativeTime}"
            description="Step card subtitle when the step was rejected"
            id="requests.run.step.card.subtitle.rejected"
            values={{
              relativeTime: <Timestamp dateTime={step.completed_at} />,
            }}
          />
        )
      }
      break
    case "skipped":
      statusText = (
        <FormattedMessage
          defaultMessage="Skipped due to a conditional"
          description="Step card subtitle when the step was skipped"
          id="requests.run.step.card.subtitle.skipped"
        />
      )
      break
    default:
      unreachable(step.status)
  }

  return <Text {...textProps}>{statusText}</Text>
}
