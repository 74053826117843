import type { WorkflowRunStatus } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import { HStack, Icon, Text, Tooltip, type SystemStyleObject } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { FormattedDate } from "../../../components/FormattedDate.js"
import { ClockIcon } from "../../../components/icons/icons.js"
import { RelativeDateTimeDisplay } from "../../../components/Timestamp.js"

// Displays the due date of a workflow run if it is not yet completed
// Otherwise, displays the completed date taking into account the final status of the workflow run
export default function WorkflowRunDueDateIndicator({
  status,
  completedAt,
  dueDate,
  textContentStyle,
}: {
  status: WorkflowRunStatus
  completedAt: string | null
  dueDate: string
  textContentStyle?: SystemStyleObject
}) {
  if (completedAt) {
    if (status === "in_progress") {
      throw new Error(
        "WorkflowRunDueDateIndicator: completed_at and status are inconsistent. A workflow run with status in_progress should not have completed_at date set"
      )
    }
    return status === "approved" ? (
      <Text color="brand.500">
        <FormattedMessage
          id="requests.completed.date"
          defaultMessage="Completed on {completedDate}"
          description="Date the request was completed on"
          values={{ completedDate: <FormattedDate value={completedAt} /> }}
        />
      </Text>
    ) : status === "rejected" ? (
      <Text color="error">
        <FormattedMessage
          id="requests.completed.date"
          defaultMessage="Rejected on {completedDate}"
          description="Date the request was rejected on"
          values={{ completedDate: <FormattedDate value={completedAt} /> }}
        />
      </Text>
    ) : status === "cancelled" ? (
      <Text>
        <FormattedMessage
          id="requests.completed.date"
          defaultMessage="Canceled on {completedDate}"
          description="Date the request was canceled on"
          values={{ completedDate: <FormattedDate value={completedAt} /> }}
        />
      </Text>
    ) : (
      unreachable(status)
    )
  }

  return (
    <Tooltip shouldWrapChildren label={<FormattedDate value={dueDate} />}>
      <HStack>
        <Icon as={ClockIcon} />
        <Text sx={textContentStyle}>
          <FormattedMessage
            id="due.tooltip"
            defaultMessage="Due {relativeTime}"
            description="Tooltip for a due date timestamp"
            values={{ relativeTime: <RelativeDateTimeDisplay dateTime={dueDate} /> }}
          />
        </Text>
      </HStack>
    </Tooltip>
  )
}
