import type { ToolMinimal } from "@brm/schema-types/types.js"
import { Box } from "@chakra-ui/react"
import { skipToken } from "@reduxjs/toolkit/query"
import type { Props, SelectInstance } from "chakra-react-select"
import { Select } from "chakra-react-select"
import { useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { useGetUserV1WhoamiQuery, usePostToolV1ListQuery } from "../../app/services/generated-api.js"
import { TABLE_DEFAULT_PARAMS, packageSortFilterOptionsForAPI } from "../../util/schema-table.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import { useObjectSchema } from "../../util/use-schema.js"
import { chakraStylesForProfilePicturePickerOption } from "./constants.js"

type ToolPickerProps = Props<ToolMinimal, false>

export default function ToolPicker(props: ToolPickerProps) {
  const { onChange, value } = props

  const intl = useIntl()

  const { data: whoami } = useGetUserV1WhoamiQuery()

  const toolSchema = useObjectSchema(whoami ? "Tool" : skipToken)

  const [toolSearchValue, setToolSearchValue] = useState("")
  const ref = useRef<SelectInstance<ToolMinimal, false>>(null)

  const apiParams = useMemo(() => {
    if (!toolSchema) {
      return undefined
    }
    return packageSortFilterOptionsForAPI<string>(
      {
        ...TABLE_DEFAULT_PARAMS,
        pageSize: 10,
        filterMap: new Map([
          ["display_name", { column: "display_name", fields: { comparator: "contains", value: toolSearchValue } }],
        ]),
      },
      toolSchema,
      intl
    )
  }, [intl, toolSchema, toolSearchValue])

  const { data, isLoading } = usePostToolV1ListQuery(apiParams ? { listQueryStringParams: apiParams } : skipToken)

  return (
    <Box flexGrow={1}>
      <Select<ToolMinimal>
        options={data?.tools || []}
        isLoading={isLoading}
        chakraStyles={chakraStylesForProfilePicturePickerOption((tool: ToolMinimal) =>
          getPublicImageGcsUrl(tool.image_asset?.gcs_file_name)
        )}
        placeholder={intl.formatMessage({
          id: "form.select.placeholder",
          defaultMessage: "Select an option...",
          description: "Placeholder for selection input",
        })}
        getOptionLabel={(tool) => tool.display_name}
        getOptionValue={(pickable) => pickable.id}
        onChange={onChange}
        value={value}
        inputValue={toolSearchValue}
        onInputChange={setToolSearchValue}
        ref={ref}
        menuPortalTarget={document.body}
        {...props}
      />
    </Box>
  )
}
