import type { CurrencyAmount } from "@brm/schema-types/types.js"
import { HStack } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import MoneyCell from "../../components/DataTable/CellRenderer/MoneyCell.js"

interface Props {
  totalContractValue: CurrencyAmount | undefined | null
}

export default function AgreementTcv({ totalContractValue }: Props) {
  return (
    <HStack alignItems="center">
      <FormattedMessage
        id="agreement.total_contract_value"
        description="Abbreviation for Total Contract Value"
        defaultMessage="TCV"
      />
      <MoneyCell currencyAmount={totalContractValue} />
    </HStack>
  )
}
