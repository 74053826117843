import type { AgreementEffectiveStatus } from "@brm/schema-types/types.js"
import { HStack, Icon, Text, Tooltip } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { UserEditIcon } from "../../components/icons/icons.js"

interface Props {
  is_signed: boolean | null
  effective_status: AgreementEffectiveStatus
  buyer_signer_name: string | null | undefined
}

const statusesExpectingSigner: AgreementEffectiveStatus[] = ["upcoming", "active"]

export default function AgremeentSigner({ is_signed, effective_status, buyer_signer_name }: Props) {
  return (
    <HStack alignItems="center">
      <Icon as={UserEditIcon} />
      <Text color={!is_signed && statusesExpectingSigner.includes(effective_status) ? "error.500" : "black"}>
        {buyer_signer_name ? (
          // Move to tooltip
          <Tooltip
            label={
              <FormattedMessage
                id="agreement.signedBy"
                description="agreement signer"
                defaultMessage="Signed By: {signer}"
                values={{ signer: buyer_signer_name }}
              />
            }
          >
            {buyer_signer_name}
          </Tooltip>
        ) : is_signed ? (
          <FormattedMessage
            id="agreement.signed.true"
            description="Description for an agreement that is signed without a signer name"
            defaultMessage="Signed by both parties"
          />
        ) : (
          <FormattedMessage
            id="agreement.notSigned"
            description="Description for an agreement that is not signed"
            defaultMessage="Not Signed"
          />
        )}
      </Text>
    </HStack>
  )
}
