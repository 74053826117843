import { getCurrentApprovalStep } from "@brm/schema-helpers/workflow.js"
import type { WorkflowRun, WorkflowRunStep, WorkflowRunStepDecision } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import {
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetUserV1WhoamiQuery,
  usePostWorkflowV1StepRunsByWorkflowRunStepIdReopenMutation,
  usePostWorkflowV1StepRunsByWorkflowRunStepIdRequestChangesMutation,
} from "../../../../app/services/generated-api.js"
import { EditIcon, NextIcon } from "../../../../components/icons/icons.js"
import {
  type GetLogoForOrganizationProps,
  WORKFLOW_RUN_STEP_FORM_ID,
  canApproveWorkflowRunStep,
  canReopenCompletedWorkflowRunStep,
  canSubmitWorkflowRunStep,
  canUnapproveWorkflowRunStep,
} from "../utils.js"
import WorkflowRunStepUnapproveButton from "./unapprove/WorkflowRunStepUnapproveButton.js"
import WorkflowRunStepAdminApprovalButton from "./WorkflowRunStepAdminApprovalButton.js"

export default function WorkflowRunStepSubmitButtons({
  step,
  run,
  isLoading,
  submitDecision,
  getLogoToShowByOrganizationId,
}: {
  step: Pick<WorkflowRunStep, "id" | "approval_steps" | "status" | "type" | "owner" | "display_name">
  run: Pick<WorkflowRun, "id" | "status" | "owner" | "assigned_credit_card_id">
  isLoading: boolean
  submitDecision?: WorkflowRunStepDecision
} & GetLogoForOrganizationProps) {
  const intl = useIntl()
  const toast = useToast()
  const rejectModal = useDisclosure()
  const requestChangesModal = useDisclosure()
  const reopenModal = useDisclosure()

  const currentApprovalStep = getCurrentApprovalStep(step.approval_steps)
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const [requestChanges, { isLoading: isRequestChangesLoading }] =
    usePostWorkflowV1StepRunsByWorkflowRunStepIdRequestChangesMutation()
  const [reopen, { isLoading: isReopenLoading }] = usePostWorkflowV1StepRunsByWorkflowRunStepIdReopenMutation()
  return canApproveWorkflowRunStep(whoami, step, run) ? (
    <>
      <HStack>
        <Button
          onClick={rejectModal.onOpen}
          variant="subtleOutlined"
          colorScheme="error"
          isDisabled={isLoading}
          isLoading={isLoading && submitDecision === "abort"}
        >
          <FormattedMessage
            defaultMessage="Reject"
            description="Button label to abort/reject the current request stage"
            id="requests.run.stage.form.reject"
          />
        </Button>
        <Button
          onClick={requestChangesModal.onOpen}
          variant="subtleOutlined"
          colorScheme="gray"
          isDisabled={isLoading}
          isLoading={isLoading && submitDecision === "abort"}
        >
          <FormattedMessage
            defaultMessage="Request changes"
            description="Button label to request changes to the current request stage"
            id="requests.run.stage.form.request_changes"
          />
        </Button>
        {currentApprovalStep && !currentApprovalStep.approvers.some((approver) => approver.user.id === whoami?.id) ? (
          // Current approver is not one of the assigned approvers. Approval on behalf of them
          <WorkflowRunStepAdminApprovalButton
            isLoading={isLoading}
            stepDisplayName={step.display_name}
            approverNames={currentApprovalStep.approvers.map((approver) => displayPersonName(approver.user, intl))}
          />
        ) : (
          <Button
            type="submit"
            form={WORKFLOW_RUN_STEP_FORM_ID}
            name={"proceed" satisfies WorkflowRunStepDecision}
            colorScheme="brand"
            isDisabled={isLoading}
            isLoading={isLoading && submitDecision === "proceed"}
          >
            <FormattedMessage
              defaultMessage="Approve"
              description="Button label to approve the current step"
              id="requests.run.stage.form.approve"
            />
          </Button>
        )}
      </HStack>
      <Modal {...rejectModal} isCentered autoFocus>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              defaultMessage="Are you sure you want to reject this step?"
              description="The title of the reject request modal"
              id="requests.run.reject.modal.title"
            />
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody display="flex" flexDirection="column" gap={4}>
            <FormattedMessage
              defaultMessage="Rejecting this step will stop all progress and close the request. This cannot be undone."
              description="The body of the reject request modal"
              id="requests.run.reject.modal.body"
            />
          </ModalBody>
          <ModalFooter gap={2}>
            <Button type="button" onClick={rejectModal.onClose}>
              <FormattedMessage
                defaultMessage="Cancel"
                description="The label of the cancel button in the reject workflow run step modal"
                id="requests.run.reject.modal.cancel"
              />
            </Button>
            <Button
              type="submit"
              form={WORKFLOW_RUN_STEP_FORM_ID}
              colorScheme="error"
              name={"abort" satisfies WorkflowRunStepDecision}
              formNoValidate={true}
              isDisabled={isLoading}
              isLoading={isLoading && submitDecision === "abort"}
              onClick={rejectModal.onClose}
            >
              <FormattedMessage
                defaultMessage="Yes, I’m sure"
                description="The label of the reject button in the reject workflow run step modal"
                id="requests.run.reject.modal.confirm"
              />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal {...requestChangesModal} isCentered autoFocus>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              defaultMessage="Request {stepName} changes?"
              description="The title of the request changes modal"
              id="requests.run.request_changes.modal.title"
              values={{ stepName: step.display_name }}
            />
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody display="flex" flexDirection="column" gap={4}>
            <FormattedMessage
              defaultMessage="This will assign the step back to {owner}. They will be notified to make changes and resubmit the step."
              description="The body of the request changes modal"
              id="requests.run.request_changes.modal.body"
              values={{ owner: displayPersonName(step.owner, intl) }}
            />
          </ModalBody>
          <ModalFooter gap={2}>
            <Button type="button" onClick={requestChangesModal.onClose}>
              <FormattedMessage
                defaultMessage="Cancel"
                description="The label of the cancel button in the request changes modal"
                id="requests.run.request_changes.modal.cancel"
              />
            </Button>
            <Button
              isLoading={isRequestChangesLoading}
              colorScheme="brand"
              onClick={async () => {
                await requestChanges({ workflowRunStepId: step.id })
                requestChangesModal.onClose()
                toast({
                  title: intl.formatMessage(
                    {
                      defaultMessage: "Changes requested from {owner}",
                      description: "Title of the toast when requesting changes from the step owner",
                      id: "requests.run.request_changes.toast.title",
                    },
                    { owner: displayPersonName(step.owner, intl) }
                  ),
                  description: intl.formatMessage({
                    defaultMessage: "They will be notified to make changes and resubmit the step",
                    description: "Description of the toast when requesting changes",
                    id: "requests.run.request_changes.toast.description",
                  }),
                  status: "success",
                })
              }}
            >
              <FormattedMessage
                defaultMessage="Request changes"
                description="The label of the request changes button in the request changes modal"
                id="requests.run.request_changes.modal.confirm"
              />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  ) : canSubmitWorkflowRunStep(whoami, step, run) ? (
    // Ready for review button
    <Button
      type="submit"
      form={WORKFLOW_RUN_STEP_FORM_ID}
      name={"proceed" satisfies WorkflowRunStepDecision}
      colorScheme="brand"
      isLoading={isLoading}
      rightIcon={<Icon as={NextIcon} />}
    >
      {step.approval_steps[0] ? (
        <FormattedMessage
          defaultMessage="Ready for review"
          description="Button label to mark the current step as ready for review"
          id="requests.run.stage.form.ready_for_review"
        />
      ) : (
        <FormattedMessage
          defaultMessage="Complete"
          description="Button label to complete the current step form"
          id="requests.run.stage.form.complete"
        />
      )}
    </Button>
  ) : canUnapproveWorkflowRunStep(whoami, step, run) ? (
    <WorkflowRunStepUnapproveButton
      workflowRunStepId={step.id}
      workflowRunId={run.id}
      workflowRunStepGathererName={displayPersonName(step.owner, intl)}
      workflowRunStepDisplayName={step.display_name}
      getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
    />
  ) : canReopenCompletedWorkflowRunStep(whoami, step, run) ? (
    <>
      <Button
        onClick={reopenModal.onOpen}
        variant="subtleOutlined"
        colorScheme="gray"
        isDisabled={isLoading}
        isLoading={isLoading && submitDecision === "abort"}
        leftIcon={<Icon as={EditIcon} />}
      >
        <FormattedMessage
          defaultMessage="Reopen"
          description="Button label to reopen a completed step"
          id="requests.run.stage.form.reopen"
        />
      </Button>
      <Modal {...reopenModal} isCentered autoFocus>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              defaultMessage="Reopen {stepName}?"
              description="The title of the reopen step modal"
              id="requests.run.reopen.modal.title"
              values={{ stepName: step.display_name }}
            />
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody display="flex" flexDirection="column" gap={4}>
            {step.owner.id === whoami?.id ? (
              <FormattedMessage
                defaultMessage="Reopening this step will allow you to make changes and require you to resubmit the step."
                description="The body of the reopen step modal for the step owner"
                id="requests.run.reopen.modal.body.owner"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Reopening this step will allow {owner} to make changes and require them to resubmit the step."
                description="The body of the reopen step modal for non-owners"
                id="requests.run.reopen.modal.body.non_owner"
                values={{ owner: displayPersonName(step.owner, intl) }}
              />
            )}
          </ModalBody>
          <ModalFooter gap={2}>
            <Button type="button" onClick={reopenModal.onClose}>
              <FormattedMessage
                defaultMessage="Cancel"
                description="The label of the cancel button in the reopen step modal"
                id="requests.run.reopen.modal.cancel"
              />
            </Button>
            <Button
              isLoading={isReopenLoading}
              colorScheme="brand"
              onClick={async () => {
                await reopen({ workflowRunStepId: step.id })
                reopenModal.onClose()
              }}
            >
              <FormattedMessage
                defaultMessage="Reopen"
                description="The label of the reopen button in the reopen step modal"
                id="requests.run.reopen.modal.confirm"
              />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  ) : null
}
