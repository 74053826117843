import type { WorkflowKind } from "@brm/schema-types/types.js"
import { Flags } from "@brm/util/flags.js"
import { Button, Stack, Text } from "@chakra-ui/react"
import { useFlags } from "launchdarkly-react-client-sdk"
import { FormattedMessage } from "react-intl"
import ErrorAlert from "../../../../components/ErrorAlert/ErrorAlert.js"
import type { WorkflowObjectType } from "./util.js"

export default function WorkflowObjectTypeSection({
  workflowKind,
  onSelectObjectType,
}: {
  workflowKind: WorkflowKind | null
  onSelectObjectType: (objectType: WorkflowObjectType) => void
}) {
  const { [Flags.VENDOR_SERVICE_REQUEST_FLAG]: vendorServiceRequestEnabled } = useFlags()

  if (workflowKind === null) {
    return <ErrorAlert />
  }

  return (
    <Stack gap={4}>
      <Text>
        {workflowKind === "purchase" ? (
          <FormattedMessage
            id="requests.start.modal.object_type.header"
            description="Header for the workflow object type selection section screen"
            defaultMessage="What would you like to purchase?"
          />
        ) : (
          <FormattedMessage
            id="requests.start.modal.object_type.header"
            description="Header for the workflow object type selection section screen"
            defaultMessage="What would you like to renew?"
          />
        )}
      </Text>
      <Button
        onClick={() => {
          onSelectObjectType("Tool")
        }}
        variant="hoverOutline"
        width="full"
        justifyContent="start"
      >
        <FormattedMessage
          id="request.start.modal.object_type.tool"
          defaultMessage="Software"
          description="Request start modal button option to request software"
        />
      </Button>
      <Button
        isDisabled={!vendorServiceRequestEnabled}
        onClick={() => {
          onSelectObjectType("Vendor")
        }}
        variant="hoverOutline"
        width="full"
        justifyContent="start"
      >
        {vendorServiceRequestEnabled ? (
          <FormattedMessage
            id="request.start.modal.object_type.vendor"
            defaultMessage="Professional services"
            description="Request start modal button option to request professional service"
          />
        ) : (
          <FormattedMessage
            id="request.start.modal.object_type.vendor.disabled"
            defaultMessage="Professional services (coming soon)"
            description="Request start modal button option text when professional service request is not enabled"
          />
        )}
      </Button>
      <Button
        isDisabled={!vendorServiceRequestEnabled}
        onClick={() => {
          onSelectObjectType("Vendor")
        }}
        variant="hoverOutline"
        width="full"
        justifyContent="start"
      >
        {vendorServiceRequestEnabled ? (
          <FormattedMessage
            id="request.start.modal.object_type.vendor"
            defaultMessage="All other spend"
            description="Request start modal button option to request all other spend"
          />
        ) : (
          <FormattedMessage
            id="request.start.modal.object_type.vendor.disabled"
            defaultMessage="All other spend (coming soon)"
            description="Request start modal button option text when all other spend request is not enabled"
          />
        )}
      </Button>
    </Stack>
  )
}
