import type {
  FieldMetadata,
  FieldMetadataWithSuggestions,
  ToolListing,
  ToolOptionWithVendor,
} from "@brm/schema-types/types.js"
import { useDisclosure, type UseModalProps } from "@chakra-ui/react"
import { useState, type ReactNode } from "react"
import { type RefCallBack } from "react-hook-form"
import { usePostToolV1Mutation } from "../../app/services/generated-api.js"
import type { DynamicFormFieldApproval, ValueWithSource } from "../../components/DynamicForm/types.js"
import { ToolListingMultiPicker } from "../../components/Form/ToolListingMultiPicker.js"
import { ToolOptionPicker, ToolOptionPickerCreatable } from "../../components/Form/ToolOptionPicker.js"
import { NewEntityModal } from "../NewEntityModal.js"

type NewToolModalProps = UseModalProps & {
  setToolOption: (toolOption: ToolOptionWithVendor | null) => void
  /**
   * Tools must have 1 toolOption present for this component to operate
   */
  tool: ToolOptionWithVendor
}

export function NewToolModal(props: NewToolModalProps): ReactNode {
  const [createTool] = usePostToolV1Mutation()
  return (
    <NewEntityModal<ToolOptionWithVendor>
      {...props}
      setEntity={props.setToolOption}
      entity={props.tool}
      config={{
        type: "Tool",
        renderEntityDisplay: ({ value }) => <ToolOptionPicker value={value} isReadOnly />,
        createMutation: async (data) => await createTool(data).unwrap(),
      }}
    />
  )
}

export function ToolOptionPickerCreatableWithModal(props: {
  autoFocus: boolean
  allowCreate: boolean
  value: ToolOptionWithVendor | undefined
  onChange: (tool?: ToolOptionWithVendor | null, fieldSource?: FieldMetadata) => void
  onCreate: (tool: ToolOptionWithVendor | null) => void
  suggestions?: ValueWithSource<ToolOptionWithVendor>[]
  fieldMetadata?: FieldMetadataWithSuggestions
  fieldApproval?: DynamicFormFieldApproval
  inputRef: RefCallBack
}): ReactNode {
  const { onChange, value, onCreate, ...rest } = props
  const newToolModal = useDisclosure()
  return (
    <>
      {newToolModal.isOpen && value && (
        <NewToolModal
          {...newToolModal}
          setToolOption={(tool) => {
            onCreate(tool)
          }}
          tool={value}
        />
      )}
      <ToolOptionPickerCreatable
        value={value && { value }}
        onChange={(tool) => {
          onChange(tool?.value || null, tool?.field_sources?.[0])
          if (tool && tool?.value?.tool_listing_id === "new") {
            newToolModal.onOpen()
          }
        }}
        {...rest}
      />
    </>
  )
}

export function ToolListingMultiPickerWithModal(props: {
  value: Array<ToolListing> | undefined
  isReadOnly: boolean
  onChange: (listings: Array<ToolListing>) => void
  inputRef: RefCallBack
}): ReactNode {
  const { value, isReadOnly, inputRef, onChange } = props
  const newToolModal = useDisclosure()

  // in the workflow run, the state is updated via polling
  // if we do NOT use this intermediary state, then things get
  // messed up while the new tool modal is open
  const [newValue, setNewValue] = useState(value)
  const newListing = newValue ? newValue.at(-1) : null

  return (
    <>
      {newToolModal.isOpen && newValue && newListing && newListing?.display_name && (
        <NewToolModal
          {...newToolModal}
          setToolOption={(tool) => {
            if (tool === null) {
              return
            }

            newValue.pop()
            if (tool) {
              const toolListing: ToolListing = {
                id: tool.tool_listing_id,
                display_name: tool.display_name,
                image_asset: tool.image_asset,
                object_type: "ToolListing",
              }
              newValue.push(toolListing)
            }

            onChange(newValue)
          }}
          tool={{
            id: null,
            description: null,
            website: null,
            image_asset: null,
            tool_listing_id: "new",
            display_name: newListing.display_name,
            object_type: "Tool",
          }}
        />
      )}

      <ToolListingMultiPicker
        inputRef={inputRef}
        isReadOnly={isReadOnly}
        allowCreate={true}
        value={value ?? []}
        onChange={(listings) => {
          const latest = listings.at(-1)

          if (latest?.id === "new") {
            newToolModal.onOpen()
            setNewValue(Array.from(listings))
            return
          }

          onChange(Array.from(listings))
        }}
      />
    </>
  )
}
