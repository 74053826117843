import { Divider, Grid, Heading, HStack, Stack, useDisclosure } from "@chakra-ui/react"
import { useMemo } from "react"
import { useIntl } from "react-intl"
import { Timeline, type TimelineProps } from "../../features/timeline/Timeline.js"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps } from "../../features/workflows/run/utils.js"
import { PAGE_PADDING_X } from "../../util/constant.js"
import { useStreaming } from "../../util/hooks/streaming.js"
import CancelIconButton from "../icons/system/CancelIconButton.js"
import CommentIconButton from "../icons/system/CommentIconButton.js"
import { TimelineCommentInput, type TimelineCommentInputProps } from "./TimelineCommentInput.js"

interface Props {
  timelineProps: TimelineProps
  heading?: string
  showHeading?: boolean
}
export default function TimelineSidebarContent({
  timelineProps,
  heading,
  showHeading = true,
  getOrganizationActorWhenActorMissing,
  getLogoToShowByOrganizationId,
}: Props & GetOrganizationActorProps & GetLogoForOrganizationProps) {
  const intl = useIntl()

  const headerCommentInput = useDisclosure()

  // For now only tool and vendor timelines can have comments added at the timeline header level
  const timelineCommentProps = useMemo(():
    | Pick<TimelineCommentInputProps, "objectId" | "objectType" | "label">
    | undefined => {
    if (timelineProps.filterParams?.toolId) {
      return {
        objectId: timelineProps.filterParams.toolId,
        objectType: "Tool",
        label: intl.formatMessage({
          defaultMessage: "Tool",
          id: "tool.timelineComment.label",
          description: "Label for tool comments",
        }),
      }
    }
    if (timelineProps.filterParams?.vendorId) {
      return {
        objectId: timelineProps.filterParams.vendorId,
        objectType: "Vendor",
        label: intl.formatMessage({
          defaultMessage: "Vendor",
          id: "vendor.timelineComment.label",
          description: "Label for vendor comments",
        }),
      }
    }
    return undefined
  }, [timelineProps.filterParams, intl])

  const [streaming, streamingController] = useStreaming()

  return (
    <>
      {showHeading && (
        <Stack pr={PAGE_PADDING_X}>
          <HStack justifyContent="space-between">
            <Heading as="h2" size="xs" fontSize="xl">
              {heading ??
                intl.formatMessage({
                  defaultMessage: "Activity",
                  description: "Default heading for the activity timeline",
                  id: "timeline.heading.default",
                })}
            </Heading>
            {timelineCommentProps &&
              (headerCommentInput.isOpen ? (
                <CancelIconButton variant="ghost" onClick={headerCommentInput.onClose} size="sm" />
              ) : (
                <CommentIconButton variant="ghost" onClick={headerCommentInput.onOpen} size="sm" />
              ))}
          </HStack>
        </Stack>
      )}
      {timelineCommentProps && headerCommentInput.isOpen && (
        <>
          <Grid templateColumns="[avatar] auto [input] 1fr" gap={2} mt={2} pr={4}>
            <TimelineCommentInput
              {...timelineCommentProps}
              onSaveComment={headerCommentInput.onClose}
              getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
              autoFocus
              streamingController={streamingController}
            />
          </Grid>
          <Divider my={2} />
        </>
      )}
      <Timeline
        {...timelineProps}
        getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
        getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
        streaming={streaming}
        streamingController={streamingController}
      />
    </>
  )
}
