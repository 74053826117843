import { Center } from "@chakra-ui/react"
import { useEffect, type FunctionComponent } from "react"
import { useLocation, useParams } from "react-router-dom"
import { isString } from "typed-assert"
import { useGetWorkflowV1RunsByIdQuery } from "../../../app/services/generated-api.js"
import Spinner from "../../../components/spinner.js"
import useIsAppFocused from "../../../util/hooks/use-is-app-focused-hook.js"
import { AUTO_SELECT_STEP_HASH } from "../constants.js"
import { WorkflowRunOverview } from "./WorkflowRunOverview.js"

const WorkflowRunPage: FunctionComponent = () => {
  const { workflowRunId } = useParams()
  const location = useLocation()
  const isAppFocused = useIsAppFocused()
  isString(workflowRunId, "Missing ID")
  const { data: workflowRun, isLoading, refetch, error } = useGetWorkflowV1RunsByIdQuery({ id: workflowRunId })

  useEffect(() => {
    if (isAppFocused) {
      void refetch()
    }
  }, [isAppFocused, refetch])

  if (error) {
    throw error
  }

  if (isLoading) {
    return (
      <Center height="100%">
        <Spinner size="md" />
      </Center>
    )
  }

  if (!workflowRun) {
    // TODO: add some kind of loading spinner here
    return null
  }

  return (
    <WorkflowRunOverview
      workflowRun={{ ...workflowRun, is_external: false }}
      autoSelectStep={location.hash === `#${AUTO_SELECT_STEP_HASH}`}
    />
  )
}
export default WorkflowRunPage
