import type {
  VendorComplianceStatus,
  VendorFinanceStatus,
  VendorItStatus,
  VendorStatus,
} from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import type { ThemeTypings } from "@chakra-ui/react"
import type { IntlShape } from "react-intl"

export const displayVendorStatus = (status: VendorStatus, intl: IntlShape) => {
  switch (status) {
    case "approved":
      return intl.formatMessage({
        id: "vendor.status.approved",
        defaultMessage: "Approved",
        description: "The label for the vendor status `approved`",
      })
    case "canceling":
      return intl.formatMessage({
        id: "vendor.status.canceling",
        defaultMessage: "Canceling",
        description: "The label for the vendor status `canceling`",
      })
    case "churned":
      return intl.formatMessage({
        id: "vendor.status.churned",
        defaultMessage: "Churned",
        description: "The label for the vendor status `churned`",
      })
    case "in_review":
      return intl.formatMessage({
        id: "vendor.status.in_review",
        defaultMessage: "In Review",
        description: "The label for the vendor status `in_review`",
      })
    case "rejected":
      return intl.formatMessage({
        id: "vendor.status.rejected",
        defaultMessage: "Rejected",
        description: "The label for the vendor status `rejected`",
      })
    case "unknown":
      return intl.formatMessage({
        id: "vendor.status.unknown",
        defaultMessage: "Unknown",
        description: "The label for the vendor status `unknown`",
      })
    default:
      unreachable(status)
  }
}

export const vendorStatusColors: Record<VendorStatus, ThemeTypings["colorSchemes"]> = {
  approved: "success",
  in_review: "success",
  canceling: "warning",
  unknown: "gray",
  rejected: "error",
  churned: "error",
}

export const displayVendorFinanceStatus = (status: VendorFinanceStatus, intl: IntlShape) => {
  switch (status) {
    case "approved":
      return intl.formatMessage({
        id: "vendor.finance.status.approved",
        defaultMessage: "Approved",
        description: "The label for the vendor finance status `approved`",
      })
    case "in_review":
      return intl.formatMessage({
        id: "vendor.finance.status.in_review",
        defaultMessage: "In Review",
        description: "The label for the vendor finance status `in_review`",
      })
    case "rejected":
      return intl.formatMessage({
        id: "vendor.finance.status.rejected",
        defaultMessage: "Rejected",
        description: "The label for the vendor finance status `rejected`",
      })
    case "unknown":
      return intl.formatMessage({
        id: "vendor.finance.status.unknown",
        defaultMessage: "Unknown",
        description: "The label for the vendor finance status `unknown`",
      })
    default:
      unreachable(status)
  }
}

export const vendorFinanceStatusColors: Record<VendorFinanceStatus, ThemeTypings["colorSchemes"]> = {
  approved: "green",
  in_review: "green",
  unknown: "gray",
  rejected: "error",
}

export const displayVendorComplianceStatus = (status: VendorComplianceStatus, intl: IntlShape) => {
  switch (status) {
    case "approved":
      return intl.formatMessage({
        id: "vendor.compliance.status.approved",
        defaultMessage: "Approved",
        description: "The label for the vendor compliance status `approved`",
      })
    case "in_review":
      return intl.formatMessage({
        id: "vendor.compliance.status.in_review",
        defaultMessage: "In Review",
        description: "The label for the vendor compliance status `in_review`",
      })
    case "rejected":
      return intl.formatMessage({
        id: "vendor.compliance.status.rejected",
        defaultMessage: "Rejected",
        description: "The label for the vendor compliance status `rejected`",
      })
    case "unknown":
      return intl.formatMessage({
        id: "vendor.compliance.status.unknown",
        defaultMessage: "Unknown",
        description: "The label for the vendor compliance status `unknown`",
      })
    default:
      unreachable(status)
  }
}

export const vendorComplianceStatusColors: Record<VendorComplianceStatus, ThemeTypings["colorSchemes"]> = {
  approved: "green",
  in_review: "green",
  unknown: "gray",
  rejected: "error",
}

export const displayVendorItStatus = (status: VendorItStatus, intl: IntlShape) => {
  switch (status) {
    case "approved":
      return intl.formatMessage({
        id: "vendor.it.status.approved",
        defaultMessage: "Approved",
        description: "The label for the vendor IT status `approved`",
      })
    case "in_review":
      return intl.formatMessage({
        id: "vendor.it.status.in_review",
        defaultMessage: "In Review",
        description: "The label for the vendor IT status `in_review`",
      })
    case "rejected":
      return intl.formatMessage({
        id: "vendor.it.status.rejected",
        defaultMessage: "Rejected",
        description: "The label for the vendor IT status `rejected`",
      })
    case "unknown":
      return intl.formatMessage({
        id: "vendor.it.status.unknown",
        defaultMessage: "Unknown",
        description: "The label for the vendor IT status `unknown`",
      })
    default:
      unreachable(status)
  }
}

export const vendorItStatusColors: Record<VendorItStatus, ThemeTypings["colorSchemes"]> = {
  approved: "green",
  in_review: "green",
  unknown: "gray",
  rejected: "error",
}
