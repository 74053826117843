import type { DocumentElement, DocumentMinimal } from "@brm/schema-types/types.js"
import type { CreateToastFnReturn } from "@chakra-ui/react"
import type { IntlShape } from "react-intl"
import { Transforms } from "slate"
import { LONG_TOAST_DURATION } from "../../../util/constant.js"
import { getAPIErrorMessage } from "../../../util/error.js"
import type { CustomEditor } from "../types.js"

/** Document support */
export const withDocuments = (
  editor: CustomEditor,
  intl: IntlShape,
  uploadDocument: (file: File) => Promise<DocumentMinimal>,
  toast: CreateToastFnReturn
) => {
  const { insertData, isVoid } = editor

  editor.isVoid = (element) => {
    return element.type === "document" ? true : isVoid(element)
  }

  editor.insertData = async (data) => {
    const { files } = data
    if (files && files.length > 0) {
      await Promise.all(
        Array.from(files, (file) =>
          uploadDocument(file)
            .then((document) => {
              if (!document) {
                handleDocumentUploadError(new Error("Document upload failed"), toast, intl)
                return
              }
              insertDocument(editor, {
                id: document.id,
                fileName:
                  document.file_name ??
                  intl.formatMessage({
                    id: "richText.document.upload.fileName.placeholder",
                    description:
                      "Placeholder default file name used to show document if a file name is not provided in the upload",
                    defaultMessage: "[Uploaded Document]",
                  }),
                mime_type: document.mime_type ?? file.type,
              })
            })
            .catch((err) => handleDocumentUploadError(err, toast, intl))
        )
      )
    }

    insertData(data)
  }

  return editor
}

export const insertDocument = (
  editor: CustomEditor,
  { id, fileName, mime_type }: { id: string; fileName: string; mime_type: string }
) => {
  const text = { text: "" }
  const document: DocumentElement = { type: "document", id, file_name: fileName, mime_type, children: [text] }
  Transforms.insertNodes(editor, document)
  Transforms.insertNodes(editor, {
    type: "paragraph",
    children: [{ text: "" }],
  })
}

export const handleDocumentUploadError = (err: unknown, toast: CreateToastFnReturn, intl: IntlShape) => {
  toast({
    description:
      getAPIErrorMessage(err) ??
      intl.formatMessage({
        id: "richText.document.upload.error",
        description: "Error toast displayed when a user uploads a document to a rich text input and an error occurs",
        defaultMessage: "Something went wrong while uploading your document",
      }),
    status: "error",
    duration: LONG_TOAST_DURATION,
  })
}
