import type { WorkflowKind } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Badge, Icon } from "@chakra-ui/react"
import { InfoSquareIcon, PurchaseIcon, RenewalIcon } from "../../components/icons/icons.js"
import { displayWorkflowKind } from "./util.js"

interface Props {
  kind: WorkflowKind
}

export default function WorkflowRunKindBadge({ kind }: Props) {
  switch (kind) {
    case "purchase":
      return (
        <Badge
          colorScheme="blue"
          textTransform="capitalize"
          display="inline-flex"
          alignItems="center"
          gap={1}
          variant="subtleOutlined"
        >
          <PurchaseIcon />
          {displayWorkflowKind(kind)}
        </Badge>
      )
    case "renewal":
      return (
        <Badge
          colorScheme="green"
          textTransform="capitalize"
          display="inline-flex"
          alignItems="center"
          gap={1}
          variant="subtleOutlined"
        >
          <RenewalIcon />
          {displayWorkflowKind(kind)}
        </Badge>
      )
    case "gather_data":
      return (
        <Badge
          colorScheme="gray"
          textTransform="capitalize"
          display="inline-flex"
          alignItems="center"
          gap={1}
          variant="subtleOutlined"
        >
          <Icon as={InfoSquareIcon} />
          {displayWorkflowKind(kind)}
        </Badge>
      )
    default:
      unreachable(kind)
  }
}
