import type { CommentTimelineEvent, TimelineEventReplyTimelineEvent } from "@brm/schema-types/types.js"
import { isObject } from "@brm/util/type-guard.js"
import { ButtonGroup, Icon, useToast } from "@chakra-ui/react"
import { type FunctionComponent } from "react"
import { useIntl } from "react-intl"
import { useSearchParams } from "react-router-dom"
import {
  useGetUserV1WhoamiQuery,
  usePostTimelineV1EventsByIdReactionsMutation,
} from "../../app/services/generated-api.js"
import { IconButtonWithTooltip } from "../IconButtonWithTooltip.js"
import { CommentIcon, EditIcon, LinkIcon, TrashIcon, ZapIcon } from "../icons/icons.js"
import { ReactionPopover } from "./ReactionPopover.js"

export const CommentActions: FunctionComponent<{
  event: CommentTimelineEvent | TimelineEventReplyTimelineEvent
  onClickReply?: () => void
  onCopyLink: () => void
  onClickEdit: () => void
  onClickDelete: () => void
  onApply?: () => Promise<void>
  isApplyLoading?: boolean
  isReadOnly?: boolean
}> = ({ event, onClickReply, onCopyLink, onClickEdit, onClickDelete, onApply, isApplyLoading, isReadOnly }) => {
  const intl = useIntl()
  const toast = useToast()
  const { data: whoami } = useGetUserV1WhoamiQuery()

  const [, setSearchParams] = useSearchParams()

  const [addReaction] = usePostTimelineV1EventsByIdReactionsMutation()

  const handleAddReaction = async (emoji: string, timelineEventId: string) => {
    setSearchParams(
      (current) => {
        current.delete("event")
        return current
      },
      { replace: true }
    )
    try {
      await addReaction({
        id: timelineEventId,
        reactionInput: {
          emoji,
        },
      }).unwrap()
    } catch (_) {
      toast({
        description: intl.formatMessage({
          id: "request.comment.reaction.add.error",
          description: "Toast error message when adding a reaction fails",
          defaultMessage: "There was an error adding your reaction",
        }),
        status: "error",
      })
    }
  }

  const replyLabel = intl.formatMessage({
    defaultMessage: "Add reply",
    description: "Aria and tooltip for the reply button on the field timeline comment",
    id: "timeline.comment.reply",
  })
  const copyLinkLabel = intl.formatMessage({
    defaultMessage: "Copy link",
    description: "Aria and tooltip for the copy link button on the field timeline comment",
    id: "timeline.comment.copyLink",
  })
  const editLabel = intl.formatMessage({
    defaultMessage: "Edit comment",
    description: "Aria and tooltip label for the edit button on the field timeline comment",
    id: "timeline.comment.edit",
  })
  const deleteLabel = intl.formatMessage({
    defaultMessage: "Delete comment",
    description: "Aria and tooltip label for the delete button on the field timeline comment",
    id: "timeline.comment.delete",
  })
  const applyLabel = intl.formatMessage({
    defaultMessage: "Apply",
    description: "Aria and tooltip label for the apply button on the field timeline comment",
    id: "timeline.comment.apply",
  })

  const showReplyButton = onClickReply && event.type === "comment" && !event.workflow_run_id
  const showApplyButton =
    onApply && event.actor === "system" && event.type === "comment" && event.field_name && !isReadOnly
  const showDeleteButton = event.actor === "system" && event.type === "comment"

  return (
    <ButtonGroup
      size="sm"
      isAttached
      variant="outline"
      visibility="hidden"
      sx={{
        ".comment-body:hover &": {
          visibility: "visible",
        },
      }}
    >
      {showReplyButton && (
        <IconButtonWithTooltip label={replyLabel} icon={<Icon as={CommentIcon} />} onClick={onClickReply} />
      )}

      {showApplyButton && (
        <IconButtonWithTooltip
          label={applyLabel}
          isLoading={isApplyLoading}
          icon={<Icon as={ZapIcon} />}
          onClick={onApply}
        />
      )}

      <ReactionPopover
        onSelect={(emoji) => handleAddReaction(emoji, event.id)}
        buttonProps={{
          // ReactionPopover's Tooltip and Popover wrapping over the IconButton messes with the ButtonGroup so we need to set the border radii manually
          // Left radius is set to none when the reaction popover is not the left-most button
          borderLeftRadius: showReplyButton || showApplyButton ? "none" : undefined,
          borderRightRadius: "none",
          borderRight: "none",
        }}
      />
      <IconButtonWithTooltip label={copyLinkLabel} icon={<Icon as={LinkIcon} />} onClick={onCopyLink} />
      {/* Only the acting user that created the comment can edit or delete it */}
      {isObject(event.actor) && whoami?.id === event.actor.id && (
        <>
          <IconButtonWithTooltip label={editLabel} icon={<Icon as={EditIcon} />} onClick={onClickEdit} />
          <IconButtonWithTooltip label={deleteLabel} icon={<Icon as={TrashIcon} />} onClick={onClickDelete} />
        </>
      )}
      {showDeleteButton && (
        <IconButtonWithTooltip label={deleteLabel} icon={<Icon as={TrashIcon} />} onClick={onClickDelete} />
      )}
    </ButtonGroup>
  )
}
