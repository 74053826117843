import { hasAllPermissions, hasPermission } from "@brm/schema-helpers/role.js"
import { schemaToFormFields } from "@brm/schema-helpers/schema.js"
import type {
  DocumentWithExtraction,
  FieldCategory,
  Permission,
  ToolDetails,
  ToolPatch,
} from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { unreachable } from "@brm/util/unreachable.js"
import {
  Avatar,
  AvatarGroup,
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { isString } from "typed-assert"
import {
  useGetToolV1ByIdQuery,
  useGetUserV1WhoamiQuery,
  usePutToolV1ByIdFollowingMutation,
} from "../../app/services/generated-api.js"
import { useContextMenu } from "../../components/ContextMenu/context-menu.js"
import OwnerCell from "../../components/DataTable/CellRenderer/OwnerCell.js"
import VendorCell from "../../components/DataTable/CellRenderer/VendorCell.js"
import { DividedRowSubHeader } from "../../components/DividedRowSubHeader.js"
import type { DynamicFormProps } from "../../components/DynamicForm/DynamicForm.js"
import { Link } from "../../components/Link.js"
import { LogoHeader } from "../../components/LogoHeader.js"
import ShowHideDrawerButton from "../../components/ShowHideDrawerButton.js"
import TagGroup from "../../components/TagGroup.js"
import TimelineSidebarContent from "../../components/Timeline/TimelineSidebarContent.js"
import { logoHeaderBoxSize } from "../../components/constants.js"
import { IntegrationProviderIcon } from "../../components/icons/IntegrationProviderIcon.js"
import { ToolLogo } from "../../components/icons/Logo.js"
import {
  FlagIcon,
  LinkExternalIcon,
  MoreMenuIcon,
  NotificationsDisabledIcon,
  NotificationsEnabledIcon,
  UsersIcon,
} from "../../components/icons/icons.js"
import NotificationIconButton from "../../components/icons/system/NotificationIconButton.js"
import { filterActiveSubscriptions } from "../../util/legal-agreement.js"
import { getDisplayUrl, getPublicImageGcsUrl } from "../../util/url.js"
import { useObjectPatchSchema } from "../../util/use-schema.js"
import { DuplicateSubscriptionAlert } from "../legal/DuplicateSubscriptionAlert.js"
import { FlagToolModal } from "./FlagToolModal.js"
import ToolCategory from "./ToolCategory.js"
import ToolOverview from "./ToolOverview.js"
import ToolSecurity from "./ToolSecurity.js"
import ToolUsers from "./ToolUsers.js"
import type { ToolTab } from "./constants.js"
import { TOOL_TIMELINE_HIDE_STORAGE_KEY, isToolTab, toolTabs } from "./constants.js"
import ToolStatusBadge from "./status/ToolStatusBadge.js"

import { Flags } from "@brm/util/flags.js"
import { useFlags } from "launchdarkly-react-client-sdk"
import DocumentsPanel from "../../components/Document/DocumentsPanel.js"
import { PaymentsPanel } from "../../components/Payments.js"
import SessionStorageSidebar from "../../components/SessionStorageSidebar.js"
import NotFoundErrorView from "../error/NotFoundErrorView.js"
import { HideToolModal } from "./HideToolModal.js"
interface ToolDataTab {
  displayName: string
  permissions?: Permission[]
  panel: JSX.Element
}

export default function Tool() {
  const { toolId, tab } = useParams()
  isString(toolId, "Missing id route param")
  const shownTab: ToolTab = tab && isToolTab(tab) ? tab : "overview"

  const { [Flags.DOCUMENT_EXTRACTION_V2_ENABLED]: documentExtractionV2Enabled } = useFlags()
  const intl = useIntl()
  const toast = useToast()
  const flagDataModal = useDisclosure()
  const hideToolModal = useDisclosure()

  const { menuListProps, menuProps, subjectProps, menuItemProps, betsyProps, menuButtonProps } =
    useContextMenu<HTMLDivElement>({
      betsyEnabled: true,
    })

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { data: tool, error } = useGetToolV1ByIdQuery({ id: toolId })
  const [updateToolFollowing, { isLoading: isFollowingSaving }] = usePutToolV1ByIdFollowingMutation()

  const [document, setDocument] = useState<DocumentWithExtraction | undefined>()
  const hasDuplicateSubscriptions = useMemo(
    () => tool && filterActiveSubscriptions(tool.legal_agreements).length > 1,
    [tool]
  )

  const followTool = useCallback(
    async (tool: ToolDetails) => {
      const newFollowState = !tool.following
      try {
        await updateToolFollowing({
          id: tool.id,
          body: { following: newFollowState },
        })
        toast({
          description: newFollowState
            ? intl.formatMessage({
                id: "tool.notification.toggle.enabled",
                description: "Notification toggle enabled toast title",
                defaultMessage: "Tool Followed",
              })
            : intl.formatMessage({
                id: "tool.notification.toggle.disabled",
                description: "Notification toggle disabled toast title",
                defaultMessage: "Tool Unfollowed",
              }),
          status: "success",
        })
      } catch (_err) {
        toast({
          description: newFollowState
            ? intl.formatMessage({
                id: "tool.notification.toggle.error.enabled",
                description: "Notification toggle enabled error toast title",
                defaultMessage: "Error following tool",
              })
            : intl.formatMessage({
                id: "tool.notification.toggle.error.disabled",
                description: "Notification toggle disabled error toast title",
                defaultMessage: "Error unfollowing tool",
              }),
          status: "error",
        })
      }
    },
    [intl, toast, updateToolFollowing]
  )

  const toolPatchSchema = useObjectPatchSchema("Tool")
  const formFieldsByCategory = useMemo(():
    | Map<FieldCategory | undefined, DynamicFormProps<ToolPatch>["formFields"]>
    | undefined => {
    if (!toolPatchSchema) {
      return undefined
    }

    const formFieldsByCategory = new Map<FieldCategory | undefined, DynamicFormProps<ToolPatch>["formFields"]>()
    const categories = FieldCategorySchema.anyOf.map((category) => category.const)

    for (const category of categories) {
      const { standard, custom } = schemaToFormFields(toolPatchSchema, "Tool", category)
      const customWithPath = custom.map((field) => ({
        ...field,
        path: ["custom", field.field_name],
        is_internal_only: false,
      }))
      const standardWithPath = standard.map((field) => ({
        ...field,
        path: [field.field_name],
        is_internal_only: false,
      }))
      formFieldsByCategory.set(category, [...standardWithPath, ...customWithPath])
    }

    const { standard, custom } = schemaToFormFields(toolPatchSchema, "Tool", undefined)
    const customWithPath = custom.map((field) => ({
      ...field,
      path: ["custom", field.field_name],
      is_internal_only: false,
    }))
    const standardWithPath = standard.map((field) => ({
      ...field,
      path: [field.field_name],
      is_internal_only: false,
    }))
    formFieldsByCategory.set(undefined, [...standardWithPath, ...customWithPath])

    return formFieldsByCategory
  }, [toolPatchSchema])

  if (error) {
    return <NotFoundErrorView error={error} />
  }

  if (!tool) {
    return null
  }

  const dataByTab: Record<ToolTab, ToolDataTab> = {
    overview: {
      displayName: intl.formatMessage({
        id: "tool.tabs.overview",
        description: "Tool view tab overview info",
        defaultMessage: "Overview",
      }),
      panel: <ToolOverview tool={tool} />,
    },
    users: {
      displayName: intl.formatMessage({
        id: "tool.tabs.users",
        description: "Tool view tab users info",
        defaultMessage: "Users",
      }),
      panel: <ToolUsers tool={tool} />,
    },
    payments: {
      displayName: intl.formatMessage({
        id: "tool.tabs.payments",
        description: "Tool view tab payments",
        defaultMessage: "Payments",
      }),
      permissions: ["transaction:read"],
      panel: <PaymentsPanel toolId={tool.id} vendorId={tool.vendor?.id} />,
    },
    security: {
      displayName: intl.formatMessage({
        id: "tool.tabs.security",
        description: "Tool view tab security",
        defaultMessage: "Security",
      }),
      permissions: ["login:read"],
      panel: <ToolSecurity tool={tool} />,
    },
    general: {
      displayName: intl.formatMessage({
        id: "tool.tabs.general",
        description: "Tool view tab general",
        defaultMessage: "General",
      }),
      permissions: [],
      panel: (
        <ToolCategory
          category={undefined}
          tool={tool}
          formFields={formFieldsByCategory?.get(undefined)}
          document={document}
          setDocument={setDocument}
        />
      ),
    },
    compliance: {
      displayName: intl.formatMessage({
        id: "tool.tabs.compliance",
        description: "Tool view tab compliance",
        defaultMessage: "Compliance",
      }),
      permissions: ["compliance:update"],
      panel: (
        <ToolCategory
          category="compliance"
          tool={tool}
          formFields={formFieldsByCategory?.get("compliance")}
          document={document}
          setDocument={setDocument}
        />
      ),
    },
    it: {
      displayName: intl.formatMessage({
        id: "tool.tabs.it",
        description: "Tool view tab it",
        defaultMessage: "IT",
      }),
      permissions: ["it:update"],
      panel: (
        <ToolCategory
          category="it"
          tool={tool}
          formFields={formFieldsByCategory?.get("it")}
          document={document}
          setDocument={setDocument}
        />
      ),
    },
    finance: {
      displayName: intl.formatMessage({
        id: "tool.tabs.finance",
        description: "Tool view tab finance",
        defaultMessage: "Finance",
      }),
      permissions: ["finance:update"],
      panel: (
        <ToolCategory
          category="finance"
          tool={tool}
          formFields={formFieldsByCategory?.get("finance")}
          document={document}
          setDocument={setDocument}
        />
      ),
    },
    documents: {
      displayName: intl.formatMessage({
        id: "tool.tabs.documents",
        description: "Tool view tab documents",
        defaultMessage: "Documents",
      }),
      panel: <DocumentsPanel documents={tool.documents} />,
    },
  }

  const availableTabs =
    tool.owner && tool.owner.user_id === whoami?.id
      ? toolTabs
      : toolTabs.filter((tab) => {
          const permissions = dataByTab[tab].permissions
          switch (tab) {
            case "general": {
              const formFields = formFieldsByCategory?.get(undefined)
              if (!formFields || formFields.length === 0) {
                return false
              }
              break
            }
            case "compliance":
            case "it":
            case "finance": {
              const formFields = formFieldsByCategory?.get(tab)
              if (!formFields || formFields.length === 0) {
                return false
              }
              break
            }
            case "documents":
              // Documents is only populated by the new v2 flow so we hide it if it's not enabled
              if (!documentExtractionV2Enabled) {
                return false
              }
              break
            case "security":
            case "users":
            case "payments":
            case "overview":
              break
            default:
              unreachable(tab)
          }
          return permissions ? hasAllPermissions(whoami?.roles, permissions) : true
        })
  return (
    <Flex flexDirection="column" gap={2} flexGrow={1} minHeight={0} {...subjectProps.baseProps}>
      <LogoHeader
        logoElement={
          <ToolLogo logo={getPublicImageGcsUrl(tool.image_asset?.gcs_file_name)} boxSize={logoHeaderBoxSize} />
        }
        heading={
          <HStack>
            <Heading size="md" display="flex" flexShrink={1} minW={0}>
              <Text as="span" isTruncated>
                {tool.display_name}
              </Text>
            </Heading>
            <Flex flexShrink={0}>
              <ToolStatusBadge status={tool.status} editToolId={toolId} />
            </Flex>
          </HStack>
        }
        rightActions={
          <>
            <Menu>
              <MenuButton as={IconButton} variant="outline" icon={<Icon as={MoreMenuIcon} />} />
              <MenuList>
                <MenuItem onClick={flagDataModal.onOpen}>
                  <FormattedMessage
                    id="tool.report.bad.data"
                    description="Report bad data menu item"
                    defaultMessage="Report bad data"
                  />
                </MenuItem>
                {hasPermission(whoami?.roles, "tool:hide") && (
                  <MenuItem onClick={hideToolModal.onOpen}>
                    <FormattedMessage id="tool.hide" description="Hide tool menu item" defaultMessage="Hide tool" />
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
            <NotificationIconButton
              variant="outline"
              notificationEnabled={tool.following}
              isDisabled={isFollowingSaving}
              onClick={() => followTool(tool)}
            />
            <ShowHideDrawerButton
              onOpen={() => setDocument(undefined)}
              hideStorageKey={TOOL_TIMELINE_HIDE_STORAGE_KEY}
              size="md"
              expandLabel={intl.formatMessage({
                id: "timeline.show.tooltip",
                description: "Label shown on button that can toggle showing/hiding a timeline sidebar",
                defaultMessage: "Show Activity",
              })}
              collapseLabel={intl.formatMessage({
                id: "timeline.hide.tooltip",
                description: "Label shown on button that can toggle showing/hiding a timeline sidebar",
                defaultMessage: "Hide Activity",
              })}
            />
            {flagDataModal.isOpen && <FlagToolModal {...flagDataModal} tool={tool} />}
            {hideToolModal.isOpen && <HideToolModal {...hideToolModal} toolId={tool.id} />}
          </>
        }
      >
        <HStack justifyContent="space-between" flexGrow={1}>
          <Stack gap={4} flexGrow={1}>
            {tool.description && (
              <Box width="70%">
                <Text>{tool.description}</Text>
              </Box>
            )}
            <DividedRowSubHeader>
              <OwnerCell owner={tool.owner} objectType="Tool" objectId={tool.id} showTooltip tooltipOpenDelay={0} />
              <VendorCell vendor={tool.vendor} flexGrow={0} showTooltip={true} />
              {tool.categories && tool.categories.length > 0 ? (
                <TagGroup
                  displayNames={tool.categories.filter((category) => !category.parent_id).map((c) => c.display_name)}
                  limit={1}
                />
              ) : null}
              <HStack as={Link} to="../users">
                <Icon as={UsersIcon} />
                <Text as="span" isTruncated>
                  <FormattedMessage
                    id="tool.users"
                    description="Tool header users label"
                    defaultMessage="{toolUsersCount} {toolUsersCount, plural, one {user} other {users}}"
                    values={{ toolUsersCount: tool.total_people }}
                  />
                </Text>
              </HStack>
              {tool.integrations.length > 0 ? (
                <HStack as={Link} to="/settings/integrations">
                  <FormattedMessage
                    id="integration.list"
                    description="Description field for the integrations an entity has sources from"
                    defaultMessage="Integrations:"
                  />
                  <AvatarGroup spacing={-1}>
                    {tool.integrations.map((integration) => (
                      <Avatar
                        key={integration.id}
                        icon={<IntegrationProviderIcon boxSize="1.5rem" integration={integration} />}
                        backgroundColor="white"
                      />
                    ))}
                  </AvatarGroup>
                </HStack>
              ) : null}
              {tool.website ? (
                <Link to={tool.website}>
                  <HStack gap={1}>
                    <Box flexGrow={1} maxWidth={250} overflow="hidden">
                      <Text noOfLines={1}>{getDisplayUrl(new URL(tool.website))}</Text>
                    </Box>
                    <Icon as={LinkExternalIcon} flexShrink={0} />
                  </HStack>
                </Link>
              ) : null}
            </DividedRowSubHeader>
          </Stack>
        </HStack>
      </LogoHeader>

      <Flex flex={1} alignItems="start" minHeight={0}>
        <Tabs
          isLazy
          isFitted
          variant="line"
          display="flex"
          flexDir="column"
          index={availableTabs.indexOf(shownTab)}
          pt={1}
          flex={1}
          minHeight={0}
          height="100%"
          overflowY="auto"
        >
          <Grid
            height="100%"
            width="100%"
            templateColumns="[tabs] minmax(0, 1fr) [activity] auto"
            templateRows="[title] auto [content] minmax(0, 1fr)"
            columnGap={0}
            rowGap={0}
          >
            <GridItem zIndex={1}>
              <TabList>
                {availableTabs.map((tab) => (
                  <Tab key={tab} as={Link} to={`../${tab}`} relative="path" onClick={() => setDocument(undefined)}>
                    {dataByTab[tab].displayName}
                  </Tab>
                ))}
              </TabList>
            </GridItem>
            <GridItem zIndex={0} borderBottomWidth="1px" />
            <GridItem>
              <TabPanels minW={600} height="100%" overflow="auto">
                {availableTabs.map((tab) => (
                  <TabPanel key={tab} padding={0} height="100%">
                    {dataByTab[tab].panel}
                  </TabPanel>
                ))}
              </TabPanels>
            </GridItem>
            <GridItem>
              <SessionStorageSidebar hideStorageKey={TOOL_TIMELINE_HIDE_STORAGE_KEY} width="450px">
                <TimelineSidebarContent
                  timelineProps={{
                    filterParams: { toolId: tool.id },
                    alerts: hasDuplicateSubscriptions && (
                      <DuplicateSubscriptionAlert toolOrVendorName={tool.display_name} />
                    ),
                  }}
                />
              </SessionStorageSidebar>
            </GridItem>
          </Grid>
        </Tabs>
      </Flex>
      <Portal>
        <Menu {...menuProps}>
          <MenuButton {...menuButtonProps} />
          <MenuList {...menuListProps}>
            {menuListProps.children}
            <MenuItem
              {...menuItemProps}
              onClick={() => followTool(tool)}
              icon={<Icon as={tool.following ? NotificationsEnabledIcon : NotificationsDisabledIcon} />}
            >
              {tool.following ? (
                <FormattedMessage
                  defaultMessage="Unfollow tool"
                  description="Label for the menu item tool unfollow button"
                  id="context-menu.item.unfollow-tool"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Follow tool"
                  description="Label for the menu item tool unfollow button"
                  id="context-menu.item.follow-tool"
                />
              )}
            </MenuItem>
            <MenuItem {...menuItemProps} onClick={flagDataModal.onOpen} icon={<Icon as={FlagIcon} color="red.700" />}>
              <FormattedMessage
                id="context-menu.item.report-bad-data"
                description="Report bad data context menu item"
                defaultMessage="Report bad data"
              />
            </MenuItem>
          </MenuList>
        </Menu>
      </Portal>
      {betsyProps?.BetsyModal}
    </Flex>
  )
}
