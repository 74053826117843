import type { FieldMetadataWithSuggestions } from "@brm/schema-types/types.js"
import type { OptionProps } from "chakra-react-select"
import { Select, chakraComponents } from "chakra-react-select"
import { forwardRef } from "react"
import { useIntl } from "react-intl"
import OptionWithFieldSource from "../DynamicForm/OptionWithFieldSource.js"
import type { ValueWithSource } from "../DynamicForm/types.js"

export interface EnumOption {
  label: string
  value: unknown
  field_sources?: FieldMetadataWithSuggestions[]
}

export interface EnumMultiSelectProps {
  isReadOnly?: boolean
  options: Array<EnumOption>
  value: Array<EnumOption>
  onChange: (values: Array<EnumOption>) => void
  suggestions?: ValueWithSource<string>[]
}

const Option = ({ children, ...props }: OptionProps<EnumOption>) => {
  const fieldSources = props.data.field_sources

  return (
    <chakraComponents.Option {...props}>
      <OptionWithFieldSource fieldSources={fieldSources}>{children}</OptionWithFieldSource>
    </chakraComponents.Option>
  )
}

const EnumMultiSelect = forwardRef<HTMLInputElement, EnumMultiSelectProps>(function EnumMultiSelect(props, ref) {
  const { isReadOnly, options, value, onChange, suggestions } = props

  const intl = useIntl()

  const optionsWithFieldSource = options.map((option) => {
    const suggestion = suggestions?.find((suggestion) => suggestion.value === option.value)
    return {
      ...option,
      field_sources: suggestion?.field_sources,
    }
  })

  const valueWithFieldSource = value.map((option) => {
    const suggestion = suggestions?.find((suggestion) => suggestion.value === option.value)
    return {
      ...option,
      field_sources: suggestion?.field_sources,
    }
  })

  return (
    <Select<EnumOption, true>
      ref={(select) => {
        if (ref) {
          if (typeof ref === "function") {
            ref(select?.inputRef ?? null)
          } else {
            ref.current = select?.inputRef ?? null
          }
        }
      }}
      isReadOnly={isReadOnly}
      isMulti={true}
      closeMenuOnSelect={false}
      placeholder={intl.formatMessage({
        id: "form.multi-select.placeholder",
        defaultMessage: "Select all that apply...",
        description: "Placeholder for multi-selection input",
      })}
      value={valueWithFieldSource}
      options={optionsWithFieldSource}
      onChange={(data) => onChange(Array.from(data))}
      components={{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Option,
      }}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (styles) => ({
          ...styles,
          zIndex: "var(--chakra-zIndices-dropdown)",
        }),
      }}
      chakraStyles={{
        placeholder: (provided) => (value ? { ...provided, color: "inherit" } : provided),
      }}
    />
  )
})

export default EnumMultiSelect
