import type { CreateDocumentResponse, DocumentMinimal, TableIdentifier } from "@brm/schema-types/types.js"
import { sha1Hash, uint8ArrayToHex } from "@brm/util/crypto.js"
import { unreachable } from "@brm/util/unreachable.js"
import { uploadFile } from "./upload-document.js"

export const DOCUMENTS_TABLE_ID: TableIdentifier = "documents"

export const getContentHash = async (f: File) => {
  return uint8ArrayToHex(await sha1Hash(await f.arrayBuffer()))
}

export async function createAndUploadDocument({
  file,
  createdDocument,
  onUpload,
  onQuarantined,
}: {
  file: File
  createdDocument: CreateDocumentResponse
  onUpload: () => Promise<DocumentMinimal>
  onQuarantined?: (file: File) => void
}): Promise<DocumentMinimal | undefined> {
  let uploadedDocument: DocumentMinimal | undefined

  switch (createdDocument.status) {
    case "pending":
      await uploadFile(createdDocument, file)
      uploadedDocument = await onUpload()
      break
    case "uploaded":
    case "scanned": {
      // At least one document with matching content_hash already existed, use any of them
      uploadedDocument = createdDocument
      break
    }
    case "quarantined":
      onQuarantined?.(file)
      break
    default:
      return unreachable(createdDocument)
  }

  return uploadedDocument
}
