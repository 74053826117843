import { Avatar, Box, HStack, Image, Stack, Text } from "@chakra-ui/react"
import "katex/dist/katex.min.css"
import type { ReactNode } from "react"
import { useEffect, useState } from "react"
import type { Descendant } from "slate"
import RichTextDisplay from "../../components/RichTextEditor/RichTextDisplay.js"
import { AgentResponse } from "./AgentResponse.js"

export interface ChatMessageAuthor {
  name: string
  renderedName?: JSX.Element
  image?: string
  role: "user" | "assistant"
}

interface Props {
  author: ChatMessageAuthor
  content: string
  isLoading: boolean
  status?: string
  after?: ReactNode
  richText?: Descendant[]
}

export const ChatMessage = (props: Props) => {
  const { author, content, isLoading, richText, status, after } = props
  const [richTextRenderKey, setRichTextRenderKey] = useState(0)

  useEffect(() => {
    // RichTextEditor is an uncontrolled component that needs to be re-rendered when the value changes.
    // Without this, Rich Text Editors in the table will not update when filtering or sorting.
    setRichTextRenderKey((key) => key + 1)
  }, [richText])

  return (
    <HStack align="flex-start" gap="5">
      <Box pt="1">
        {author.role === "user" ? (
          <Avatar size="sm" src={author.image} name={author.name} />
        ) : (
          <Image src={author.image} boxSize={6} />
        )}
      </Box>
      <Stack width="100%" spacing="1">
        {author.renderedName ? author.renderedName : <Text fontWeight="medium">{author.name}</Text>}
        {author.role === "user" && richText ? (
          <RichTextDisplay key={richTextRenderKey} content={richText} />
        ) : (
          <AgentResponse isLoading={isLoading} content={content} status={status} />
        )}
        {!isLoading && after}
      </Stack>
    </HStack>
  )
}
