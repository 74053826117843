import type { EnhancedStore } from "@reduxjs/toolkit"
import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import * as Sentry from "@sentry/react"
import { userReducer } from "../features/auth/user-slice.js"
import { queryErrorHandler } from "./query-error-handler.js"
import { brmApi } from "./services/brm-api.js"
import { clearbitApi } from "./services/clearbit-api.js"

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
const sentryReduxEnhancer = Sentry.createReduxEnhancer()

export const store: EnhancedStore = configureStore({
  reducer: {
    [brmApi.reducerPath]: brmApi.reducer,
    [clearbitApi.reducerPath]: clearbitApi.reducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([brmApi.middleware, queryErrorHandler, clearbitApi.middleware]),
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
