export const displayScope = (scope: string) => {
  let parsedScope = scope
  if (scope.includes("googleapis")) {
    try {
      parsedScope = new URL(scope).pathname.slice(1)
    } catch (_err) {
      parsedScope = scope
    }
  }
  return parsedScope
}
