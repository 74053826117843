import type { AlternateActorType } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Avatar, AvatarBadge, Icon } from "@chakra-ui/react"
import type { User } from "@sentry/react"
import type { FunctionComponent } from "react"
import { useIntl } from "react-intl"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps } from "../../features/workflows/run/utils.js"
import { log } from "../../util/logger.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import BraimIcon from "../BraimIcon.js"
import { UserIcon } from "../icons/icons.js"
import { Link } from "../Link.js"
import { timelineEventPrimaryIconBoxSize } from "./common.js"

export const ActorAvatar: FunctionComponent<
  {
    eventId: string
    actor: User | AlternateActorType
  } & GetLogoForOrganizationProps &
    GetOrganizationActorProps
> = ({ eventId, actor, getOrganizationActorWhenActorMissing, getLogoToShowByOrganizationId }) => {
  const intl = useIntl()

  if (typeof actor === "string") {
    switch (actor) {
      case "system":
        return <BraimIcon boxSize={timelineEventPrimaryIconBoxSize} />
      case "unregistered_seller_user": {
        if (getOrganizationActorWhenActorMissing) {
          const organizationActor = getOrganizationActorWhenActorMissing()
          return (
            <Avatar
              boxSize={timelineEventPrimaryIconBoxSize}
              name={organizationActor.displayName}
              src={getPublicImageGcsUrl(organizationActor.logo)}
              icon={<Icon as={UserIcon} />}
            />
          )
        }

        log.error("No organization actor found for unregistered_seller_user event", null, { eventId })
        return <Avatar boxSize={timelineEventPrimaryIconBoxSize} icon={<Icon as={UserIcon} />} />
      }
      case "unknown":
        return <Avatar boxSize={timelineEventPrimaryIconBoxSize} icon={<Icon as={UserIcon} />} />
      default:
        unreachable(actor)
    }
  }

  const eventActorLogo = getLogoToShowByOrganizationId?.(actor.organization_id)
  return (
    <Link to={`/people/${actor.person.id}`}>
      <Avatar
        boxSize={timelineEventPrimaryIconBoxSize}
        name={displayPersonName(actor.person, intl)}
        src={getPublicImageGcsUrl(actor.person.profile_image?.gcs_file_name)}
        icon={<Icon as={UserIcon} />}
      >
        {eventActorLogo && <AvatarBadge as={Avatar} boxSize={3} src={getPublicImageGcsUrl(eventActorLogo)} />}
      </Avatar>
    </Link>
  )
}
