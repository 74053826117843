import type { TimePeriod } from "@brm/schema-types/types.js"
import { formatDate, formatYearMonth } from "@brm/util/format-date-time.js"
import type { CardProps } from "@chakra-ui/react"
import { Button, HStack, Heading, Stack, Text } from "@chakra-ui/react"
import type { FC, FunctionComponent } from "react"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  legacyDateToPlainDate,
  legacyDateToYearMonth,
  plainDateToLegacyDate,
} from "../../../../packages/util/src/date-time.js"
import { useGetLoginV1UserActivityQuery, type LoginUserActivityResponse } from "../../app/services/generated-api.js"
import { Link } from "../../components/Link.js"
import { useWidth } from "../home/use-width.js"
import ChartCard from "./ChartCard.js"
import { PercentWithTimePeriod } from "./PercentWithTimePeriod.js"
import SeriesChart from "./SeriesChart.js"
import { type OptionWithLabel, type XYChartMinimalProps } from "./types.js"
import {
  abbreviatedDateAxisProps,
  abbreviatedMonthAxisProps,
  displayTimePeriod,
  getChartTimePeriodDateParams,
  userActivityChartTimePeriodOptions,
} from "./utils.js"

interface Props extends CardProps {
  chartHeight: number
  toolListingId: string
  xyChartProps?: XYChartMinimalProps
  startingPeriod?: TimePeriod
  pollingInterval?: number
}

export interface LoginDatum {
  // visx / d3 uses legacy Dates for time scales
  // eslint-disable-next-line no-restricted-globals
  start_date: Date
  active_users: number
}

export const UserActivityChartCard: FunctionComponent<Props> = ({
  chartHeight,
  toolListingId,
  xyChartProps,
  startingPeriod = "last_twelve_months",
  ...cardProps
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState<TimePeriod>(startingPeriod)
  const dateParams = getChartTimePeriodDateParams(selectedPeriod)

  const { data: currentData, isLoading: isCurrentDataLoading } = useGetLoginV1UserActivityQuery({
    startDate: dateParams.startDate,
    endDate: dateParams.endDate,
    interval: dateParams.interval,
    toolListingId,
  })
  const { data: previousData, isLoading: isPreviousDataLoading } = useGetLoginV1UserActivityQuery({
    startDate: dateParams.prevStartDate,
    endDate: dateParams.prevEndDate,
    interval: dateParams.interval,
    toolListingId,
  })

  if (
    isCurrentDataLoading ||
    isPreviousDataLoading ||
    !currentData?.intervals ||
    currentData.intervals.every((interval) => interval.active_users === 0) ||
    !previousData
  ) {
    return null
  }

  return (
    <UserActivityChartCardInner
      currentData={currentData}
      previousData={previousData}
      chartHeight={chartHeight}
      xyChartProps={xyChartProps}
      selectedPeriod={selectedPeriod}
      setSelectedPeriod={setSelectedPeriod}
      {...cardProps}
    />
  )
}

type UserActivityChartCardInnerProps = CardProps & {
  currentData: LoginUserActivityResponse
  previousData: LoginUserActivityResponse
  selectedPeriod: TimePeriod
  setSelectedPeriod: (period: TimePeriod) => void
  chartHeight: number
  xyChartProps?: XYChartMinimalProps
}

const UserActivityChartCardInner: FC<UserActivityChartCardInnerProps> = ({
  currentData,
  previousData,
  chartHeight,
  xyChartProps,
  selectedPeriod,
  setSelectedPeriod,
  ...cardProps
}) => {
  const intl = useIntl()
  const { width, ref } = useWidth<HTMLDivElement>(200)

  const currentTotalActive = currentData.total_active_users
  const previousTotalActive = previousData.total_active_users
  const activityChange =
    previousTotalActive === 0 ? null : (currentTotalActive - previousTotalActive) / previousTotalActive

  return (
    <ChartCard<OptionWithLabel<TimePeriod>>
      {...cardProps}
      title={intl.formatMessage({
        id: "userActivityChart.title",
        description: "The title for the active users chart",
        defaultMessage: "Active Users",
      })}
      chartSources={["okta"]}
      description={
        <HStack gap={4}>
          <Heading size="md" color="gray.900">
            {currentTotalActive}
          </Heading>
          <PercentWithTimePeriod timePeriod={selectedPeriod} percent={activityChange} />
        </HStack>
      }
      selectProps={{
        options: userActivityChartTimePeriodOptions(),
        value: { value: selectedPeriod, label: displayTimePeriod(selectedPeriod) },
        onChange: (newSelection) => {
          if (newSelection) {
            setSelectedPeriod(newSelection.value)
          }
        },
      }}
      footer={
        <Button as={Link} variant="outline" to="../security" fontSize="sm">
          <FormattedMessage
            id="tool.context.usage.activity.viewAll"
            description="Button to view all activity"
            defaultMessage="View all activity"
          />
        </Button>
      }
    >
      <Stack ref={ref} flexGrow={1} gap={0}>
        {currentTotalActive === 0 ? (
          <Text fontSize="lg" color="gray.600" alignSelf="center" align="center" py={8}>
            <FormattedMessage
              id="activityChart.noData"
              description="No data to display"
              defaultMessage="No okta login data to display this period"
            />
          </Text>
        ) : (
          <SeriesChart<LoginDatum>
            xyChartProps={xyChartProps}
            width={width}
            height={chartHeight}
            axisProps={
              selectedPeriod === "last_thirty_days"
                ? [abbreviatedDateAxisProps(intl)]
                : [abbreviatedMonthAxisProps(intl)]
            }
            chartData={{
              type: "line",
              data: currentData.intervals.map(({ start_date, active_users }) => ({
                // visx / d3 uses legacy Date objects for time scales. We do the conversion manually with the Temporal to
                // make sure there are no off-by-one/timezone bugs.
                start_date: plainDateToLegacyDate(start_date),
                active_users,
              })),
            }}
            datumTransformers={{
              tooltip: (datum) => (
                <Stack spacing={1}>
                  <Text fontWeight="normal">
                    {selectedPeriod === "last_thirty_days"
                      ? formatDate(intl, legacyDateToPlainDate(datum.start_date), { month: "long", day: "numeric" })
                      : formatYearMonth(intl, legacyDateToYearMonth(datum.start_date), {
                          year: "numeric",
                          month: "long",
                        })}
                  </Text>
                  <div>
                    {intl.formatMessage(
                      {
                        id: "activityChart.activeUsers",
                        description: "The number of active users",
                        defaultMessage: "{activeUsers, number} users",
                      },
                      { activeUsers: datum.active_users }
                    )}
                  </div>
                </Stack>
              ),
              xAccessor: (datum) => datum?.start_date,
              yAccessor: (datum) => datum?.active_users,
            }}
          />
        )}
      </Stack>
    </ChartCard>
  )
}
