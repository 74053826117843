export const DEFAULT_TOAST_DURATION = 4_000
export const SHORT_TOAST_DURATION = 1_000
export const LONG_TOAST_DURATION = 10_000

export const SHORT_TOOLTIP_OPEN_DELAY = 750

export const USD = "USD"

export const MONEY_PATTERN = /^\d+(\.\d+)?$/u

export const PAGE_PADDING_Y = 3

export const PAGE_PADDING_X = 4

export const SAVED_PATH = "SAVED_PATH"
