import type { WorkflowRunStepWithContext } from "@brm/schema-types/types.js"
import type { Theme } from "@brm/theme"
import { Box, Flex, HStack, Icon, Text, useTheme } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { useSearchParams } from "react-router-dom"
import CardWithHoverActions from "../../../../components/CardWithHoverActions.js"
import { CheckCircleIcon } from "../../../../components/icons/icons.js"
import { Link } from "../../../../components/Link.js"
import WorkflowStepIcon from "../../WorkflowStepIcon.js"
import {
  displayWorkflowRunStepStatus,
  type GetLogoForOrganizationProps,
  type WorkflowRunWithExternalFlag,
} from "../utils.js"
import WorkflowRunStepStatusText from "./status/WorkflowRunStepStatusText.js"
import WorkflowRunStepApproverAvatars from "./WorkflowRunStepApproverAvatars.js"
import WorkflowRunStepGathererAvatars from "./WorkflowRunStepGathererAvatars.js"

export default function WorkflowRunStepCard({
  step,
  workflowRun,
  onClick,
  selected,
  getLogoToShowByOrganizationId,
}: {
  step: WorkflowRunStepWithContext
  workflowRun: WorkflowRunWithExternalFlag
  onClick: () => void
  selected?: boolean
  showConnector?: boolean
} & GetLogoForOrganizationProps) {
  const intl = useIntl()
  const [searchParams] = useSearchParams()
  const theme = useTheme<Theme>()
  return (
    <HStack flex={1} maxWidth={300}>
      <CardWithHoverActions
        selected={selected}
        flexGrow={1}
        onClick={onClick}
        overflow="hidden"
        cardBodyProps={{
          as: "div",
          display: "flex",
          gap: 2,
          alignItems: "center",
          backgroundColor: step.status === "pending" || step.status === "pending_submitted" ? "gray.50" : undefined,
        }}
        tooltipProps={{
          label: <WorkflowRunStepStatusText step={step} workflowRun={workflowRun} />,
          placement: "bottom",
        }}
      >
        <Link
          to={{ search: new URLSearchParams({ ...Object.fromEntries(searchParams), step: step.id }).toString() }}
          display="contents"
        >
          <WorkflowStepIcon stepType={step.type} />
          <Flex
            flexDirection="column"
            sx={{
              [`@container workflow-run-overview (width < ${theme.breakpoints["lg"]})`]: {
                display: "none",
              },
            }}
          >
            <Text fontWeight="medium" isTruncated>
              {step.display_name}
            </Text>
            <Text fontSize="xs" isTruncated>
              {displayWorkflowRunStepStatus(step, intl)}
            </Text>
          </Flex>
          <Box
            // Display contents to prevent the flex gap from being applied from the parent when there is no content rendered inside
            display="contents"
            sx={{
              [`@container workflow-run-overview (width < ${theme.breakpoints["xl"]})`]: {
                display: "none",
              },
            }}
          >
            {step.status === "in_progress" ? (
              <WorkflowRunStepGathererAvatars
                step={step}
                externalGatherers={workflowRun.seller_users}
                getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                sellerLogoGcsFileName={workflowRun.vendor?.image_asset?.gcs_file_name}
              />
            ) : step.status === "submitted" ? (
              <WorkflowRunStepApproverAvatars
                step={step}
                getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
              />
            ) : step.status === "completed" || step.status === "approved" ? (
              <Icon as={CheckCircleIcon} color="brand.600" />
            ) : null}
          </Box>
        </Link>
      </CardWithHoverActions>
    </HStack>
  )
}
