import type { CreditCardMinimal } from "@brm/schema-types/types.js"
import { chakra } from "@chakra-ui/react"
import { useIntl } from "react-intl"

export default function CreditCardCell({ card }: { card: CreditCardMinimal | null }) {
  const intl = useIntl()

  if (!card) {
    return null
  }

  const displayName = card.display_name
  const lastFour = card.last_four ? ` (*${card?.last_four})` : ""

  if (!displayName) {
    if (lastFour.length > 0) {
      return intl.formatMessage(
        {
          id: "transaction.unnamedCard ",
          description: "Message displayed when the card was not given a name",
          defaultMessage: "Untitled Card {lastFour}",
        },
        { lastFour }
      )
    }
    return (
      <chakra.span textTransform="uppercase">
        {intl.formatMessage({
          id: "transaction.deletedCreditCard",
          description: "Message shown instead of last 4 digits when external credit card data is missing",
          defaultMessage: "Deleted Card",
        })}
      </chakra.span>
    )
  }

  return `${displayName}${lastFour}`
}
