import type { VendorDetails, VendorOption, WorkflowKind } from "@brm/schema-types/types.js"
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react"
import { Controller, useWatch, type Control, type UseFormSetValue } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import ErrorAlert from "../../../../components/ErrorAlert/ErrorAlert.js"
import BasicOrganizationEntityPicker from "../../../../components/Form/BasicOrganizationEntityPicker.js"
import { VendorOptionPickerCreatable } from "../../../../components/Form/VendorOptionPicker.js"
import { NextIcon } from "../../../../components/icons/icons.js"
import { Link } from "../../../../components/Link.js"
import Spinner from "../../../../components/spinner.js"
import { getDisplayUrl } from "../../../../util/url.js"
import {
  generateWorkflowDisplayName,
  type StartWorkflowFormState,
  type WorkflowVendorSectionFormState,
} from "./util.js"

export default function WorkflowVendorSection({
  control,
  selectedVendor,
  vendorDetails,
  workflowKind,
  setValue,
  newVendorCreateIsLoading,
}: {
  control: Control<WorkflowVendorSectionFormState>
  selectedVendor: VendorOption | null
  vendorDetails: VendorDetails | undefined
  workflowKind: WorkflowKind | null
  setValue: UseFormSetValue<StartWorkflowFormState>
  newVendorCreateIsLoading: boolean
}) {
  const intl = useIntl()

  const newVendorDisplayName = useWatch({ control, name: "new_tool_or_vendor_display_name" })
  const isNewVendor = Boolean(newVendorDisplayName)

  const { website, description, display_name, vendor_listing_id } = selectedVendor ?? vendorDetails ?? {}

  if (!workflowKind) {
    return <ErrorAlert />
  }

  return (
    <Stack height="full">
      <Text>
        <FormattedMessage
          id="requests.start.modal.vendor.header"
          description="Header for the start workflow modal vendor selection section"
          defaultMessage="1. Search for a vendor"
        />
      </Text>
      <Controller
        name="vendor"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field, fieldState }) => (
          <FormControl isInvalid={fieldState.invalid} isRequired isDisabled={newVendorCreateIsLoading} marginTop={3}>
            <FormLabel>
              <FormattedMessage
                defaultMessage="Vendor name"
                description="The label for the vendor field in the start workflow modal form"
                id="requests.start.modal.vendor.label"
              />
            </FormLabel>
            <VendorOptionPickerCreatable
              autoFocus
              allowCreate={true}
              value={field.value && { value: field.value }}
              onChange={(vendor) => {
                field.onChange(vendor?.value)
                setValue("tool_or_vendor_owner", null)
                setValue("new_tool_or_vendor_url", null)

                if (!vendor?.value) {
                  setValue("new_tool_or_vendor_display_name", null)
                  return
                }

                if (vendor.value.vendor_listing_id === "new") {
                  // handle the creation of a new tool
                  setValue("new_tool_or_vendor_display_name", vendor.value.display_name)
                } else {
                  setValue("new_tool_or_vendor_display_name", null)
                  setValue("display_name", generateWorkflowDisplayName(vendor.value.display_name, workflowKind, intl))
                }
              }}
              // This vendor picker is rendered in a modal, so we shouldn't make the portal target document body
              menuPortalTarget={undefined}
            />
            {fieldState.error?.message && <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>}
          </FormControl>
        )}
      />
      {isNewVendor && (
        <>
          <Text>
            <FormattedMessage
              id="requests.start.modal.vendor.help_message"
              description="Header for the start workflow modal vendor selection section"
              defaultMessage="{name} will be added as a vendor in your organization"
              values={{ name: newVendorDisplayName }}
            />
          </Text>
          <Controller
            name="new_tool_or_vendor_url"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <FormControl isInvalid={fieldState.invalid} isRequired isDisabled={newVendorCreateIsLoading}>
                <FormLabel>
                  <FormattedMessage
                    defaultMessage="Vendor URL"
                    description="The label for the vendor URL field in the start workflow modal form"
                    id="requests.start.modal.vendor.url.label"
                  />
                </FormLabel>
                <Input
                  autoComplete="off"
                  value={field.value ?? ""}
                  placeholder={intl.formatMessage({
                    id: "requests.start.modal.vendor.create.url.placeholder",
                    description: "Placeholder for the vendor URL input field",
                    defaultMessage: "Please provide the URL of the vendor you are adding...",
                  })}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              </FormControl>
            )}
          />
          {newVendorCreateIsLoading && (
            <Center flex={1} flexDirection="column">
              <Spinner size="md" colorScheme="brand" />
              <FormattedMessage
                id="requests.start.modal.newTool.loading"
                defaultMessage="Loading..."
                description="Request start modal continue button text"
              />
            </Center>
          )}
        </>
      )}

      {selectedVendor && !isNewVendor && (
        <Controller
          name="tool_or_vendor_owner"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={fieldState.invalid} isRequired marginTop={3}>
              <FormLabel>
                <FormattedMessage
                  defaultMessage="Vendor owner"
                  description="The label for the vendor owner field in the start workflow modal form"
                  id="requests.start.modal.vendor.owner.label"
                />
              </FormLabel>
              <BasicOrganizationEntityPicker
                // This is an uncontrolled component so if the owner changes we force re-render to make sure it picks up the new "initialValue"
                key={field.value?.id}
                includedEntities={["person"]}
                initialSelected={field.value}
                onChangePerson={(person) => field.onChange(person)}
                isRequired
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: "var(--chakra-zIndices-modal)" }),
                }}
              />
            </FormControl>
          )}
        />
      )}

      {vendor_listing_id && vendor_listing_id !== "new" && (
        <FormControl marginTop={3} isReadOnly>
          <FormLabel>
            <FormattedMessage
              defaultMessage="Vendor description"
              description="The label for the vendor description field in the start workflow modal form"
              id="requests.start.modal.vendor.description.label"
            />
          </FormLabel>
          <Textarea
            rows={10}
            value={description ?? ""}
            placeholder={intl.formatMessage(
              {
                id: "requests.start.modal.tool.description.placeholder",
                defaultMessage: "No description found for {tool}",
                description: "Placeholder shown when there is no description on a displayed tool",
              },
              { tool: display_name }
            )}
            isReadOnly
          />
        </FormControl>
      )}

      {website && (
        <Flex>
          <Button
            as={Link}
            color="blue.500"
            to={website}
            rightIcon={<Icon as={NextIcon} />}
            variant="link"
            marginTop={2}
          >
            <FormattedMessage
              id="requests.start.modal.tool.website"
              description="Link text to click which navigates user to the website of a tool"
              defaultMessage="Learn more about {website}"
              values={{ website: getDisplayUrl(new URL(website)) }}
            />
          </Button>
        </Flex>
      )}
    </Stack>
  )
}
