import { hasPermission } from "@brm/schema-helpers/role.js"
import { canDeleteUser, canEditUser } from "@brm/schema-helpers/user.js"
import type { Role, UserWithSettings } from "@brm/schema-types/types.js"
import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { createColumnHelper } from "@tanstack/react-table"
import type React from "react"
import { useMemo, useState, type FC } from "react"
import type { IntlShape } from "react-intl"
import { FormattedMessage, useIntl } from "react-intl"
import type { PostUserV1OrganizationApiResponse } from "../../app/services/generated-api.js"
import {
  useGetOrganizationV1CurrentQuery,
  useGetUserV1WhoamiQuery,
  usePostUserV1ResendInviteMutation, // Corrected mutation name
} from "../../app/services/generated-api.js"
import UserCell from "../../components/DataTable/CellRenderer/UserCell.js"
import DataTable from "../../components/DataTable/DataTable.js"
import { FormattedDate } from "../../components/FormattedDate.js"
import { CheckIcon, MoreMenuIcon } from "../../components/icons/icons.js"
import { getRolesToShowFromRoles } from "../../util/user-roles.js"
import { RoleTag } from "../organization/RoleTag.js"
import { getLabelByRoleOrUser } from "../organization/role.js"
import { UserUpdateModal } from "../organization/users/UserUpdateModal.js"
import { DeleteUserConfirmationModal } from "./DeleteUserConfirmationModal.js"

const columnHelper = createColumnHelper<UserWithSettings>()
const getColumns = (
  intl: IntlShape,
  include2FA: boolean,
  actionsComponent: (user: UserWithSettings) => JSX.Element | null
) => [
  columnHelper.accessor("email", {
    header: intl.formatMessage({
      id: "organization.users.table.header.name",
      description: "Organization users table column header for name",
      defaultMessage: "Name",
    }),
    enableColumnFilter: false,
    enableSorting: false,
    size: 200,
    cell: (info) => <UserCell user={info.row.original} />,
  }),
  columnHelper.accessor(
    (row): Array<Role | "user"> => {
      return getRolesToShowFromRoles(row.roles)
    },
    {
      header: intl.formatMessage({
        id: "organization.users.table.header.roles",
        description: "Organization users table column header for roles",
        defaultMessage: "Role",
      }),
      enableColumnFilter: false,
      enableSorting: false,
      size: 350,
      cell: (info) => <RoleTagsWithOverflowSupport tags={info.getValue()} />,
    }
  ),
  ...(include2FA
    ? [
        columnHelper.accessor("totp_enabled", {
          header: intl.formatMessage({
            id: "organization.users.table.header.2fa",
            description: "Organization users table column header for 2fa enabled",
            defaultMessage: "2FA enabled?",
          }),
          enableColumnFilter: false,
          enableSorting: false,
          size: 120,
          cell: (info) => info.getValue() && <Icon as={CheckIcon} />,
        }),
      ]
    : []),
  columnHelper.accessor("created_at", {
    header: intl.formatMessage({
      id: "organization.users.table.header.date_joined",
      description: "Organization users table column header for join date",
      defaultMessage: "Date joined",
    }),
    enableColumnFilter: false,
    enableSorting: false,
    size: 200,
    cell: (info) => {
      if (info.row.original.status === "pending") {
        return intl.formatMessage(
          {
            id: "organization.users.table.value.date_invited",
            description: "Date the user was invited",
            defaultMessage: "Invited on {date}",
          },
          { date: <FormattedDate value={info.getValue()} /> }
        )
      }
      return <FormattedDate value={info.getValue()} />
    },
  }),
  columnHelper.display({
    header: intl.formatMessage({
      id: "invitation.users.table.header.actions",
      description: "Invitation table column header for Actions",
      defaultMessage: "Actions",
    }),
    cell: (item) => actionsComponent(item.row.original),
    size: 70,
  }),
]

export const UserList: React.FunctionComponent<{
  userList?: PostUserV1OrganizationApiResponse
}> = ({ userList }) => {
  const intl = useIntl()
  const collator = useMemo(() => new Intl.Collator(intl.locale), [intl.locale])

  const { data: organization } = useGetOrganizationV1CurrentQuery()
  const { data: user } = useGetUserV1WhoamiQuery()

  const activeUsers = useMemo(() => {
    if (!userList?.users) {
      return []
    }

    return Array.from(userList.users).toSorted((a, b) => {
      if (a.status === "active" && b.status === "active") {
        return 0
      }
      if (a.status === "pending") {
        return 1
      }
      if (b.status === "pending") {
        return -1
      }

      return collator.compare(a.first_name || "", b.first_name || "")
    })
  }, [collator, userList?.users])

  const [userToUpdate, setUserToUpdate] = useState<null | UserWithSettings>(null)

  const deleteUserConfirmationModalState = useDisclosure()
  const [userBeingDeleted, setUserBeingDeleted] = useState<UserWithSettings>()

  const [resendInvite] = usePostUserV1ResendInviteMutation()
  const toast = useToast()

  if (!organization) {
    return null
  }

  const columns = getColumns(intl, !organization.sso_only, (rowUser: UserWithSettings) => (
    <Menu variant="tableCell" isLazy>
      <MenuButton
        as={IconButton}
        variant="ghost"
        icon={<Icon as={MoreMenuIcon} />}
        aria-label={intl.formatMessage({
          defaultMessage: "Actions",
          description: "The ARIA label for the actions dropdown button in the user table",
          id: "organization.users.table.action.actions.label",
        })}
      />
      <MenuList>
        <MenuItem isDisabled={!(user && canEditUser(rowUser, user))} onClick={() => setUserToUpdate(rowUser)}>
          <FormattedMessage
            defaultMessage="Edit"
            description="The label for the edit user button in the user table"
            id="organization.users.table.action.edit.label"
          />
        </MenuItem>
        {rowUser.status === "pending" && hasPermission(user?.roles, "user:update") && (
          <MenuItem
            onClick={async () => {
              try {
                await resendInvite({ body: { userId: rowUser.id } }).unwrap()
                toast({
                  title: intl.formatMessage({
                    defaultMessage: "Invite resent",
                    description: "Toast message when invite is successfully resent",
                    id: "organization.users.table.action.resendInvite.success",
                  }),
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                })
              } catch (_err) {
                toast({
                  title: intl.formatMessage({
                    defaultMessage: "Failed to resend invite",
                    description: "Toast message when invite resend fails",
                    id: "organization.users.table.action.resendInvite.error",
                  }),
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                })
              }
            }}
          >
            <FormattedMessage
              defaultMessage="Resend Invite"
              description="The label for the resend invite button in the user table"
              id="organization.users.table.action.resendInvite.label"
            />
          </MenuItem>
        )}
        <MenuItem
          isDisabled={!(user && canDeleteUser(rowUser, user))}
          onClick={() => {
            setUserBeingDeleted(rowUser)
            deleteUserConfirmationModalState.onOpen()
          }}
        >
          <FormattedMessage
            defaultMessage="Delete"
            description="The label for the delete user button in the user table"
            id="organization.users.table.action.delete.label"
          />
        </MenuItem>
      </MenuList>
    </Menu>
  ))
  return (
    <>
      <Box flexShrink={1} flexGrow={10} overflow="auto">
        <DataTable data={activeUsers} columns={columns} />
      </Box>
      {userToUpdate && (
        <UserUpdateModal user={userToUpdate} isOpen={userToUpdate !== null} onClose={() => setUserToUpdate(null)} />
      )}
      {userBeingDeleted && (
        <DeleteUserConfirmationModal {...deleteUserConfirmationModalState} userBeingDeleted={userBeingDeleted} />
      )}
    </>
  )
}

interface RoleTagsWithOverflowSupportProps {
  tags: Array<Role | "user">
}

const RoleTagsWithOverflowSupport: FC<RoleTagsWithOverflowSupportProps> = (props) => {
  const maxTagsToShow: number = 3
  const intl = useIntl()
  const labelByRole = getLabelByRoleOrUser(intl)
  const isOverflown = props.tags.length > maxTagsToShow

  return (
    <Box overflow="hidden">
      {props.tags.slice(0, maxTagsToShow).map((role) => (
        <RoleTag role={role} key={role} />
      ))}
      {isOverflown && (
        <Tooltip
          label={isOverflown && intl.formatList(props.tags.slice(maxTagsToShow).map((role) => labelByRole[role]))}
          isDisabled={!isOverflown}
          placement="top-end"
        >
          <Tag variant="outline" mr={1}>
            <FormattedMessage
              defaultMessage="+{numberOfTags}"
              description="The number of tags that are hidden currently hidden"
              id="organization.users.table.roleTags.overflow.label"
              values={{ numberOfTags: props.tags.length - maxTagsToShow }}
            />
          </Tag>
        </Tooltip>
      )}
    </Box>
  )
}
