import type { DateString, FieldMetadata } from "@brm/schema-types/types.js"
import { legacyDateToPlainDate, localeFirstDayOfWeek } from "@brm/util/date-time.js"
import { Circle, Icon } from "@chakra-ui/react"
// chakra-dayzed-datepicker requires date-fns library: https://github.com/aboveyunhai/chakra-dayzed-datepicker?tab=readme-ov-file#install-the-dependency
import { CalendarPanel, Month_Names_Short, Weekday_Names_Short } from "chakra-dayzed-datepicker"
import { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { ChevronLeftDoubleIcon, ChevronLeftIcon, ChevronRightDoubleIcon, ChevronRightIcon } from "../icons/icons.js"

const Calendar = ({
  onChange,
  value,
  minDate,
  maxDate,
}: {
  onChange: (value: DateString | null, fieldSource?: FieldMetadata) => void
  // chakra-dayzed-datepicker library requires a Date object
  // eslint-disable-next-line no-restricted-globals
  value: Date | undefined
  /** Dates before this value will be disabled */
  // eslint-disable-next-line no-restricted-globals
  minDate?: Date
  /** Dates after this value will be disabled */
  // eslint-disable-next-line no-restricted-globals
  maxDate?: Date
}) => {
  // eslint-disable-next-line no-restricted-globals
  const today = useMemo(() => new Date(), [])
  const intl = useIntl()
  const [offset, setOffset] = useState(0)
  const stringValue = value?.toString()
  useEffect(() => {
    if (stringValue) {
      setOffset(0)
    }
  }, [stringValue])

  /** The first day of the week with 1 = Monday, 7 = Sunday. Default to Sunday if we don't have locale info. */
  const firstDayOfWeek = useMemo(() => localeFirstDayOfWeek(intl.locale) ?? 7, [intl.locale])
  return (
    <CalendarPanel
      dayzedHookProps={{
        showOutsideDays: true,
        onDateSelected: ({ date }) => {
          onChange(legacyDateToPlainDate(date).toString())
        },
        selected: value,
        date: value,
        minDate,
        maxDate,
        offset,
        onOffsetChanged: setOffset,
      }}
      configs={{
        dateFormat: "YYYY-MM-DD",
        monthNames: Month_Names_Short,
        dayNames: Weekday_Names_Short,
        // chakra-dayzed-datepicker uses 0..6 with 0 = Sunday, 6 = Saturday
        // Intl.Locale's and Temporal uses 1..7 with 1 = Monday, 7 = Sunday
        firstDayOfWeek: (firstDayOfWeek % 7) as 0 | 1 | 2 | 3 | 4 | 5 | 6,
      }}
      propsConfigs={{
        dateHeadingProps: {
          fontSize: "md",
          // Fix width so arrows don't move around when switching months
          width: "70px",
        },
        calendarPanelProps: {
          contentProps: {
            border: 0,
            padding: 2,
          },
          dividerProps: {
            display: "none",
          },
          headerProps: {
            justifyContent: "space-between",
            width: "full",
          },
          wrapperProps: {
            width: "auto",
            justifyContent: "center",
          },
          bodyProps: {
            // Fix height so calendar doesn't jump around when switching months
            height: "230px",
          },
        },
        dateNavBtnProps: {
          variant: "ghost",
          color: "gray.500",
          size: "md",
          sx: {
            // Hide the default "<<" "<" ">" ">>" text content
            fontSize: 0,
            ".date-nav-icon": {
              fontSize: "initial",
            },
            // The props are shared between all four nav buttons, so we use CSS to show the correct icon for each.
            "&:nth-of-type(1) .date-nav-icon:not(.left-double)": {
              display: "none",
            },
            "&:nth-of-type(2) .date-nav-icon:not(.left)": {
              display: "none",
            },
            "&:nth-of-type(3) .date-nav-icon:not(.right)": {
              display: "none",
            },
            "&:nth-of-type(4) .date-nav-icon:not(.right-double)": {
              display: "none",
            },
          },
          leftIcon: (
            <>
              <Icon as={ChevronLeftDoubleIcon} className="date-nav-icon left-double" />
              <Icon as={ChevronLeftIcon} className="date-nav-icon left" />
              <Icon as={ChevronRightIcon} className="date-nav-icon right" />
              <Icon as={ChevronRightDoubleIcon} className="date-nav-icon right-double" />
            </>
          ),
          iconSpacing: 0,
        },
        weekdayLabelProps: {
          height: "30px",
          width: "30px",
          alignContent: "center",
          fontWeight: "medium",
        },
        dayOfMonthBtnProps: {
          defaultBtnProps: {
            borderRadius: "full",
            fontWeight: "normal",
            alignItems: "center",
            _hover: {
              backgroundColor: "gray.50",
            },
            height: "30px",
            width: "30px",
          },
          selectedBtnProps: {
            backgroundColor: "brand.700",
            color: "white",
            _hover: {
              backgroundColor: "brand.700",
            },
          },
          todayBtnProps: {
            flexDirection: "column",
            rightIcon: (
              <Circle
                bgColor={today.getDate() === value?.getDate() ? "white" : "brand.700"}
                style={{
                  width: "4px",
                  height: "4px",
                  position: "absolute",
                  left: "Calc(50% - 2.5px)",
                  bottom: "4px",
                }}
              />
            ),
          },
        },
      }}
    />
  )
}

export default Calendar
