import type { DocumentElement } from "@brm/schema-types/types.js"
import { Box } from "@chakra-ui/react"
import { useSelected, type RenderElementProps } from "slate-react"
import { DocumentDisplay } from "../Document/DocumentDisplay.js"
import type { ExtendedElementProps } from "./types.js"

export const Document = ({
  attributes,
  children,
  element,
  getDocumentUrl,
}: RenderElementProps & {
  element: DocumentElement
  getDocumentUrl?: (documentId: string) => string
} & ExtendedElementProps) => {
  const selected = useSelected()
  const url = getDocumentUrl?.(element.id)
  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false}>
        <Box
          maxWidth="350px"
          height="auto"
          margin={2}
          padding={2}
          borderRadius={4}
          outline={selected ? "2px solid var(--chakra-colors-brand-500)" : "none"}
          cursor={url ? "pointer" : undefined}
          onClick={() => {
            if (!url) return
            window.open(url, "_blank")
          }}
        >
          <DocumentDisplay
            document={{
              id: element.id,
              file_name: element.file_name,
              mime_type: element.mime_type,
              status: "uploaded",
            }}
            to={url}
          />
        </Box>
      </div>
    </div>
  )
}
