import { displayPersonName } from "@brm/util/names.js"
import {
  Avatar,
  Button,
  Flex,
  Icon,
  IconButton,
  Spacer,
  Tooltip,
  chakra,
  forwardRef,
  useDisclosure,
  type ButtonProps,
  type StyleProps,
} from "@chakra-ui/react"
import type { FC, ReactElement, ReactNode } from "react"
import { useHotkeys } from "react-hotkeys-hook"
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useLocalStorage } from "usehooks-ts"
import { useGetNotificationV1UnreadQuery, useGetUserV1WhoamiQuery } from "../../app/services/generated-api.js"
import StartWorkflowModal from "../../features/workflows/run/start/StartWorkflowModal.js"
import { getPublicImageGcsUrl } from "../../util/url.js"
import BraimIcon from "../BraimIcon.js"
import BrmLogo from "../BrmLogo.js"
import { Link } from "../Link.js"
import { RevealingButtonGroup, RevealingIconButton } from "../RevealingIconButton.js"
import LayoutLeftFilledIcon from "../icons/LayoutLeftFilledIcon.js"
import {
  AgreementIcon,
  CalendarIcon,
  DotIcon,
  InboxIcon,
  LayoutLeftIcon,
  PlusIcon,
  RequestIcon,
  SecurityIcon,
  SettingsIcon,
  SupportChatIcon,
  SupportGuidesIcon,
  ToolsIcon,
  UserIcon,
  UsersIcon,
  VendorIcon,
} from "../icons/icons.js"
import { ModifierKey } from "../labels/shortcuts.js"
import { NAVBAR_Z_INDEX } from "./constants.js"

const SIDEBAR_EXPANDED_KEY = "sidebar-expanded"

const opacityProps = (isExpanded: boolean): StyleProps => ({
  opacity: isExpanded ? 1 : 0,
  transition: "opacity 0.3s ease-in-out",
})

const NavButton = forwardRef<ButtonProps, "button">(function NavButton(props, ref) {
  return <Button variant="ghost" justifyContent="flex-start" gap={1} flexShrink={0} {...props} ref={ref} />
})

const NavLink: FC<
  ButtonProps & { to?: string; onClick?: () => void; label: ReactNode; icon: ReactElement; isExpanded: boolean }
> = (props) => {
  const location = useLocation()
  const { to, onClick, label, icon, isExpanded, ...buttonProps } = props
  return (
    <Tooltip label={label} placement="right" isDisabled={isExpanded}>
      {to ? (
        <NavButton
          as={Link}
          to={to}
          aria-current={location.pathname.startsWith(to) ? "page" : undefined}
          flexShrink={0}
          {...buttonProps}
        >
          {icon}
          <chakra.span {...opacityProps(isExpanded)}>{label}</chakra.span>
        </NavButton>
      ) : (
        <NavButton onClick={onClick} flexShrink={0} {...buttonProps}>
          {icon}
          <chakra.span {...opacityProps(isExpanded)}>{label}</chakra.span>
        </NavButton>
      )}
    </Tooltip>
  )
}

export default function NavSideBar() {
  const intl = useIntl()
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { data: unreadQueryResult } = useGetNotificationV1UnreadQuery()

  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()
  const [isExpanded, setIsExpanded] = useLocalStorage(SIDEBAR_EXPANDED_KEY, true)

  const startWorkflowModal = useDisclosure()

  useHotkeys(
    ["shift+mod+k"],
    () => {
      navigate("/braim")
    },
    { enableOnFormTags: ["input", "select", "textarea"] }
  )

  const newWorkflowLabel = intl.formatMessage({
    id: "Sor.nav.newRequest",
    description: "Sor nav link to create a new request",
    defaultMessage: "Create new request",
  })
  const addAgreementLabel = intl.formatMessage({
    defaultMessage: "Add agreement",
    description: "Sor nav link to create a new agreement",
    id: "Sor.nav.newAgreement",
  })

  return (
    <Flex
      flexDir="column"
      as="nav"
      zIndex={NAVBAR_Z_INDEX}
      width={isExpanded ? "10rem" : "42px"}
      transition="width 0.3s ease-in-out"
      borderRightWidth={1}
    >
      <Flex overflow="hidden" alignItems="center" justifyContent="space-between" px={1} pt={2}>
        <Link
          flexShrink={0}
          to="/"
          aria-label={intl.formatMessage({
            id: "navHeader.homeLink",
            description: "Label for the logo that links to the home page",
            defaultMessage: "Home",
          })}
        >
          <BrmLogo />
        </Link>
        <chakra.span {...opacityProps(isExpanded)}>
          <Tooltip
            label={intl.formatMessage({
              id: "nav.expandSidebar",
              description: "Tooltip for the button that collapses the navigation sidebar",
              defaultMessage: "Collapse navigation",
            })}
            placement="right"
          >
            <IconButton
              aria-label={intl.formatMessage({
                id: "nav.expandSidebar.ariaLabel",
                description: "Aria label for the button that collapses the navigation sidebar",
                defaultMessage: "Collapse navigation sidebar",
              })}
              variant="ghost"
              flexShrink={0}
              icon={<Icon as={LayoutLeftFilledIcon} />}
              onClick={() => setIsExpanded(false)}
            />
          </Tooltip>
        </chakra.span>
      </Flex>
      <Flex
        flexDir="column"
        gap={2}
        flexGrow={1}
        overflowY="auto"
        p={1}
        py={2}
        sx={{
          scrollbarWidth: "none",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        <NavLink
          to="/inbox"
          label={intl.formatMessage({
            id: "Sor.nav.inbox",
            description: "Sor nav link to view inbox",
            defaultMessage: "Inbox",
          })}
          icon={
            <>
              <Icon
                height={4}
                viewBox="0 0 200 200"
                color="brand.300"
                position="absolute"
                left={3.5}
                bottom={3.5}
                opacity={unreadQueryResult?.unread ? 1 : 0}
                as={DotIcon}
                transition="opacity .3s ease-in-out"
              />
              <Icon as={InboxIcon} />
            </>
          }
          isExpanded={isExpanded}
        />
        <RevealingButtonGroup>
          <NavLink
            to="/requests"
            label={intl.formatMessage({
              id: "Sor.nav.requests",
              description: "Sor nav link to view requests",
              defaultMessage: "Requests",
            })}
            icon={<Icon as={RequestIcon} />}
            isExpanded={isExpanded}
            flexShrink={isExpanded ? 0 : 1}
            flexGrow={isExpanded ? 1 : 0}
          />
          {isExpanded && (
            <Tooltip label={newWorkflowLabel} openDelay={200}>
              <RevealingIconButton
                icon={<Icon as={PlusIcon} />}
                aria-label={newWorkflowLabel}
                {...startWorkflowModal.getButtonProps()}
              />
            </Tooltip>
          )}
        </RevealingButtonGroup>
        {startWorkflowModal.isOpen && (
          <StartWorkflowModal
            {...startWorkflowModal}
            // This modal is triggered from a sidebar button that is revealed on hover. Returning focus to that button causes
            // broken tooltip rendering behavior on a now-hidden button
            returnFocusOnClose={false}
            toolId={location.pathname.startsWith("/tools/") && id ? id : undefined}
          />
        )}
        <NavLink
          to="/renewal-calendar"
          label={intl.formatMessage({
            id: "Sor.nav.renewalCalendar",
            description: "Sor nav link to view renewal calendar",
            defaultMessage: "Renewals",
          })}
          icon={<Icon as={CalendarIcon} />}
          isExpanded={isExpanded}
        />
        <NavLink
          to="/tools"
          label={intl.formatMessage({
            id: "Sor.nav.tools",
            description: "Sor nav link to view tool list",
            defaultMessage: "Tools",
          })}
          icon={<Icon as={ToolsIcon} />}
          isExpanded={isExpanded}
        />
        <NavLink
          to="/vendors"
          label={intl.formatMessage({
            id: "Sor.nav.vendors",
            description: "Sor nav link to view vendor list",
            defaultMessage: "Vendors",
          })}
          icon={<Icon as={VendorIcon} />}
          isExpanded={isExpanded}
        />
        <RevealingButtonGroup>
          <NavLink
            to="/agreements"
            label={intl.formatMessage({
              id: "Sor.nav.agreements",
              description: "Sor nav link to view legal agreements list",
              defaultMessage: "Agreements",
            })}
            icon={<Icon as={AgreementIcon} />}
            isExpanded={isExpanded}
            flexShrink={isExpanded ? 0 : 1}
            flexGrow={isExpanded ? 1 : 0}
          />
          {isExpanded && (
            <Tooltip label={addAgreementLabel} openDelay={200}>
              <RevealingIconButton
                as={Link}
                to="/agreements#upload=true"
                icon={<Icon as={PlusIcon} />}
                aria-label={addAgreementLabel}
              />
            </Tooltip>
          )}
        </RevealingButtonGroup>
        <NavLink
          to="/people"
          label={intl.formatMessage({
            id: "Sor.nav.people",
            description: "Sor nav link to view people",
            defaultMessage: "People",
          })}
          icon={<Icon as={UsersIcon} />}
          isExpanded={isExpanded}
        />
        <NavLink
          to="/security"
          label={intl.formatMessage({
            id: "Sor.nav.security",
            description: "Sor nav link to view security",
            defaultMessage: "Security",
          })}
          icon={<Icon as={SecurityIcon} />}
          isExpanded={isExpanded}
        />
        <NavLink
          to="/braim"
          label={
            <FormattedMessage
              id="navHeader.braim.shortcut"
              description="Label for the logo that links to the braim page"
              defaultMessage="Ask <blue>AI</blue> &nbsp;<light>{modifierKey}+⇧+K</light>"
              values={{
                blue: (chunks) => <chakra.span color="blueLight.700">{chunks}</chakra.span>,
                modifierKey: <ModifierKey />,
                light: (chunks) => (
                  <chakra.span fontWeight="light" color="gray.500">
                    {chunks}
                  </chakra.span>
                ),
              }}
            />
          }
          icon={<BraimIcon />}
          isExpanded={isExpanded}
        />
        {!isExpanded && (
          <Flex justifyContent="start">
            <Tooltip
              label={intl.formatMessage({
                id: "nav.expandSidebar",
                description: "Tooltip for the button that expands the navigation sidebar",
                defaultMessage: "Expand navigation",
              })}
              placement="right"
            >
              <IconButton
                aria-label={intl.formatMessage({
                  id: "nav.expandSidebar.ariaLabel",
                  description: "Aria label for the button that expands the navigation sidebar",
                  defaultMessage: "Expand navigation sidebar",
                })}
                variant="ghost"
                flexShrink={0}
                icon={<Icon as={LayoutLeftIcon} />}
                onClick={() => setIsExpanded(true)}
              />
            </Tooltip>
          </Flex>
        )}
        {/* Middle gap between primary navigation items and settings/support */}
        <Spacer />
        <NavLink
          label={intl.formatMessage({
            id: "nav.menu.helpCenter",
            description: "Navigation menu item link to help center",
            defaultMessage: "Help center",
          })}
          icon={<Icon as={SupportGuidesIcon} />}
          isExpanded={isExpanded}
          onClick={() => {
            window.open("https://support.brm.ai", "_blank", "noopener")
            window.Pylon?.("hide")
          }}
        />
        <NavLink
          label={intl.formatMessage({
            id: "nav.menu.chat",
            description: "Navigation menu item link to chat with support for help",
            defaultMessage: "Chat with support",
          })}
          icon={<Icon as={SupportChatIcon} />}
          isExpanded={isExpanded}
          onClick={() => {
            window.Pylon?.("show")
          }}
        />
        <NavLink
          to="/settings"
          label={intl.formatMessage({
            id: "Sor.nav.settings",
            description: "Sor nav link to view settings",
            defaultMessage: "Settings",
          })}
          icon={<Icon as={SettingsIcon} />}
          isExpanded={isExpanded}
        />
      </Flex>
      {/* User */}
      <Flex gap={4} p={2} pt={4} borderTopWidth={1} alignItems="center" whiteSpace="nowrap">
        <Link to="/settings/profile" display="contents">
          <Avatar
            src={getPublicImageGcsUrl(whoami?.profile_image?.gcs_file_name)}
            icon={<Icon as={UserIcon} />}
            boxSize={6}
          />
          <Flex direction="column" flexGrow={1} minW={0} {...opacityProps(isExpanded)}>
            <chakra.span fontWeight="medium" textOverflow="ellipsis" overflow="hidden">
              {whoami && displayPersonName(whoami, intl)}
            </chakra.span>
            <chakra.span color="muted" textOverflow="ellipsis" overflow="hidden">
              {whoami?.email}
            </chakra.span>
          </Flex>
        </Link>
      </Flex>
    </Flex>
  )
}
