import type { FieldConfig } from "@brm/schema-types/types.js"
import { FieldCategorySchema } from "@brm/schemas"
import { getFieldMapKey } from "@brm/type-helpers/field.js"
import { dereferenceSchema, getTitle } from "@brm/util/schema.js"
import { Badge, Box, Flex, Heading, Tooltip } from "@chakra-ui/react"
import type { JSONSchema, JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { type FunctionComponent, useMemo } from "react"
import { FormattedMessage } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import { RenderedMarkdown } from "../../../components/RenderedMarkdown.js"
import { CriterionProvenance } from "./CriterionProvenance.js"

export const CriterionListItem: FunctionComponent<{
  criterion: FieldConfig
  defs: Record<string, ReadonlyDeep<JSONSchema>>
  onSelect: (field: FieldConfig) => void
  isActive: boolean
}> = ({ criterion, defs, onSelect, isActive }) => {
  const dereferencedConfig = useMemo(() => {
    const schema = dereferenceSchema(criterion.field_schema as JSONSchemaObject, defs) as JSONSchemaObject
    return { ...criterion, field_schema: schema }
  }, [criterion, defs])

  const title = getTitle(criterion.field_name, dereferencedConfig.field_schema)
  const description = dereferencedConfig.field_schema.uiDescription ?? dereferencedConfig.field_schema.description

  const categorySchema = FieldCategorySchema.anyOf.find((category) => category.const === criterion.category)

  const isDisabled = criterion.is_enabled === false
  const disabledColor = "gray.800"

  return (
    <Box
      as="button"
      onClick={() => onSelect(dereferencedConfig)}
      textAlign="left"
      width="100%"
      p={2}
      borderRadius="md"
      _hover={{ bg: "gray.100" }}
      bg={isActive ? "gray.100" : "transparent"}
      opacity={isDisabled ? 0.5 : 1}
    >
      <Flex flexDir="column" gap={0.5} minW={0}>
        <Flex alignItems="center" gap={1} minW={0}>
          <Heading
            size="xxs"
            isTruncated
            mr={1}
            id={getFieldMapKey(criterion)}
            color={isDisabled ? disabledColor : "inherit"}
          >
            {title}
          </Heading>
          {criterion.category && (
            <Badge colorScheme={categorySchema?.colorScheme} variant="subtleOutlined">
              {getTitle(criterion.category, categorySchema)}
            </Badge>
          )}
          {criterion.is_custom && (
            <Tooltip
              label={
                <Box textAlign="center">
                  <CriterionProvenance fieldConfig={criterion} />
                </Box>
              }
            >
              <Badge colorScheme="blue" variant="subtleOutlined">
                <FormattedMessage
                  defaultMessage="Custom"
                  description="Badge for custom criterion"
                  id="settings.criteria.badge.custom"
                />
              </Badge>
            </Tooltip>
          )}
          {isDisabled && (
            <Badge colorScheme="gray" variant="subtle">
              <FormattedMessage
                defaultMessage="Disabled"
                description="Badge for disabled criterion"
                id="settings.criteria.badge.disabled"
              />
            </Badge>
          )}
        </Flex>
        <Box noOfLines={1} color={isDisabled ? disabledColor : "gray.600"} fontSize="sm">
          {description && <RenderedMarkdown content={description} />}
        </Box>
      </Flex>
    </Box>
  )
}
