import { hasPermission } from "@brm/schema-helpers/role.js"
import type { Person } from "@brm/schema-types/types.js"
import { useToast } from "@chakra-ui/react"
import { memo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import type { PersonPicker } from "../../../app/services/generated-api.js"
import {
  useGetUserV1WhoamiQuery,
  usePatchToolV1ByIdMutation,
  usePatchVendorV1ByIdMutation,
  usePostOrganizationV1PickableEntitiesQuery,
} from "../../../app/services/generated-api.js"
import { UserPersonEntityCell } from "../../UserPersonEntityCell.js"

interface Props {
  owner: Person | null
  objectType: "Tool" | "Vendor"
  objectId: string
  showTooltip?: boolean
  subText?: string
  /** By default this will have a delay of SHORT_TOOLTIP_OPEN_DELAY */
  tooltipOpenDelay?: number
}
const arePropsEqual = (prevProps: Props, nextProps: Props) =>
  prevProps.owner?.id === nextProps.owner?.id &&
  prevProps.objectId === nextProps.objectId &&
  prevProps.showTooltip === nextProps.showTooltip &&
  prevProps.subText === nextProps.subText &&
  prevProps.tooltipOpenDelay === nextProps.tooltipOpenDelay

/**
 * Extension of PersonCell that takes in a Person who is the owner of a tool and allows editing of the tool owner.
 *
 * Note: Though similar to OrganizationEntityPicker for Person, this component has an intentionally different style that uses Menu instead of a Select.
 * It also is only a clickable MenuButton when depending on the current user's permissions and is a link to the Person's detail page otherwise.
 */
export default memo(function OwnerCell({ owner, objectType, objectId, showTooltip, tooltipOpenDelay }: Props) {
  const intl = useIntl()
  const toast = useToast()

  const [updateToolOwner] = usePatchToolV1ByIdMutation()
  const [updateVendorOwner] = usePatchVendorV1ByIdMutation()
  const { data: whoami } = useGetUserV1WhoamiQuery()

  const { data: pickablePersons } = usePostOrganizationV1PickableEntitiesQuery({
    body: {
      filter: {
        entities: ["person"],
      },
    },
  })
  const validNewOwners = pickablePersons as PersonPicker[] | undefined

  const canEdit =
    (owner && owner.user_id === whoami?.id) ||
    hasPermission(whoami?.roles, objectType === "Tool" ? "tool:update" : "vendor:update")

  const updateOwner = async (person: PersonPicker | null) => {
    if (objectType === "Tool") {
      try {
        await updateToolOwner({
          id: objectId,
          toolPatch: { id: objectId, object_type: "Tool", owner: person },
        }).unwrap()
        toast({
          status: "success",
          description: person
            ? intl.formatMessage({
                id: "tool.owner.update.success",
                description: "Toast shown when the tool owner is successfully updated",
                defaultMessage: "Tool owner updated",
              })
            : intl.formatMessage({
                id: "tool.owner.remove.success",
                description: "Toast shown when the tool owner is successfully removed",
                defaultMessage: "Tool owner removed",
              }),
        })
      } catch {
        toast({
          status: "error",
          description: intl.formatMessage({
            id: "tool.owner.update.error",
            description: "Toast shown when there is an error updating tool owner",
            defaultMessage: "Failed to update tool owner",
          }),
        })
      }
    } else {
      try {
        await updateVendorOwner({
          id: objectId,
          vendorPatch: { id: objectId, object_type: "Vendor", owner: person },
        }).unwrap()
        toast({
          status: "success",
          description: person
            ? intl.formatMessage({
                id: "vendor.owner.update.success",
                description: "Toast shown when the vendor owner is successfully updated",
                defaultMessage: "Vendor owner updated",
              })
            : intl.formatMessage({
                id: "vendor.owner.remove.success",
                description: "Toast shown when the vendor owner is successfully removed",
                defaultMessage: "Vendor owner removed",
              }),
        })
      } catch {
        toast({
          status: "error",
          description: intl.formatMessage({
            id: "vendor.owner.update.error",
            description: "Toast shown when there is an error updating vendor owner",
            defaultMessage: "Failed to update vendor owner",
          }),
        })
      }
    }
  }

  const pickableOwnerEntity: PersonPicker | null = owner
    ? { ...owner, type: "person", image_asset: owner.profile_image }
    : null

  return (
    <UserPersonEntityCell
      canEdit={canEdit}
      entity={pickableOwnerEntity}
      showTooltip={showTooltip}
      toolTipLabel={
        <FormattedMessage
          id="tool.header.owner"
          description="Tooltip explaining the person shown there is the owner of the tool"
          defaultMessage="Change Owner"
        />
      }
      onSelect={async (pickableEntity) => {
        if (pickableEntity !== null && pickableEntity?.type !== "person") {
          throw new Error("Unexpected entity type")
        }
        await updateOwner(pickableEntity)
      }}
      pickableEntities={validNewOwners}
      tooltipOpenDelay={tooltipOpenDelay}
      noEntitySelectedLabel={intl.formatMessage({
        defaultMessage: "No owner",
        id: "owner.display.none",
        description: "Text shown when there is no owner for a tool",
      })}
      searchForEntityLabel={intl.formatMessage({
        id: "owner.search.placeholder",
        description: "Placeholder text for the search input when changing the owner",
        defaultMessage: "Search for the new owner",
      })}
    />
  )
}, arePropsEqual)
