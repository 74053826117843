import { Alert, AlertDescription, Stack, type StackProps, type SystemStyleObject } from "@chakra-ui/react"
import { ErrorBoundary } from "react-error-boundary"
import { useSessionStorage } from "usehooks-ts"
import { PAGE_PADDING_X } from "../util/constant.js"

interface Props {
  hideStorageKey: string
  children: React.ReactNode
  sx?: SystemStyleObject
  width?: StackProps["width"]
}
export default function SessionStorageSidebar({ hideStorageKey, children, sx, width }: Props) {
  const [hideTimeline] = useSessionStorage(hideStorageKey, false)

  if (hideTimeline) {
    return null
  }

  return (
    <Stack
      width={width ?? "384px"}
      minH={0}
      pl={PAGE_PADDING_X}
      pt={4}
      flexShrink={0}
      height="100%"
      isolation="isolate"
      background="white"
      borderLeft="1px solid"
      borderColor="gray.200"
      sx={sx}
    >
      <ErrorBoundary
        fallbackRender={({ error }) => (
          <Alert variant="error">
            <AlertDescription>{error.message}</AlertDescription>
          </Alert>
        )}
      >
        {children}
      </ErrorBoundary>
    </Stack>
  )
}
