import type { Transaction, TransactionMinimal, TransactionSummaryResponse } from "@brm/schema-types/types.js"
import type { BRMPaths } from "@brm/type-helpers/paths.js"
import { useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import DataTable from "../../../components/DataTable/DataTable.js"
import EmptyTableState from "../../../components/DataTable/EmptyTableState.js"
import { useSchemaColumns } from "../../../components/DataTable/use-schema-columns.js"
import { matchesLegalAgreementPath } from "../../../util/json-schema.js"
import type { TableParamsState } from "../../../util/schema-table.js"
import { useObjectSchema } from "../../../util/use-schema.js"

const defaultColumns: BRMPaths<Transaction>[] = ["transacted_at", "currency_amount", "person", "receipts"]

export default function AssociatedTransactions({
  transactionData,
  isFetching,
  tableParams,
  setTableParams,
}: {
  transactionData?: TransactionSummaryResponse
  isFetching?: boolean
  tableParams: TableParamsState<string>
  setTableParams: (params: Partial<TableParamsState<string>>) => void
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const intl = useIntl()
  const hashParams = new URLSearchParams(location.hash.slice(1))
  const transactionSchema = useObjectSchema("ReconciledTransaction")
  const columns = useSchemaColumns<TransactionMinimal>({
    objectSchema: transactionSchema,
    shownColumns: defaultColumns,
    enableSorting: true,
  })
  if (!transactionSchema || !transactionData || !columns) {
    return null
  }
  const { transactions, total } = transactionData

  return transactions && transactions.length > 0 ? (
    <DataTable<TransactionMinimal>
      borderWidth="1px"
      overflow="auto"
      data={transactions}
      sorting={tableParams.sorting}
      onSortingChange={(sort) => {
        const newSorting = sort instanceof Function ? sort(tableParams.sorting) : tableParams.sorting
        setTableParams?.({ sorting: newSorting })
      }}
      columns={columns}
      onRowClick={(transaction) => {
        const hash = new URLSearchParams(hashParams)
        hash.set("transaction", transaction.id)
        hash.delete("document")
        return navigate(
          {
            search: matchesLegalAgreementPath(location.pathname) ? location.search : undefined,
            hash: `#${hash}`,
          },
          { state: location.state }
        )
      }}
      paginationProps={{
        page: tableParams.page,
        pageSize: tableParams.pageSize,
        onPageChange: (page) => setTableParams({ page }),
        onPageSizeChange: (pageSize) => setTableParams({ pageSize }),
        totalListElements: total,
      }}
    />
  ) : (
    !isFetching && (
      <EmptyTableState
        emptyState={intl.formatMessage({
          id: "tool.payments.emptyState",
          description: "Text to display instead of the payments table when there are no payments for the tool",
          defaultMessage:
            "No transaction data found. This tool is free or your payment method is not integrated with BRM",
        })}
      />
    )
  )
}
