import type { CurrencyAmount, FrequencyOrOneTime } from "@brm/schema-types/types.js"
import { Text } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import MoneyCell from "../../../components/DataTable/CellRenderer/MoneyCell.js"
import { EmptySymbol } from "../../../components/EmptySymbol.js"
import { FormattedDuration } from "../../../components/FormattedDate.js"

interface Props {
  requested_spend: CurrencyAmount | null
  requested_spend_frequency: FrequencyOrOneTime | null
  requested_payments_count: number | null
}

export default function RequestedSpend({ requested_spend, requested_spend_frequency }: Props) {
  if (!requested_spend) {
    return <EmptySymbol />
  }

  return (
    <Text>
      {!requested_spend_frequency || requested_spend_frequency === "one_time" ? (
        <MoneyCell currencyAmount={requested_spend} />
      ) : (
        <FormattedMessage
          id="requestedSpend.perFrequency"
          description="Shows the requested spend amount per frequency (e.g. $100 per month)"
          defaultMessage="{amount} per {frequency}"
          values={{
            amount: <MoneyCell currencyAmount={requested_spend} />,
            frequency: <FormattedDuration value={requested_spend_frequency} />,
          }}
        />
      )}
    </Text>
  )
}
