import type { TimelineEvent } from "@brm/schema-types/types.js"
import { Box, Grid } from "@chakra-ui/react"
import React from "react"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps } from "../../features/workflows/run/utils.js"
import type { StreamingData } from "../../util/hooks/streaming.js"
import { TimelineEventListItem } from "./TimelineEventListItem.js"

export interface FieldTimelineProps {
  timelineEvents: TimelineEvent[]
  streaming?: StreamingData
  isReadOnly?: boolean
}

const FieldTimeline = React.memo(function FieldTimeline({
  timelineEvents,
  getLogoToShowByOrganizationId,
  getOrganizationActorWhenActorMissing,
  streaming,
  isReadOnly,
}: FieldTimelineProps & GetLogoForOrganizationProps & GetOrganizationActorProps) {
  if (timelineEvents.length === 0) {
    return null
  }
  return (
    <Box fontSize="sm">
      <Grid as="ul" templateColumns="[avatar] auto [body] minmax(0, 1fr)" columnGap={2}>
        {timelineEvents?.map((event, index) => (
          <TimelineEventListItem
            key={event.id + event.updated_at}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
            event={event}
            nextEvent={timelineEvents[index + 1]}
            enableDeeplinking={true}
            streaming={streaming}
            isReadOnly={isReadOnly}
          />
        ))}
      </Grid>
    </Box>
  )
})

export default FieldTimeline
