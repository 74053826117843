import { Box, Tooltip, type TextProps } from "@chakra-ui/react"
import { useRef } from "react"

export default function OverflownText({ children, ...props }: TextProps) {
  const ref = useRef<HTMLDivElement>(null)
  const isOverflown = ref.current ? ref.current.scrollWidth > ref.current.clientWidth : false

  return (
    <Tooltip label={children} isDisabled={!isOverflown}>
      <Box position="relative" minW={0} isTruncated ref={ref} {...props}>
        {children}
      </Box>
    </Tooltip>
  )
}
