import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system"

const baseStyle = defineStyle({
  lineHeight: "inherit",
  height: "1lh",
  width: "1lh",
  verticalAlign: "text-bottom",
})

export const iconTheme = defineStyleConfig({
  baseStyle,
})
