import type { DocumentMinimal, Receipt, Transaction } from "@brm/schema-types/types.js"

/**
 * Returns the URL to link to of a document that was just uploaded by the user.
 */
export const ownDocumentDownloadUrl = (document: DocumentMinimal): string =>
  new URL(`/document/v1/own/${document.id}/content`, import.meta.env.VITE_API_BASE_URL).href

/**
 * Returns the URL to link to for downloading a receipt document for the given transaction.
 */
export const transactionReceiptDocumentDownloadUrl = (transaction: Pick<Transaction, "id">, receipt: Receipt): string =>
  new URL(
    `/transaction/v1/${transaction.id}/receipts/${receipt.id}/document/content`,
    import.meta.env.VITE_API_BASE_URL
  ).href

/**
 * Returns the URL to link to for downloading a compliance document
 */

export const toolComplianceDocumentDownloadUrl = (toolId: string, fieldPath: string) =>
  new URL(`/tool/v1/${toolId}/${fieldPath}/content`, import.meta.env.VITE_API_BASE_URL).href

export const timelineEventDocumentDownloadUrl = ({ eventId, documentId }: { eventId: string; documentId: string }) =>
  new URL(`/timeline/v1/events/${eventId}/document/${documentId}/content`, import.meta.env.VITE_API_BASE_URL).href

export const workflowDocumentDownloadUrl = ({
  workflowRunId,
  documentId,
}: {
  workflowRunId: string
  documentId: string
}) =>
  new URL(`/workflow/v1/runs/${workflowRunId}/documents/${documentId}/content`, import.meta.env.VITE_API_BASE_URL).href

export const imageDownloadUrl = (imageAssetId: string) =>
  new URL(`/image-asset/v1/${imageAssetId}/content`, import.meta.env.VITE_API_BASE_URL).href

export const userDocumentDownloadUrl = (documentId: string) =>
  new URL(`/user/v1/documents/${documentId}/content`, import.meta.env.VITE_API_BASE_URL).href
