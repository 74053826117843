import { getCurrentApprovalStep } from "@brm/schema-helpers/workflow.js"
import type { WorkflowRunStepWithContext } from "@brm/schema-types/types.js"
import { displayPersonName } from "@brm/util/names.js"
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import { getPublicImageGcsUrl } from "../../../../util/url.js"
import type { GetLogoForOrganizationProps } from "../utils.js"

export default function WorkflowRunStepApproverAvatars({
  step,
  getLogoToShowByOrganizationId,
}: {
  step: WorkflowRunStepWithContext
} & GetLogoForOrganizationProps) {
  const intl = useIntl()

  const currentApprovalStep = getCurrentApprovalStep(step.approval_steps)
  const approvers = currentApprovalStep?.approvers || []

  return (
    <AvatarGroup spacing={-2}>
      {approvers.map((approver) => {
        const approverLogo = getLogoToShowByOrganizationId?.(approver.user.organization_id)
        return (
          <Avatar
            key={approver.user.id}
            name={displayPersonName(approver.user, intl)}
            src={getPublicImageGcsUrl(approver.user.profile_image?.gcs_file_name)}
            borderWidth={0}
          >
            {approverLogo && <AvatarBadge as={Avatar} boxSize={3} src={approverLogo} />}
          </Avatar>
        )
      })}
    </AvatarGroup>
  )
}
