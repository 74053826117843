import { isPendingDocumentExtractionStatus } from "@brm/schema-helpers/document.js"
import { getLegalAgreementVerificationStatusSchema, legalAgreementOwnerIds } from "@brm/schema-helpers/legal.js"
import { hasPermission } from "@brm/schema-helpers/role.js"
import { schemaToFormFields } from "@brm/schema-helpers/schema.js"
import type {
  DocumentMinimal,
  DocumentWithExtraction,
  FieldMetadata,
  FieldMetadataWithSuggestions,
  FieldSourceOutputProperties,
  LegalAgreementInput,
  LegalAgreementListItem,
  LegalAgreementMinimal,
  LegalClausesFieldsMetadata,
  ToolOption,
  ToolWithStandardObjects,
  Transaction,
  TransactionSummaryResponse,
  UserWithOrganization,
  Vendor,
  VendorOption,
} from "@brm/schema-types/types.js"
import { FieldSourceInputPropertiesSchema } from "@brm/schemas"
import { formatDate } from "@brm/util/format-date-time.js"
import { getDiscriminatorSchema, getTitle } from "@brm/util/schema.js"
import { safeTrim } from "@brm/util/string.js"
import { isEmpty, isObject } from "@brm/util/type-guard.js"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  Badge,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Progress,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
  type MenuItemProps,
} from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { skipToken } from "@reduxjs/toolkit/query"
import deepmerge from "deepmerge"
import { excludeKeys } from "filter-obj"
import { flatten } from "flat"
import { usePostHog } from "posthog-js/react"
import { useCallback, useEffect, useMemo, useRef, useState, type FunctionComponent, type ReactNode } from "react"
import type { Control, FieldPath, Path, SetValueConfig } from "react-hook-form"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import type { Location } from "react-router-dom"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { isPresent } from "ts-is-present"
import type { Except } from "type-fest"
import { isNotUndefined } from "typed-assert"
import { useDebouncedCallback } from "use-debounce"
import { useResizeObserver, useSessionStorage } from "usehooks-ts"
import zIndices from "../../../../../packages/theme/src/foundations/z-index.js"
import {
  useGetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlQuery,
  useGetToolV1ByIdQuery,
  useGetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlQuery,
  useGetUserV1WhoamiQuery,
  useGetVendorV1ByIdQuery,
  useLazyGetLegalV1AgreementsByIdQuery,
  usePostDocumentV1ByIdDeprecatedBackgroundExtractMutation,
  usePostLegalV1AgreementsByIdVerificationMutation,
  usePostLegalV1AgreementsListQuery,
  usePostLegalV1AgreementsMutation,
  usePostTransactionV1ListQuery,
  usePutLegalV1AgreementsByIdMutation,
} from "../../../app/services/generated-api.js"
import { useContextMenu } from "../../../components/ContextMenu/context-menu.js"
import UserWithProviderBadge from "../../../components/DataTable/CellRenderer/UserWithProviderBadge.js"
import { DocumentViewerWithHighlighting } from "../../../components/Document/DocumentViewerWithHighlighting.js"
import { DynamicFormField, type DynamicFormFieldProps } from "../../../components/DynamicForm/DynamicFormField.js"
import { FieldSourceFooter } from "../../../components/DynamicForm/FieldSourceFooter.js"
import { getDefaultUserFieldSource, updateFormFieldsWithDirtyFields } from "../../../components/DynamicForm/utils.js"
import { IconButtonWithTooltip } from "../../../components/IconButtonWithTooltip.js"
import OverflownText from "../../../components/OverflownText.js"
import { RenderedMarkdown } from "../../../components/RenderedMarkdown.js"
import { Timestamp } from "../../../components/Timestamp.js"
import {
  BackIcon,
  CheckCircleIcon,
  CheckIcon,
  EditIcon,
  MoreMenuIcon,
  NextIcon,
  PlusIcon,
  TrashIcon,
  TwoStarsIcon,
} from "../../../components/icons/icons.js"
import { ownDocumentDownloadUrl } from "../../../util/document.js"
import { StatusCodes, getAPIErrorMessage, getAPIErrorStatusCode } from "../../../util/error.js"
import { getLegalDateShortcuts, getLegalDurationShortcuts, initializeReactHookFromState } from "../../../util/form.js"
import { matchesLegalAgreementPath, pathForLegalAgreement } from "../../../util/json-schema.js"
import { log } from "../../../util/logger.js"
import {
  TABLE_DEFAULT_PARAMS,
  packageSortFilterOptionsForAPI,
  type TableParamsState,
} from "../../../util/schema-table.js"
import { useObjectInputSchema, useObjectSchema } from "../../../util/use-schema.js"
import { useDocumentVisibility } from "../../../util/visibility.js"
import { cleanValues } from "../../workflows/run/utils.js"
import { AgreementListSidebar } from "../AgreementListSidebar.js"
import { DeleteLegalAgreementModal } from "../DeleteLegalAgreementModal.js"
import { LegalDocumentsBulkUploadModal } from "../LegalDocumentsUploadModal.js"
import { AGREEMENT_LIST_MINIMIZE_STORAGE_KEY } from "../constants.js"
import { AssociatedDocuments, type AssociatedDocumentsFormState } from "./AssociatedDocuments.js"
import AssociatedTransactions from "./AssociatedTransactions.js"

const SAVE_AGREEMENT_DEBOUNCE_DELAY = 150

export interface FullAccessLegalAgreement
  extends Required<LegalAgreementMinimal>,
    Pick<LegalAgreementListItem, "email_source"> {
  documents: Required<DocumentWithExtraction>[]
  tools: ToolWithStandardObjects[]
  vendor: Vendor | null
}
export interface LegalAgreementEditorDrawerContentProps {
  create?: boolean
}

const getFieldSourceLink = (location: Location, fieldSource: FieldMetadata) => {
  const hashParams = new URLSearchParams(location.hash.slice(1))
  switch (fieldSource.type) {
    case "document":
      hashParams.set("document", fieldSource.id ?? "")
      return {
        search: matchesLegalAgreementPath(location.pathname) ? location.search : undefined,
        hash: `#${hashParams}`,
      }
    case "transaction":
      hashParams.set("transaction", fieldSource.id ?? "")
      return {
        search: matchesLegalAgreementPath(location.pathname) ? location.search : undefined,
        hash: `#${hashParams}`,
      }
    case "user":
      if (fieldSource.assigned_by_metadata?.object_field_source) {
        hashParams.set(
          fieldSource.assigned_by_metadata.object_field_source.type,
          fieldSource.assigned_by_metadata.object_field_source.id
        )
        return {
          search: matchesLegalAgreementPath(location.pathname) ? location.search : undefined,
          hash: `#${hashParams}`,
        }
      }
      return undefined
    default:
      return undefined
  }
}

/**
 * Given there exists legal agreement in the path, this component will fetch and render the legal agreement editor.
 * Or render a create legal agreement drawer if there is no existing legalAgreementId.
 */
export const LegalAgreementEditor: FunctionComponent<LegalAgreementEditorDrawerContentProps> = ({ create }) => {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const isVerifyMode = searchParams.get("show_sidebar")
  const hashParams = new URLSearchParams(location.hash.slice(1))
  const { legalAgreementId } = useParams()
  const documentId = hashParams.get("document")
  const transactionId = hashParams.get("transaction")
  const [minimizeSidebar, setMinimizeSidebar] = useSessionStorage(AGREEMENT_LIST_MINIMIZE_STORAGE_KEY, false)
  const containerRef = useRef<HTMLDivElement>(null)
  useResizeObserver({
    ref: containerRef,
    onResize: ({ width }) => {
      if (width !== undefined && width < 1200 && !minimizeSidebar) {
        setMinimizeSidebar(true)
      }
    },
  })
  /** Tool and vendor is only in path when we are creating a blank agreement */
  const vendorId = create && hashParams.get("vendor")
  const toolId = create && hashParams.get("tool")

  const { data: whoami } = useGetUserV1WhoamiQuery()
  const legalAgreementSchema = useObjectSchema("LegalAgreement")
  const transactionSchema = useObjectSchema("ReconciledTransaction")
  const { data: tool } = useGetToolV1ByIdQuery(toolId ? { id: toolId } : skipToken)
  const { data: vendor } = useGetVendorV1ByIdQuery(vendorId ? { id: vendorId } : skipToken)
  const [fetchAgreement, { data: legalAgreement, isError, error }] = useLazyGetLegalV1AgreementsByIdQuery()
  const [nextAgreementId, setNextAgreementId] = useState<string | undefined>(undefined)
  const [tableParams, setTableParams] = useState<TableParamsState<string>>({
    ...TABLE_DEFAULT_PARAMS,
    sorting: [{ id: "transacted_at", desc: true }],
  })
  const transactionsApiParams = useMemo(
    () =>
      tableParams && transactionSchema && packageSortFilterOptionsForAPI<string>(tableParams, transactionSchema, intl),
    [intl, tableParams, transactionSchema]
  )
  const { data: transactionData, isFetching: isFetchingTransactions } = usePostTransactionV1ListQuery(
    transactionsApiParams && legalAgreement
      ? { listQueryStringParams: transactionsApiParams, agreementId: legalAgreement.id }
      : skipToken
  )

  const currentSource:
    | { document: DocumentWithExtraction; type: "transaction" | "document"; transaction?: Transaction }
    | undefined = useMemo(() => {
    const matchingTransaction = transactionData?.transactions?.find((transaction) => transaction.id === transactionId)
    const matchingTransactionReceipt = matchingTransaction?.receipts[0]
    const firstTransaction = transactionData?.transactions?.find((t) => t.receipts.length > 0)
    const firstTransactionReceipt = firstTransaction?.receipts[0]
    const matchingDocument = legalAgreement?.documents.find((d) => d.id === documentId)
    const firstDocument = legalAgreement?.documents[0]
    // Always prioritize matching document and transactions
    if (matchingDocument) {
      return { document: matchingDocument, type: "document" }
    }
    if (matchingTransactionReceipt) {
      return { document: matchingTransactionReceipt.document, type: "transaction", transaction: matchingTransaction }
    }
    // If no selected document or transaction, use first document
    if (firstDocument) {
      return { document: firstDocument, type: "document" }
    }
    if (legalAgreement?.agreement_type === "subscription" && firstTransactionReceipt) {
      return { document: firstTransactionReceipt.document, type: "transaction", transaction: firstTransaction }
    }
    return undefined
  }, [
    transactionData?.transactions,
    legalAgreement?.documents,
    legalAgreement?.agreement_type,
    transactionId,
    documentId,
  ])

  const documentUrlResult = useGetLegalV1AgreementsByIdDocumentsAndDocumentIdUrlQuery(
    currentSource?.type === "document" && currentSource?.document.id && legalAgreementId
      ? { documentId: currentSource.document.id, id: legalAgreementId }
      : skipToken
  )
  const receiptUrlResult = useGetTransactionV1ByIdReceiptsAndReceiptIdDocumentUrlQuery(
    currentSource?.type === "transaction" && currentSource?.transaction && currentSource?.transaction.receipts[0]
      ? { id: currentSource.transaction.id, receiptId: currentSource.transaction.receipts[0].id }
      : skipToken
  )
  const documentVisibility = useDocumentVisibility()
  const downloadUrl =
    currentSource?.type === "transaction" ? receiptUrlResult.data?.download_url : documentUrlResult.data?.download_url
  const apiParams = useMemo(
    () =>
      legalAgreementSchema &&
      packageSortFilterOptionsForAPI<string>(
        {
          sorting: [{ id: "verification_status", desc: false }],
          sortingColumns: ["verification_status"],
          page: 1,
          pageSize: 100,
          filterMap: new Map([
            [
              "verification_status",
              {
                column: "verification_status",
                fields: { comparator: "any", includeNull: false, values: ["in_review", "draft"] },
              },
            ],
          ]),
          savedViewState: {},
        },
        legalAgreementSchema,
        intl
      ),
    [legalAgreementSchema, intl]
  )
  const { data: pendingAgreements } = usePostLegalV1AgreementsListQuery(
    apiParams ? { listQueryStringParams: apiParams } : skipToken,
    {
      pollingInterval: documentVisibility === "visible" ? 5000 : undefined,
    }
  )
  const agreementsIndex = pendingAgreements?.items.findIndex((agreement) => agreement.id === legalAgreement?.id) ?? -1

  useEffect(() => {
    if (pendingAgreements && pendingAgreements.total > 1 && agreementsIndex !== -1) {
      const nextAgreement = pendingAgreements.items[(agreementsIndex + 1) % pendingAgreements.total]
      setNextAgreementId(nextAgreement?.id)
    }
  }, [agreementsIndex, pendingAgreements])

  useEffect(() => {
    async function fetchData() {
      if (legalAgreementId) {
        await fetchAgreement({ id: legalAgreementId })
      }
    }
    void fetchData()
  }, [fetchAgreement, legalAgreementId])

  const onClose = () => {
    navigate(location.state?.prevLocation ?? "/agreements")
  }

  return (
    <Flex width="full" height="full" flex={1} overflow="hidden" ref={containerRef}>
      {isVerifyMode && (
        <AgreementListSidebar
          selectedAgreementId={legalAgreement?.id}
          agreementList={pendingAgreements}
          isMinimized={minimizeSidebar}
        />
      )}
      {isError && (
        <Flex direction="column" flex="1">
          <Alert status="error">
            <AlertDescription>
              {getAPIErrorStatusCode(error) === StatusCodes.FORBIDDEN ? (
                <FormattedMessage
                  defaultMessage="You do not have permissions to read this legal agreement."
                  description="Error message when user lacks permissions to read this legal agreement"
                  id="legalAgreementEditorDrawer.noPermissions"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Something went wrong loading the legal agreement."
                  description="Error message when legal agreement fails to load"
                  id="legalAgreementEditorDrawer.loadError"
                />
              )}
            </AlertDescription>
          </Alert>
        </Flex>
      )}
      {whoami && (!legalAgreementId || legalAgreement) && (
        <LegalAgreementEditorContent
          transactionData={transactionData}
          isFetchingTransactions={isFetchingTransactions}
          transactionTableParams={tableParams}
          setTransactionTableParams={(newParams) => {
            setTableParams({ ...tableParams, ...newParams })
          }}
          assignToCriteriaFieldSource={{
            type: currentSource?.type,
            id: currentSource?.type === "transaction" ? currentSource.transaction?.id : currentSource?.document.id,
            source_display_name:
              currentSource?.type === "transaction"
                ? `${currentSource.transaction?.merchant_identification} ${
                    currentSource.transaction?.transacted_at &&
                    formatDate(intl, Temporal.PlainDate.from(currentSource.transaction.transacted_at))
                  }`
                : (currentSource?.document.file_name ?? undefined),
          }}
          legalAgreement={legalAgreement as FullAccessLegalAgreement | undefined}
          tool={tool}
          whoami={whoami}
          vendor={vendor ?? tool?.vendor}
          onClose={onClose}
          currentDocument={currentSource?.document}
          refetchAgreement={fetchAgreement}
          downloadUrl={downloadUrl}
          verifiedAgreementFooter={
            isVerifyMode &&
            nextAgreementId && (
              <Button
                gap={2}
                onClick={() => {
                  if (nextAgreementId) {
                    navigate(pathForLegalAgreement(nextAgreementId, true), {
                      state: location.state,
                    })
                  }
                }}
                variant="outline"
              >
                {intl.formatMessage({
                  id: "legalAgreement.modal.nextAgreement.button",
                  defaultMessage: "Next agreement",
                  description: "Button to navigate to the next legal agreement",
                })}
                <Icon as={NextIcon} />
              </Button>
            )
          }
        />
      )}
    </Flex>
  )
}

// This is a separate component so that it can receive the fetched `legalAgreement` as opposed to just the ID and doesn't have to deal with data fetching.
const LegalAgreementEditorContent: FunctionComponent<{
  /** Used for filling the tool when in the context of a tool page. */
  tool?: ToolOption
  /** Used for filling the vendor when in the context of a vendor page. */
  vendor?: VendorOption
  /** Decides over whether edit or add mode is rendered. */
  legalAgreement?: FullAccessLegalAgreement
  whoami: UserWithOrganization
  /** Document to view in document viewer */
  currentDocument?: DocumentWithExtraction
  /** Object type used for assigning criteria field source. */
  assignToCriteriaFieldSource?: FieldSourceOutputProperties
  /** Document url for document viewer */
  downloadUrl?: string
  refetchAgreement: (args: { id: string }) => void
  onClose: () => void
  /** Next agreement  */
  nextAgreement?: LegalAgreementListItem
  /** CTA to render in footer after agreement is verified */
  verifiedAgreementFooter?: ReactNode
  isFetchingTransactions?: boolean
  transactionData?: TransactionSummaryResponse
  /** Transaction table sorting */
  setTransactionTableParams?: (params: Partial<TableParamsState<string>>) => void
  transactionTableParams?: TableParamsState<string>
}> = ({
  legalAgreement,
  onClose,
  whoami,
  currentDocument,
  downloadUrl,
  refetchAgreement,
  tool,
  vendor,
  assignToCriteriaFieldSource,
  verifiedAgreementFooter,
  isFetchingTransactions,
  transactionData,
  setTransactionTableParams,
  transactionTableParams,
}) => {
  const toast = useToast()
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const posthog = usePostHog()
  const [createLegalAgreement] = usePostLegalV1AgreementsMutation()
  const [verifyLegalAgreement] = usePostLegalV1AgreementsByIdVerificationMutation()
  const [updateAgreement] = usePutLegalV1AgreementsByIdMutation()
  const [uncontrolledComponentResetId, setUncontrolledComponentResetId] = useState(0)
  const incrementUncontrolledComponentResetId = useCallback(() => setUncontrolledComponentResetId((id) => id + 1), [])
  const [initializeExtraction] = usePostDocumentV1ByIdDeprecatedBackgroundExtractMutation()
  const uploadModal = useDisclosure()

  const [activeTab, setActiveTab] = useState(0)
  const [isEditingDisplayName, setIsEditingDisplayName] = useState(legalAgreement?.display_name ? false : true)
  const [selectedProvenancePath, setSelectedProvenancePath] = useState<(string | number)[]>()
  const [deleteMessage, setDeleteMessage] = useState<string | undefined>()
  const rootSchema = useObjectInputSchema("LegalAgreement")

  const form = useForm<LegalAgreementInput>({
    defaultValues:
      legalAgreement ??
      ({
        object_type: "LegalAgreement",
        display_name: "",
        agreement_type: "enterprise",
        tools: [tool].filter(isPresent),
        vendor,
        documents: [],
        fields_metadata: {},
        custom: {},
      } satisfies LegalAgreementInput),
    shouldFocusError: false,
  })

  const debouncedSaveLegalAgreement = useDebouncedCallback(async () => {
    const legalAgreementPayload =
      rootSchema &&
      updateFormFieldsWithDirtyFields<LegalAgreementInput>(form.formState.dirtyFields, form.getValues(), rootSchema)
    if (legalAgreementPayload && !isEmpty(legalAgreementPayload) && legalAgreement?.id) {
      const cleanedValues = cleanValues(legalAgreementPayload, rootSchema)
      const updatedFields = Object.keys(
        flatten(form.formState.dirtyFields)
      ) as (keyof typeof form.formState.dirtyFields)[]
      // Display name is handled separately
      void form.trigger(updatedFields.filter((path) => path !== "display_name"))
      const requestInput = deepmerge<LegalAgreementInput>(legalAgreement, cleanedValues as LegalAgreementInput, {
        arrayMerge: (_, source) => source,
      })
      await updateAgreement({ body: requestInput, id: legalAgreement.id })
      // Reset dirty fields when the draft state gets flushed to the server so that changing a value back
      // to its original value will get recorded as a field change event even though from the perspective
      // of the form, the value is equal to its default value and therefore not dirty.
      form.reset(form.getValues(), { keepValues: true, keepDefaultValues: false, keepErrors: true })
    }
  }, SAVE_AGREEMENT_DEBOUNCE_DELAY)

  useEffect(() => {
    // Only autosave if we have a legal agreement
    if (legalAgreement) {
      // Subscribe to react-hook-form watch to execute onChange on form state change
      const subscription = form.watch(() => {
        // react-hook-form does not update dirtyFields by the time the watch onChange is called.
        // setTimeout so we can leverage the dirtyField state and cleanly update only the draftState
        // fields that have been changed since the form was loaded.
        setTimeout(debouncedSaveLegalAgreement, 1)
      })
      return () => subscription.unsubscribe()
    }
    return
  }, [form, debouncedSaveLegalAgreement, legalAgreement])

  const pathString = selectedProvenancePath?.join(".")

  useEffect(() => {
    form.reset(legalAgreement, { keepDirtyValues: true, keepErrors: true })
  }, [form, legalAgreement])

  useEffect(() => {
    // Poll for document status updates every second
    const interval = setInterval(async () => {
      if (
        document.visibilityState !== "hidden" &&
        legalAgreement &&
        legalAgreement.documents.find(
          (document) =>
            isPendingDocumentExtractionStatus(document.extraction_status) ||
            (document.extraction_jobs &&
              document.extraction_jobs.find(
                (extraction) => extraction.extraction_type === "legal_agreement_fields" && !extraction.extracted_at
              ))
        )
      ) {
        refetchAgreement({ id: legalAgreement.id })
      }
    }, 5000)
    return () => clearTimeout(interval)
  }, [legalAgreement, refetchAgreement])

  initializeReactHookFromState(form)
  const legalAgreementType = form.watch("agreement_type")
  const discriminatedRootSchema = useMemo(
    () => getDiscriminatorSchema(rootSchema, { agreement_type: legalAgreementType }),
    [rootSchema, legalAgreementType]
  )

  const legalFormFields = useMemo(
    () => discriminatedRootSchema && schemaToFormFields(discriminatedRootSchema, "LegalAgreement", "all"),
    [discriminatedRootSchema]
  )

  const allFields = useMemo(() => {
    if (!legalFormFields) {
      return []
    }
    const { standard, custom } = legalFormFields
    const standardFields = standard.map((field) => ({ ...field, is_internal_only: false }))
    const customFields = custom.map((field) => ({ ...field, is_internal_only: false }))
    return [...standardFields, ...customFields]
  }, [legalFormFields])

  const verifiedFieldCount = useMemo(() => {
    return allFields
      .filter((field) => field.field_name !== "display_name")
      .filter((field) => {
        const { is_custom, field_name } = field
        const path = is_custom ? `custom.${field_name}` : `${field_name}`
        const value = form.getValues(path as Path<LegalAgreementInput>)
        const fieldMetadata = form.getValues(
          `fields_metadata.${path}` as Path<LegalAgreementInput>
        ) as FieldMetadataWithSuggestions
        const isValueEmpty = isObject(value) ? isEmpty(excludeKeys(value, (_, v) => isEmpty(v))) : isEmpty(value)
        const isDirty = form.getFieldState(path as Path<LegalAgreementInput>).isDirty
        return fieldMetadata?.verified || (isValueEmpty && !isDirty)
      }).length
  }, [allFields, form])

  // Exclude display name from all field count
  const allFieldCount = useMemo(
    () => allFields.filter((field) => field.field_name !== "display_name").length,
    [allFields]
  )

  const getValue = useCallback((path: string) => form.watch((path || undefined) as Path<LegalAgreementInput>), [form])
  // setValue is expecting a path from the shape of the formState. We could make DynamicForm generically typed
  // to support this, but due to the dynamic nature of the form generation we will keep as is for now.
  const setValue = form.setValue as (path: string, value: unknown, options?: SetValueConfig) => void
  const toolVendorOwners = useMemo(() => {
    if (!legalAgreement) {
      return new Set<string>()
    }
    return legalAgreementOwnerIds(legalAgreement)
  }, [legalAgreement])

  const deleteLegalAgreementModal = useDisclosure()

  const onProvenanceClick = useCallback(
    (provenancePath: (number | string)[], fieldSource?: FieldSourceOutputProperties) => {
      if (!fieldSource || !("id" in fieldSource)) {
        return
      }
      const destinationLink = getFieldSourceLink(location, fieldSource)
      if (destinationLink) {
        navigate(destinationLink, { state: location.state })
      }

      setSelectedProvenancePath(provenancePath)
    },
    [location, navigate]
  )

  const renderFieldSource = useCallback(
    (path: (string | number)[], fieldSource?: FieldSourceOutputProperties): ReactNode => {
      if (!fieldSource) {
        return null
      }

      return <FieldSourceFooter fieldSource={fieldSource} onClick={() => onProvenanceClick(path, fieldSource)} />
    },
    [onProvenanceClick]
  )

  // We cannot directly pass in the form state to the api because there are certain fields that need to be formatted before sending to the api
  const formatInputForApi = (values: LegalAgreementInput): LegalAgreementInput => {
    return {
      ...values,
      display_name: values.display_name.trim(),
      // Mark any field that was edited as now coming from "user" input as opposed to extraction, but preserve
      // all previous source metadata for all other fields.
      fields_metadata: {
        ...values.fields_metadata,
      },
      ...(values.agreement_type === "enterprise"
        ? {
            buyer_signer_name: safeTrim(values.buyer_signer_name),
            buyer_signer_title: safeTrim(values.buyer_signer_title),
            vendor_signer_name: safeTrim(values.vendor_signer_name),
            vendor_signer_title: safeTrim(values.vendor_signer_title),
          }
        : {}),
      auto_renewal_opt_out_period: values.auto_renews ? values.auto_renewal_opt_out_period : undefined,
      start_date: safeTrim(values.start_date),
      end_date: safeTrim(values.end_date),
      first_invoice_date: safeTrim(values.first_invoice_date),
    }
  }

  const onVerifyField = useCallback(
    async (fieldPath: (string | number)[] | undefined, values: LegalAgreementInput) => {
      if (!legalAgreement) {
        return
      }
      if (fieldPath) {
        posthog.capture("legal_agreement_verify_field")
      } else {
        posthog.capture("legal_agreement_verify_all")
      }
      const legalAgreementInput = formatInputForApi(values)
      try {
        await verifyLegalAgreement({
          id: legalAgreement.id,
          agreementVerificationRequest: { input: legalAgreementInput, field_path: fieldPath },
        }).unwrap()
        refetchAgreement({ id: legalAgreement.id })
      } catch (err) {
        log.error("Error saving legal agreement", err, { legalAgreement: legalAgreementInput })
        toast({
          status: "error",
          description: getAPIErrorMessage(err) ?? (
            <FormattedMessage
              defaultMessage="An error occurred saving the legal agreement"
              description="The toast description for an error when saving a legal agreement"
              id="legalAgreement.modal.toast.error"
            />
          ),
        })
      }
    },
    [legalAgreement, verifyLegalAgreement, refetchAgreement, toast, posthog]
  )

  const onDisplayNameSave = useCallback(
    async (updatedName: string) => {
      if (legalAgreement) {
        const displayNameFieldProps = getValue("fields_metadata.display_name") as FieldMetadata
        await onVerifyField(["display_name"], {
          ...(form.formState.defaultValues as LegalAgreementInput),
          agreement_type: legalAgreement.agreement_type,
          display_name: updatedName,
          fields_metadata: {
            display_name: { ...displayNameFieldProps, ...getDefaultUserFieldSource(intl, whoami) },
          },
        })
        setIsEditingDisplayName(false)
      } else {
        form.setValue("display_name", updatedName)
      }
    },
    [form, getValue, intl, legalAgreement, onVerifyField, whoami]
  )

  const onDiscard = useCallback(() => {
    form.reset()
    onClose()
  }, [form, onClose])

  const getDocumentDownloadURL = useCallback(
    (path: (string | number)[], document: DocumentMinimal) => {
      // If the legal agreement is new or the document was just changed, we can access the documents through the owned-documents route (since the user would have just uploaded them)
      if (!legalAgreement || form.getFieldState(path.join(".") as FieldPath<LegalAgreementInput>).isDirty) {
        return ownDocumentDownloadUrl(document)
      }
      // Else access them through the legal agreement ID
      return new URL(
        `/legal/v1/agreements/${legalAgreement.id}/${path.join("/")}/content`,
        import.meta.env.VITE_API_BASE_URL
      ).href
    },
    [form, legalAgreement]
  )
  const { menuListProps, menuProps, subjectProps, menuItemProps, betsyProps, menuButtonProps } =
    useContextMenu<HTMLDivElement>({
      betsyEnabled: true,
    })

  const menuActions = useCallback(
    (additionalProps?: MenuItemProps) => (
      <>
        <MenuItem
          key="rename-agreement"
          icon={<Icon as={EditIcon} />}
          iconSpacing={0}
          onClick={() => setIsEditingDisplayName(true)}
          {...additionalProps}
        >
          <FormattedMessage
            defaultMessage="Rename agreement"
            description="The label for the rename legal agreement option in the options menu"
            id="legalAgreement.modal.options.rename"
          />
        </MenuItem>
        {legalAgreement && (toolVendorOwners.has(whoami.id) || hasPermission(whoami.roles, "legal:delete")) && (
          <MenuItem
            key="delete-agreement"
            icon={<Icon as={TrashIcon} />}
            iconSpacing={0}
            onClick={deleteLegalAgreementModal.onOpen}
            {...additionalProps}
          >
            <FormattedMessage
              defaultMessage="Delete agreement"
              description="The label for the delete legal agreement option in the options menu"
              id="legalAgreement.modal.options.delete"
            />
          </MenuItem>
        )}
        <MenuItem
          key="add-documents"
          icon={<Icon as={PlusIcon} />}
          iconSpacing={0}
          onClick={uploadModal.onOpen}
          {...additionalProps}
        >
          <FormattedMessage
            defaultMessage="Add documents"
            description="The label for the add document option in the options menu"
            id="legalAgreement.modal.options.addDocuments"
          />
        </MenuItem>
      </>
    ),

    [legalAgreement, toolVendorOwners, whoami.id, whoami.roles, deleteLegalAgreementModal.onOpen, uploadModal.onOpen]
  )

  const legalFormProps: Except<DynamicFormFieldProps, "path" | "formField" | "rootBaseSchema"> = useMemo(
    () => ({
      control: form.control,
      getValue,
      setValue,
      resetField: form.resetField as (path: string, options?: SetValueConfig) => void,
      isReadOnly: false,
      uncontrolledComponentResetId,
      incrementUncontrolledComponentResetId,
      renderFieldSource,
      format: "compact",
      onProvenanceClick,
      getDocumentDownloadUrl: getDocumentDownloadURL,
      onVerifyField: legalAgreement
        ? (field: (number | string)[] | undefined) => onVerifyField(field, form.getValues())
        : undefined,
    }),
    [
      form,
      getValue,
      setValue,
      uncontrolledComponentResetId,
      incrementUncontrolledComponentResetId,
      renderFieldSource,
      onProvenanceClick,
      getDocumentDownloadURL,
      legalAgreement,
      onVerifyField,
    ]
  )

  const onUploadSubmit = async (uploadedDocuments: DocumentMinimal[]) => {
    // Trigger bulk-processing
    await Promise.all(
      uploadedDocuments.map((doc) =>
        initializeExtraction({
          id: doc.id,
          deprecatedDocumentBackgroundExtractionRequest: {
            deprecated_extraction_type: "user_upload",
            agreement_id: legalAgreement?.id,
            agreement_input: form.getValues(),
          },
        }).unwrap()
      )
    )
  }

  const formFields = useMemo(
    () =>
      legalFormFields &&
      discriminatedRootSchema && (
        <Stack gap={8}>
          {allFields.map((field) => {
            const path = field.is_custom ? ["custom", field.field_name] : [field.field_name]
            const dateShortcuts = getLegalDateShortcuts(
              field.field_name,
              discriminatedRootSchema,
              form.getValues(),
              intl
            )

            const durationShortcuts = getLegalDurationShortcuts(field.field_name, intl)

            return (
              <DynamicFormField
                {...legalFormProps}
                key={path.join(".")}
                formField={{ ...field, is_internal_only: false }}
                path={path}
                dateShortcuts={dateShortcuts}
                durationShortcuts={durationShortcuts}
                rootBaseSchema={discriminatedRootSchema}
              />
            )
          })}
        </Stack>
      ),
    [allFields, discriminatedRootSchema, form, intl, legalFormFields, legalFormProps]
  )

  const contextMenuActions = useMemo(() => menuActions(menuItemProps), [menuActions, menuItemProps])

  return (
    <Flex
      alignItems="start"
      minHeight="100vh"
      minWidth={0}
      flexGrow={1}
      flexShrink={1}
      {...subjectProps.baseProps}
      overflow="hidden"
    >
      <Portal>
        <Menu {...menuProps}>
          <MenuButton {...menuButtonProps} />
          <MenuList zIndex={zIndices.popover} {...menuListProps}>
            {menuListProps.children}
            {contextMenuActions}
          </MenuList>
        </Menu>
      </Portal>
      {betsyProps?.BetsyModal}
      <Center boxShadow="xl" minH={0} borderRightWidth="1px" height="100%" flex={2}>
        <DocumentViewerWithHighlighting
          requestingEntity={{
            object_type: "LegalAgreement",
            object_id: legalAgreement?.id,
          }}
          document={currentDocument}
          menuActions={contextMenuActions}
          fieldMetadata={
            selectedProvenancePath &&
            (getValue(["fields_metadata", ...selectedProvenancePath].join(".")) as FieldMetadataWithSuggestions)
          }
          path={selectedProvenancePath}
          onAssignToCriteriaSuccess={async (path, newVal, source) => {
            setValue(path.join("."), newVal, {
              shouldDirty: true,
            })
            const existingFieldMetadata = excludeKeys(
              getValue(["fields_metadata", ...path].join(".")) || {},
              (k) => k in FieldSourceInputPropertiesSchema.properties
            )
            if (
              assignToCriteriaFieldSource?.id &&
              (assignToCriteriaFieldSource.type === "document" || assignToCriteriaFieldSource?.type === "transaction")
            ) {
              // Set assigned by metadata
              if (path.includes("clauses")) {
                setValue(
                  ["fields_metadata", ...path].join("."),
                  {
                    ...existingFieldMetadata,
                    ...getDefaultUserFieldSource(intl, whoami),
                    assigned_by_metadata: {
                      source,
                      object_field_source: {
                        id: assignToCriteriaFieldSource.id,
                        type: assignToCriteriaFieldSource.type,
                        source_display_name: assignToCriteriaFieldSource.source_display_name,
                      },
                    },
                  } satisfies FieldMetadataWithSuggestions,
                  {
                    shouldDirty: true,
                  }
                )
                const clausesMetadata = excludeKeys(
                  (getValue(["fields_metadata", ...path.slice(0, -1)].toString()) || {}) as LegalClausesFieldsMetadata,
                  Array.from(Object.keys(FieldSourceInputPropertiesSchema.properties))
                )
                setValue(
                  ["fields_metadata", "clauses"].join("."),
                  {
                    ...clausesMetadata,
                    verified: false,
                    updated_at: Temporal.Now.instant().toString(),
                  } satisfies FieldMetadataWithSuggestions,
                  {
                    shouldDirty: true,
                  }
                )
              } else {
                setValue(
                  ["fields_metadata", ...path].join("."),
                  {
                    ...existingFieldMetadata,
                    ...getDefaultUserFieldSource(intl, whoami),
                    verified: false,
                    assigned_by_metadata: {
                      source,
                      object_field_source: {
                        id: assignToCriteriaFieldSource.id,
                        type: assignToCriteriaFieldSource.type,
                        source_display_name: assignToCriteriaFieldSource.source_display_name,
                      },
                    },
                  } satisfies FieldMetadataWithSuggestions,
                  {
                    shouldDirty: true,
                  }
                )
              }
            }
            incrementUncontrolledComponentResetId()
          }}
          value={pathString && getValue(pathString)}
          rootSchema={rootSchema}
          downloadUrl={downloadUrl}
          closeButton={
            location?.state?.prevLocation && (
              <IconButtonWithTooltip
                icon={<Icon as={BackIcon} />}
                onClick={onDiscard}
                variant="ghost"
                label={intl.formatMessage({
                  defaultMessage: "Back",
                  id: "documentViewer.backButton.tooltip",
                  description: "label for previous page button in document viewer toolbar",
                })}
              />
            )
          }
        />
      </Center>
      <Flex
        width="full"
        as="form"
        maxW="lg"
        height="full"
        flexDirection="column"
        noValidate
        alignItems="start"
        onSubmit={form.handleSubmit(async (values: LegalAgreementInput) => {
          setActiveTab(0)
          const legalAgreementInput = formatInputForApi(values)
          try {
            const createdAgreement = await createLegalAgreement({ legalAgreementInput }).unwrap()
            navigate(
              {
                pathname: pathForLegalAgreement(createdAgreement.id),
                search: location.search,
              },
              {
                state: location.state,
              }
            )
            toast({
              status: "success",
              description: (
                <FormattedMessage
                  defaultMessage="Legal agreement saved successfully"
                  description="The toast description for a successful save of a legal agreement"
                  id="legalAgreement.modal.toast.success"
                />
              ),
            })
          } catch (err) {
            log.error("Error creating legal agreement", err, { legalAgreement: legalAgreementInput })
          }
        })}
      >
        <Flex direction="column" p={4} borderBottomWidth={1} width="full">
          {uploadModal.isOpen && (
            // set returnFocusOnClose false to prevent unintended side nav focus issues
            <LegalDocumentsBulkUploadModal {...uploadModal} onSubmit={onUploadSubmit} returnFocusOnClose={false} />
          )}
          <HStack alignItems="start" justifyContent="space-between">
            <Stack flexGrow={1}>
              <DisplayNameField
                legalAgreement={legalAgreement}
                onChange={onDisplayNameSave}
                isEditing={isEditingDisplayName}
                control={form.control}
                value={(getValue("display_name") as string) ?? ""}
              />
              <HStack justifyContent="space-between">
                {legalAgreement && legalAgreement.updated_at && (
                  <Text fontSize="xs" fontWeight="light">
                    <FormattedMessage
                      id="legalAgreement.modal.lastUpdated"
                      description="Last updated timestamp for legal agreement"
                      defaultMessage="Last saved {timestamp}"
                      values={{ timestamp: <Timestamp dateTime={legalAgreement.updated_at} /> }}
                    />
                  </Text>
                )}
                {legalAgreement && allFieldCount > 0 && (
                  <HStack fontSize="xs">
                    <FormattedMessage
                      defaultMessage="Verified {verified}/{total}"
                      description="Text showing the number of verified fields"
                      id="legalAgreement.modal.verification.progress"
                      values={{
                        verified: verifiedFieldCount,
                        total: allFieldCount,
                      }}
                    />
                    {verifiedFieldCount === allFieldCount && <Icon as={CheckCircleIcon} color="brand.600" />}
                  </HStack>
                )}
              </HStack>
              {legalAgreement && allFieldCount > 0 && (
                <Progress value={(verifiedFieldCount * 100) / allFieldCount} colorScheme="brand" size="sm" />
              )}
            </Stack>
            {legalAgreement?.email_source?.map((userWithProvider) => (
              <UserWithProviderBadge key={userWithProvider.id} user={userWithProvider} />
            ))}
            <HStack>
              {!isEditingDisplayName && (
                <Menu placement="bottom-end">
                  <MenuButton
                    as={IconButton}
                    aria-label={intl.formatMessage({
                      defaultMessage: "Agreement options button",
                      description: "The icon label for a button to open the options menu",
                      id: "legalAgreement.modal.options.iconButton",
                    })}
                    size="sm"
                    icon={<Icon as={MoreMenuIcon} />}
                    variant="ghost"
                  />
                  <MenuList fontSize="sm">{menuActions()}</MenuList>
                </Menu>
              )}
            </HStack>
          </HStack>
        </Flex>
        <Flex direction="column" overflowY="auto" width="full" overflowX="hidden">
          <Stack padding={4} paddingBottom={0}>
            <AssociatedDocuments
              control={form.control as unknown as Control<AssociatedDocumentsFormState>}
              legalAgreement={legalAgreement}
              fields={allFields}
              legalAgreementInput={formatInputForApi(form.getValues())}
              fieldsMetadata={form.getValues("fields_metadata")}
              onDocumentAdded={(extractionResponse) => {
                if (!extractionResponse.agreement_id) {
                  return
                }
                refetchAgreement({ id: extractionResponse.agreement_id })
                if (!legalAgreement) {
                  navigate(pathForLegalAgreement(extractionResponse.agreement_id), {
                    state: location.state,
                  })
                }
              }}
              onVerify={async () =>
                await onVerifyField(["documents"], {
                  ...(form.formState.defaultValues as LegalAgreementInput),
                  documents: form.getValues().documents,
                })
              }
              submitDocuments={async () => {
                isNotUndefined(legalAgreement, "Legal agreement must be defined to remove a document")
                await onVerifyField(["documents"], {
                  ...(form.formState.defaultValues as LegalAgreementInput),
                  agreement_type: legalAgreement.agreement_type,
                  documents: form.getValues().documents,
                })
              }}
              onLastDocumentRemoved={async () => {
                setDeleteMessage(
                  intl.formatMessage({
                    id: "legalAgreement.modal.deleteLast.document.message",
                    defaultMessage:
                      "You have removed the last document from this agreement. Do you want to delete the agreement as well? It cannot be restored.",
                    description:
                      "Error message shown when the user tries to delete an agreement with only one document",
                  })
                )
                deleteLegalAgreementModal.onOpen()
              }}
            />
          </Stack>
          {discriminatedRootSchema && (
            <Stack padding={4} paddingTop={0} borderTopWidth={1}>
              {currentDocument?.summary && (
                <Accordion allowToggle defaultIndex={0} marginBottom={2}>
                  <AccordionItem border={0}>
                    <h2>
                      <AccordionButton
                        flex={1}
                        justifyContent="space-between"
                        paddingX={0}
                        _hover={{ backgroundColor: "white" }}
                        fontWeight="medium"
                      >
                        <Flex gap={1} justifyContent="start">
                          <FormattedMessage
                            defaultMessage="Document Summary"
                            description="The title for the agreement executive summary section"
                            id="legalAgreement.modal.summary.title"
                          />
                          <Icon as={TwoStarsIcon} color="purple.700" />
                        </Flex>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderWidth={1}
                      borderColor="gray.200"
                      borderRadius="md"
                      backgroundColor="gray.50"
                      padding={2}
                    >
                      <RenderedMarkdown content={currentDocument.summary} />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )}
              {/* Subscriptions have a transactions tab but enterprise will not */}
              {legalAgreement?.agreement_type === "subscription" ? (
                <Tabs index={activeTab} onChange={(i) => setActiveTab(i)}>
                  <TabList justifyContent="space-around" color="gray.500">
                    <Tab fontWeight="semibold" width="50%">
                      <FormattedMessage
                        defaultMessage="Agreement Criteria"
                        description="The tab title for agreement criteria"
                        id="legalAgreement.modal.tab.title.criteria"
                      />
                    </Tab>
                    <Tab fontWeight="semibold" width="50%" flexDirection="row" gap={2}>
                      <FormattedMessage
                        defaultMessage="Transactions"
                        description="The tab title for associated transactions"
                        id="legalAgreement.modal.tab.title.transactions"
                      />
                      <Badge
                        colorScheme={activeTab === 1 ? "green" : "gray"}
                        size="sm"
                        variant="subtleOutlined"
                        borderRadius="full"
                        borderColor={!activeTab ? "gray.200" : undefined}
                      >
                        {transactionData?.total ?? 0}
                      </Badge>
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel paddingX={0}>{formFields}</TabPanel>
                    <TabPanel paddingX={0}>
                      {transactionTableParams && setTransactionTableParams && (
                        <AssociatedTransactions
                          transactionData={transactionData}
                          isFetching={isFetchingTransactions}
                          tableParams={transactionTableParams}
                          setTableParams={setTransactionTableParams}
                        />
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              ) : (
                formFields
              )}
            </Stack>
          )}
        </Flex>
        {activeTab === 0 && (
          <Flex
            gap={2}
            justifyContent="space-between"
            flexDirection="row-reverse"
            p={4}
            borderTop={1}
            borderStyle="solid"
            borderColor="gray.200"
            width="full"
            alignItems="center"
          >
            {legalAgreement?.verification_status === "verified" ? (
              <Flex gap={2} fontWeight="semibold" color="brand.700">
                <FormattedMessage
                  id="legalAgreement.modal.verification.message"
                  defaultMessage="All criteria accepted"
                  description="Message shown when the legal agreement has been verified"
                />
                <Icon as={CheckIcon} />
              </Flex>
            ) : legalAgreement ? (
              <Button
                colorScheme="brand"
                onClick={() => onVerifyField(undefined, form.getValues())}
                rightIcon={<Icon as={CheckIcon} />}
              >
                {intl.formatMessage({
                  id: "legalAgreement.modal.acceptAll.button",
                  defaultMessage: "Accept all",
                  description: "Button to accept all criteria",
                })}
              </Button>
            ) : (
              <Button colorScheme="brand" gap={2} type="submit">
                {intl.formatMessage({
                  id: "legalAgreement.modal.create.button",
                  defaultMessage: "Create agreement",
                  description: "Button to create an agreement",
                })}
              </Button>
            )}
            {legalAgreement?.verification_status === "verified" && verifiedAgreementFooter}
          </Flex>
        )}
      </Flex>
      {legalAgreement && (
        <DeleteLegalAgreementModal
          {...deleteLegalAgreementModal}
          legalAgreementBeingDeleted={{ id: legalAgreement.id }}
          deletionMessage={deleteMessage}
          onCancel={() => {
            setDeleteMessage(undefined)
            deleteLegalAgreementModal.onClose()
          }}
          onDelete={() => {
            setDeleteMessage(undefined)
            deleteLegalAgreementModal.onClose()
            onClose()
          }}
        />
      )}
    </Flex>
  )
}

const DisplayNameField = ({
  legalAgreement,
  onChange,
  isEditing,
  control,
  value,
}: {
  legalAgreement?: FullAccessLegalAgreement
  onChange: (name: string) => void
  isEditing: boolean
  control: Control<LegalAgreementInput>
  value: string
}) => {
  const intl = useIntl()
  const isEditable = !!legalAgreement
  const verificationStatusSchema = getLegalAgreementVerificationStatusSchema(legalAgreement?.verification_status)
  // Control value so that it does not get updated when agreement fields autosave
  const [stateValue, setStateValue] = useState<string>(value)

  useEffect(() => {
    if (!isEditing) {
      setStateValue(value)
    }
  }, [isEditing, value])

  return (
    <Controller
      rules={{
        validate: (value) => {
          if (isEmpty(value.trim())) {
            return intl.formatMessage({
              defaultMessage: "Please enter a display name",
              description: "Error message for display name required field",
              id: "form.error.display_name.required",
            })
          }
          return true
        },
      }}
      control={control}
      name="display_name"
      render={({ field, fieldState }) => (
        <FormControl as="fieldset" isInvalid={fieldState.invalid} isRequired>
          {isEditing ? (
            <HStack align="center">
              <Stack width="full">
                <Input
                  autoFocus
                  width="full"
                  value={stateValue}
                  onChange={(e) => setStateValue(e.currentTarget.value)}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter Agreement name",
                    id: "legalAgreement.modal.displayName.input",
                    description: "The placeholder for the agreement name input",
                  })}
                  onBlur={() => onChange(stateValue)}
                />
                <FormErrorMessage fontWeight="normal">{fieldState.error?.message}</FormErrorMessage>
              </Stack>
              {isEditable && (
                <IconButton
                  icon={<Icon as={CheckIcon} color="success.600" />}
                  aria-label={intl.formatMessage({
                    defaultMessage: "Save agreement name",
                    id: "legalAgreement.modal.displayName.save",
                    description: "The aria-label for the save button for the agreement name",
                  })}
                  onClick={() => onChange(stateValue)}
                  variant="ghost"
                />
              )}
            </HStack>
          ) : (
            <HStack align="center">
              <Heading fontWeight="semibold" fontSize="lg" maxW="270px">
                <OverflownText>{field.value}</OverflownText>
              </Heading>
              {legalAgreement?.verification_status && legalAgreement.verification_status !== "verified" && (
                <Badge variant="subtleOutlined" size="md" colorScheme={verificationStatusSchema?.colorScheme}>
                  {getTitle(legalAgreement.verification_status, verificationStatusSchema)}
                </Badge>
              )}
            </HStack>
          )}
        </FormControl>
      )}
    />
  )
}
