import type { TimePeriod } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Flex } from "@chakra-ui/react"
import { useIntl } from "react-intl"
import PercentArrowIcon from "../../components/icons/PercentArrowIcon.js"
import { colorForPercent } from "../../util/percent.js"

export const PercentWithTimePeriod = ({
  timePeriod,
  percent,
}: {
  timePeriod: TimePeriod
  percent: number | null | undefined
}) => {
  const intl = useIntl()
  if (!percent) return null

  const timePeriodText = () => {
    switch (timePeriod) {
      case "last_twenty_four_months":
        return intl.formatMessage({
          id: "spendChart.spendChange.percent",
          description: "Abbreviation for 24 months-over-24 months",
          defaultMessage: "over previous 24-months",
        })
      case "last_twelve_months":
      case "year_to_date":
        return intl.formatMessage({
          id: "spendChart.spendChange.percent",
          description: "Abbreviation for year-over-year",
          defaultMessage: "YoY",
        })
      case "last_thirty_days":
      case "month_to_date":
        return intl.formatMessage({
          id: "userActivityChart.activeChange",
          description: "Abbreviation for month over month",
          defaultMessage: "MoM",
        })
      default:
        unreachable(timePeriod)
    }
  }

  return (
    <Flex gap={1} fontSize="sm" color={colorForPercent(percent)}>
      <PercentArrowIcon percent={percent} />
      {timePeriodText()}
    </Flex>
  )
}
