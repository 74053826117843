import { HStack, Icon } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { ZapIcon, ZapOffIcon } from "../../components/icons/icons.js"

interface Props {
  autoRenews: boolean
}

export default function AgreementAutoRenews({ autoRenews }: Props) {
  return (
    <HStack alignItems="center">
      <Icon as={autoRenews ? ZapIcon : ZapOffIcon} />
      {autoRenews ? (
        <FormattedMessage id="agreement.renews" description="agreement renews" defaultMessage="Auto renews" />
      ) : (
        <FormattedMessage
          id="agreement.noRenewal"
          description="agreement no renewal"
          defaultMessage="Does not auto renew"
        />
      )}
    </HStack>
  )
}
