import { Flex, Icon, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal } from "@chakra-ui/react"
import { chakra } from "@chakra-ui/system"
import { useSelected, type RenderElementProps } from "slate-react"
import { Link } from "../Link.js"
import { LinkExternalIcon } from "../icons/icons.js"

export default function InlineLink({
  isReadOnly,
  url,
  children,
  ...baseElementProps
}: RenderElementProps & {
  isReadOnly?: boolean
  url: string
}) {
  const selected = useSelected()

  return isReadOnly ? (
    <Link color="blueDark.700" {...baseElementProps} to={url}>
      {children}
    </Link>
  ) : (
    <Popover trigger="hover" placement="top" isLazy isOpen={selected || undefined}>
      <PopoverTrigger>
        <Link color="blueDark.700" {...baseElementProps} to={url}>
          {children}
        </Link>
      </PopoverTrigger>
      <Portal>
        <PopoverContent backgroundColor="gray.50">
          <PopoverBody>
            <Link color="blueDark.700" {...baseElementProps} to={url} display="flex" gap={2} alignItems="center">
              <Flex flexShrink={1} minWidth={0}>
                <chakra.span overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                  {url}
                </chakra.span>
              </Flex>
              <Icon as={LinkExternalIcon} />
            </Link>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
