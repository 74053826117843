import type { SavedView } from "@brm/schema-types/types.js"
import { Badge, Box, Button, Divider, Flex, Heading, Icon, WrapItem } from "@chakra-ui/react"
import { Fragment } from "react"
import { useIntl } from "react-intl"
import FullTextSearch from "../../features/search/FullTextSearch.js"
import { PAGE_PADDING_X } from "../../util/constant.js"
import type { SavedViewState } from "../../util/schema-table.js"
import { UserIcon, UsersIcon } from "../icons/icons.js"

interface Props {
  /** Should already be translated */
  displayTitle: string
  savedViews?: SavedView[]
  savedViewState?: SavedViewState
  onSavedViewStateChange?: (savedViewState: SavedViewState) => void
  /** This should be a Table Filter */
  children?: React.ReactNode
}

/** Header for a data table that includes filters and saved views where the entire page is the data table */
export default function FullDataTableHeader({
  displayTitle,
  children,
  savedViews,
  savedViewState,
  onSavedViewStateChange,
}: Props) {
  const intl = useIntl()

  const onSavedViewClick = (savedView: SavedView) => {
    onSavedViewStateChange?.({ id: savedView.id, view: savedView })
  }

  return (
    <Flex flexDir="column" paddingX={PAGE_PADDING_X} gap={4} flexShrink={0}>
      <Flex py={3} gap={2} borderBottomWidth="1px">
        <FullTextSearch order={2} ml="auto" />
        <Heading as="h1" size="xs">
          {displayTitle}
        </Heading>
        <Flex gap={2} flexShrink={1} flexWrap="wrap" alignItems="center">
          {savedViews?.map((savedView) => {
            const isSelected = savedView.id === savedViewState?.id

            const colorScheme = isSelected ? "brand" : "gray"

            const savedViewButton = savedView.is_default ? (
              <Badge
                as={Button}
                size="md"
                variant="clickable"
                borderRadius="full"
                colorScheme={colorScheme}
                leftIcon={<Icon as={UserIcon} />}
                onClick={() => onSavedViewClick(savedView)}
              >
                {intl.formatMessage({
                  defaultMessage: "My View",
                  description: "Text for a saved view showing what the user has saved in their view of the table",
                  id: "dataTable.defaultView.name",
                })}
              </Badge>
            ) : (
              <Badge
                as={Button}
                size="md"
                variant="clickable"
                borderRadius="full"
                colorScheme={colorScheme}
                leftIcon={<Icon as={savedView.is_shared ? UsersIcon : UserIcon} />}
                onClick={() => onSavedViewClick(savedView)}
              >
                {savedView.name}
              </Badge>
            )

            return (
              <Fragment key={savedView.id}>
                <WrapItem>{savedViewButton}</WrapItem>
                {savedView.is_default && savedViews.length > 1 && <Divider orientation="vertical" height="1.5rem" />}
              </Fragment>
            )
          })}
        </Flex>
      </Flex>
      <Box flexShrink={1}>{children}</Box>
    </Flex>
  )
}
