import type { AgreementEffectiveStatus } from "@brm/schema-types/types.js"
import { unreachable } from "@brm/util/unreachable.js"
import { Badge, Icon } from "@chakra-ui/react"
import { CheckIcon, HourglassIcon, UpcomingIcon } from "../../components/icons/icons.js"
import { displayLegalAgreementStatus, legalAgreementStatusColors } from "./helpers.js"

interface Props {
  status: AgreementEffectiveStatus | null
}

function StatusIcon({ status }: { status: AgreementEffectiveStatus }) {
  switch (status) {
    case "active":
      return <Icon as={CheckIcon} />
    case "expired":
      return <Icon as={HourglassIcon} />
    case "upcoming":
      return <Icon as={UpcomingIcon} />
    case "unknown":
      return null
    default:
      return unreachable(status)
  }
}
export default function LegalAgreementStatusBadge({ status }: Props) {
  if (!status || status === "unknown") return null
  return (
    <Badge
      variant="subtleOutlined"
      colorScheme={legalAgreementStatusColors(status)}
      size="sm"
      fontSize="xs"
      display="flex"
      gap={1}
    >
      {displayLegalAgreementStatus(status)}
      <StatusIcon status={status} />
    </Badge>
  )
}
