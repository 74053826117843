import type { DatePaginationParams, TimelineParams } from "@brm/schema-types/types.js"
import { Box, Flex, Grid, Stack } from "@chakra-ui/react"
import { Temporal } from "@js-temporal/polyfill"
import { skipToken } from "@reduxjs/toolkit/query"
import { useEffect, useRef, useState, type FunctionComponent, type ReactNode } from "react"
import useInfiniteScroll from "react-infinite-scroll-hook"
import { FormattedMessage } from "react-intl"
import type { GetTimelineV1EventsApiArg } from "../../app/services/generated-api.js"
import { useGetTimelineV1EventsQuery } from "../../app/services/generated-api.js"
import Spinner from "../../components/spinner.js"
import { TimelineEventListItem } from "../../components/Timeline/TimelineEventListItem.js"
import type { StreamingController, StreamingData } from "../../util/hooks/streaming.js"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps } from "../workflows/run/utils.js"
import { TIMELINE_DEFAULT_LIMIT, timelineGridGapPx, timelineIconsWidthPx } from "./constants.js"

// Schema returns page params in snake_case, but rtk-query-codegen accepts only camelCase
const formatPageParams = (timelineParams: TimelineParams): GetTimelineV1EventsApiArg => {
  const { tool_id, vendor_id, workflow_run_id, ...params } = timelineParams
  return { ...params, toolId: tool_id, vendorId: vendor_id, workflowRunId: workflow_run_id }
}

export interface TimelineProps {
  filterParams?: Pick<GetTimelineV1EventsApiArg, "toolId" | "vendorId" | "workflowRunId">
  /** TimelineAlertItems that are pinned to the top of the timeline */
  alerts?: ReactNode
  streaming?: StreamingData
  streamingController?: StreamingController
}

export const Timeline: FunctionComponent<TimelineProps & GetOrganizationActorProps & GetLogoForOrganizationProps> = ({
  getOrganizationActorWhenActorMissing,
  getLogoToShowByOrganizationId,
  filterParams = {},
  alerts,
  streaming,
  streamingController,
}) => {
  const [initialInstant] = useState(() => Temporal.Now.instant())
  // Initially start with only two pages, then append more params to load more pages.
  const [paginationParamsForEachPage, setPaginationParamsForEachPage] = useState<DatePaginationParams[]>([
    // Query that gets new events until the next polling interval
    { since: initialInstant.toString() },
    // Gets first historical page
    { until: initialInstant.toString() },
  ])

  // Also subscribe here to only the query for the last page, in addition to the <TimelineEvents> component, to read the nextPageParams.
  const lastPageQuery = useGetTimelineV1EventsQuery({
    limit: TIMELINE_DEFAULT_LIMIT,
    ...filterParams,
    ...paginationParamsForEachPage.at(-1),
  })
  const firstPageQuery = useGetTimelineV1EventsQuery({
    limit: TIMELINE_DEFAULT_LIMIT,
    ...filterParams,
    ...paginationParamsForEachPage.at(0),
  })

  // Preload next page (but don't show)
  useGetTimelineV1EventsQuery(
    lastPageQuery.data?.nextPageParams ? formatPageParams(lastPageQuery.data.nextPageParams) : skipToken
  )

  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const [sentryRef, { rootRef: infiniteScrollRootRefCallback }] = useInfiniteScroll({
    loading: lastPageQuery.isFetching,
    hasNextPage: !!lastPageQuery.data?.nextPageParams,
    disabled: lastPageQuery.isError,
    onLoadMore: () => {
      setPaginationParamsForEachPage((params) =>
        lastPageQuery.data?.nextPageParams ? [...params, formatPageParams(lastPageQuery.data.nextPageParams)] : params
      )
    },
    rootMargin: "0px 0px 800px 0px",
  })
  useEffect(() => {
    infiniteScrollRootRefCallback(scrollContainerRef.current)
  }, [infiniteScrollRootRefCallback])

  // If the first page has a previousPageParams, we need to add a new page with previousPageParams.since and the until as the current time with the offset
  useEffect(() => {
    if (firstPageQuery.data?.previousPageParams?.since) {
      const newSince = firstPageQuery.data.previousPageParams.since

      // When we have a new page of events, we need to set the since and until values so that they fetch a consistent set of events.
      setPaginationParamsForEachPage((params) => [{ since: newSince }, ...params])
    }
  }, [firstPageQuery.data])

  if (lastPageQuery.data?.items.length === 0 && paginationParamsForEachPage.length === 1) {
    return (
      <Flex height="100px" justifyContent="center" alignItems="center" color="gray.500">
        <FormattedMessage
          defaultMessage="No request activity yet"
          description="Empty state for timeline"
          id="timeline.emptyState"
        />
      </Flex>
    )
  }

  return (
    <Stack pr={2} ref={scrollContainerRef} minHeight={0} flexShrink={1} fontSize="sm" overflowY="auto">
      <Grid
        as="ul"
        templateColumns={`[icons] ${timelineIconsWidthPx}px [text] minmax(0, 1fr) [integration] auto`}
        columnGap={`${timelineGridGapPx}px`}
      >
        {alerts}
        {firstPageQuery.isLoading && !lastPageQuery.isLoading && (
          <Flex justifyContent="center" gridColumn="span 3">
            <Spinner size="md" />
          </Flex>
        )}
        {paginationParamsForEachPage.map((params) => (
          <TimelineEvents
            key={`${params.since}${params.until ? `-${params.until}` : ""}`}
            params={{ ...params, ...filterParams, limit: TIMELINE_DEFAULT_LIMIT }}
            getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
            getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
            streaming={streaming}
            streamingController={streamingController}
          />
        ))}
        {lastPageQuery.isFetching && (
          <Flex justifyContent="center" gridColumn="span 3">
            <Spinner size="md" />
          </Flex>
        )}
      </Grid>
      {/* This box acts as the trigger for the intersection observer */}
      <Box position="relative" bottom="30rem" ref={sentryRef} />
    </Stack>
  )
}

const TimelineEvents: FunctionComponent<
  {
    /** The params to request this page of the timeline. */
    params: GetTimelineV1EventsApiArg
    streaming?: StreamingData
    streamingController?: StreamingController
  } & GetOrganizationActorProps &
    GetLogoForOrganizationProps
> = ({
  params,
  getOrganizationActorWhenActorMissing,
  getLogoToShowByOrganizationId,
  streaming,
  streamingController,
}) => {
  const { data } = useGetTimelineV1EventsQuery(params)
  const items = data?.items
  return items?.map((event, index) => (
    <TimelineEventListItem
      key={`${event.id}-${event.updated_at}`}
      event={event}
      nextEvent={items[index + 1]}
      enableDeeplinking={false}
      showFieldName={true}
      getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
      getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
      streaming={streaming}
      streamingController={streamingController}
    />
  ))
}
