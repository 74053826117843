import type {
  DateTimeString,
  DocumentWithExtraction,
  EmailString,
  ImageAsset,
  InvitedSellerUser,
  TimelineEvent,
  WorkflowRunStatePatch,
  WorkflowRunStatePatch1,
} from "@brm/schema-types/types.js"
import { setOfLinkStatusesInWhichSellerCantEdit } from "@brm/type-helpers/workflow.js"
import { dereferenceSchema } from "@brm/util/schema.js"
import { Button, Divider, Flex, HStack, Heading, Spacer, Text, useToast } from "@chakra-ui/react"
import type { JSONSchemaObject } from "@json-schema-tools/meta-schema"
import { useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams, useSearchParams } from "react-router-dom"
import type { ReadonlyDeep } from "type-fest"
import { isString } from "typed-assert"
import {
  useGetUserV1WhoamiQuery,
  usePostWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepIdMutation,
} from "../../../../app/services/generated-api.js"
import { CollapsibleTimelineWithInput } from "../../../timeline/CollapsibleTimelineWithInput.js"
import type { GetLogoForOrganizationProps, GetOrganizationActorProps, WorkflowRunWithExternalFlag } from "../utils.js"
import { WorkflowRunStepStatusBadge } from "./status/WorkflowRunStepStatusBadge.js"
import { WorkflowRunStepForm } from "./WorkflowRunStepForm.js"
import { WorkflowRunStepProgressBar } from "./WorkflowRunStepStepsCompletedBar.js"

const formId = "brm-link-workflow-run-step-form"

interface SellerWithName {
  first_name: string
  last_name: string
  profile_image?: null | ImageAsset
  email: EmailString
  is_pending: boolean
  invited_at: DateTimeString
  opened_at: DateTimeString
}

function isSellerWithName(seller: InvitedSellerUser): seller is SellerWithName {
  if (seller.first_name && seller.last_name) {
    return true
  }

  return false
}

/**
 * The form that is presented to the owner of a workflow step to fill out.
 * Usually the owner will arrive from a link in a notification email.
 */
export const BrmLinkWorkflowRunStepForm: React.FunctionComponent<
  {
    workflowRun: WorkflowRunWithExternalFlag
    stepTimelineEvents: Array<TimelineEvent>
    document?: DocumentWithExtraction
    setDocument: (document: DocumentWithExtraction | undefined) => void
  } & GetOrganizationActorProps &
    GetLogoForOrganizationProps
> = ({
  getOrganizationActorWhenActorMissing,
  getLogoToShowByOrganizationId,
  workflowRun,
  stepTimelineEvents,
  document,
  setDocument,
}) => {
  const { data: whoami } = useGetUserV1WhoamiQuery()
  const { code } = useParams<{ code: string }>()
  const [searchParams] = useSearchParams()
  const stepId = searchParams.get("step")
  isString(code, "Missing link auth code param")
  isString(stepId, "Missing workflow run step ID param")

  const intl = useIntl()
  const toast = useToast()

  const [submitState, { isLoading: isSubmitting }] =
    usePostWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepIdMutation()
  const [updateDraftState] = usePostWorkflowV1LinksByCodeWorkflowRunStepStatesAndWorkflowRunStepIdMutation()

  const contextSchema = useMemo(
    () => dereferenceSchema(workflowRun.context_schema as ReadonlyDeep<JSONSchemaObject>),
    [workflowRun.context_schema]
  )

  const saveDraft = async (state: WorkflowRunStatePatch) => {
    await updateDraftState({
      code,
      workflowRunStepId: stepId,
      workflowRunStepDraftStateUpdateInput: {
        state,
        submit: false,
      },
    })
  }

  const workflowRunStep = workflowRun.steps.find((step) => step.id === stepId)

  if (!workflowRunStep) {
    return null
  }

  const onValidSubmit = async (state: WorkflowRunStatePatch1) => {
    try {
      await submitState({
        workflowRunStepId: stepId,
        code,
        workflowRunStepDraftStateUpdateInput: {
          submit: true,
          state,
        },
      }).unwrap()
      toast({
        description: intl.formatMessage({
          id: "request.link.submit.success",
          description: "link submit success toast title",
          defaultMessage: "Successfully submitted!",
        }),
        status: "success",
      })
    } catch {
      toast({
        description: intl.formatMessage({
          id: "request.link.submit.error",
          description: "link submit error toast title",
          defaultMessage: "There was an error while submitting this form",
        }),
        status: "error",
      })
    }
  }

  const resetForm = async () => {
    await updateDraftState({
      workflowRunStepId: stepId,
      code,
      workflowRunStepDraftStateUpdateInput: {
        submit: false,
        state: null,
      },
    })
  }

  const isReadOnly = setOfLinkStatusesInWhichSellerCantEdit.has(workflowRunStep.status)
  const sellerUserWithName = workflowRun.seller_users.find(isSellerWithName)

  return (
    <Flex flexDirection="column" height="100%" minWidth="50%" flexBasis="50%" flexGrow={1} borderLeftWidth={1}>
      {/* Form area */}
      <WorkflowRunStepForm
        document={document}
        setDocument={setDocument}
        workflowRunStep={workflowRunStep}
        workflowRun={workflowRun}
        headerComponent={
          <Flex px={6} py={4} borderBottomWidth={1}>
            <Flex flexDirection="column" flexGrow={1}>
              <Flex gap={2} pr={2} alignItems="center">
                <Flex direction="column">
                  <HStack>
                    <Heading as="h2" size="xs" fontSize="xl">
                      {workflowRunStep.display_name}
                    </Heading>
                    <WorkflowRunStepStatusBadge step={workflowRunStep} />
                  </HStack>
                  <Text color="gray.600">
                    {sellerUserWithName ? (
                      <FormattedMessage
                        defaultMessage="Assigned to {firstName} {lastName}, Collaborating with {collaboratorFirstName} {collaboratorLastName}"
                        description="The subheading of the workflow step form"
                        id="requests.run.stage.form.heading.intake"
                        values={{
                          firstName: workflowRunStep.owner.first_name,
                          lastName: workflowRunStep.owner.last_name,
                          collaboratorFirstName: sellerUserWithName.first_name,
                          collaboratorLastName: sellerUserWithName.last_name,
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="Assigned to {firstName} {lastName}"
                        description="The subheading of the workflow step form"
                        id="requests.run.stage.form.heading.intake.noCollaborator"
                        values={{
                          firstName: workflowRunStep.owner.first_name,
                          lastName: workflowRunStep.owner.last_name,
                        }}
                      />
                    )}
                  </Text>
                </Flex>
                <Spacer />
                {workflowRunStep.field_counts.total > 0 && (
                  <WorkflowRunStepProgressBar
                    stepStatus={workflowRunStep.status}
                    fieldCounts={workflowRunStep.field_counts}
                  />
                )}
              </Flex>
              {whoami && (
                <>
                  <Divider my={2} />
                  <CollapsibleTimelineWithInput
                    fieldTimelineProps={{
                      timelineEvents: stepTimelineEvents,
                    }}
                    timelineCommentInputProps={{
                      disableReplyTasks: true,
                      objectType: "WorkflowRunStep",
                      objectId: workflowRunStep.id,
                      label: workflowRunStep.display_name,
                      workflowRunId: workflowRun.id,
                      showPrivacyControls: true,
                    }}
                    getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
                    getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
                  />
                </>
              )}
            </Flex>
          </Flex>
        }
        isReadOnly={isReadOnly}
        onValidSubmit={onValidSubmit}
        saveDraft={saveDraft}
        onReset={resetForm}
        id={formId}
        contextSchema={contextSchema}
        p={2}
        flexShrink={1}
        flexGrow={2}
        minHeight={0}
        minWidth="60%"
        overflowY="auto"
        submitButtons={
          <Flex gap={2} justifyContent="flex-end" mt={4}>
            <Button type="reset" form={formId} isDisabled={isReadOnly || isSubmitting}>
              <FormattedMessage
                defaultMessage="Reset"
                description="Button label to reset the BRM link form"
                id="requests.run.stage.form.reset"
              />
            </Button>
            <Button
              type="submit"
              form={formId}
              colorScheme="brand"
              isLoading={isSubmitting}
              // Disable submission if the seller has already submitted
              isDisabled={isReadOnly || workflowRunStep.seller_submitted_at !== null}
            >
              <FormattedMessage
                defaultMessage="Submit"
                description="Button label to submit the BRM link form"
                id="requests.run.stage.form.submit"
              />
            </Button>
          </Flex>
        }
        getOrganizationActorWhenActorMissing={getOrganizationActorWhenActorMissing}
        getLogoToShowByOrganizationId={getLogoToShowByOrganizationId}
      />
    </Flex>
  )
}
