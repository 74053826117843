import type { WorkflowConditionGroupInput } from "@brm/schema-types/types.js"
import { Select } from "chakra-react-select"

interface ConditionalToggleProps {
  conditionalToggle: null | "conditional"
  setConditionalToggle: (value: null | "conditional") => void
  onChangeConditional: (conditionalSchema: WorkflowConditionGroupInput | null) => void
  labels: {
    conditional: string
    always: string
  }
}

export default function ConditionalToggle({
  conditionalToggle,
  setConditionalToggle,
  onChangeConditional,
  labels,
}: ConditionalToggleProps) {
  return (
    <Select<{ value: null | "conditional" }>
      variant="unstyled"
      value={{ value: conditionalToggle }}
      onChange={(option) => {
        if (option?.value === null) {
          onChangeConditional(null)
        }
        setConditionalToggle(option?.value ?? null)
      }}
      isSearchable={false}
      options={[{ value: null }, { value: "conditional" }]}
      formatOptionLabel={({ value }) => {
        return value === "conditional" ? labels.conditional : labels.always
      }}
      chakraStyles={{
        menu: (base) => ({
          // To make sure that the menu width is always the size of the largest option even if the width of the select itself is smaller
          ...base,
          width: "max-content",
          minWidth: "100%",
        }),
      }}
    />
  )
}
