import { formatFieldPathLabel } from "@brm/schema-helpers/format.js"
import type { FilterField, ObjectType } from "@brm/schema-types/types.js"
import { Button, ButtonGroup, HStack, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react"
import type { JSONSchema } from "@json-schema-tools/meta-schema"
import { useIntl } from "react-intl"
import type { ReadonlyDeep } from "type-fest"
import CancelIconButton from "../../icons/system/CancelIconButton.js"
import AppliedFilterValue from "./AppliedFilterValue.js"
import FilterConfigPopoverBody from "./FilterConfigPopoverBody.js"
import type { FilterProps } from "./FilterTypes/types.js"
import { formatComparator } from "./constants.js"

export interface FilterMenuProps extends FilterProps {
  displayPath: readonly string[]
  objectSchema: ReadonlyDeep<JSONSchema>
  filter: FilterField
  icon: JSX.Element
  pickableFilters?: Partial<Record<ObjectType, object>>
  onRemove: () => void
}

export default function AppliedFilter({
  displayPath,
  fieldSchema,
  isNullable,
  objectSchema,
  filter,
  icon,
  onChange,
  onRemove,
  pickableFilters,
}: FilterMenuProps) {
  const intl = useIntl()

  const pathStr = displayPath.join(".")
  const formattedPath = formatFieldPathLabel(displayPath, objectSchema)
  return (
    <Menu key={pathStr} defaultIsOpen={false} isLazy>
      <ButtonGroup background="white" isAttached>
        <MenuButton as={Button} variant="outline">
          <HStack fontSize="sm">
            {icon}
            <Text fontWeight="medium">{formattedPath}</Text>
            <Text fontWeight="light">{formatComparator(filter, fieldSchema, intl)}</Text>
            <AppliedFilterValue fieldSchema={fieldSchema} filter={filter} />
          </HStack>
        </MenuButton>
        <CancelIconButton
          variant="outline"
          label={intl.formatMessage(
            {
              id: "filterColumnMenu.filter.remove",
              description: "The label for the button that removes an applied filter",
              defaultMessage: "Remove {filterName} filter",
            },
            { filterName: formattedPath }
          )}
          onClick={onRemove}
        />
      </ButtonGroup>
      <MenuList>
        <FilterConfigPopoverBody
          pickableFilters={pickableFilters}
          displayPath={displayPath}
          fieldSchema={fieldSchema}
          isNullable={isNullable}
          filter={filter}
          onChange={onChange}
        />
      </MenuList>
    </Menu>
  )
}
